import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brand: [],
  error: null,
};

export const deviceBrandLookupSlice = createSlice({
  name: "getDeviceBrand",
  initialState,
  reducers: {
    getDeviceBrandData: (state, action) => {
      ;
      if (action.payload.type === "BRAND") {
        
        state.brand = action.payload;
      } else if (action.payload.type === "error") {
        state.error = action.payload;
      }
    },
  },
});

export const { getDeviceBrandData } = deviceBrandLookupSlice.actions;
