import { createSlice } from "@reduxjs/toolkit";
import { getLookupCodesListAPI } from "../../actions/lookupCodes/getLookupCodesList";

interface LookupCodesListState {
  getLookupCodes: any[];
  loading: boolean;
  error: { code: string; message: string };
}

const initialState: LookupCodesListState = {
  getLookupCodes: [],
  loading: false,
  error: { code: "", message: "" },
};

export const getLookupCodesSlice = createSlice({
  name: "getLookupCodes",
  initialState,
  reducers: {
    resetGetLookupCodes: (state) => {
      state.getLookupCodes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLookupCodesListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getLookupCodesListAPI.fulfilled, (state, action) => {
        state.getLookupCodes = action.payload.lookupCodes.data;
        state.loading = false;
      })
      .addCase(getLookupCodesListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetLookupCodes } = getLookupCodesSlice.actions;
export default getLookupCodesSlice.reducer;
