import { createSlice } from "@reduxjs/toolkit";
import { getStationDeviceConnectorsListAPI } from "../../actions/stations/getStationDeviceConnectors";

const initialState = {
  stationDeviceConnectors: [],
  loading: false,
  error: null,
};

export const stationDeviceConnectorsListSlice = createSlice({
  name: "getStationDeviceConnectors",
  initialState,
  reducers: {
    deleteStationDeviceConnectors: (state) => {
      state.stationDeviceConnectors = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStationDeviceConnectorsListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStationDeviceConnectorsListAPI.fulfilled, (state, action) => {
        state.stationDeviceConnectors = action.payload.users.data;
        state.loading = false;
      })
      .addCase(getStationDeviceConnectorsListAPI.rejected, (state, action) => {
        state.stationDeviceConnectors = action.payload.error;
        state.loading = false;
      });
  },
});
export const { deleteStationDeviceConnectors } =
  stationDeviceConnectorsListSlice.actions;
export default stationDeviceConnectorsListSlice.reducer;
