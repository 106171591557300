import axios from "axios";
import { looupStatesDropdownSlice } from "../../slice/stations/getLookupStatesLitSlice";
import apiHeader from "../../../Components/ApiHeader";

const { actions } = looupStatesDropdownSlice;

export const lookUpStatesDropdownAPI: any =
  ({ ApiUrl, Type, params }: any) =>
  (dispatch: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    axios
      .get(ApiUrl, {
        params: params,
        // headers: {
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
      })
      .then((response) => {
        const { data } = response;
        dispatch(actions.getStatesDropData({ type: Type, data }));
      })
      .catch((err) => {
        dispatch(
          actions.getStatesDropData({ type: "error", paylod: err.message })
        );
      });
  };
