import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  model: [],
  error: null,
};

export const deviceModelLookupSlice = createSlice({
  name: "getDeviceModel",
  initialState,
  reducers: {
    getDeviceModelData: (state, action) => {
      if (action.payload.type === "MODEL") {
        state.model = action.payload;
      } else if (action.payload.type === "error") {
        state.error = action.payload;
      }
    },
  },
});

export const { getDeviceModelData } = deviceModelLookupSlice.actions;
