import Layout from "../Main/Layout";
import Login from "../Pages/CommonPages/Login";
import { Route } from "../Types/Route";
import AuthorisedRoutes from "./AuthorisedRoutes";
import { CommonPaths } from "./Paths";
import { useRoutes } from "react-router-dom";
import Home from "../Pages/Home";
import EndUsers from "../Pages/CommonPages/endUsers/EndUsers";
import ChargingNetworks from "../Pages/CommonPages/networks/ChargingNetworks";
import StationEmenities from "../Pages/CommonPages/stationEmenities/StationEmenities";
import ChargingDevice from "../Pages/CommonPages/chargingDevice/ChargingDevice";
import AddStationEmenities from "../Pages/CommonPages/stationEmenities/AddStationEmenities";
import AddChargingDevices from "../Pages/CommonPages/chargingDevice/AddChargingDevice";
import AddChargingNetworks from "../Pages/CommonPages/networks/AddChargingNetworks";
import Stations from "../Pages/CommonPages/stations/Stations";
import AddStations from "../Pages/CommonPages/stations/AddStations";
import ChargerConnectorType from "../Pages/CommonPages/chargerConnectorType/ChargerConnectorType";
import AddChargerConnectorType from "../Pages/CommonPages/chargerConnectorType/AddChargerConnectorType";
import ChargerBookings from "../Pages/CommonPages/chargerBookings/ChargerBookings";
import AddChargerBookings from "../Pages/CommonPages/chargerBookings/AddChargerBookings";
import Vehicles from "../Pages/CommonPages/Vehicles";
import LookupTypes from "../Pages/CommonPages/lookupTypes/LookupTypes";
import LookupCodes from "../Pages/CommonPages/lookupCodes/LookupCodes";
import TransactionReport from "../Pages/CommonPages/Reports/TransactionReport";
import VehicleChargedReport from "../Pages/CommonPages/Reports/VehicleChargedReport";
import UtilizationReport from "../Pages/CommonPages/Reports/UtilizationReport";
import FinanceReport from "../Pages/CommonPages/Reports/FinanceReport";
import ChargingSessions from "../Pages/CommonPages/chargingSessions/ChargingSessions";
import AddChargingSession from "../Pages/CommonPages/chargingSessions/AddChargingSession";
import AdminUsers from "../Pages/CommonPages/adminUsers/AdminUsers";
import AddAdminUsers from "../Pages/CommonPages/adminUsers/AddAdminUsers";
import Banners from "../Pages/CommonPages/banners/Banners";
import AddBanners from "../Pages/CommonPages/banners/AddBanners";
import NetworkErrorPage from "../Components/NetworkErrorPage";
import Categories from "../Pages/CommonPages/categories/Categories";
import Products from "../Pages/CommonPages/products/Products";
import AddProducts from "../Pages/CommonPages/products/AddProducts";
import ConfigSettings from "../Pages/CommonPages/settings/ConfigSettings";
import Wallet from "../Pages/CommonPages/wallet/Wallet";
// import Categories from "../Pages/CommonPages/categories/Categories";

const { LOGIN_PATH, HOME, VEHICLES, CHARGING_NETWORKS,
    ADMIN_USERS, ADD_ADMIN_USERS, END_USERS, STATIONS, STATION_EMINITIES, CHARGING_DEVICE, ADD_STATIONS,
    ADD_STATION_EMINITIES, ADD_CHARGING_DEVICE, ADD_CHARGING_NETWORKS, CHARGER_CONNECTOR_TYPE,
    ADD_CHARGER_CONNECTOR_TYPE, BANNERS, CHARGER_BOOKINGS, ADD_CHARGER_BOOKINGS,
    LOOKUP_TYPES, LOOKUP_CODES, REPORTS_TRANSACTIONS, REPORTS_VEHICLES_CHARGED,
    REPORTS_UTILIZATION, REPORTS_FINANCE, CHARGING_SESSIONS, ADD_CHARGING_SESSION, ADD_BANNERS, CATEGORIES,
    PRODUCTS, ADD_PRODUCTS, CONFIGSETTINGS, WALLET } = CommonPaths;

const AppRoutes = () => {

    const adminPrivateRoutes: Route[] = [
        {
            element: <Home />,
            path: HOME
        },
        {
            element: <Home />,
            path: "/"
        },
        {
            element: <Vehicles />,
            path: VEHICLES
        },
        {
            element: <ChargingNetworks />,
            path: CHARGING_NETWORKS
        },
        {
            element: <AddChargingNetworks />,
            path: ADD_CHARGING_NETWORKS
        },
        {
            element: <AdminUsers />,
            path: ADMIN_USERS
        },
        {
            element: <AddAdminUsers />,
            path: ADD_ADMIN_USERS
        },
        {
            element: <EndUsers />,
            path: END_USERS
        },
        {
            element: <Stations />,
            path: STATIONS
        },
        {
            element: <AddStations />,
            path: ADD_STATIONS
        },
        {
            element: <StationEmenities />,
            path: STATION_EMINITIES
        },
        {
            element: <AddStationEmenities />,
            path: ADD_STATION_EMINITIES
        },
        {
            element: <ChargingDevice />,
            path: CHARGING_DEVICE
        },
        {
            element: <AddChargingDevices />,
            path: ADD_CHARGING_DEVICE
        },
        {
            element: <ChargerConnectorType />,
            path: CHARGER_CONNECTOR_TYPE
        },
        {
            element: <AddChargerConnectorType />,
            path: ADD_CHARGER_CONNECTOR_TYPE
        },
        {
            element: <LookupTypes />,
            path: LOOKUP_TYPES
        },
        {
            element: <LookupCodes />,
            path: LOOKUP_CODES
        },
        {
            element: <Categories />,
            path: CATEGORIES
        },
        {
            element: <Products />,
            path: PRODUCTS
        },
        {
            element: <AddProducts />,
            path: ADD_PRODUCTS
        },
        {
            element: <ChargerBookings />,
            path: CHARGER_BOOKINGS
        },
        {
            element: <AddChargerBookings />,
            path: ADD_CHARGER_BOOKINGS
        },
        {
            element: <TransactionReport />,
            path: REPORTS_TRANSACTIONS
        },
        {
            element: <VehicleChargedReport />,
            path: REPORTS_VEHICLES_CHARGED
        },
        {
            element: <UtilizationReport />,
            path: REPORTS_UTILIZATION
        },
        {
            element: <FinanceReport />,
            path: REPORTS_FINANCE
        },
        {
            element: <ChargingSessions />,
            path: CHARGING_SESSIONS
        },
        {
            element: <AddChargingSession />,
            path: ADD_CHARGING_SESSION
        },
        {
            element: <Banners />,
            path: BANNERS
        },
        {
            element: <AddBanners />,
            path: ADD_BANNERS
        },
        {
            element: <ConfigSettings />,
            path: CONFIGSETTINGS
        },
        {
            element: <Wallet />,
            path: WALLET
        },
        {
            element: <NetworkErrorPage />,
            path: "*"
        },
    ];

    const adminRoutes: Route[] = [
        {
            element: <Layout />,
            children: [
                {
                    element: <AuthorisedRoutes />,
                    children: adminPrivateRoutes
                }
            ]
        },
        {
            element: <Login />,
            path: LOGIN_PATH
        },
    ];

    return useRoutes(adminRoutes);
};

export default AppRoutes;