import { createSlice } from "@reduxjs/toolkit";
import { addWalletSettingsAPI } from "../../actions/configSettings/addWalletSettings";

const initialState = {
  addWalletSettings: {},
  loading: false,
  error: { code: "", message: "" },
};

const addWalletSettingsSlice = createSlice({
  name: "addWalletSettings",
  initialState,
  reducers: {
    resetWalletSettings: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addWalletSettingsAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(addWalletSettingsAPI.fulfilled, (state, action) => {
        state.addWalletSettings = action.payload.data;
        state.loading = false;
      })
      .addCase(addWalletSettingsAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetWalletSettings } = addWalletSettingsSlice.actions;
export default addWalletSettingsSlice.reducer;
