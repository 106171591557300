import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chargerType: [],
  error: null,
};

export const chargerTypeLookupSlice = createSlice({
  name: "getChargerType",
  initialState,
  reducers: {
    getChargerTypeData: (state, action) => {
      ;
      if (action.payload.type === "CHARGER_TYPE") {
        state.chargerType = action.payload;
      } else if (action.payload.type === "error") {
        state.error = action.payload;
      }
    },
  },
});

export const { getChargerTypeData } = chargerTypeLookupSlice.actions;
