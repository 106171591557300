import { createSlice } from "@reduxjs/toolkit";
import { getUserByIdAPI } from "../actions/getUserById";

const initialState = {
  userById: {},
  loading: false,
  error: null,
};

const userByIdSlice = createSlice({
  name: "userById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserByIdAPI.pending, (state, action) => {
        state.userById = initialState.userById;
        state.loading = true;
      })
      .addCase(getUserByIdAPI.fulfilled, (state, action) => {
        state.userById = action.payload.user;
        state.loading = false;
      })
      .addCase(getUserByIdAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export default userByIdSlice.reducer;
