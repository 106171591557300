import { addNetworksAPI } from "../../actions/chargingNetworks/addNetworks";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addNetwork: {},
  loading: false,
  error: { code: "", message: "" },
};

const addNetworkSlice = createSlice({
  name: "addNetwork",
  initialState,
  reducers: {
    resetUserData: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNetworksAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNetworksAPI.fulfilled, (state, action) => {
        state.addNetwork = action.payload.data;
        state.loading = false;
      })
      .addCase(addNetworksAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetUserData } = addNetworkSlice.actions;
export default addNetworkSlice.reducer;
