
// const token: any = localStorage.getItem("token");
function apiHeader(token: any) {
    return {
        "access-control-allow-origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
        token: JSON.parse(token),
    };
};

export default apiHeader;