import { createAsyncThunk } from "@reduxjs/toolkit";
import { BANNERS } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const addBannersAPI: any = createAsyncThunk(
  "addBanners",
  async ({ formData, method, id }: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);

    try {
      const res = await axios({
        method: method,
        url: !!id ? BANNERS + id : BANNERS,
        data: formData,
        // headers: {
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
      });
      return res;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
