import { createSlice } from "@reduxjs/toolkit";
import { getStationEmenitiesByIdApi } from "../../actions/stationEmenities/getStationEmenityById";

const initialState = {
  userById: {},
  loading: false,
  error: null,
};

const stationEmenitieByIdSlice = createSlice({
  name: "userById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStationEmenitiesByIdApi.pending, (state, action) => {
        state.userById = initialState.userById;
        state.loading = true;
      })
      .addCase(getStationEmenitiesByIdApi.fulfilled, (state, action) => {
        state.userById = action.payload.user;
        state.loading = false;
      })
      .addCase(getStationEmenitiesByIdApi.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export default stationEmenitieByIdSlice.reducer;
