import { createSlice } from "@reduxjs/toolkit";
import { getChargingDeviceConnectorListAPI } from "../../actions/chargingDevice/getChargingDeviceConnectorList";

interface DeviceConnectorListState {
  deviceConnectors: any[];
  loading: any;
  error: string | null;
}

const initialState: DeviceConnectorListState = {
  deviceConnectors: [],
  loading: false,
  error: null,
};

export const chargingDeviceConnectorListSlice = createSlice({
  name: "getChargingDeviceConnectors",
  initialState,
  reducers: {
    resetGetChargingDeviceConnectors: (state) => {
      state.deviceConnectors = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChargingDeviceConnectorListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getChargingDeviceConnectorListAPI.fulfilled, (state, action) => {
        state.deviceConnectors = action.payload.deviceConnectors.data;
        state.loading = false;
      })
      .addCase(getChargingDeviceConnectorListAPI.rejected, (state, action) => {
        state.deviceConnectors = action.payload.error;
        state.loading = "failed";
      });
  },
});

export const { resetGetChargingDeviceConnectors } =
  chargingDeviceConnectorListSlice.actions;
export default chargingDeviceConnectorListSlice.reducer;
