import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHARGING_DEVICES } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const addChargingDevicesAPI: any = createAsyncThunk(
  "addChargingDevices",
  async ({ formData, method, id }: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios({
        method: method,
        url: !!id ? CHARGING_DEVICES + id : CHARGING_DEVICES,
        data: formData,
        headers: commonHead,
      });

      ;
      return res;
    } catch (error: any) {
      // if (error instanceof Error) {
      return rejectWithValue({
        error: { code: error.response.status, message: error.message },
      });
      // }
    }
  }
);
