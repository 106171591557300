import { createSlice } from "@reduxjs/toolkit";
import { addBannerImagesAPI } from "../../actions/banners/addBannerImages";

const initialState = {
  addBannerImages: {},
  loading: false,
  error: null,
};

const addBannerImagesSlice = createSlice({
  name: "addBannerImages",
  initialState,
  reducers: {
    resetUserData: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBannerImagesAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBannerImagesAPI.fulfilled, (state, action) => {
        state.addBannerImages = action.payload.data;
        state.loading = false;
      })
      .addCase(addBannerImagesAPI.rejected, (state, action) => {
        state.error = action.payload?.error;
        state.loading = false;
      });
  },
});

export const { resetUserData } = addBannerImagesSlice.actions;
export default addBannerImagesSlice.reducer;
