import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CommonPaths } from "../../../Routes/Paths";
import { getNetworkListAPI } from "../../../store/actions/chargingNetworks/getNetworkList";
import { deleteNetworksAPI } from "../../../store/actions/chargingNetworks/deleteChargingNetworks";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import TableHeader from "../../../Components/TableHeader";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import config from "../../../Constants/config";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import { deleteNetworkData } from "../../../store/slice/chargingNetworks/deleteNetworkSlice";

export type TableRow = {
    networkId: number,
    networkManager: string,
    networkName: string,
    phoneNumber: string,
    email: string,
};

const ChargingNetworks = () => {
    // const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [rows, setRows] = useState<number>(5);
    const [viewPopup, setViewPopup] = React.useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [associatedDeletePopup, setAssociatedDeletePopup] = useState<boolean>(false);
    const [rowId, setRowId] = useState<number>();
    const [editRowId, setEditRowId] = useState<number>();
    const [viewData, setViewData] = useState<any>({});
    const [chargeNetSkipValue, setChargeNetSkipValue] = useState<number>(0);
    const [searchVal, setSearchVal] = useState<string>('');
    // const [refreshClicked, setRefreshClicked] = useState<boolean>(false);
    const dispatch = useDispatch();
    const networksData = useSelector((state: any) => state?.getNetworks);
    // const isLoading = useSelector((state: any) => state?.getNetworks?.loading);
    const errorMessage = useSelector((state: any) => state?.getNetworks?.networks?.error);
    const associatedDeleteErrorMessage = useSelector((state: any) => state?.deleteNetwork?.deleteNetwork?.data?.error);
    // const networkLoading = useSelector((state: any) => state?.getNetworks?.networks?.loading);
    const isDeleted = useSelector((state: any) => state?.deleteNetwork?.loading);
    const navigate = useNavigate();
    // const [poorConnection, setPoorConnection] = useState<boolean>(false);
    const popup = true;
    const { ADD_CHARGING_NETWORKS } = CommonPaths;
    // useEffect(() => {
    //     function onlineHandler() {
    //         setIsOnline(true);
    //     }

    //     function offlineHandler() {
    //         setIsOnline(false);
    //     }
    //     window.addEventListener("online", onlineHandler);
    //     window.addEventListener("offline", offlineHandler);

    //     return () => {
    //         window.removeEventListener("online", onlineHandler);
    //         window.removeEventListener("offline", offlineHandler);
    //     };
    // }, []);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         isLoading ? setPoorConnection(true) : setPoorConnection(false);
    //     }, 5000);
    //     return () => clearInterval(interval);
    // }, [isLoading]);

    // useEffect(() => {
    //     if (networksData?.loading) {
    //         setTimeout(() => {
    //             networksData?.loading && setSlowNetConnection(networksData?.loading);
    //         }, 5000);
    //     };
    //     // eslint-disable-next-line
    // }, [networksData?.loading]);

    // useEffect(() => {
    //     setSlowNetConnection(false);
    // }, [networksData?.networks]);

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };

    const handleEditRow = (rowData: TableRow) => {
        setEditRowId(rowData.networkId);
        navigate(ADD_CHARGING_NETWORKS, { state: { editdata: rowData, popup: popup, id: editRowId } });
    };

    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.networkId);
    };

    const handleDeleteRow = (rowId: any) => {
        dispatch(deleteNetworksAPI(rowId));
        setDeletePopup(false);
    };

    useEffect(() => {
        !isDeleted && getNetworksList(chargeNetSkipValue, searchVal, rows);
        // eslint-disable-next-line
    }, [isDeleted, searchVal, rows]);

    const updateRefreshButton = (value: any) => {
        value && getNetworksList(chargeNetSkipValue, searchVal, rows);
    };

    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon aria-label="Eye" role="img" className="testPopUp" id="faEye" icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon className="testEdit" data-testid="faPen" icon={faPen} onClick={() => handleEditRow(rowData)} />
                <FontAwesomeIcon className="testDelete" data-testid="faTrash" icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };

    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setChargeNetSkipValue(event.page + 1);
        getNetworksList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };
    const getNetworksList = (cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
        };
        dispatch(getNetworkListAPI(params));
    };

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };
    useEffect(() => {
        associatedDeleteErrorMessage && setAssociatedDeletePopup(true);
        // eslint-disable-next-line
    }, [associatedDeleteErrorMessage]);

    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className='mx-3'>
            {
                networksData?.error?.code !== "" ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <>
                        <TableHeader heading='Network List' searchKeys="Search Network Name" label="Add Networks"  fontSize="1rem" width="12rem" addEvent={(e) => {navigate(ADD_CHARGING_NETWORKS); }} onSearch={handleSearch} />
                        <div className="card">
                            <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow' value={networksData?.networks?.rows} loading={networksData?.loading} scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                                <Column field="networkName" header="Network Name" sortable ></Column>
                                <Column field="networkManager" header="Manager" sortable ></Column>
                                <Column field="email" header="Email" sortable ></Column>
                                <Column field="phoneNumber" header="Mobile" sortable ></Column>
                                <Column frozen={true} alignFrozen="right" header="Actions" className="test" body={actionItems} ></Column>
                            </DataTable>
                            <Paginator data-testid="open-popup" first={currentPage} rows={rows} totalRecords={networksData?.networks?.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />
                            <Dialog className='viewPopup' header={`Network : ${viewData.networkName}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                                <div >
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Network Name </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6" data-testid="viewNetworkName">{viewData.networkName}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Network Manager</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6" data-testid="viewNetworkManager">{viewData.networkManager}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Email</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6" data-testid="viewEmail">{viewData.email}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Mobile No.</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6" data-testid="viewPhoneNumber">{viewData.phoneNumber}</div>
                                    </div>
                                </div>
                            </Dialog>
                            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                                <div className="delpop_btn_div">
                                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)}>Yes</Button>
                                </div>
                            </Dialog>
                            {
                                associatedDeleteErrorMessage &&
                                <Dialog className='deletePopup' header="Record is not Deleted" visible={associatedDeletePopup} style={{ width: '50vw' }} onHide={() => { setAssociatedDeletePopup(false); dispatch(deleteNetworkData()); }} >
                                    <div className="delpop_btn_div">
                                        {associatedDeleteErrorMessage}
                                    </div>
                                </Dialog>
                            }
                            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='networkList' />
                        </div>
                    </>
            }
        </div>
    );
};

export default ChargingNetworks;