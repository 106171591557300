import { createSlice } from "@reduxjs/toolkit";
import { stopChargingAPI } from "../../actions/chargingSessions/stopCharging";

const initialState = {
  stopChargerBookings: {},
  loading: false,
  error: null,
};

const stopChargingSlice = createSlice({
  name: "stopCharging",
  initialState,
  reducers: {
    resetUserData: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(stopChargingAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(stopChargingAPI.fulfilled, (state, action) => {
        state.stopChargerBookings = action.payload.data;
        state.loading = false;
      })
      .addCase(stopChargingAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetUserData } = stopChargingSlice.actions;
export default stopChargingSlice.reducer;
