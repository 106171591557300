import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";

type TableHeaderProps = {
    heading: string;
    label?: string;
    addEvent?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onSearch?: (val: string) => void;
    dateRangePicker?: JSX.Element;
    selectLocation?: JSX.Element;
    searchKeys?: string;
    searchItem?: string;
    fontSize?: string;
    width?: string;
};

const TableHeader = ({
    heading,
    searchKeys,
    addEvent,
    label,
    fontSize,
    onSearch,
    searchItem,
    dateRangePicker,
    selectLocation,
    width
}: TableHeaderProps) => {

    const handleSearchData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        if (onSearch) {
            onSearch(searchValue);
        }
    };

    return (
        <div className='tableHeader pt-2 grid'>
            <div className="col-12 md:col-4">
                <h4 className="pt-2 pl-4">{heading}</h4>
            </div>
            <div className={`w-full md:w-auto tableHeader2 col-12 md:col-8 ${width ? 'flex-row' : ''}`}>
                <div className={`grid ${width ? 'flex' : ''}`}>
                    <div className={label ? "col-12 md:col-8" : "col-12 md:col-12"} style={{ flex: width ? 1 : 'none' }}>
                        <div className="w-full p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText
                                name="searchbar"
                                placeholder={searchKeys}
                                value={searchItem}
                                onChange={handleSearchData}
                                className='p-inputtext-sm w-full'
                            />
                        </div>
                        <div>
                            {dateRangePicker}
                            {selectLocation}
                        </div>
                    </div>
                    {label && (
                        <div style={{ width: width || 'auto' }} className="col-12 md:col-4 flex-grow-0 flex-shrink-0">
                            <Button
                                label={label}
                                style={{ fontSize }}
                                size='small'
                                // icon={label !== "SUBMIT" && "pi pi-plus"} 
                                className='addButton'
                                onClick={(e) => addEvent && addEvent(e)}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TableHeader;
