import { createSlice } from "@reduxjs/toolkit";
import { addLookupTypesAPI } from "../../actions/lookupTypes/addLookupTypes";

const initialState = {
  addLookupTypes: {},
  loading: false,
  error: { code: "", message: "" },
};

const addLookupTypesSlice = createSlice({
  name: "addLookupTypes",
  initialState,
  reducers: {
    // resetUserData: (state) => {
    //   state = initialState;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addLookupTypesAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLookupTypesAPI.fulfilled, (state, action) => {
        state.addLookupTypes = action.payload.data;
        state.loading = false;
      })
      .addCase(addLookupTypesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

// export const { resetUserData } = addLookupTypesSlice.actions;
export default addLookupTypesSlice.reducer;
