import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteChargingDeviceConnectorsAPI } from "../../../store/actions/chargingDevice/deleteChargingDeviceConnector";
import { getChargingDeviceConnectorListAPI } from "../../../store/actions/chargingDevice/getChargingDeviceConnectorList";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";

type TableRow = {
    chargerConnectorTypeId: string,
    chargingDeviceConnectorUid: string,
    positionOfConnector: string,
    chargingDeviceConnectorId: string,
};

const ListOfConnectors = ({ visible, setVisible, setEditData, device_Id, newDeviceId, location, chargingDeviceId }: any) => {
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>({});
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [rowId, setRowId] = useState<string>();
    const dispatch = useDispatch();
    const connectors = useSelector((state: any) => state?.getChargingDeviceConnectors?.deviceConnectors);
    const connectorsData = useSelector((state: any) => state?.getChargingDeviceConnectors);
    const errorMessage = useSelector((state: any) => state?.getChargingDeviceConnectors?.deviceConnectors?.errorMessage);
    const navigate = useNavigate();

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };

    const handleEditRow = (rowData: TableRow) => {
        setEditData(rowData);
        setVisible(true);
    };
    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.chargingDeviceConnectorId);
    };
    const handleDeleteRow = (rowId: any) => {
        setRowId((prev: any) => prev + 1);
        dispatch(deleteChargingDeviceConnectorsAPI(rowId));
        setDeletePopup(false);
        !!chargingDeviceId ? getChargingDeviceConnectorsEditList() : getChargingDeviceConnectorsAddList();
    };

    const getChargingDeviceConnectorsEditList = () => {
        let params = {
            type: "list",
            limit: 10,
            skip: 1,
            search: "",
            chargingdeviceid: chargingDeviceId,
        };
        dispatch(getChargingDeviceConnectorListAPI(params));
    };
    const getChargingDeviceConnectorsAddList = () => {
        let params = {
            type: "list",
            limit: 10,
            skip: 1,
            search: "",
            chargingdeviceid: device_Id ?? newDeviceId,
        };
        dispatch(getChargingDeviceConnectorListAPI(params));
    };
    useEffect(() => {
        !!chargingDeviceId ? getChargingDeviceConnectorsEditList() : getChargingDeviceConnectorsAddList();
        // eslint-disable-next-line
    }, [chargingDeviceId, device_Id, newDeviceId, visible]);
    const updateRefreshButton = (value: any) => {
        value && getChargingDeviceConnectorsEditList();
    };
    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon icon={faPen} onClick={() => handleEditRow(rowData)} />
                <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };
    const getErrorComponent = () => {
        let errorDiv;
        if (connectorsData?.deviceConnectors?.code) {
            if (connectorsData?.deviceConnectors?.code === 400) {
                errorDiv = <div>400 error code</div>;
            } else if (connectorsData?.deviceConnectors?.code === 500) {
                errorDiv = <div>500 error code</div>;
            } else if (connectorsData?.deviceConnectors?.code === 404) {
                errorDiv = <NetworkErrorPage updateRefreshButton={updateRefreshButton} />;
            }
        }
        return errorDiv;
    };
    return (
        <>
            {
                connectorsData?.loading === "failed" ?
                    getErrorComponent() :
                    <DataTable className='tableHeadingRow' scrollable value={connectors.rows} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="chargingDeviceName" header="Device Name"></Column>
                        <Column field="chargerTypeName" header="Connector Type"></Column>
                        {/* <Column field="chargingDeviceConnectorUid" header="Connector UID" ></Column> */}
                        <Column field="positionOfConnector" header="Connector Position" ></Column>
                        <Column frozen={true} alignFrozen="right" body={actionItems} header="Actions"></Column>
                    </DataTable>
            }
            <Dialog className='viewPopup' header={`Device Connector : ${viewData.chargerTypeName}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                <div >
                    <div className="grid">
                        <div className="col-5 col-offset-1">Device Name</div>
                        <div className="col-1">:</div>
                        <div className="col-5">{viewData.chargingDeviceName}</div>
                    </div>
                    <div className="grid">
                        <div className="col-5 col-offset-1">Connector Type</div>
                        <div className="col-1">:</div>
                        <div className="col-5">{viewData.chargerTypeName}</div>
                    </div>
                    <div className="grid">
                        <div className="col-5 col-offset-1">Position of Connector</div>
                        <div className="col-1">:</div>
                        <div className="col-5">{viewData.positionOfConnector}</div>
                    </div>
                </div>
            </Dialog>
            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                <div className="delpop_btn_div">
                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)} >Yes</Button>
                </div>
            </Dialog>
            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='chargingDeviceConnectorsList' />
        </>
    );
};

export default ListOfConnectors;