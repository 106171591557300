import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stationStatus: [],
  error: null,
};

export const stationStatusesLookupSlice = createSlice({
  name: "getStationStatuses",
  initialState,
  reducers: {
    getStationStatusesData: (state, action) => {
      if (action.payload.type === "STATION_STATUSES") {
        state.stationStatus = action.payload;
      } else if (action.payload.type === "error") {
        state.error = action.payload;
      }
    },
  },
});

export const { getStationStatusesData } = stationStatusesLookupSlice.actions;
