import { BANNERIMAGES } from "../../../API/UserApi";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addBannerImagesAPI: any = createAsyncThunk(
  "addBannerImages",
  async ({ formData, method }: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    try {
      const res = await axios({
        method: method,
        url: BANNERIMAGES,
        data: formData,
        headers: {
          token: JSON.parse(token),
          "Content-Type": "multipart/form-data",
          "access-control-allow-origin": "*",
          // "Content-Type": formData.imageUrl.name,
        },
      });
      return res;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
