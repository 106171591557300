import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Paginator } from "primereact/paginator";
import { RadioButton } from "primereact/radiobutton";
import config from "../../../Constants/config";
import getConvertedDate from "../../../Utils/getConvertedDate";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import { getStationsListAPI } from "../../../store/actions/stations/getStationsList";
import { getTransactionReportListAPI } from "../../../store/actions/transactionReport/getTransactionReportList";
import { ITransactionListRows } from "../../../store/slice/transactionReport/getTransactionReportListSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { MultiSelect } from 'primereact/multiselect';

type tableHeaderProps = {
    heading?: string;
    label: string;
    addEvent: (e: any) => void;
    onSearch?: (val: string) => void;
    dateRangePicker?: any;
    selectLocation?: any;
};

interface FormValues {
    adminName: string;
    email: string;
    station: string;
    reportType: string;
}

interface Stations {
    stationName: string;
    stationId: string;
};
const TableHeaders = ({ heading, addEvent, label, onSearch, dateRangePicker, selectLocation }: tableHeaderProps) => {
    const [searchValue, setSearchValue] = useState("");
    const handleSearchData = (e: any) => {
        const seacrhValue = e.target.value;
        setSearchValue(seacrhValue);
        onSearch!(seacrhValue);
    };
    return (
        <div className='tableHeader p-2 grid'>
            <div className="col-12 md:col-3">
                <div className="w-full p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder="Search Booking Id" value={searchValue} onChange={handleSearchData} className='p-inputtext-sm w-full ' />
                </div>
            </div>
            <div className="col-12 md:col-3 ">
                {selectLocation}
            </div>
            <div className="col-8 md:col-4">
                {dateRangePicker}
            </div>
            <div className="col-4 md:col-2">
                <Button label={label} size='small' className='addButton w-full' icon={label !== "SUBMIT" && "pi pi-plus"} onClick={(e) => { addEvent(e); }} />
            </div>
        </div>
    );
};

const TransactionReport = () => {
    const [rows, setRows] = useState<number>(5);
    const [dateRange, setDateRange] = useState<any>(null);
    const [showVal, setShowVal] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [searchVal, setSearchVal] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>({});
    const [selectedStation, setSelectedStation] = useState<any>(null);
    const [chargeNetSkipValue, setChargeNetSkipValue] = useState<number>(0);
    const [openSubscription, setOpenSubscription] = useState<boolean>(false);
    const transactionData = useSelector((state: any) => state?.getTransactionReportList);
    const stationsData = useSelector((state: any) => state?.getStations?.stations?.rows);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const maxSelectableDate = new Date();
    const calendarRef: any = useRef(null);
    const toDate = dateRange?.[1] ? getConvertedDate(dateRange?.[1]) : "";
    const fromDate = dateRange?.[0] ? getConvertedDate(dateRange?.[0]) : "";
    const radioButtonGroup = [
        {
            label: "Daily",
            htmlFor: "daily",
            inputId: "dailyType",
            value: "Daily",
        },
        {
            label: "Weekly",
            htmlFor: "weeklyType",
            inputId: "weeklyType",
            value: "Weekly",
        },
        {
            label: "Monthly",
            htmlFor: "monthlyType",
            inputId: "monthlyType",
            value: "Monthly",
        }
    ];
    const stationsList: { label: string; value: string; }[] = stationsData?.map((station: Stations) => (
        {
            label: station.stationName,
            value: station.stationId,
        }
    )) || [];

    const allStationsList = [{
        label: "All Stations",
        value: '',
    }, ...stationsList];

    const handleSubmit = (values: FormValues) => {
    };

    // const onReportTypeChange = (e: any) => {
    //     let reportFormat = [...reportType];
    //     if (e.checked)
    //         reportFormat.push(e.value);
    //     else
    //         reportFormat.splice(reportFormat.indexOf(e.value), 1);
    //     console.log(reportFormat, "last reportFormat");
    //     setReportType(reportFormat);
    // };
    const validateForm = (values: FormValues) => {

        const errors: Partial<FormValues> = {};
        if (!values.adminName) {
            errors.adminName = 'Required';
        }
        if (!values.email) {
            errors.email = 'Required';
        } else {
            let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (!values.email.match(mailformat)) {
                errors.email = 'Please enter a valid email address.';
            }
        }
        if (!values.reportType) {
            errors.reportType = "Required";
        }
        if (!values.station) {
            errors.station = 'Required';
        }
        return errors;
    };
    const handleDateSelection = (type: string) => {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        if (type === 'previous') {
            const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
            const lastDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
            setDateRange([lastMonth, lastDayOfLastMonth]);
            setShowVal(false);
        } else if (type === 'current') {
            setDateRange([firstDayOfMonth, currentDate]);
            setShowVal(false);
        } else if (type === 'clear') {
            setDateRange(null);
        } else if (type === 'custom') {
            setVisible(!visible);
            setShowVal(false);
        }
    };
    const handleVisibleChange = () => {
        setVisible(!visible);
    };
    const handleViewPopup = (rowData: ITransactionListRows) => {
        setViewPopup(true);
        setViewData(rowData);
    };
    const actionItems = (rowData: ITransactionListRows) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon aria-label="Eye" role="img" className="testPopUp" id="faEye" icon={faEye} onClick={() => handleViewPopup(rowData)} />
            </div>
        );
    };
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                calendarRef.current &&
                !calendarRef.current.contains(event.target) &&
                event.target.className !== 'datarange_btn' // Check if the click is not on the button
            ) {
                setShowVal(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const getTransactionList = (cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
            transactionreports: true,
            statonId: '',
            fromDate: '',
            toDate: '',
        };
        dispatch(getTransactionReportListAPI(params));
    };

    useEffect(() => {
        getTransactionList(chargeNetSkipValue, searchVal, rows);
        dispatch(getStationsListAPI());
        // eslint-disable-next-line
    }, [searchVal]);
    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setChargeNetSkipValue(event.page + 1);
        getTransactionList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };
    const handleSearch = (val: string) => {
        setSearchVal(val);
    };
    const filteredGetTransactionList = (cp: number, searchVal: string, rows: number, fromDate: any) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
            transactionreports: true,
            statonId: selectedStation,
            fromDate: fromDate,
            toDate: toDate,
        };

        dispatch(getTransactionReportListAPI(params));
    };

    const User = (row: ITransactionListRows) => row.firstName !== null ? row.firstName + ' ' + row.lastName : "Guest";
    const getStartDate = (row: ITransactionListRows) => row.chargingStartTime.split(" ")[0];
    const getStartTime = (row: ITransactionListRows) => row.chargingStartTime.split(" ")[1];
    const getEndTime = (row: ITransactionListRows) => row.chargingEndTime.split(" ")[1];

    const viewGetStartDate = viewData?.chargingStartTime?.split(" ")[0];
    const viewGetStartTime = viewData?.chargingStartTime?.split(" ")[1];
    const viewGetEndTime = viewData?.chargingEndTime?.split(" ")[1];

    const updateRefreshButton = (value: boolean) => {
        value && getTransactionList(chargeNetSkipValue, searchVal, rows);
    };
    // console.log(transactionData, "transactionData");
    return (
        <div className='m-3'>
            <div className="d-flex justify-content-between">
                <h4>Transaction Report</h4>
                <button className="subscribe" onClick={() => setOpenSubscription(true)}>Subscribe Reports</button>
            </div>
            {
                // true ?
                transactionData?.error?.code ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <div className="card">
                        <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow'
                            header={
                                <TableHeaders label="SUBMIT"
                                    addEvent={(e: any) => { filteredGetTransactionList(chargeNetSkipValue, searchVal, rows, fromDate); }}
                                    onSearch={handleSearch}
                                    dateRangePicker={
                                        <div style={{ width: "250px" }}>
                                            <div ref={calendarRef}>
                                                <Calendar
                                                    value={dateRange}
                                                    onChange={(e: any) => { setDateRange(e.target.value); }} // setState1 --- pass state1 in apply btn
                                                    selectionMode="range"
                                                    readOnlyInput
                                                    numberOfMonths={2}
                                                    showIcon
                                                    style={{
                                                        height: "43px",
                                                    }}
                                                    dateFormat="dd/mm/yy"
                                                    maxDate={maxSelectableDate}
                                                    appendTo="self"
                                                    placeholder="Select Date Range"
                                                    onFocus={() => { setShowVal(true); }}
                                                    visible={visible}
                                                    onVisibleChange={handleVisibleChange}
                                                    showOnFocus={false}
                                                />
                                            </div>
                                            {
                                                showVal ?
                                                    <div className="datarange_btn_div">
                                                        <button className="datarange_btn" onClick={() => handleDateSelection('previous')}>Previous Month</button>
                                                        <button className="datarange_btn" onClick={() => handleDateSelection('current')}>Current Month</button>
                                                        <button className="datarange_btn" onClick={() => handleDateSelection('custom')}>Custom</button>
                                                        <button className="datarange_btn" onClick={() => handleDateSelection('clear')}>Clear</button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    }
                                    selectLocation={
                                        // <Dropdown
                                        //     value={selectedStation}
                                        //     onChange={(e: any) => { setSelectedStation(e.target.value); }}
                                        //     options={allStationsList}
                                        //     optionLabel="label"
                                        //     placeholder="All Stations"
                                        //     className="w-full md:w-14rem"
                                        //     style={{
                                        //         height: "43px"
                                        //     }}
                                        // />
                                        <MultiSelect value={selectedStation}
                                            onChange={(e: any) => setSelectedStation(e.value)}
                                            options={allStationsList}
                                            // defaultValue={allStationsList?.[0]}
                                            optionLabel="label"
                                            placeholder="Select Cities"
                                            maxSelectedLabels={2}
                                            className="w-full md:w-14rem"
                                            style={{
                                                height: "43px"
                                            }}
                                        />
                                    }
                                />
                            }
                            value={transactionData?.transactionList?.rows}
                            scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                            <Column field="sessionId" header="Session Id" sortable></Column>
                            <Column field="bookingId" header="Booking Id" sortable></Column>
                            <Column body={User} header="Customer Name" sortable></Column>
                            <Column body={getStartDate} header="Date" sortable></Column>
                            <Column field="mobileNumber" header="Mobile No" sortable></Column>
                            <Column field="email" header="Email" sortable></Column>
                            <Column body={getStartTime} header="Start Time" sortable></Column>
                            <Column body={getEndTime} header="End Time" sortable></Column>
                            <Column field="totalTime" header="Total Time" sortable></Column>
                            <Column field="totalPowerConsumption" header="Units Consumed" sortable></Column>
                            <Column field="unitPrice" header="Unit Price" sortable></Column>
                            <Column field="amountCharged" header="Amount" sortable></Column>
                            <Column field="chargingStatus" header="Charging Status" sortable></Column>
                            <Column header="Actions" body={actionItems}></Column>
                        </DataTable>
                        <Paginator first={currentPage} rows={rows} totalRecords={transactionData?.transactionList?.total_count} rowsPerPageOptions={[5, 10, 15]} onPageChange={(e) => onPageChange(e)} />
                        <Dialog className='viewPopup' header={`Transaction Report :`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                            <div >
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Booking Id </div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewData?.bookingId}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>First Name</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewData.firstName}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Last Name</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewData.lastName}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Mobile No</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewData.mobileNumber}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Email</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewData.email}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Date</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewGetStartDate}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Start Time</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewGetStartTime}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>End Time</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewGetEndTime}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Total Time</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewData.totalTime}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Power Consumption</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewData.totalPowerConsumption}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Unit Price</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewData.unitPrice}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Amount Charged</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewData.amountCharged}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Charging Status</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">{viewData.chargingStatus}</div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
            }
            <Dialog
                className='s_popup'
                header="Subscription Form"
                visible={openSubscription}
                style={{ width: '50vw' }}
                onHide={() => { setOpenSubscription(false); setSelectedStation(null); }}
            >
                <Form
                    // initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid" name='subscribeForm'>
                            <div className='w-full'>
                                <Field name="adminName">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='adminName'>Admin Name*</label>
                                            <InputText id='admin-Name' {...input} placeholder="Admin Name" autoComplete='off' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='w-full'>
                                <Field name="email">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='email'>Email*</label>
                                            <InputText id="email" role='textbox' {...input} placeholder="Email" autoComplete='off' type='email' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='w-full'>
                                <Field name="station">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='station'>Select Station*</label>
                                            <Dropdown
                                                {...input}
                                                value={selectedStation}
                                                onChange={(e: any) => { input.onChange(e.target.value); setSelectedStation(e.target.value); }}
                                                options={allStationsList}
                                                optionLabel="label"
                                                placeholder="Select Stations"
                                            />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            {/* <div className='w-full'>
                                <Field name="reportType">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='reportType'>Subscription Type*</label>
                                            <div className="flex flex-wrap justify-content-center gap-3">
                                                <div className="flex align-items-center">
                                                    <Checkbox {...input} inputId="dailyType" name="reportType" value="Daily" onChange={(e) => { input.onChange(e.target.value); onReportTypeChange(e); }} checked={reportType.includes('Daily')} />
                                                    <label htmlFor="dailyType" className="ml-2">Daily</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox {...input} inputId="weeklyType" name="reportType" value="Weekly" onChange={(e) => { input.onChange(e.target.value); onReportTypeChange(e); }} checked={reportType.includes('Weekly')} />
                                                    <label htmlFor="weeklyType" className="ml-2">Weekly</label>
                                                </div>
                                                <div className="flex align-items-center">
                                                    <Checkbox {...input} inputId="monthlyType" name="reportType" value="Monthly" onChange={(e) => { input.onChange(e.target.value); onReportTypeChange(e); }} checked={reportType.includes('Monthly')} />
                                                    <label htmlFor="monthlyType" className="ml-2">Monthly</label>
                                                </div>
                                            </div>
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div> */}
                            <div className='w-full'>
                                <Field name="reportType">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='reportType'>Subscription Type*</label>
                                            <div className="flex flex-wrap gap-3">
                                                {radioButtonGroup.map(radioButton => {
                                                    return (
                                                        <div className="flex align-items-center">
                                                            <RadioButton
                                                                {...input}
                                                                type="radio"
                                                                inputId={radioButton.inputId}
                                                                name="reportType"
                                                                value={radioButton.value}
                                                                checked={input.value === radioButton.value}
                                                                onChange={(e) => input.onChange(e.target.value)}
                                                            />
                                                            <label htmlFor={radioButton.htmlFor} className="ml-2">{radioButton.label}</label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <button
                                className={window.innerWidth < 786 ? 'col-4 submit_btn' : 'col-4 s_submit'}
                                type="submit"
                            // onSubmit={(e: any) => console.log(typeof (e), "typeof event")}
                            >
                                Submit
                            </button>
                        </form>
                    )}
                />
            </Dialog>
            <TokenHandleFunction navigate={navigate} errorMessage={transactionData?.transactionList?.errorMessage} referenceKey='transactionReportList' />
        </div >
    );
};

export default TransactionReport;