import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Field, Form } from "react-final-form";
import TableHeader from "../../../Components/TableHeader";
import config from "../../../Constants/config";
import { addLookupTypesAPI } from "../../../store/actions/lookupTypes/addLookupTypes";
import { getLookupTypesListAPI } from "../../../store/actions/lookupTypes/getLookupTypesList";
import { deleteLookupTypesAPI } from "../../../store/actions/lookupTypes/deleteLookupTypes";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import PostToaster from "../../../Components/PostToaster";

type FormValues = {
    lookupType: string;
    lookupTypeDisplayName: string;
    description: string;
};
const initialValues: FormValues = {
    lookupType: '',
    lookupTypeDisplayName: '',
    description: '',
};
export type TableRow = {
    lookupTypeId: number,
    lookupType: string,
    lookupTypeDisplayName: string,
    description: string,
};

const LookupTypes = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>({});
    const [rowId, setRowId] = useState<number>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [rows, setRows] = useState<number>(5);
    const [lookupTypeSkipValue, setLookupTypeSkipValue] = useState<number>(0);
    const [searchVal, setSearchVal] = useState<string>("");
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const lookupTypesList = useSelector((state: any) => state?.getLookupTypes);
    const lookupTypesData = useSelector((state: any) => state?.getLookupTypes);
    const errorMessage = useSelector((state: any) => state?.getLookupTypes?.getLookupTypes?.errorMessage);
    const addLookupTypeError = useSelector((state: any) => state?.addLookupTypes?.error);
    const addLookupTypeLoading = useSelector((state: any) => state?.addLookupTypes?.loading);
    const isDeleted = useSelector((state: any) => state?.deleteLookupTypes?.loading);
    const isRecordAdded = useSelector((state: any) => state?.addLookupTypes?.loading);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };

    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.lookupTypeId);
    };

    const handleDeleteRow = (rowId: any) => {
        dispatch(deleteLookupTypesAPI(rowId));
        setDeletePopup(false);
    };
    useEffect(() => {
        (!isDeleted || !isRecordAdded) && getLookupList(lookupTypeSkipValue, searchVal, rows);
        // eslint-disable-next-line
    }, [isDeleted, isRecordAdded, searchVal, rows]);

    const getLookupList = (cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
        };
        dispatch(getLookupTypesListAPI(params));
    };
    const updateRefreshButton = (value: any) => {
        value && getLookupList(lookupTypeSkipValue, searchVal, rows);
    };
    const actionItems = (rowData: any) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon className="testPopUp" icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon className="testDelete" icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };

    const handleSubmit = (values: FormValues) => {
        const newValues = { ...values, createdBy: 1, updatedBy: 1 };
        dispatch(addLookupTypesAPI({ formData: newValues, method: "POST" }));
        setSubmitClicked(true);
    };

    const validateForm = (values: FormValues) => {

        const errors: Partial<FormValues> = {};
        // Add validation logic for each field
        if (!values.lookupType) {
            errors.lookupType = 'Required';
        }
        if (!values.lookupTypeDisplayName) {
            errors.lookupTypeDisplayName = 'Required';
        }
        if (!values.description) {
            errors.description = 'Required';
        }
        return errors;
    };

    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setLookupTypeSkipValue(event.page + 1);
        getLookupList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };
    const handleSearch = (val: string) => {
        setSearchVal(val);
    };
    useEffect(() => {
        submitClicked && !addLookupTypeLoading && addLookupTypeError?.code === '' && setVisible(false);
        submitClicked && !addLookupTypeLoading && addLookupTypeError?.code !== '' && setShowErrorComponent(true);
    }, [addLookupTypeError, addLookupTypeLoading, submitClicked]);

    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className='mx-3'>
            {
                lookupTypesData?.error.code !== "" ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <>
                        <TableHeader heading='Lookup Types' searchKeys="Search Lookup Type" label="ADD" addEvent={() => { setVisible(true); }} onSearch={handleSearch} />
                        <div className="card">
                            <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow' value={lookupTypesList?.getLookupTypes?.rows} loading={lookupTypesList?.loading} scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                                <Column field="lookupType" header="Lookup Type" sortable ></Column>
                                <Column field="lookupTypeDisplayName" header="Lookup Name" sortable ></Column>
                                <Column field="description" header="Description" sortable ></Column>
                                <Column frozen={true} alignFrozen="right" header="Actions" className="test" body={actionItems} ></Column>
                            </DataTable>
                            <Paginator first={currentPage} rows={rows} totalRecords={lookupTypesList?.getLookupTypes?.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />

                            <Dialog className={window.innerWidth < 786 ? '' : "dialog_form"} header="Add Lookup Types" visible={visible} style={{ width: '50vw', height: '250px !important' }} onHide={() => setVisible(false)}>
                                <Form
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                    validate={validateForm}
                                    render={({ handleSubmit }) => (
                                        <form onSubmit={handleSubmit} className="p-fluid row">
                                            <div className='col-6 inputwidth'>
                                                <Field name="lookupType">
                                                    {({ input, meta }) => (
                                                        <div className="field">
                                                            <label>Lookup Type</label>
                                                            <InputText  {...input} placeholder="Lookup Type" />
                                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className='col-6 inputwidth'>
                                                <Field name="lookupTypeDisplayName">
                                                    {({ input, meta }) => (
                                                        <div className="field">
                                                            <label>Display Name</label>
                                                            <InputText  {...input} placeholder="Display Name" />
                                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className='col-6 inputwidth'>
                                                <Field name="description">
                                                    {({ input, meta }) => (
                                                        <div className="field">
                                                            <label>Description</label>
                                                            <InputText  {...input} placeholder="Description" />
                                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <button className={window.innerWidth < 786 ? 'col-4 submit_btn' : 'col-1 submit_btn'} type="submit" >Submit</button>
                                        </form>
                                    )}
                                />
                                <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />
                            </Dialog>

                            <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />

                            <Dialog className='viewPopup' header={`Lookup Type Details :`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                                <div >
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>LookupType </div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{viewData.lookupType}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Lookup Type Display Name</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{viewData.lookupTypeDisplayName}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Description</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{viewData.description}</div>
                                    </div>
                                </div>
                            </Dialog>

                            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                                <div className="delpop_btn_div">
                                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)}>Yes</Button>
                                </div>
                            </Dialog>
                            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='lookupTypesList' />
                        </div>
                    </>
            }
        </div>
    );
};


export default LookupTypes;