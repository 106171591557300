import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Countries: [],
  error: null,
};

export const looupCountryDropdownSlice = createSlice({
  name: "getCountries",
  initialState,
  reducers: {
    getCountryDropData: (state, action) => {
      state.Countries = action.payload;
    },
  },
});

export const { getCountryDropData } = looupCountryDropdownSlice.actions;
