import { createSlice } from "@reduxjs/toolkit";
import { addConnectors } from "../../actions/chargingDevice/addConnectors";

const initialState = {
  connectors: {},
  loading: false,
  error: null,
};

const addConnectorsSlice = createSlice({
  name: "addConnect",
  initialState,
  reducers: {
    resetUserData: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addConnectors.pending, (state) => {
        state.loading = true;
      })
      .addCase(addConnectors.fulfilled, (state, action) => {
        state.connectors = action.payload.data;
        state.loading = false;
      })
      .addCase(addConnectors.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetUserData } = addConnectorsSlice.actions;
export default addConnectorsSlice.reducer;
