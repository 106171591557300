
import { Sidebar } from 'primereact/sidebar';
import { useEffect, useState } from 'react';
import { SidebarData } from './SidebarData';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';


type MenubarProps = {
    showSidebar: boolean;
    hideSidebar: (val: boolean) => void;
};


const SidebarOverlay = styled.div`
.p-sidebar-mask {
    position: ${window.innerWidth > 764 ? "relative !important;" : "fixed"}
}`;


const Menubar = ({ showSidebar, hideSidebar }: MenubarProps) => {

    const location = useLocation();
    const [dismissible, setDismissible] = useState<boolean>(false);
    const [showSubNav, setShowSubNav] = useState<boolean>(false);

    useEffect(() => {
        setDismissible(!showSidebar);
    }, [showSidebar]);
    const handleSubNav = (item: any, index: number) => {
        item.subNav && setShowSubNav(!showSubNav);
        item.title !== "Reports" ? setShowSubNav(false) : setShowSubNav(true);
    };

    return (
        <SidebarOverlay>
            <Sidebar dismissable={window.innerWidth < 786 ? true : dismissible} blockScroll={true} appendTo={window.innerWidth > 784 ? "self" : null} modal={window.innerWidth < 784 ? true : false} visible={showSidebar} onHide={() => hideSidebar!(!showSidebar)} >
                <ul className='p-0 ml-1 ulList'>
                    {
                        SidebarData.map((item: any, index) => (
                            <Link to={item.path} key={item.path + index + 2}
                                onClick={window.innerWidth < 786 ? () => hideSidebar!(!showSidebar) : () => { }}>
                                <div className={`sidebarLinks p-2 m-2
                                    ${(location.pathname === '/' ? location.pathname = '/home' : location.pathname.startsWith(`${item.path}`)) ? "active text-white" : "text-black"}`}

                                    key={index}
                                    onClick={() => { handleSubNav(item, index); }}
                                    style={{ borderRadius: '8px' }}
                                >
                                    {item.icon} &nbsp;
                                    {item.title}
                                    {
                                        showSubNav && item.subNav &&
                                        item.subNav.map((sub: any, idx: any) => (
                                            <Link to={sub.subNavPath} key={sub.subNavPath + idx + 2}>
                                                <div
                                                    className={`sidebarLinks p-2 pl-3  
                                                    ${location.pathname === sub.subNavPath ? "active text-white" : "text-black"}`}
                                                    key={idx}
                                                >
                                                    {sub.subNavIcon} &nbsp;
                                                    {sub.subNavTitle}
                                                </div>
                                            </Link>
                                        )
                                        )
                                    }
                                </div>
                            </Link>
                        ))
                    }
                </ul>

            </Sidebar>
        </SidebarOverlay>
    );
};

export default Menubar;