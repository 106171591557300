import { createSlice } from "@reduxjs/toolkit";
import { getEndUserListAPI } from "../../actions/endUsers/getEndUsersList";

interface EndUsersListState {
  users: any[];
  loading: boolean;
  error: { code: string; message: string };
}

const initialState: EndUsersListState = {
  users: [],
  loading: false,
  error: { code: "", message: "" },
};

export const endUsersListSlice = createSlice({
  name: "getEndUsers",
  initialState,
  reducers: {
    resetGetEndUsers: (state) => {
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEndUserListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEndUserListAPI.fulfilled, (state, action) => {
        state.users = action.payload.users.data;
        state.loading = false;
      })
      .addCase(getEndUserListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetEndUsers } = endUsersListSlice.actions;
export default endUsersListSlice.reducer;
