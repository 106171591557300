import { createSlice } from "@reduxjs/toolkit";
import { deleteStationAmenitiesAPI } from "../../actions/stations/deleteStationAmenities";

const initialState = {
  addStation: {},
  loading: false,
  error: null,
};

const deleteStationAmenitiesSlice = createSlice({
  name: "deleteStationAmenities",
  initialState,
  reducers: {
    deleteUserData: (state, action) => {
      state.addStation = action.payload.userAdded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStationAmenitiesAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteStationAmenitiesAPI.fulfilled, (state, action) => {
        state.addStation = action.payload.userDeleted;
        state.loading = false;
      })
      .addCase(deleteStationAmenitiesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteUserData } = deleteStationAmenitiesSlice.actions;
export default deleteStationAmenitiesSlice.reducer;
