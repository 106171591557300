enum CommonPaths {
  LOGIN_PATH = "/login",
  HOME = "/home",
  MANAGE_USERS = "/manage_users",
  STATION_MANAGERS = "/station_managers",
  VEHICLES = "/vehicles",
  CHARGING_NETWORKS = "/charging_networks",
  ADMIN_USERS = "/admin_users",
  ADD_ADMIN_USERS = "/admin_users/add_admin_users",
  END_USERS = "/end_users",
  STATIONS = "/stations",
  STATION_EMINITIES = "/station_amenities",
  CHARGING_DEVICE = "/charging_device",
  ADD_STATIONS = "/stations/add_stations",
  ADD_STATION_EMINITIES = "/station_amenities/add_station_amenities",
  ADD_CHARGING_DEVICE = "/charging_device/add_charging_device",
  ADD_CHARGING_NETWORKS = "/charging_networks/add_charging_networks",
  CHARGER_CONNECTOR_TYPE = "/charger_types",
  ADD_CHARGER_CONNECTOR_TYPE = "/add_charger_connector_type",
  ADD_STATION_MANAGER = "/add_station_manager",
  BANNERS = "/banners",
  ADD_BANNERS = "/banners/add_banners",
  CHARGER_BOOKINGS = "/chargerbookings",
  ADD_CHARGER_BOOKINGS = "/add_charger_bookings",
  LOOKUP_TYPES = "/lookup_types",
  LOOKUP_CODES = "/lookup_codes",
  REPORTS = "/reports",
  REPORTS_TRANSACTIONS = "/reports/transactions",
  REPORTS_VEHICLES_CHARGED = "/reports/vehicles_charged",
  REPORTS_UTILIZATION = "/reports/utilization",
  REPORTS_FINANCE = "/reports/finance",
  CHARGING_SESSIONS = "/charging_sessions",
  ADD_CHARGING_SESSION = "/charging_sessions/add_charging_session",
  CATEGORIES = "/categories",
  PRODUCTS = "/products",
  ADD_PRODUCTS = "/products/addproduct",
  CONFIGSETTINGS = "/configsettings",
  WALLET = "/wallet",
}

export { CommonPaths };
