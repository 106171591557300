import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBannerImagesListAPI } from "../../../store/actions/banners/getBannerImagesList";
import { deleteBannerImagesAPI } from "../../../store/actions/banners/deleteBannerImages";
// import TableHeader from "../../../Components/TableHeader";
// import { Column } from "primereact/column";
// import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
// import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
// import { Image } from 'primereact/image';
import { OrderList } from 'primereact/orderlist';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { BANNERIMAGES } from "../../../API/UserApi";
import { useNavigate } from "react-router-dom";
import { CommonPaths } from "../../../Routes/Paths";
import config from "../../../Constants/config";

export type TableRow = {
    sequence: string,
    imageUrl: string,
    bannerImageId?: number;
};
interface Product {
    sequence: string;
    imageUrl: string;
    bannerImageId?: number;
}

const ListOfBannerImages = ({ visible, setVisible, editBannerId, setSubmitClicked, setEditRowData, editRowData }: any) => {
    const bannerSkipValue = 0;
    // const [currentPage, setCurrentPage] = useState<number>(1);
    // const [rows, setRows] = useState<number>(10);
    const rows = 10;
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [rowId, setRowId] = useState<number>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const bannerImagesData = useSelector((state: any) => state?.getBannerImagesList?.bannerImages?.rows);
    // const totalCount = useSelector((state: any) => state?.getBannerImagesList?.bannerImages?.total_count);
    // const isLoadingAddBannerImages = useSelector((state: any) => state?.addBannerImages?.loading);
    const banner_Id = useSelector((state: any) => state?.addBanners?.addBanners?.data?.bannerId);
    const isLoadingDelete = useSelector((state: any) => state?.deleteBannerImages?.loading);
    const [products, setProducts] = useState<Product[]>([]);
    const [touched, setTouched] = useState(false);
    const token: any = localStorage.getItem("token");
    const { BANNERS } = CommonPaths;
    const { GLOBAL_IMAGE_DOMAIN } = config;

    const getBannerImagesList = (cp: number, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: "",
            bannerid: editBannerId ?? banner_Id,
        };
        params.bannerid && dispatch(getBannerImagesListAPI(params));
    };
    useEffect(() => {
        setProducts(bannerImagesData);
    }, [bannerImagesData]);

    // const handleDeleteRowId = (rowData: TableRow) => {
    //     setDeletePopup(true);
    //     setRowId(rowData.bannerImageId);
    // };

    const handleDeleteRow = (rowId: any) => {
        dispatch(deleteBannerImagesAPI(rowId));
        setDeletePopup(false);
    };
    useEffect(() => {
        !isLoadingDelete && getBannerImagesList(bannerSkipValue, rows);
        // eslint-disable-next-line
    }, [isLoadingDelete]);

    // const actionItems = (rowData: TableRow) => {
    //     return (
    //         <div className='actionItems'>
    //             <FontAwesomeIcon className="testDelete" icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
    //         </div>
    //     );
    // };

    // const onPageChange = (event: any) => {
    //     setCurrentPage(event.first);
    //     setBannerSkipValue(event.page + 1);
    //     getBannerImagesList(event.page + 1, rows);
    //     setRows(event.rows);
    // };

    useEffect(() => {
        getBannerImagesList(bannerSkipValue, rows);
        // eslint-disable-next-line
    }, [banner_Id]);

    // useEffect(() => {
    //     !isLoadingAddBannerImages && getBannerImagesList(bannerSkipValue, rows);
    //     // eslint-disable-next-line
    // }, [isLoadingAddBannerImages]);
    useEffect(() => {
        !visible && getBannerImagesList(bannerSkipValue, rows);
        // eslint-disable-next-line
    }, [visible]);

    // const imgBody = (row: any) => {
    //     return row.imageUrl && <Image src={row.imageUrl} width="55" preview />;
    // };
    const handleImgSequence = () => {
        axios.put(BANNERIMAGES, products, {
            headers: {
                token: JSON.parse(token),
            }
        });
        navigate(BANNERS);
    };
    const itemTemplate = (item: TableRow) => {
        return (
            // <div className="flex flex-wrap p-2 align-items-center gap-3">
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <img className="w-10rem shadow-2 flex-shrink-0 border-round" src={GLOBAL_IMAGE_DOMAIN + item.imageUrl} alt='logo' />
                <FontAwesomeIcon className="testDelete" icon={faTrash} onClick={() => {
                    setDeletePopup(true);
                    setRowId(item.bannerImageId);
                }}
                />
            </div>
        );
    };

    useEffect(() => {
        setTouched(false);
    }, []);
    return (
        <div className="mx-3">
            {/* <DataTable scrollHeight="150px" className='tableHeadingRow' header={<TableHeader heading='Banners List' label="ADD" addEvent={(e) => { console.log(e); setVisible(true); }} />} value={bannerImagesData} scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                    <Column field="sequence" header="Sequence Id" sortable ></Column>
                    <Column body={imgBody} header="Image" sortable ></Column>
                    <Column frozen={true} alignFrozen="right" header="Actions" className="test" body={actionItems}></Column>
                </DataTable> */}
            <div className="d-flex align-items-center px-5 ">
                <h4 className="col-6">
                    Add Banner Images
                </h4>
                <div className="col-6 d-flex justify-content-end ">
                    <Button className="addDeviceBtn" onClick={() => {
                        setVisible(true);
                        setSubmitClicked(false);
                    }}>
                        Add
                    </Button>
                </div>
            </div>

            {products?.length > 0 &&
                <>

                    <div className="banner_img_div">
                        <div className="card xl:flex xl:justify-content-center overflow-y-scroll" style={{ height: "330px" }}>
                            <OrderList className="col-6" value={products} onChange={(e) => { setProducts(e.value); setTouched(true); }} itemTemplate={itemTemplate} dragdrop ></OrderList>
                            {/* <div className='done_div'> */}
                            {/* <button className={window.innerWidth < 786 ? 'col-4 save_btn' : 'col-1 save_btn'} onClick={handleImgSequence}>{touched ? "Save" : "Done"}</button> */}
                            {/* </div> */}
                        </div>
                        {
                            touched && <button className={window.innerWidth < 786 ? 'col-4 save_btn' : 'col-1 save_btn'} onClick={handleImgSequence}>Save</button>
                        }
                    </div>
                    {/* <Paginator first={currentPage} rows={rows} totalRecords={totalCount} rowsPerPageOptions={[5, 10, 15]} onPageChange={(e) => onPageChange(e)} /> */}
                </>
            }
            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                <div className="delpop_btn_div">
                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)}>Yes</Button>
                </div>
            </Dialog>
        </div>
    );
};

export default ListOfBannerImages;