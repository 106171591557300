const error = require('../Images/error.jpg');

const NetworkErrorPage = ({ updateRefreshButton }: any) => {
    return (
        <>
            <div className="flex justify-content-center">
                <img src={error} alt="logo" height="400px" />
            </div>
            <button onClick={() => updateRefreshButton(true)}>Refresh</button>
        </>
    );
};

export default NetworkErrorPage;