import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../Constants/config";
import TableHeader from "../../../Components/TableHeader";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import { stopChargingAPI } from "../../../store/actions/chargingSessions/stopCharging";
import { getChargingSessionListAPI } from "../../../store/actions/chargingSessions/getChargingSession";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlugCircleXmark } from '@fortawesome/free-solid-svg-icons';
// import moment from "moment";

type TableRow = {
    amenityName: string,
    description: string,
    image: null,
    amenitiesId: number,
    chargingStatus: string,
    stationDeviceConnectorId: string;
    stationDeviceUid: string;
    bookingId: string;
    userId: number;
    stationId: number;
    startDate: string;
    startTime: string;
    endTime: string;
    chargingEndTime: string;
    chargingStartTime: string;
    createdAt: string;
    additionalCost: null;
    amountCharged: number;
    createdBy: number;
    isDeleted: number;
    isEnabled: number;
    sessionId: number;
    totalPowerConsumption: string;
    transactionId: string;
    totalTime: number;
    updatedAt: string;
    updatedBy: number;
};

const ChargingSessions = () => {
    const [rows, setRows] = useState<number>(5);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [chargeSessionSkipValue, setChargeNetSkipValue] = useState<number>(0);
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>({});
    const [searchVal, setSearchVal] = useState<string>('');
    const [stop, setStop] = useState<boolean>(false);
    const dispatch = useDispatch();
    const chargingSessionsData = useSelector((state: any) => state?.getChargingSessionList?.sessionList);
    const chargingSessionsList = useSelector((state: any) => state?.getChargingSessionList);
    const { stopChargerBookings, loading } = useSelector((state: any) => state?.stopCharging);

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };

    const handleStopCharging = (rowData: TableRow) => {
        let payload = {
            connectorId: rowData.stationDeviceConnectorId,
            deviceId: rowData.stationDeviceUid,
            positionOfConnector: 1,
            // positionOfConnector: rowData.positionOfConnector,
            bookingId: rowData.bookingId,
            userId: rowData.userId,
            stationId: rowData.stationId,
            transactionId: rowData.transactionId,
        };
        dispatch(stopChargingAPI({
            formData: payload,
            method: "POST",
            stationDeviceId: rowData.stationDeviceUid
        }));
        setStop(true);
    };

    const alertFunction = () => {
        return (stopChargerBookings.status ? alert('Successfully Charging Stoped') : null,
            setStop(false),
            getSessionnsList(chargeSessionSkipValue, searchVal, rows)
        );
    };

    useEffect(() => {
        stop && !loading && alertFunction();
        // eslint-disable-next-line
    }, [stop, loading]);
    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon icon={faEye} onClick={() => handleViewPopup(rowData)} />
                {
                    rowData.chargingStatus === "IN_PROGRESS" &&
                    <>
                        <Tooltip target=".stop_charging" mouseTrack mouseTrackLeft={10} />
                        <button style={{ background: 'none', border: 'none' }}>
                            <FontAwesomeIcon className="stop_charging" data-pr-tooltip={"Stop Charging"} data-pr-position="left" icon={faPlugCircleXmark} onClick={() => handleStopCharging(rowData)} />
                        </button>
                    </>

                }
            </div>
        );
    };
    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setChargeNetSkipValue(event.page + 1);
        getSessionnsList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };
    const getSessionnsList = (cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
        };
        dispatch(getChargingSessionListAPI(params));
    };
    const updateRefreshButton = (value: any) => {
        value && getSessionnsList(chargeSessionSkipValue, searchVal, rows);
    };
    useEffect(() => {
        getSessionnsList(chargeSessionSkipValue, searchVal, rows);
        // eslint-disable-next-line
    }, [searchVal, rows]);
    useEffect(() => {
        !stop && setTimeout(() => {
            getSessionnsList(chargeSessionSkipValue, searchVal, rows);
        }, 3000);
        // eslint-disable-next-line
    }, [stop, searchVal, rows]);
    const getStartDate = (row: any) => {
        let startDate = new Date(row.chargingStartTime);
        return `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
    };
    const getStartTime = (row: any) => {
        let startTime = new Date(row.chargingStartTime);
        return startTime.toLocaleTimeString();
    };
    const getEndTime = (row: any) => {
        let endTime = row.chargingEndTime ? new Date(row.chargingEndTime) : null;
        return endTime ? endTime.toLocaleTimeString() : "";
    };
    const handleSearch = (val: string) => {
        setSearchVal(val);
    };

    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };


    return (
        <div className='mx-3'>
            {chargingSessionsList?.error?.code !== "" ?
                <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                <div>
                    <TableHeader
                        heading='Charging Sessions'
                        searchKeys="Search"
                        onSearch={handleSearch}
                    />
                    <div className="card">
                        <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow'
                            value={chargingSessionsData?.data?.rows}
                            loading={chargingSessionsData?.loading}
                            scrollable
                            removableSort
                        >
                            <Column field="bookingId" header="Booking_Id" sortable></Column>
                            <Column className="sessionDate" field="chargingStartTime" body={getStartDate} header="Date" sortable></Column>
                            <Column field="chargingStartTime" body={getStartTime} header="Start_Time" sortable></Column>
                            <Column field="chargingEndTime" body={getEndTime} header="End_Time" sortable></Column>
                            <Column field="totalPowerConsumption" header="Power_Consumption(kw)" sortable></Column>
                            <Column field="amountCharged" header="Amount_Charged(Rs)" sortable></Column>
                            <Column field="chargingStatus" header="Status" sortable></Column>
                            <Column frozen={true} alignFrozen="right" header="Actions" body={actionItems}></Column>
                        </DataTable>
                        <Paginator first={currentPage} rows={rows} totalRecords={chargingSessionsData?.data?.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />
                    </div>
                    <Dialog className='viewPopup' header={`Charging Session -${viewData.bookingId}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                        <div >
                            <div className="grid">
                                <div className={window.innerWidth < 786 ? "col-3" : "col-5 col-offset-1"}>Booking Id</div>
                                <div className="col-1">:</div>
                                <div className="col-5">{viewData.bookingId}</div>
                            </div>
                            <div className="grid">
                                <div className={window.innerWidth < 786 ? "col-3" : "col-5 col-offset-1"}>Date</div>
                                <div className="col-1">:</div>
                                <div className="col-5">{getStartDate(viewData)}</div>
                            </div>
                            <div className="grid">
                                <div className={window.innerWidth < 786 ? "col-3" : "col-5 col-offset-1"}>Start Time</div>
                                <div className="col-1">:</div>
                                <div className="col-5">{getStartTime(viewData)}</div>
                            </div>
                            <div className="grid">
                                <div className={window.innerWidth < 786 ? "col-3" : "col-5 col-offset-1"}>End Time </div>
                                <div className="col-1">:</div>
                                <div className="col-5">{getEndTime(viewData)}</div>
                            </div>
                            <div className="grid">
                                <div className={window.innerWidth < 786 ? "col-3" : "col-5 col-offset-1"}>Power Consumption (kW)</div>
                                <div className="col-1">:</div>
                                <div className="col-5">{viewData.totalPowerConsumption}</div>
                            </div>
                            <div className="grid">
                                <div className={window.innerWidth < 786 ? "col-3" : "col-5 col-offset-1"}>Amount Charged (Rs) </div>
                                <div className="col-1">:</div>
                                <div className="col-5">{viewData.amountCharged}</div>
                            </div>
                            <div className="grid">
                                <div className={window.innerWidth < 786 ? "col-3" : "col-5 col-offset-1"}>Charging Status </div>
                                <div className="col-1">:</div>
                                <div className="col-5">{viewData.chargingStatus}</div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            }
        </div>
    );
};

export default ChargingSessions;