import { combineReducers } from "@reduxjs/toolkit";
import usersListSlice from "../slice/getUsersReducer";
import userByIdSlice from "../slice/getUserByIdSlice";
import addAdminUserSlice from "../slice/addAdminUserSlice";
import { looupDropdownSlice } from "../slice/getDropdownSlice";
import deleteUserSlice from "../slice/deleteUserSlice";
import storage from "redux-persist/lib/storage";
import endUsersListSlice from "./endUsers/getEndUsersSlice";
import networksListSlice from "./chargingNetworks/getNetworkSlice";
import stationsListSlice from "./stations/getStationsSlice";
import stationEmenitiesListSlice from "./stationEmenities/getStationEmenitiesSlice";
import stationEmenitieByIdSlice from "./stationEmenities/getStationEmenityByIdSlice";
import chargingDevicesListSlice from "./chargingDevice/getChargingDeviceSlice";
import deleteNetworkSlice from "./chargingNetworks/deleteNetworkSlice";
import deleteStationSlice from "./stations/deleteStationSlice";
import deleteChargingDeviceSlice from "./chargingDevice/deleteChargingDeviceSlice";
import addNetworkSlice from "./chargingNetworks/addNetworkSlice";
import addStationEmenitiesSlice from "./stationEmenities/addStationEmenitiesSlice";
import addChargingDevicesSlice from "./chargingDevice/addChargingDevicesSlice";
import { looupNetworkNameDropdownSlice } from "./stations/getLookupNetworkNamesSlice";
import addStationsSlice from "./stations/addStationsSlice";
import { looupStationNameDropdownSlice } from "./chargingDevice/getLookupStationsSlice";
import chargerConnectorsListSlice from "./chargerConnectorType/getChargingConnectorsListSlice";
import addChargerConnectorTypesSlice from "./chargerConnectorType/addChargerConnectorTypesSlice";
import { looupDeviceNameDropdownSlice } from "./chargerConnectorType/getLookupDevicesSlice";
import deleteChargerConnectorTypeSlice from "./chargerConnectorType/deleteChargerConnectorTypeSlice";
import deleteStationEmenitiesSlice from "./stationEmenities/deleteStationEmenitiesSlice";
import chargerBookingsListSlice from "./chargerBookings/getChargerBookingsSlice";
import addStationDeviceSlice from "./stations/addStationDeviceSlice";
import addChargingDeviceConnectorsSlice from "./chargingDevice/addChargingDeviceConnectorsSlice";
import chargingDeviceConnectorListSlice from "./chargingDevice/getChargingDeviceConnectorSlice";
import deleteChargingDeviceConnectorSlice from "./chargingDevice/deleteChargingDeviceConnectorSlice";
import stationDevicesListSlice from "./stations/getStationDevicesSlice";
import addStationDeviceConnectorsSlice from "./stations/addStationDeviceConnectorsSlice";
import addStationAminityListSlice from "./stations/addStationAminityListSlice";
import deleteStationDeviceSlice from "./stations/deleteStationDeviceSlice";
import addConnectorSlice from "./chargingDevice/addConnectorSlice";
import stationDeviceConnectorsListSlice from "./stations/getStationDeviceConnectorSlice";
import { connectorStatusLookupSlice } from "./stations/getConnectorStatusLookupSlice";
import getStationAminitiesSlice from "./stations/getStationAminitySlice";
import { looupStatesDropdownSlice } from "./stations/getLookupStatesLitSlice";
import deleteStationAmenitiesSlice from "./stations/deleteStationAmenitiesSlice";
import addLookupTypesSlice from "./lookupTypes/addLookupTypesSlice";
import getLookupTypesSlice from "./lookupTypes/getLookupTypesSlice";
import deleteLookupTypesSlice from "./lookupTypes/deleteLookupTypesSlice";
import getLookupCodesSlice from "./lookupCodes/getLookupCodesSlice";
import addLookupCodesSlice from "./lookupCodes/addLookupCodesSlice";
import deleteLookupCodesSlice from "./lookupCodes/deleteLookupCodesSlice";
import deleteChargerBookingsSlice from "./chargerBookings/deleteChargerBookingsSlice";
import addChargerBookingsSlice from "./chargerBookings/addChargerBookingsSlice";
import startChargingSlice from "./chargerBookings/startChargingSlice";
import { chargerTypeLookupSlice } from "./chargingDevice/getChargerTypeLookupSlice";
import { stationStatusesLookupSlice } from "./stations/getStationStatusLookupSlice";
import { deviceStatusLookupSlice } from "./stations/getDeviceStatusLookupSlice";
import getUserRolesSlice from "./adminUsers/getUserRolesSlice";
import bannersListSlice from "./banners/getBannersSlice";
import deleteBannersSlice from "./banners/deleteBannersSlice";
import addBannersSlice from "./banners/addBannersSlice";
import editBannersSlice from "./banners/editBannersSlice";
import { bannersTypeLookupSlice } from "./banners/getBannersTypeLookupSlice";
import addBannerImagesSlice from "./banners/addBannerImagesSlice";
import bannerImagesListSlice from "./banners/getBannerImagesSlice";
import deleteBannerImagesSlice from "./banners/deleteBannerImagesSlice";
import getStationViewByIdSlice from "./stations/getStationViewByIdSlice";
import { looupCountryDropdownSlice } from "./stations/getLookupCountryListSlice";
import { devicePowerRatingLookupSlice } from "./chargingDevice/getDevicePowerRatingLookupSlice";
import { deviceModelLookupSlice } from "./chargingDevice/getDeviceModelLookupSlice";
import { deviceBrandLookupSlice } from "./chargingDevice/getDeviceBrandLookupSlice";
import getStationImageSlice from "./stations/getStationImageSlice";
import deleteStationImageSlice from "./stations/deleteStationImageSlice";
import addStationImageSlice from "./stations/addStationImageSlice";
import getChargingSessionListSlice from "./chargingSessions/getChargingSessionListSlice";
import transactionReportListSlice from "./transactionReport/getTransactionReportListSlice";
import getWalletSettingsSlice from "./configSettings/getWalletSettingsListSlice";
import addWalletSettingsSlice from "./configSettings/addWalletSettingsSlice";
import stopChargingSlice from "./chargingSessions/stopChargingSlice";
import addAmountUserWalletSlice from "./endUsers/addAmountUserWalletSlice";
import regenerateQRCodeSlice from "./stations/regenerateQRCodeSlice";

export const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["getUsers"],
};

const reducer = combineReducers({
  getUsersList: usersListSlice,
  getDropList: looupDropdownSlice.reducer,
  getUserRoles: getUserRolesSlice,
  getNetworkList: looupNetworkNameDropdownSlice.reducer,
  getStationList: looupStationNameDropdownSlice.reducer,
  getStatesList: looupStatesDropdownSlice.reducer,
  getCountries: looupCountryDropdownSlice.reducer,
  getDeviceList: looupDeviceNameDropdownSlice.reducer,
  getStatusDropList: connectorStatusLookupSlice.reducer,
  getDeviceBrand: deviceBrandLookupSlice.reducer,
  getDeviceModel: deviceModelLookupSlice.reducer,
  getDevicePowerRating: devicePowerRatingLookupSlice.reducer,
  getChargerTypeDropList: chargerTypeLookupSlice.reducer,
  getStationStatusesDropList: stationStatusesLookupSlice.reducer,
  getDeviceStatusDropList: deviceStatusLookupSlice.reducer,
  getLookupTypes: getLookupTypesSlice,
  addLookupTypes: addLookupTypesSlice,
  deleteLookupTypes: deleteLookupTypesSlice,
  getLookupCodes: getLookupCodesSlice,
  addLookupCodes: addLookupCodesSlice,
  deleteLookupCodes: deleteLookupCodesSlice,
  addUser: addAdminUserSlice,
  deleteUser: deleteUserSlice,
  userById: userByIdSlice,
  getEndUsers: endUsersListSlice,
  getNetworks: networksListSlice,
  getStations: stationsListSlice,
  addStationEmenities: addStationEmenitiesSlice,
  getStationEmenities: stationEmenitiesListSlice,
  getStationEmenitiesById: stationEmenitieByIdSlice,
  deleteStationEmenities: deleteStationEmenitiesSlice,
  getChargingDevices: chargingDevicesListSlice,
  deleteNetwork: deleteNetworkSlice,
  deleteStations: deleteStationSlice,
  deleteChargingDevice: deleteChargingDeviceSlice,
  addNetwork: addNetworkSlice,
  addStation: addStationsSlice,
  addStationDevice: addStationDeviceSlice,
  addStationDeviceConnector: addStationDeviceConnectorsSlice,
  addSationAmenitiesList: addStationAminityListSlice,
  getStationImageList: getStationImageSlice,
  deleteStationImages: deleteStationImageSlice,
  addAndEditStationImage: addStationImageSlice,
  getStationViewById: getStationViewByIdSlice,
  getStationDevices: stationDevicesListSlice,
  getStationDeviceConnectors: stationDeviceConnectorsListSlice,
  deleteStationDevice: deleteStationDeviceSlice,
  deleteStationAmenities: deleteStationAmenitiesSlice,
  addChargingDevices: addChargingDevicesSlice,
  addChargingDeviceConnectors: addChargingDeviceConnectorsSlice,
  getChargingDeviceConnectors: chargingDeviceConnectorListSlice,
  deleteChargingDeviceConnector: deleteChargingDeviceConnectorSlice,
  getChargerConnectors: chargerConnectorsListSlice,
  addChargerConnectorType: addChargerConnectorTypesSlice,
  deleteChargerConnectorType: deleteChargerConnectorTypeSlice,
  getChargerBookings: chargerBookingsListSlice,
  addChargerBookings: addChargerBookingsSlice,
  startCharging: startChargingSlice,
  stopCharging: stopChargingSlice,
  deleteChargerBookings: deleteChargerBookingsSlice,
  addConnectors: addConnectorSlice,
  getStationAminity: getStationAminitiesSlice,
  getBannersList: bannersListSlice,
  getBannersType: bannersTypeLookupSlice.reducer,
  addBanners: addBannersSlice,
  editBanners: editBannersSlice,
  deleteBanners: deleteBannersSlice,
  addBannerImages: addBannerImagesSlice,
  getBannerImagesList: bannerImagesListSlice,
  deleteBannerImages: deleteBannerImagesSlice,
  getChargingSessionList: getChargingSessionListSlice,
  getTransactionReportList: transactionReportListSlice,
  getWalletSettingsList: getWalletSettingsSlice,
  addWalletSettings: addWalletSettingsSlice,
  addAmountToUserWallet: addAmountUserWalletSlice,
  regenerateQRCode: regenerateQRCodeSlice,
});

export default reducer;
