import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
// import { Image } from "primereact/image";
import { getStationImageListAPI } from "../../../store/actions/stations/getStationImageList";
import { deleteStationImagesAPI } from "../../../store/actions/stations/deleteStationImages";
// import { ListOfStationImagesContainer } from "./styled.component";
import { OrderList } from 'primereact/orderlist';
import axios from "axios";
import { CommonPaths } from "../../../Routes/Paths";
import { STATION_IMAGES } from "../../../API/UserApi";
import config from "../../../Constants/config";

// type TableRow = {
//     // amenityId: string,
//     // stationImageId?: string,
//     image: string,
// };
interface Product {
    sequence: string;
    imageUrl: string;
    bannerImageId?: number;
}
const ListOfStationImages = ({ editStationId }: any) => {
    // const [viewPopup, setViewPopup] = useState<boolean>(false);
    // const [viewData, setViewData] = useState<any>({});
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [rowId, setRowId] = useState<string>();
    const [products, setProducts] = useState<Product[]>([]);
    const [touched, setTouched] = useState(false);
    const location = useLocation();
    const imagesList = useSelector((state: any) => state?.getStationImageList?.stationImages);
    const station_Id = useSelector((state: any) => state?.addStation?.addStation?.data?.stationId);
    const isLoadingDelete = useSelector((state: any) => state?.deleteStationImages?.loading);
    const isLoadingSubmit = useSelector((state: any) => state?.addAndEditStationImage?.loading);
    const errorMessage = useSelector((state: any) => state?.getStationAminity?.stationAminities?.errorMessage);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { STATIONS } = CommonPaths;
    const { GLOBAL_IMAGE_DOMAIN } = config;
    const token: any = localStorage.getItem("token");

    // console.log(imagesList, "imagesList");
    // console.log(isLoadingSubmit, "isLoadingSubmit");
    let getStationImageListPayload = useMemo(() => {
        return {
            type: "list",
            limit: 10,
            skip: 1,
            search: "",
            stationid: station_Id ?? location?.state?.editdata?.stationId ?? editStationId
        };
    }, [station_Id, location?.state?.editdata?.stationId, editStationId]);

    // const handleDeleteRowId = (rowData: string | undefined) => {
    //     setDeletePopup(true);
    //     // setRowId(rowData.stationImageId);
    //     setRowId(rowData);
    // };
    const handleDeleteRow = (rowId: any) => {
        setRowId((prev: any) => prev + 1);
        dispatch(deleteStationImagesAPI(rowId));
        setDeletePopup(false);
    };
    useEffect(() => {
        dispatch(getStationImageListAPI(getStationImageListPayload));
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        !isLoadingSubmit && dispatch(getStationImageListAPI(getStationImageListPayload));
        // eslint-disable-next-line
    }, [isLoadingSubmit]);
    useEffect(() => {
        !isLoadingDelete && dispatch(getStationImageListAPI(getStationImageListPayload));
        // eslint-disable-next-line
    }, [isLoadingDelete]);

    // const imgBody = (row: any) => {
    //     return row && <p><Image src={row.image} width="55" preview /></p>;
    // };

    // const actionItems = (rowData: TableRow) => {
    //     return (
    //         <div className='actionItems'>
    //             <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
    //         </div>
    //     );
    // };
    const itemTemplate = (item: any) => {
        return (
            // <div className="flex flex-wrap p-2 align-items-center gap-3">
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <img className="w-10rem shadow-2 flex-shrink-0 border-round" src={`${GLOBAL_IMAGE_DOMAIN + item.image}`} alt='logo' />
                <FontAwesomeIcon className="testDelete" icon={faTrash} onClick={() => {
                    setDeletePopup(true);
                    setRowId(item.stationImageId);
                }}
                />
            </div>
        );
    };
    useEffect(() => {
        setProducts(imagesList.rows);
        setTouched(false);
    }, [imagesList]);
    const handleImgSequence = () => {
        
        axios.put(STATION_IMAGES, products, {
            headers: {
                token: JSON.parse(token),
            }
        });
        navigate(STATIONS);
    };
    return (
        <>
            {/* <DataTable className='tableHeadingRow' value={imagesList.rows} tableStyle={{ minWidth: '50rem' }}>
                <Column body={imgBody} header="Image"></Column>
                <Column body={actionItems} header="Actions"></Column>
            </DataTable> */}
            {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                {
                    imagesList?.rows?.map((item: any) => (
                        // <div className='staImgLi'>
                        <ListOfStationImagesContainer
                            className="col-4"
                        >
                            <Image src={item.image} alt="logo" width='150px' height='150px' />
                            <div>
                                <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteRowId(item.stationImageId)} />
                            </div>
                        </ListOfStationImagesContainer>
                        // </div>
                    ))
                }
            </div> */}

            {products?.length > 0 &&
                <>
                    <div className="banner_img_div">
                        <div className="card xl:flex xl:justify-content-center mb-2" style={{ height: "320px" }}>
                            <OrderList className="col-6" value={products} onChange={(e) => { setProducts(e.value); setTouched(true); }} itemTemplate={itemTemplate} dragdrop ></OrderList>
                        </div>
                        {
                            touched && <button className={window.innerWidth < 786 ? 'col-4 save_btn' : 'col-1 save_btn'} onClick={handleImgSequence}>Save</button>
                        }
                    </div>
                </>
            }
            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                <div className="delpop_btn_div">
                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)} >Yes</Button>
                </div>
            </Dialog >
            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='stationAminitiesList' />
        </>
    );
};

export default ListOfStationImages;