const config = {
  GLOBAL_DOMAIN: process.env.REACT_APP_GLOBAL_DOMAIN,
//    ? process.env.REACT_APP_GLOBAL_DOMAIN
//    : "http://devapi.atumcharge.com/api/v1/",
  // GLOBAL_DOMAIN: "http://192.168.41.109:3001/api/v1/",
  GLOBAL_IMAGE_DOMAIN: ( process.env.REACT_APP_IMG_DOMAIN + '/' + process.env.REACT_APP_IMG_CONTAINER + '/'),
//    "https://vilassetmanagement.blob.core.windows.net/atumcharge/",
  GLOBAL_CHARGING_DOMAIN: process.env.REACT_APP_CHARGING_DOMAIN, //"https://demo.atumcharge.com/",

  Payment_Gateway: {
    MERCHANT_ID: process.env.REACT_APP_PG_MERCHANT_ID, //"ATUMLI31629784468922",
    MERCHANT_KEY: process.env.REACT_APP_PG_MERCHANT_KEY, //"jHy_AN%j#MzTIT7D",
    WEBSITE: process.env.REACT_APP_PG_WEBSITE, //"DEFAULT",
    HOST_NAME: process.env.REACT_APP_PG_HOST_NAME, //"securegw-stage.paytm.in",
    CALLBACK_URL: process.env.REACT_APP_PG_CALLBACK_URL,
      // "https://securegw-stage.paytm.in/theia/paytmCallback?orderId=",
    INDUSTRY_TYPE: process.env.REACT_APP_PG_IND_TYPE, //"ECommerce",
    MERCHANT_URL: process.env.REACT_APP_PG_MERCHANT_URL
  },
  resetAlertTimer: 3000,
  tableHeight: "330px",
};

export default config;
