import { createAsyncThunk } from "@reduxjs/toolkit";
import { STOPCHARGING } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const stopChargingAPI: any = createAsyncThunk(
  "stopCharging",
  async (
    { formData, method, stationDeviceId }: any,
    { rejectWithValue }: any
  ) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);

    try {
      const res = await axios({
        method: method,
        url: STOPCHARGING + "/" + stationDeviceId,
        data: formData,
        // headers: {
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
      });
      return res;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
