import config from "../Constants/config";

const { GLOBAL_DOMAIN, GLOBAL_CHARGING_DOMAIN } = config;

//login API
export const LOGIN = GLOBAL_DOMAIN + "endusers/login";
export const USER_LIST = GLOBAL_DOMAIN + "adminusers";
export const LOOKUP_CODES = GLOBAL_DOMAIN + "lookupcodes";
export const LOOKUP_TYPES = GLOBAL_DOMAIN + "lookuptypes";
export const END_USERS = GLOBAL_DOMAIN + "endusers";
export const USER_ROLES = GLOBAL_DOMAIN + "userroles";
export const CHARGING_NETWORKS = GLOBAL_DOMAIN + "chargingnetworks";
export const CHARGING_STATIONS = GLOBAL_DOMAIN + "chargingstations";
export const STATION_DEVICES = GLOBAL_DOMAIN + "stationdevices";
export const STATION_IMAGES = GLOBAL_DOMAIN + "stationimages";
export const STATION_DEVICE_CONNECTORS =
  GLOBAL_DOMAIN + "stationdeviceconnectors";
export const DEVICE_CONNECTORS = GLOBAL_DOMAIN + "deviceconnectors";
export const STATION_AMENITIES = GLOBAL_DOMAIN + "stationamenities";
export const CHARGING_DEVICES = GLOBAL_DOMAIN + "chargingdevices";
export const CHARGING_DEVICES_CONNECTORS =
  GLOBAL_DOMAIN + "chargingdeviceconnectors";
export const AMENITIES = GLOBAL_DOMAIN + "amenities";
export const CHARGER_TYPES = GLOBAL_DOMAIN + "chargertypes";
export const CHARGER_BOOKINGS = GLOBAL_DOMAIN + "chargerbookings";
export const CHARGING_SESSIONS = GLOBAL_DOMAIN + "chargingsessions";
export const BANNERS = GLOBAL_DOMAIN + "banners";
export const BANNERIMAGES = GLOBAL_DOMAIN + "bannerimages";
export const STARTCHARGING = GLOBAL_CHARGING_DOMAIN + "remoteStartTransaction";
export const STOPCHARGING = GLOBAL_CHARGING_DOMAIN + "remoteStopTransaction";
export const ADD_WALLET_AMOUNT = GLOBAL_DOMAIN + "paymentgateway/generateToken";
export const USER_TRANSACTIONS = GLOBAL_DOMAIN + "userTransactions";
