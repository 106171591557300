import { createSlice } from "@reduxjs/toolkit";
import { deleteLookupCodesAPI } from "../../actions/lookupCodes/deleteLookupCodes";

const initialState = {
  addUser: {},
  loading: false,
  error: null,
};

const deleteLookupCodesSlice = createSlice({
  name: "deleteLookupCodes",
  initialState,
  reducers: {
    deleteUserData: (state, action) => {
      state.addUser = action.payload.userAdded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteLookupCodesAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteLookupCodesAPI.fulfilled, (state, action) => {
        state.addUser = action.payload.userDeleted;
        state.loading = false;
      })
      .addCase(deleteLookupCodesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteUserData } = deleteLookupCodesSlice.actions;
export default deleteLookupCodesSlice.reducer;
