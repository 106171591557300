import { CHARGER_BOOKINGS } from "../../../API/UserApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";
// import { useDispatch } from "react-redux";
// import { startChargingAPI } from "./startCharging";

export const getChargerBookingsListAPI: any = createAsyncThunk(
  "getChargerBookingsList",
  async (params: any, { rejectWithValue }: any) => {
    // const dispatch = useDispatch();
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    // const { deviceId, ...newParams } = params;
    try {
      const res = await axios.get(CHARGER_BOOKINGS, {
        // headers: {
        //   // istoken: "false",
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
        params: params,
      });
      // if (params.id) {
      //   dispatch(
      //     startChargingAPI({
      //       formData: res,
      //       method: "POST",
      //       stationDeviceId: params.id,
      //       // stationDeviceId: "A654321"
      //     })
      //   );
      // }
      return { bookings: res };
    } catch (error: any) {
      // if (error instanceof Error) {
      return rejectWithValue({ error: error.message });
      // }
    }
  }
);
