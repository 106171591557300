import { createSlice } from "@reduxjs/toolkit";
import { getStationAminityListAPI } from "../../actions/stations/getStationAminityList";

interface StationAmenitiesListState {
  stationAminities: any[];
  loading: any;
  error: string | null;
}

const initialState: StationAmenitiesListState = {
  stationAminities: [],
  loading: false,
  error: null,
};

export const getStationAminitySlice = createSlice({
  name: "getStationAminityList",
  initialState,
  reducers: {
    resetGetStationAminities: (state) => {
      state.stationAminities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStationAminityListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStationAminityListAPI.fulfilled, (state, action) => {
        state.stationAminities = action.payload.stationAmenity.data;
        state.loading = false;
      })
      .addCase(getStationAminityListAPI.rejected, (state, action) => {
        state.stationAminities = action.payload.error;
        state.loading = "failed";
      });
  },
});

export const { resetGetStationAminities } = getStationAminitySlice.actions;
export default getStationAminitySlice.reducer;
