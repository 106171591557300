import { Outlet } from "react-router-dom";
import Header from "./Header";
import { useState } from "react";
import Sidebar from "./Sidebar";
// import useNetworkStatus from "./useNetworkStatus";
// import NetworkErrorPage from "../Components/NetworkErrorPage";
// import SlowInternetConnectionPage from "../Components/SlowInternetConnectionPage";

const Layout = () => {
    const [sidebar, setSidebar] = useState<boolean>(true);
    // const { isOnline, connectivityissue } = useNetworkStatus();
    return (
        <div className="grid" style={{ position: "fixed", top: 0, right: 0, left: 0 }}>
            <div className="col-12 pb-0" style={{ height: "10%", boxShadow: "0px 0px 5px 0px black" }}>
                <Header setVisible={(val: boolean) => { setSidebar(val); }} />
            </div>
            <div className="col-12 pt-0 pb-0" style={{ height: "90%" }}>
                <div className="row">
                    <div className="col-2 pt-0 pr-0 pb-0">
                        <Sidebar showSidebar={sidebar} hideSidebar={(val: boolean) => setSidebar(val)} />
                    </div>
                    <div className={sidebar ? "col-10" : "col-12"} style={{ backgroundColor: "#d5dddd8c" }}>
                        {/* {!isOnline ? <NetworkErrorPage /> : connectivityissue ? <SlowInternetConnectionPage /> : <Outlet />} */}
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Layout;