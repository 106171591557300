import { createSlice } from "@reduxjs/toolkit";
import { deleteChargerBookingsAPI } from "../../actions/chargerBookings/deleteChargerBookings";

const initialState = {
  addChargerConnectorType: {},
  loading: false,
  error: null,
};

const deleteChargerBookingsSlice = createSlice({
  name: "deleteChargerBookings",
  initialState,
  reducers: {
    deleteUserData: (state, action) => {
      state.addChargerConnectorType = action.payload.userAdded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteChargerBookingsAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteChargerBookingsAPI.fulfilled, (state, action) => {
        state.addChargerConnectorType = action.payload.userDeleted;
        state.loading = false;
      })
      .addCase(deleteChargerBookingsAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteUserData } = deleteChargerBookingsSlice.actions;
export default deleteChargerBookingsSlice.reducer;
