
import { InputText } from "primereact/inputtext";
// import { Password } from 'primereact/password';
import { useForm } from "react-hook-form";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
// import logo from '../../Images/logo.png';
import { CommonPaths } from "../../Routes/Paths";
import axios from "axios";
import { LOGIN } from "../../API/UserApi";
import { useLocalStorage } from 'primereact/hooks';
import { useLocation, useNavigate } from "react-router-dom";
// import { Messages } from 'primereact/messages';
import { useEffect, useState } from "react";
// import localStorage from "redux-persist/es/storage";

const logo = require('../../Images/logo.png');

type LoginData = {
  EMAIL: string;
  PASSWORD: string;
};

type User = {
  data: {
    data: {
      token: any;
      userData: {
        email: string;
        password: string;
      };
    };
  };
};

const { HOME } = CommonPaths;

const Login = () => {
  // const [alert, setAlert] = useState<string>();
  const location = useLocation();
  const state = location.state as { from: Location; };
  const from = state ? state.from.pathname : HOME;
  const form = useForm<LoginData>();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;
  const [token, setToken] = useLocalStorage<any>("", "token");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  useEffect(() => {
    let currentToken = localStorage.getItem('token');
    if (!!currentToken) {
      navigate(from, { replace: true });
    }
    // eslint-disable-next-line
  }, [from, navigate, localStorage.getItem('token')]);

  // const onSubmit = async ({ EMAIL, PASSWORD }: LoginData) => {
  //   try {
  //     const response = await axios.post<User>(LOGIN, {
  //       EMAIL,
  //       PASSWORD,
  //       deviceType: "web",
  //     });

  //     setToken(response.data.token);
  //     setMessage("login successfully");
  //     navigate(HOME);
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //   }
  // };
  const onSubmit = ({ EMAIL, PASSWORD }: LoginData) => {
    axios(
      {
        method: "post",
        url: LOGIN,
        data: {
          email: EMAIL,
          password: PASSWORD,
          // deviceType: "web",
        },
        headers: { istoken: 'false' },
      }
    ).then((res: User) => {
      // const token = res.data.token;
      // localStorage.setItem("token", JSON.stringify(token));
      // const cleanedToken = res.data.token.replace(/^"(.*)"$/, '$1');
      // setToken(cleanedToken);
      localStorage.setItem("userDetails", JSON.stringify(res));
      const { token } = res?.data?.data;
      setToken(token);
      navigate(HOME);
      // setMessage("login successfully")
    }).catch((error) => {
      console.log("Error in Token======>",error.response.data.message);
      setMessage(error?.response?.data?.message);
    });
  };
  const header = (
    <>
      <div className="text-4xl text-center pt-1">Login</div>
    </>
  );

  return (
    <div className="container px-3 xl:px-8" style={{ height: "100vh" }}>
      <div className=" mx-0 xl:mx-8 px-0 xl:px-8 flex flex-wrap align-items-center justify-content-center card-container blue-container h-100">
        <Card className="loginBackground w-100  mx-0 xl:mx-8 " header={header} >
          <div className="flex justify-content-center">
            <img src={logo} alt="logo" height="40px" />
          </div>
          <div className="login_err">
            {message}
          </div>
          <div className="border-round min-w-max p-1rem 0 0  m-3">
            <div className="min-w-max border-round text-white font-bold flex align-items-center justify-content-center">
              <form className="w-100" onSubmit={handleSubmit(onSubmit)} noValidate>
                {!showForgetPassword ?
                  <>
                    <div className="flex flex-column gap-2 pb-5">
                      <label htmlFor="email">Email</label>
                      <InputText id="email" aria-describedby="username-help" autoComplete="off" {...register("EMAIL", {
                        required: {
                          value: true,
                          message: "Email is required"
                        },
                        pattern: {
                          value: new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]/),
                          message: "Enter valid email address"
                        }
                      })} />
                      <small id="username-help" className="text-danger">
                        {errors.EMAIL?.message}
                      </small>
                    </div>
                    <div className="flex flex-column gap-2">
                      <label htmlFor="password">Password</label>
                      <InputText id="password" role="textbox" type="password" aria-describedby="password-help" {...register("PASSWORD", {
                        required: {
                          value: true,
                          message: "Password is required"
                        }
                      })} />
                      <small id="password-help" className="text-danger">
                        {errors.PASSWORD?.message}
                      </small>
                    </div>
                    <a href="#" onClick={() => setShowForgetPassword(true)}>Forgot password ?</a>
                    <div className="flex justify-content-center mt-5">
                      <Button label="Login" type="submit" />
                    </div>
                  </>
                  :
                  <>
                    <div className="flex flex-column gap-2">
                      <label htmlFor="password">Enter your new password</label>
                      <InputText id="password" role="textbox" type="password" aria-describedby="password-help" {...register("PASSWORD", {
                        required: {
                          value: true,
                          message: "Password is required"
                        }
                      })} />
                      <small id="password-help" className="text-danger">
                        {errors.PASSWORD?.message}
                      </small>
                    </div>
                    <div className="flex justify-content-center mt-5">
                      <Button label="Submit" type="submit" />
                    </div>
                  </>
                }
              </form>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Login;