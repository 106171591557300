import { Button } from "primereact/button";

type AddHeaderProp = {
    btnText: string,
    setVisible?: (e: boolean) => void,
    children: any,
    setEditData?: any;
};

const AddBtnHeader = ({ btnText, setVisible, children, setEditData }: AddHeaderProp) => {
    return (
        <>
            <div className="d-flex align-items-center px-5 ">
                {children}
                <div className="col-6 d-flex justify-content-end ">
                    <Button className="addDeviceBtn" onClick={() => { setVisible!(true); setEditData({ positionOfConnector: "", chargerConnectorTypeId: "" }); }}>
                        {btnText}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AddBtnHeader;