import { createAsyncThunk } from "@reduxjs/toolkit";
import { BANNERS } from "../../../API/UserApi";

import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const deleteBannersAPI: any = createAsyncThunk(
  "deleteBanners",
  async (rowId: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);

    try {
      const res = await axios.delete(BANNERS + "/" + rowId, {
        // headers: {
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
      });
      ;
      return { networkDeleted: res };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
