import { deleteChargerConnectorTypesAPI } from "../../actions/chargerConnectorType/deleteChargerConnectorType";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteChargerConnectorType: {},
  loading: false,
  error: null,
};

const deleteChargerConnectorTypeSlice = createSlice({
  name: "deleteChargerConnectorType",
  initialState,
  reducers: {
    deleteConnectorTypeData: (state) => {
      state.deleteChargerConnectorType = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteChargerConnectorTypesAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteChargerConnectorTypesAPI.fulfilled, (state, action) => {
        state.deleteChargerConnectorType = action.payload.connectorTypeDeleted;
        state.loading = false;
      })
      .addCase(deleteChargerConnectorTypesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteConnectorTypeData } =
  deleteChargerConnectorTypeSlice.actions;
export default deleteChargerConnectorTypeSlice.reducer;
