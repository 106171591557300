import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import reducer, { persistConfig } from "./slice";

// import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const presistReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: presistReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: true,
    }),
});

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch : ()=>typeof store.dispatch = useDispatch();
// export const useAppSelector : TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
export default store;
