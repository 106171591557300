import { createSlice } from "@reduxjs/toolkit";
import { getTransactionReportListAPI } from "../../actions/transactionReport/getTransactionReportList";

export interface ITransactionListRows {
  sessionId: number;
  userId: number;
  bookingId: string;
  chargingStartTime: string;
  chargingEndTime: string;
  totalPowerConsumption: string;
  amountCharged: number;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  updatedBy: number;
  isEnabled: number;
  isDeleted: number;
  chargingStatus: string;
  stationDeviceConnectorId: number;
  additionalCost: number;
  stationId: any;
  firstName?: string;
  lastName?: string;
}
export interface ITransactionListType {
  pages: number;
  rows: ITransactionListRows[];
  total_count: number;
}
export interface IErrorState {
  code: string;
  message: string;
}
export interface TransactionReportListState {
  transactionList: any[];
  loading: boolean;
  error: IErrorState;
  // error: string | null;
}

const initialState: TransactionReportListState = {
  transactionList: [],
  loading: false,
  error: {
    code: "",
    message: "",
  },
  // error: null,
};

export const transactionReportListSlice = createSlice({
  name: "getTransactionReportList",
  initialState,
  reducers: {
    resetGetTransactionList: (state) => {
      state.transactionList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionReportListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTransactionReportListAPI.fulfilled, (state, action) => {
        state.transactionList = action?.payload?.transactionReport?.data;
        state.loading = false;
      })
      .addCase(getTransactionReportListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetTransactionList } = transactionReportListSlice.actions;
export default transactionReportListSlice.reducer;
