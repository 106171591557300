import React, { useEffect,useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { CommonPaths } from '../../../Routes/Paths';
import AddDeviceConnector from './AddDeviceConnector';
import ListOfConnectors from './ListOfConnectors';
import AddBtnHeader from '../../../Components/AddBtnHeader';
import { CHARGING_STATIONS, LOOKUP_CODES } from '../../../API/UserApi';
import { addChargingDevicesAPI } from '../../../store/actions/chargingDevice/addChargingDevices';
import { lookUpStationNameDropdownAPI } from '../../../store/actions/chargingDevice/getLookupStations';
import { getChargerConnectorsListAPI } from '../../../store/actions/chargerConnectorType/getChargingConnectorsList';
import { chargerTypeLookUpDropdownAPI } from '../../../store/actions/chargingDevice/getChargerTypeLookup';
import { devicePowerRatingLookUpDropdownAPI } from '../../../store/actions/chargingDevice/getDevicePowerRatingLookup';
import { deviceModelLookUpDropdownAPI } from '../../../store/actions/chargingDevice/getDeviceModelLookup';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { deviceBrandLookUpDropdownAPI } from '../../../store/actions/chargingDevice/getDeviceBrandLookup';
import { InputTextarea } from "primereact/inputtextarea";
import PostToaster from '../../../Components/PostToaster';

interface FormValues {
    chargingDeviceName: string;
    brand: string;
    model: string;
    powerRating: string;
    deviceSpecifications: string;
    type: string;
}
const initialValues: FormValues = {
    chargingDeviceName: '',
    brand: '',
    model: '',
    powerRating: '',
    deviceSpecifications: '',
    type: '',
};
interface DeviceFormValues {
    chargerConnectorTypeId: string;
    // chargingDeviceConnectorUid:string;
    positionOfConnector: string;
}
// const deviceInitialValues: DeviceFormValues = {
//     chargerConnectorTypeId: '',
//     // chargingDeviceConnectorUid:'',
//     positionOfConnector: '',
// };


const AddChargingDevices = () => {
    const [visible, setVisible] = useState(false);
    const [editData, setEditData] = useState<DeviceFormValues | null>();
    const navigate = useNavigate();
    const { CHARGING_DEVICE } = CommonPaths;
    const location = useLocation();
    const dispatch = useDispatch();
    const [chargerTypeValue, setChargerTypeValue] = useState<any>(location?.state?.editdata?.type);
    const [powerRatingValue, setPowerRatingValue] = useState<any>(location?.state?.editdata?.powerRating);
    const [modelValue, setModelValue] = useState<any>(location?.state?.editdata?.model);
    const [brandValue, setBrandValue] = useState<any>(location?.state?.editdata?.brand);
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const device_Id = useSelector((state: any) => state?.addChargingDevices?.addChargingDevice?.data?.chargingDeviceId);
    const chargerType = useSelector((state: any) => state?.getChargerTypeDropList?.chargerType?.data);
    const powerRatingList = useSelector((state: any) => state?.getDevicePowerRating?.powerRating?.data);
    const modelList = useSelector((state: any) => state?.getDeviceModel?.model?.data);
    const brandList = useSelector((state: any) => state?.getDeviceBrand?.brand?.data);
    const addChargingDeviceError = useSelector((state: any) => state?.addChargingDevices?.error);
    const addChargingDeviceLoading = useSelector((state: any) => state?.addChargingDevices?.loading); 

    let defaultChargerType: any;
    if (chargerType) {
        defaultChargerType = chargerType[0]?.lookupName;
    }
    let defaultPowerRating: any;
    if (powerRatingList) {
        defaultPowerRating = powerRatingList[0]?.lookupName;
    }
    let defaultModel: any;
    if (modelList) {
        defaultModel = modelList[0]?.lookupName;
    }
    let defaultBrand: any;
    if (brandList) {
        defaultBrand = brandList && brandList[0]?.lookupName;
    }
    const [addConnectorOpen, setAddConnectorOpen] = useState<boolean>(false);
    const addConnectorRef = useRef<HTMLDivElement | null>(null);

    if (location?.state) {
        initialValues.chargingDeviceName = location?.state?.editdata?.chargingDeviceName;
        initialValues.brand = location?.state?.editdata?.brand;
        initialValues.model = location?.state?.editdata?.model;
        initialValues.powerRating = location?.state?.editdata?.powerRating;
        initialValues.deviceSpecifications = location?.state?.editdata?.deviceSpecifications;
        initialValues.type = location?.state?.editdata?.type;
    } else {
        initialValues.chargingDeviceName = "";
        initialValues.brand = defaultBrand;
        initialValues.model = defaultModel;
        initialValues.powerRating = defaultPowerRating;
        initialValues.deviceSpecifications = "";
        initialValues.type = defaultChargerType;
    }

    const handleSubmit = (values: FormValues) => {
        // Handle form submission
        const newStationDevice = { ...values };
        if (!location?.state?.editdata?.chargingDeviceId) {
            dispatch(addChargingDevicesAPI({ formData: newStationDevice, method: "POST" }));
        } else {
            dispatch(addChargingDevicesAPI({ formData: values, method: "PUT", id: `/${location.state.editdata.chargingDeviceId}` }));
        }

        setSubmitClicked(true);
    };

    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        // Add validation logic for each field
        if (!values.chargingDeviceName) {
            errors.chargingDeviceName = 'Required';
        }
        if (!values.brand) {
            errors.brand = 'Required';
        }
        if (!values.model) {
            errors.model = 'Required';
        }
        if (!values.powerRating) {
            errors.powerRating = 'Required';
        }
        if (!values.deviceSpecifications) {
            errors.deviceSpecifications = 'Required';
        }
        if (!values.type) {
            errors.type = 'Required';
        }
        return errors;
    };

    const getStationName = () => {
        dispatch(lookUpStationNameDropdownAPI({ ApiUrl: CHARGING_STATIONS }));
    };

    const getChargerTypeList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "CHARGER_TYPE"
        };
        dispatch(chargerTypeLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "CHARGER_TYPE", params: params }));
    };
    const getChargingDevicePowerRatingList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "POWER_RATING"
        };
        dispatch(devicePowerRatingLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "POWER_RATING", params: params }));
    };
    const getChargingDeviceModelList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "MODEL"
        };
        dispatch(deviceModelLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "MODEL", params: params }));
    };
    const getChargingDeviceBrandList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "BRAND"
        };
        dispatch(deviceBrandLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "BRAND", params: params }));
    };
    useEffect(() => {
        getStationName();
        let params = {
            type: "list",
            limit: 10,
            skip: 1,
            search: "",
        };
        dispatch(getChargerConnectorsListAPI(params));
        getChargerTypeList();
        getChargingDevicePowerRatingList();
        getChargingDeviceModelList();
        getChargingDeviceBrandList();
        // eslint-disable-next-line
    }, []);

    const handleClosePage = () => {
        navigate(CHARGING_DEVICE);
    };
    const handleConnector = () => {
        // setAddConnectorOpen(false);
        navigate(CHARGING_DEVICE);
    };
    useEffect(() => {
        if (submitClicked && !addChargingDeviceLoading && addChargingDeviceError.code === '') {
            addConnectorRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        if (submitClicked && !addChargingDeviceLoading && addChargingDeviceError.code !== '') {
            setShowErrorComponent(true);
        }
    }, [addChargingDeviceError, addChargingDeviceLoading, submitClicked]);

    return (
        <div>
            <div className="addPage_header container">
                <button className='back_Btn' onClick={handleClosePage}><i className="pi pi-arrow-left" style={{ fontSize: '1rem', color: 'white' }}></i></button>
                <h5 className='heading'>{location?.state?.popup ? "Edit Charging Device" : "Add Charging Device"}</h5>
            </div>
            <div className='card mt-3 container' style={{ height: "440px", overflowY: "scroll" }}>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <>
                            <form onSubmit={handleSubmit} className="p-fluid row m-0" name='deviceForm'>
                                <div className='col-4'>
                                    <Field name="chargingDeviceName">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label htmlFor='chargingDeviceName'>Charging Device Name*</label>
                                                <InputText id="chargingDeviceName" role='textbox' {...input} placeholder="Charging Device Name" />
                                                {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-4">
                                    <Field name="brand">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label>Brand*</label>
                                                <Dropdown
                                                    {...input}
                                                    value={brandValue || initialValues}
                                                    optionValue="lookupName"
                                                    options={brandList}
                                                    optionLabel="lookupDisplayName"
                                                    placeholder="Select Brand"
                                                    className="w-full md:w-40rem"
                                                    onChange={(e: any) => { input.onChange(e.target.value); setBrandValue(e.target.value); }}
                                                />
                                                {meta.error && meta.touched && (
                                                    <span className="error_msg">{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-4">
                                    <Field name="model">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label>Model*</label>
                                                <Dropdown
                                                    {...input}
                                                    value={modelValue || initialValues}
                                                    optionValue="lookupName"
                                                    options={modelList}
                                                    optionLabel="lookupDisplayName"
                                                    placeholder="Select Model"
                                                    className="w-full md:w-40rem"
                                                    onChange={(e: any) => { input.onChange(e.target.value); setModelValue(e.target.value); }}
                                                />
                                                {meta.error && meta.touched && (
                                                    <span className="error_msg">{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-4">
                                    <Field name="powerRating">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label>Power Rating*</label>
                                                <Dropdown
                                                    {...input}
                                                    value={powerRatingValue || initialValues}
                                                    optionValue="lookupName"
                                                    options={powerRatingList}
                                                    optionLabel="lookupDisplayName"
                                                    placeholder="Select Power Rating"
                                                    className="w-full md:w-40rem"
                                                    onChange={(e: any) => { input.onChange(e.target.value); setPowerRatingValue(e.target.value); }}
                                                />
                                                {meta.error && meta.touched && (
                                                    <span className="error_msg">{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-4">
                                    <Field name="type">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label>Charger Type*</label>
                                                <Dropdown
                                                    {...input}
                                                    value={chargerTypeValue || initialValues}
                                                    optionValue="lookupName"
                                                    options={chargerType}
                                                    optionLabel="lookupDisplayName"
                                                    placeholder="Select Charger Type"
                                                    className="w-full md:w-40rem"
                                                    onChange={(e: any) => { input.onChange(e.target.value); setChargerTypeValue(e.target.value); }}
                                                />
                                                {meta.error && meta.touched && (
                                                    <span className="error_msg">{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                {/* <div className='ml-4'>
                                <Field name="deviceSpecifications">
                                    {({ input, meta }) => (
                                        <div className="field ">
                                            <label htmlFor='deviceSpecifications'>Device Specifications*</label>
                                            <InputText id="deviceSpecifications" role='textbox' {...input} placeholder="Device Specifications" className='spcwid' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div> */}
                                <div className='col-12'>
                                    <Field name="deviceSpecifications">
                                        {({ input, meta }) => (
                                            <div className="field ">
                                                <label htmlFor='deviceSpecifications'>Device Specifications*</label>
                                                <InputTextarea id="deviceSpecifications" autoResize role='textbox' {...input} placeholder="Device Specifications" rows={5} cols={30} className='form-control' />
                                                {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <button className={window.innerWidth < 786 ? 'col-4 submit_btn' : 'col-1 submit_btn'} type="submit" >Submit</button>
                            </form>
                            {/* {showErrorComponent &&
                                <div className="card flex justify-content-center">
                                    <Toast ref={toast} onHide={() => setShowErrorComponent(false)} />
                                </div>
                            } */}
                            <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />

                        </>
                    )}
                />
                <div ref={addConnectorRef}>
                {
                    submitClicked || addConnectorOpen || location?.state?.editdata?.chargingDeviceId
                        ?
                        <Card className='mx-5'>
                            <AddBtnHeader btnText='Add' setVisible={setVisible} setEditData={setEditData}>
                                <h4 className='col-6 pb-5'>
                                    Add connectors
                                </h4>
                            </AddBtnHeader>

                            <AddDeviceConnector visible={visible} header={editData?.chargerConnectorTypeId ? "Edit connectors :" : "Add connectors :"} setVisible={setVisible} device_Id={location?.state?.editdata?.chargingDeviceId} chargingDeviceId={location?.state?.editdata?.chargingDeviceId} editData={editData} newDeviceId={device_Id} />
                            <ListOfConnectors visible={visible} setVisible={setVisible} setEditData={setEditData} device_Id={location?.state?.editdata?.chargingDeviceId} chargingDeviceId={location?.state?.editdata?.chargingDeviceId} newDeviceId={device_Id} />
                            <div className='done_div'>
                                <button className={window.innerWidth < 786 ? 'col-4 done_btn' : 'col-1 done_btn'} onClick={handleConnector}>Done</button>
                            </div>
                        </Card>
                        : null
                }
                    </div>
            </div>
        </div>
    );
};

export default AddChargingDevices;