import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHARGING_STATIONS } from "../../../API/UserApi";

import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const deleteStationsAPI: any = createAsyncThunk(
  "deleteStations",
  async (rowId: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios.delete(CHARGING_STATIONS + "/" + rowId, {
        // headers: {
        //   // istoken: "false",
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
      });
      return { stationDeleted: res };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
