import { STATION_DEVICE_CONNECTORS } from "../../../API/UserApi";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import apiHeader from "../../../Components/ApiHeader";

export const addConnectors: any = createAsyncThunk(
  "addConnector",
  async ({ formData, method, id }: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios({
        method: method,
        url: !!id ? STATION_DEVICE_CONNECTORS + id : STATION_DEVICE_CONNECTORS,
        data: formData,
        headers: commonHead,
      });
      return { users: res };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
