import { createAsyncThunk } from "@reduxjs/toolkit";
import { STATION_IMAGES } from "../../../API/UserApi";
import axios from "axios";

export const addAndEditStationImageAPI: any = createAsyncThunk(
  "addAndEditStationImage",
  async ({ formData, method, id }: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    try {
      const res = await axios({
        method: method,
        url: !!id ? STATION_IMAGES + id : STATION_IMAGES,
        data: formData,
        headers: {
          "access-control-allow-origin": "*",
          token: JSON.parse(token),
          "Content-Type": "multipart/form-data",
        },
      });

      return res;
    } catch (error: any) {
      return rejectWithValue({
        error: { code: error.response.status, message: error.message },
      });
    }
  }
);
