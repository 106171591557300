import axios from "axios";
import { deviceBrandLookupSlice } from "../../slice/chargingDevice/getDeviceBrandLookupSlice";
import apiHeader from "../../../Components/ApiHeader";

const { actions } = deviceBrandLookupSlice;

export const deviceBrandLookUpDropdownAPI: any =
  ({ ApiUrl, Type, params }: any) =>
  (dispatch: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    axios
      .get(ApiUrl, {
        params: params,
        headers: commonHead,
      })
      .then((response) => {
        const { data } = response;
        dispatch(actions.getDeviceBrandData({ type: Type, data }));
      })
      .catch((err) => {
        dispatch(
          actions.getDeviceBrandData({
            type: "error",
            paylod: err.message,
          })
        );
      });
  };
