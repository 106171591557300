import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Field, Form } from "react-final-form";
import TableHeader from "../../../Components/TableHeader";
import config from "../../../Constants/config";
import { getLookupCodesListAPI } from "../../../store/actions/lookupCodes/getLookupCodesList";
import { getLookupTypesListAPI } from "../../../store/actions/lookupTypes/getLookupTypesList";
import { addLookupCodesAPI } from "../../../store/actions/lookupCodes/addLookupCodes";
import { deleteLookupCodesAPI } from "../../../store/actions/lookupCodes/deleteLookupCodes";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import PostToaster from "../../../Components/PostToaster";

type FormValues = {
    lookupTypeName: string;
    lookupName: string;
    lookupDisplayName: string;
    description: string;
    lookupTypeId?: string;
};
export type TableRow = {
    id: number,
    lookupTypeId: string,
    lookupTypeName: string;
    lookupName: string;
    lookupDisplayName: string;
    description: string;
};

const LookupCodes = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>({});
    const [rowId, setRowId] = useState<number>();
    const [lookupTypename, setLookupTypeName] = useState<string>("");
    const [post, setPost] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [rows, setRows] = useState<number>(5);
    const [lookupCodeSkipValue, setLookupCodeSkipValue] = useState<number>(0);
    const [searchVal, setSearchVal] = useState<string>("");
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const lookupTypesList = useSelector((state: any) => state?.getLookupTypes?.getLookupTypes?.rows);
    const lookupCodesList = useSelector((state: any) => state?.getLookupCodes?.getLookupCodes);
    const getLookupCodeError = useSelector((state: any) => state?.getLookupCodes?.error);
    const errorMessage = useSelector((state: any) => state?.getLookupCodes?.getLookupCodes?.errorMessage);
    const addLookupCodeError = useSelector((state: any) => state?.addLookupCodes?.error);
    const addLookupCodeLoading = useSelector((state: any) => state?.addLookupCodes?.loading);
    const isDeleted = useSelector((state: any) => state?.deleteLookupCodes?.loading);
    const isRecordAdded = useSelector((state: any) => state?.addLookupTypes?.loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let lookupCodesListPayload = {
        type: "list",
        limit: 100,
        skip: 1,
        search: "",
    };
    let lookupTypesListPayload = {
        type: "list",
        limit: '',
        skip: 1,
        search: "",
    };

    const getLookupCodesList = (cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
        };
        dispatch(getLookupCodesListAPI(params));
    };

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };

    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.id);
    };

    const handleDeleteRow = (rowId: any) => {
        dispatch(deleteLookupCodesAPI(rowId));
        setDeletePopup(false);
    };

    useEffect(() => {
        (!isDeleted || !isRecordAdded) && getLookupCodesList(lookupCodeSkipValue, searchVal, rows);
        // eslint-disable-next-line
    }, [isDeleted, isRecordAdded, searchVal, rows]);

    const updateRefreshButton = (value: any) => {
        value && getLookupCodesList(lookupCodeSkipValue, searchVal, rows);
    };
    const actionItems = (rowData: any) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon className="testPopUp" icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon className="testDelete" icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };

    const onSubmit = (values: FormValues) => {
        setPost(true);
        setSubmitClicked(true);
        const newFormValues = { ...values, createdBy: 1, updatedBy: 1, lookupTypeName: lookupTypename };
        dispatch(addLookupCodesAPI({ formData: newFormValues, method: "POST" }));
    };

    useEffect(() => {
        post && !visible && setTimeout(() => {
            dispatch(getLookupCodesListAPI(lookupCodesListPayload));
        }, 1000);
        // eslint-disable-next-line
    }, [post, lookupTypename]);

    useEffect(() => {
        getLookupCodesList(lookupCodeSkipValue, searchVal, rows);
        // eslint-disable-next-line
    }, [searchVal, rows, lookupCodeSkipValue]);
    useEffect(() => {
        dispatch(getLookupTypesListAPI(lookupTypesListPayload));
        // eslint-disable-next-line
    }, []);
    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        // Add validation logic for each field
        if (!values.lookupTypeId) {
            errors.lookupTypeId = 'Required';
        }
        if (!values.lookupName) {
            errors.lookupName = 'Required';
        }
        if (!values.lookupDisplayName) {
            errors.lookupDisplayName = 'Required';
        }
        if (!values.description) {
            errors.description = 'Required';
        }

        return errors;
    };
    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setLookupCodeSkipValue(event.page + 1);
        getLookupCodesList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };
    const handleSearch = (val: string) => {
        setSearchVal(val);
    };
    useEffect(() => {
        submitClicked && !addLookupCodeLoading && addLookupCodeError?.code === '' && setVisible(false);
        submitClicked && !addLookupCodeLoading && addLookupCodeError?.code !== '' && setShowErrorComponent(true);
    }, [addLookupCodeError, addLookupCodeLoading, submitClicked]);

    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className='mx-3'>
            {
                getLookupCodeError?.code !== "" ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <>
                        <TableHeader heading='Lookup Codes' searchKeys="Search Lookup Name" label="ADD" addEvent={() => { setVisible(true); }} onSearch={handleSearch} />
                        <div className="card">
                            <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow' value={lookupCodesList?.rows} loading={lookupCodesList?.loading} scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                                <Column field="lookupTypeName" header="Lookup Type" sortable ></Column>
                                <Column field="lookupName" header="Lookup Name" sortable ></Column>
                                <Column field="lookupDisplayName" header="Display Name" sortable ></Column>
                                <Column field="description" header="Description" sortable ></Column>
                                <Column frozen={true} alignFrozen="right" header="Actions" className="test" body={actionItems} ></Column>
                            </DataTable>
                            <Paginator first={currentPage} rows={rows} totalRecords={lookupCodesList?.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />

                            <Dialog header="Add Lookup Codes" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                                <Form
                                    onSubmit={onSubmit}
                                    validate={validateForm}
                                    render={({ handleSubmit }) => (
                                        <form onSubmit={handleSubmit} className="p-fluid row">
                                            <div className="col-6 inputwidth">
                                                <Field name="lookupTypeId" >
                                                    {({ input, meta }) => (
                                                        <div className="field">
                                                            <label>Lookup Type </label>
                                                            <Dropdown
                                                                {...input}
                                                                optionValue="lookupTypeId"
                                                                options={lookupTypesList}
                                                                optionLabel="lookupType"
                                                                placeholder="Lookup Type Name"
                                                                className="w-full md:w-40rem"
                                                                onChange={(e) => {
                                                                    input.onChange(e.target.value);
                                                                    const data = lookupTypesList?.filter((option: any) => (option.lookupTypeId === e.target.value));
                                                                    setLookupTypeName(data[0]?.lookupType);
                                                                }}
                                                            />
                                                            {meta.error && meta.touched && (
                                                                <span className="error_msg">{meta.error}</span>
                                                            )}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className='col-6 inputwidth'>
                                                <Field name="lookupName">
                                                    {({ input, meta }) => (
                                                        <div className="field">
                                                            <label>Lookup Name</label>
                                                            <InputText  {...input} placeholder="Lookup Name" />
                                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className='col-6 inputwidth'>
                                                <Field name="lookupDisplayName">
                                                    {({ input, meta }) => (
                                                        <div className="field">
                                                            <label>Display Name</label>
                                                            <InputText  {...input} placeholder="Display Name" />
                                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className='col-6 inputwidth'>
                                                <Field name="description">
                                                    {({ input, meta }) => (
                                                        <div className="field">
                                                            <label>Description</label>
                                                            <InputText  {...input} placeholder="Description" />
                                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <button className={window.innerWidth < 786 ? 'col-4 submit_btn' : 'col-1 network_submit_btn'} type="submit">Submit</button>
                                        </form>
                                    )}
                                />
                                {
                                    showErrorComponent && <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />
                                }
                            </Dialog>
                            <Dialog className='viewPopup' header={`Lookup Code Details:`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                                <div >
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4"}>Lookup Type Name</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{viewData.lookupTypeName}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4"}>Lookup Name </div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{viewData.lookupName}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4"}>Lookup Display Name</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{viewData.lookupDisplayName}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4"}>Description</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{viewData.description}</div>
                                    </div>
                                </div>
                            </Dialog>
                            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                                <div className="delpop_btn_div">
                                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)}>Yes</Button>
                                </div>
                            </Dialog>
                            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='lookupCodesList' />
                        </div>
                    </>
            }
        </div>
    );
};


export default LookupCodes;