import { createAsyncThunk } from "@reduxjs/toolkit";
import { USER_LIST } from "../../API/UserApi";
import axios from "axios";
import apiHeader from "../../Components/ApiHeader";

export const getUserByIdAPI: any = createAsyncThunk(
  "getUserById",
  async (rowId: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios.get(USER_LIST + "/" + rowId, {
        // headers: {
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
      });
      return { user: res };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
