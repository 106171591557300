import { createSlice } from "@reduxjs/toolkit";
import { addUsersAPI } from "../actions/postRequests/addUsers";

const initialState = {
  addUser: {},
  loading: false,
  error: { code: "", message: "" },
};

const addAdminUserSlice = createSlice({
  name: "addUser",
  initialState,
  reducers: {
    resetUserData: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUsersAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUsersAPI.fulfilled, (state, action) => {
        state.addUser = action.payload.data;
        state.loading = false;
      })
      .addCase(addUsersAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetUserData } = addAdminUserSlice.actions;
export default addAdminUserSlice.reducer;
