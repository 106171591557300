import { addAmountUserWalletAPI } from "../../actions/endUsers/addAmountUserWallet";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addAmount: {},
  loading: false,
  error: { code: "", message: "" },
};

const addAmountUserWalletSlice = createSlice({
  name: "addAmountToUserWallet",
  initialState,
  reducers: {
    resetUserAmount: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAmountUserWalletAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAmountUserWalletAPI.fulfilled, (state, action) => {
        state.addAmount = action.payload.data;
        state.loading = false;
      })
      .addCase(addAmountUserWalletAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetUserAmount } = addAmountUserWalletSlice.actions;
export default addAmountUserWalletSlice.reducer;
