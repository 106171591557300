import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOOKUP_TYPES } from "../../../API/UserApi";

import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const deleteLookupTypesAPI: any = createAsyncThunk(
  "deleteLookupTypes",
  async (rowId: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios.delete(LOOKUP_TYPES + "/" + rowId, {
        headers: commonHead,
      });
      ;
      return { networkDeleted: res };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
