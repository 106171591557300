import { createSlice } from "@reduxjs/toolkit";
import { deleteChargingDevicesAPI } from "../../actions/chargingDevice/deleteChargingDevice";

const initialState = {
  deleteDevice: {},
  loading: false,
  error: null,
};

const deleteChargingDeviceSlice = createSlice({
  name: "deleteChargingDevice",
  initialState,
  reducers: {
    deleteChargingDeviceData: (state) => {
      state.deleteDevice = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteChargingDevicesAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteChargingDevicesAPI.fulfilled, (state, action) => {
        state.deleteDevice = action.payload.chargingDeviceDeleted;
        state.loading = false;
      })
      .addCase(deleteChargingDevicesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteChargingDeviceData } = deleteChargingDeviceSlice.actions;
export default deleteChargingDeviceSlice.reducer;
