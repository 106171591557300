import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonPaths } from '../../../Routes/Paths';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import PostToaster from '../../../Components/PostToaster';
import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import config from '../../../Constants/config';
import { addWalletSettingsAPI } from '../../../store/actions/configSettings/addWalletSettings';
import { getWalletSettingsListAPI } from '../../../store/actions/configSettings/getWalletSettingsList';

export interface ConfigJson {
    minAmount: string;
}
type FormValues = {
    configSettingName: string;
    description: string;
    configSettingDisplayname: string;
    configJson: ConfigJson;
};
const initialValues: FormValues = {
    configSettingName: '',
    description: '',
    configSettingDisplayname: '',
    configJson: {
        minAmount: '',
    }
};

const ConfigSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    // const toast = useRef<Toast>(null);
    const { CHARGING_NETWORKS } = CommonPaths;
    const [addWalletSettings, setAddWalletSettings] = useState<boolean>(false);
    // const [formData, setFormData] = useState<FormValues>({
    //     configSettingName: '',
    //     description: '',
    //     configSettingDisplayname: '',
    // });
    // const [configJson, setConfigJson] = useState<any>({
    //     // configJson: {
    //     minAmount: ''
    //     // }
    // });
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [configSettingName, setConfigSettingName] = useState('');
    const addNetworkError = useSelector((state: any) => state?.addNetwork?.error);
    const addNetworkLoading = useSelector((state: any) => state?.addNetwork?.loading);
    const walletList = useSelector((state: any) => state?.getWalletSettingsList?.walletSettings);
    if (addWalletSettings) {
        initialValues.configSettingName = location?.state?.editdata?.networkName;
        initialValues.description = location?.state?.editdata?.networkManager;
        initialValues.configSettingDisplayname = location?.state?.editdata?.email;
        initialValues.configJson.minAmount = location?.state?.editdata?.email;
    } else {
        initialValues.configSettingName = "";
        initialValues.description = "";
        initialValues.configSettingDisplayname = "";
        initialValues.configJson.minAmount = "";
    }

    // const handleChange = (e: any) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    // };
    // const handleConfigJsonChange = (e: any) => {
    //     const { value } = e.target;
    //     console.log(e.target.value, e.target.name, "1111111111111111111111111111");
    //     setConfigJson({
    //         // ...configJsonData,
    //         // configJson: {
    //         // ...configJsonData.configJson,
    //         // [name]: value,
    //         minAmount: value,
    //         // },
    //     });
    // };
    const handleSubmit = (values: FormValues) => {
        // setFormData(values);
        // const newData = { ...values, configJson: {...configJson, minAmount: values.configJson.minAmount} };
        // console.log("newData@@@@@@@@@@@@@@@@@@@@@@@", newData);
        // Handle form submission
        // if (!location?.state?.editdata?.networkId) {
        dispatch(addWalletSettingsAPI({ formData: values, method: "POST" }));
        // } else {
        //     dispatch(addNetworksAPI({ formData: values, method: "PUT", id: `/${location.state.editdata.networkId}` }));
        // }
        // setSubmitClicked(true);
        setAddWalletSettings(false);
        // setFormData(initialValues);
        // setConfigJson(initialConfigValues);
    };

    const getWalletSettingsList = () => {
        let params = {
            type: "list",
            limit: '',
            skip: '',
            search: '',
        };
        dispatch(getWalletSettingsListAPI(params));
    };
    useEffect(() => {
        getWalletSettingsList();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        submitClicked && !addNetworkLoading && addNetworkError.code === '' && navigate(CHARGING_NETWORKS);
        submitClicked && !addNetworkLoading && addNetworkError.code !== '' && setShowErrorComponent(true);
        return () => { setShowErrorComponent(false); };
    }, [CHARGING_NETWORKS, addNetworkError, addNetworkLoading, navigate, submitClicked]);

    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        // Add validation logic for each field
        if (!values.configSettingName) {
            errors.configSettingName = 'Required';
            // var letters = /^[A-Za-z]+$/;
            // if (values?.configSettingName?.match(letters)) {
            //     errors.description = "Please enter text value only";
            // }
        }
        if (!values.description) {
            errors.description = 'Required';
        }
        if (!values.configSettingDisplayname) {
            errors.configSettingDisplayname = 'Required';
        }
        if (!values.configJson.minAmount) {
            errors.configJson = {
                ...errors.configJson,
                minAmount: 'Required'
            };
        }
        return errors;
    };

    // const handleClosePage = () => {
    //     navigate(CHARGING_NETWORKS);
    // };

    return (
        <div>
            {/* <div className='addPage_header'>
                <h5 className='heading'>Wallet Settings</h5>
                <button className='back_Btn' onClick={handleClosePage}><i className="pi pi-arrow-left" style={{ fontSize: '1rem', color: 'white' }}></i></button>
            </div> */}
            <TabView>
                <TabPanel header="Wallet Settings">
                    <div className="col-12 md:col-4">
                        <Button label='Add' size='small' icon="pi pi-plus" onClick={() => setAddWalletSettings(true)} style={{ position: 'relative', left: '35vw' }} />
                    </div>
                    <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow mt-4' value={walletList?.rows} loading={walletList?.loading} scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                        <Column field="configSettingName" header="Config Setting Name" sortable ></Column>
                        <Column field="configSettingDisplayname" header="Display Name" sortable ></Column>
                        <Column field="description" header="Description" sortable ></Column>
                        <Column field="minAmount" header="Min.Amount" sortable ></Column>
                    </DataTable>
                </TabPanel>
            </TabView>
            <Dialog header="Add Wallet Details Settings" className='s_popup' visible={addWalletSettings} onHide={() => {
                setAddWalletSettings(false);
                // setFormData(initialValues);
                // setConfigJson(initialConfigValues);
            }}
            >
                <div className='mx-3' >
                    <Form
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validate={validateForm}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className="p-fluid row" name='networkForm'>
                                <div className='w-full'>
                                    <Field name="configSettingName">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label htmlFor='configSettingName'>Config Setting Name*</label>
                                                <InputText
                                                    id='configSettingName'
                                                    {...input}
                                                    placeholder="Config Setting Name"
                                                    autoComplete='off'
                                                    type="text"
                                                    value={configSettingName}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        value = value.replace(/[^A-Za-z]/ig, '');
                                                        input.onChange(value);
                                                        setConfigSettingName(value);
                                                    }}
                                                />
                                                {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div className='w-full'>
                                    <Field name="configSettingDisplayname">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label htmlFor='configSettingDisplayname'>Config Setting Display Name*</label>
                                                <InputText id="configSettingDisplayname" {...input} placeholder="Config Setting Display Name" autoComplete='off' />
                                                {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className='w-full'>
                                    <Field name="description">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label htmlFor='description'>Description*</label>
                                                <InputText id='description' {...input} placeholder="Description" autoComplete='off' />
                                                {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                {/* {Object.entries(formData.configJson).map(([key, value]) => ( */}
                                <div className='w-full'>
                                    <Field name="configJson.minAmount">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label htmlFor='minAmount'>Min Amount*</label>
                                                <InputText id='minAmount'  {...input} placeholder='Min Amount' autoComplete='off' />
                                                {/* value={configJson?.minAmount} onChange={handleConfigJsonChange} */}
                                                {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                {/* ))} */}
                                <button className={window.innerWidth < 786 ? 'col-4 submit_btn' : 'col-4 s_submit'} type="submit">Submit</button>
                            </form>
                        )}
                    />
                </div>
                <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />
            </Dialog>


        </div>
    );
};


export default ConfigSettings;