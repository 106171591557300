import { useEffect, useState } from "react";
// import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonPaths } from "../../../Routes/Paths";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { LOOKUP_CODES } from "../../../API/UserApi";
import { bannersTypeLookUpDropdownAPI } from "../../../store/actions/banners/getBannersTypeLookup";
import ListOfBannerImages from "./ListOfBannerImages";
import { addBannersAPI } from "../../../store/actions/banners/addBanners";
import AddBannerImages from "./AddBannerImages";

type FormValues = {
    type: string,
};

const initialValues: FormValues = {
    type: '',
};

const AddBanners = () => {
    // const [file, setFile] = useState<any>(null);
    const [listVisible, setListVisible] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    // const [addImageOpen, setAddImageOpen] = useState<boolean>(false);
    const [showAddBanner, setShowAddBanner] = useState<boolean>(false);
    const [editRowData, setEditRowData] = useState<any>();
    const [bannerType, setBannerType] = useState<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const bannersTypelist = useSelector((state: any) => state?.getBannersType?.bannersType?.data);
    const { BANNERS } = CommonPaths;

    if (location?.state) {
        initialValues.type = location?.state?.editdata?.type;
    } else {
        initialValues.type = '';
    }

    // const validateForm = (values: FormValues) => {
    //     const errors: Partial<FormValues> = {};
    //     // Add validation logic for each field

    //     if (!values.type) {
    //         errors.type = 'Required';
    //     }
    //     return errors;
    // };

    // const handleSubmit = (values: FormValues) => {
    //     console.log(values);
    //     dispatch(addBannersAPI({ formData: values, method: location?.state?.editdata?.bannerId ? "PUT" : "POST" }));
    //     if (location?.state?.editdata?.bannerId) {
    //         dispatch(addBannersAPI({ formData: values, method: "PUT", id: `/${location?.state?.editdata?.bannerId}` }));
    //     } else {
    //         dispatch(addBannersAPI({ formData: values, method: "POST" }));
    //     }
    //     setShowAddBanner(true);
    // };

    const handleClosePage = () => {
        navigate(BANNERS);
    };

    // const handleChange = (e: any) => {
    //     console.log(e.target.files);
    //     setFile(URL.createObjectURL(e.target.files[0]));
    // };

    const getBannersTypeList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "BANNERS"
        };
        dispatch(bannersTypeLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "BANNERS", params: params }));
    };

    useEffect(() => {
        getBannersTypeList();
        // eslint-disable-next-line
    }, []);

    const handleApiCall = (e: any) => {
        setBannerType(e.target.value);
        const values = e.target.value;
        if (location?.state?.editdata?.bannerId) {
            dispatch(addBannersAPI({ formData: values, method: "PUT", id: `/${location?.state?.editdata?.bannerId}` }));
        } else {
            dispatch(addBannersAPI({ formData: { type: values }, method: "POST" }));
        }
        setShowAddBanner(true);
    };
    return (
        <>
            <div className="mx-5">
                <div className='d-flex justify-content-between align-items-center '>
                    {/* <div className='addPage_header'> */}
                    <div>
                        <h5 >{location?.state?.popup ? "Edit Banners" : "Add Banners"}</h5>
                    </div>
                    <div>
                        <button className='back_Btn' onClick={handleClosePage}><i className="pi pi-arrow-left" style={{ fontSize: '1rem', color: 'white' }}></i></button>
                    </div>
                </div>
                <div className='card mt-1' >
                    {!location?.state?.editdata?.bannerId &&
                        <div className="col-6 inputwidth">
                            <div style={{ marginBottom: "5px" }}>
                                <label>Banners Type* </label>
                                <Dropdown
                                    value={bannerType}
                                    optionValue="lookupName"
                                    options={bannersTypelist}
                                    optionLabel="lookupDisplayName"
                                    placeholder="Select Banners Type"
                                    className="w-full md:w-40rem"
                                    disabled={location?.state ? true : false}
                                    onChange={(e) => handleApiCall(e)}
                                />
                            </div>
                        </div>

                        // <Form
                        //     initialValues={initialValues}
                        //     onSubmit={handleSubmit}
                        //     validate={validateForm}
                        //     render={({ handleSubmit }) => (
                        //         <form onSubmit={handleSubmit} className="p-fluid row">
                        //             <div className="col-6 inputwidth">
                        //                 <Field name="type" >
                        //                     {({ input, meta }) => (
                        //                         <div style={{ marginBottom: "5px" }}>
                        //                             <label>Banners Type* </label>
                        //                             <Dropdown
                        //                                 {...input}
                        //                                 optionValue="lookupName"
                        //                                 options={bannersTypelist}
                        //                                 optionLabel="lookupDisplayName"
                        //                                 placeholder="Select Banners Type"
                        //                                 className="w-full md:w-40rem"
                        //                                 disabled={location?.state ? true : false}
                        //                             // onChange={(e) => handleApiCall(e)}
                        //                             />
                        //                             {meta.error && meta.touched && (
                        //                                 <span className="error_msg">{meta.error}</span>
                        //                             )}
                        //                         </div>
                        //                     )}
                        //                 </Field>
                        //             </div>
                        //             <button className={window.innerWidth < 786 ? 'col-4 banners_submit_btn' : 'col-1 banners_submit_btn'} type="submit" >Submit</button>
                        //         </form>
                        //     )}
                        // />
                    }
                    {
                        showAddBanner || location?.state?.editdata?.bannerId ?
                            // <Card className='mx-1'>
                            <>
                                <AddBannerImages
                                    visible={listVisible}
                                    header="Add Banner Images :"
                                    setVisible={(e: boolean) => setListVisible(e)}
                                    submitClicked={submitClicked}
                                    setSubmitClicked={(e: boolean) => setSubmitClicked(e)}
                                    editRowData={editRowData}
                                    editBannerId={location?.state?.editdata?.bannerId}
                                />
                                <ListOfBannerImages
                                    visible={listVisible}
                                    setVisible={(e: boolean) => setListVisible(e)}
                                    setSubmitClicked={(e: boolean) => setSubmitClicked(e)}
                                    setEditRowData={setEditRowData}
                                    editRowData={editRowData}
                                    editBannerId={location?.state?.editdata?.bannerId}
                                />
                                {/* <div className='done_div'>
                                    <button className={window.innerWidth < 786 ? 'col-4 done_btn' : 'col-1 done_btn'} onClick={handleConnector}>Done</button>
                                </div> */}
                            </>
                            // </Card>
                            :
                            null
                    }
                </div>
            </div>
        </>
    );
};

export default AddBanners;