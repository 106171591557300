import { editBannersAPI } from "../../actions/banners/editBanners";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editBanners: {},
  loading: false,
  error: null,
};

const editBannersSlice = createSlice({
  name: "editBanners",
  initialState,
  reducers: {
    resetUserData: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editBannersAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(editBannersAPI.fulfilled, (state, action) => {
        state.editBanners = action.payload.data;
        state.loading = false;
      })
      .addCase(editBannersAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetUserData } = editBannersSlice.actions;
export default editBannersSlice.reducer;
