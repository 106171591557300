import { createSlice } from "@reduxjs/toolkit";
import { getStationsListAPI } from "../../actions/stations/getStationsList";

interface StationsListState {
  stations: any[];
  loading: boolean;
  error: { code: string; message: string };
}

const initialState: StationsListState = {
  stations: [],
  loading: false,
  error: { code: "", message: "" },
};

export const stationsListSlice = createSlice({
  name: "getStations",
  initialState,
  reducers: {
    resetGetStations: (state) => {
      state.stations = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStationsListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStationsListAPI.fulfilled, (state, action) => {
        state.stations = action.payload.stations.data;
        state.loading = false;
      })
      .addCase(getStationsListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});
export const { resetGetStations } = stationsListSlice.actions;
export default stationsListSlice.reducer;
