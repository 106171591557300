import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHARGING_STATIONS } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";
// import localStorage from "redux-persist/es/storage";

export const getStationsListAPI: any = createAsyncThunk(
  "getStationList",
  async (params: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios.get(CHARGING_STATIONS, {
        // headers: {
        //   // istoken: "false",
        //   token: JSON.parse(token) ,
        // },
        headers: commonHead,
        params: params,
      });
      return { stations: res };
    } catch (error: any) {
      // if (error instanceof Error) {
      return rejectWithValue({
        error: { code: error.response.status, message: error.message },
      });
      // }
    }
  }
);
