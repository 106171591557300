import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { CommonPaths } from '../../../Routes/Paths';
import { getStationEmenitiesByIdApi } from '../../../store/actions/stationEmenities/getStationEmenityById';
import { addUpdateSTationEmenitiesAPI } from '../../../store/actions/stationEmenities/addStationEmenities';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

interface FormValues {
    stationDeviceConnectorId: string;
    bookingId: string;
    startTime: string;
    endTime: string;
    chargingStatus: string;
}

const initialValues: FormValues = {
    stationDeviceConnectorId: '',
    bookingId: '',
    startTime: '',
    endTime: '',
    chargingStatus: '',
};

const AddChargingSession = () => {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const navigate = useNavigate();
    const { CHARGING_SESSIONS } = CommonPaths;
    const location = useLocation();
    const dispatch = useDispatch();
    // const station_Name = useSelector((state: any) => state?.getStationList?.StationName?.type?.rows);

    // const stationName = station_Name?.map((item: any) => (
    //     {
    //         value: item.stationId,
    //         label: item.stationName
    //     }
    // ));

    if (!!location.state?.editdata?.amenitiesId) {
        initialValues.stationDeviceConnectorId = location?.state?.editdata?.stationDeviceConnectorId;
        initialValues.bookingId = location?.state?.editdata?.bookingId;
        initialValues.startTime = location?.state?.editdata?.startTime;
        initialValues.endTime = location?.state?.editdata?.endTime;
        initialValues.chargingStatus = location?.state?.editdata?.chargingStatus;
    } else {
        initialValues.stationDeviceConnectorId = "";
        initialValues.bookingId = "";
        initialValues.startTime = "";
        initialValues.endTime = "";
        initialValues.chargingStatus = "";
    }

    useEffect(() => {
        if (!!location.state?.editdata?.amenitiesId) {
            dispatch(getStationEmenitiesByIdApi(location?.state?.editdata?.amenitiesId));
        }
        // eslint-disable-next-line
    }, [location.state]);

    const handleSubmit = (values: FormValues) => {
        // Handle form submission
        const newAmenities = { ...values };
        if (!location.state.editdata.amenitiesId) {
            dispatch(addUpdateSTationEmenitiesAPI({ formData: newAmenities, method: "POST" }));
        } else {
            const editRowId = location.state?.editdata?.amenitiesId;
            dispatch(addUpdateSTationEmenitiesAPI({ formData: values, method: "PUT", id: `/${editRowId}` }));
        }
        navigate(CHARGING_SESSIONS);
    };

    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        // Add validation logic for each field
        if (!values.stationDeviceConnectorId) {
            errors.stationDeviceConnectorId = 'Required';
        }
        if (!values.bookingId) {
            errors.bookingId = 'Required';
        }
        if (!values.startTime) {
            errors.startTime = 'Required';
        }
        if (!values.endTime) {
            errors.endTime = 'Required';
        }
        if (!values.chargingStatus) {
            errors.chargingStatus = 'Required';
        }

        return errors;
    };

    const handleClosePage = () => {
        navigate(CHARGING_SESSIONS);
    };

    return (
        <div>
            <div className="addPage_header container">
            <button className='back_Btn' onClick={handleClosePage}><i className="pi pi-arrow-left" style={{ color: 'white' }}></i></button>
            <h5 className='heading'>Add Charging Session</h5>   
            </div>
            <div className='card mt-3 mx-3' >
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid row">
                            <div className='col-6 inputwidth'>
                                <Field name="stationDeviceConnectorId">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Station Device Connector Id</label>
                                            <InputText  {...input} placeholder="Station Device Connector Id" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="bookingId">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Booking Id</label>
                                            <InputText  {...input} placeholder="Booking Id" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="startTime">
                                    {({ input, meta }) => (
                                        <div className="field" >
                                            <label>Start Time</label>
                                            <Calendar id="start-time" value={startTime} placeholder='Start Time' onChange={(e: any) => setStartTime(e.target.value)} timeOnly />
                                            {/* <InputText  {...input} placeholder="Start Time" /> */}
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="endTime">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>End Time</label>
                                            <Calendar id="end-time" value={endTime} placeholder='End Time' onChange={(e: any) => setEndTime(e.target.value)} timeOnly />
                                            {/* <InputText  {...input} placeholder="End Time" /> */}
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="chargingStatus">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Charging Status</label>
                                            <InputText  {...input} placeholder="Charging Status" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <button className='col-1 submit_btn' type="submit" >Submit</button>
                        </form>
                    )}
                />
            </div>
        </div>
    );
};

export default AddChargingSession;