import axios from "axios";
import { looupNetworkNameDropdownSlice } from "../../slice/stations/getLookupNetworkNamesSlice";
import apiHeader from "../../../Components/ApiHeader";

const { actions } = looupNetworkNameDropdownSlice;

export const lookUpNetworkNameDropdownAPI: any =
  ({ ApiUrl }: any) =>
  (dispatch: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);

    axios
      .get(ApiUrl, {
        //    params: params,
        //  headers: {
        //   //  istoken: "false",
        //   token : JSON.parse(token)
        //  },
        headers: commonHead,
      })
      .then((response) => {
        const { data } = response;
        dispatch(actions.getDropData({ type: data }));
      })
      .catch((err) => {
        dispatch(actions.getDropData({ type: "error", paylod: err.message }));
      });
  };
