import { createSlice } from "@reduxjs/toolkit";
import { deleteStationsAPI } from "../../actions/stations/deleteStations";

const initialState = {
  deleteStation: {},
  loading: false,
  error: null,
};

const deleteStationSlice = createSlice({
  name: "deleteStations",
  initialState,
  reducers: {
    deleteStationData: (state) => {
      state.deleteStation = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStationsAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteStationsAPI.fulfilled, (state, action) => {
        state.deleteStation = action.payload.stationDeleted;
        state.loading = false;
      })
      .addCase(deleteStationsAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});
export const { deleteStationData } = deleteStationSlice.actions;
export default deleteStationSlice.reducer;
