import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../Constants/config";
import apiHeader from "../../../Components/ApiHeader";
import { USER_TRANSACTIONS } from "../../../API/UserApi";
import axios from "axios";
import { Toast } from "primereact/toast";
import { CommonPaths } from "../../../Routes/Paths";
import { getEndUserListAPI } from "../../../store/actions/endUsers/getEndUsersList";

declare global {
    interface Window {
        Paytm: any;
    }
}

// const { actions } = userWalletSlice;

const Wallet = ({ setNavigateToWallet }: any) => {


    const toast: any = useRef(null);
    const orderInfo = useSelector((state: any) => state?.addAmountToUserWallet?.addAmount?.data?.orderInfo);
    const txnToken = useSelector((state: any) => state?.addAmountToUserWallet?.addAmount?.data?.txnToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Payment_Gateway } = config;
    const { END_USERS } = CommonPaths;
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    const uData = localStorage.getItem("userData") || "";
    const userData = JSON.parse(uData);

    const getEndUsersList = () => {
        let params = {
            type: "list",
            limit: 5,
            skip: 1,
            search: '',
            userroleid: 0,
        };
        dispatch(getEndUserListAPI(params));
    };
    const configData = {
        "root": "",
        "style": {
            "bodyBackgroundColor": "#fff",
            "bodyColor": "#333",
            "themeBackgroundColor": "#77cab9",
            "themeColor": "#fff",
            "headerBackgroundColor": "#77cab9",
            "headerColor": "#fff",
            "errorColor": "red",
            "successColor": "green",
            "card": {
                "padding": "",
                "backgroundColor": "",
            }
        },
        "data": {
            "orderId": `${orderInfo?.ORDER_ID}`,
            "token": `${txnToken}`,
            "tokenType": "TXN_TOKEN",
            "amount": `${orderInfo?.orderTotalAmount}`,
        },
        "payMode": {
            "labels": {},
            "filter": {
                "exclude": [
                    "WALLET",
                    "BANK_MANDATE",
                    "BANK_TRANSFER",
                    "DEBIT_CARD",
                    "EMI",
                    "EMI_DC",
                    "MGV",
                    "LOGIN",
                    "PPBL",
                    "PDC",
                    "SC",
                    "BALANCE"
                ]
            },
            "order": [
                "CARD",
                "NB",
                "UPI"
            ]
        },
        "website": "WEB",
        "flow": "DEFAULT",
        "merchant": {
            "mid": Payment_Gateway.MERCHANT_ID,
            'name': 'ATUM Life',
            "redirect": false,
        },
        "handler": {
            "transactionStatus": function transactionStatus(paymentStatus: any) {
                window.Paytm.CheckoutJS.close();
                let datas = {
                    "userId": userData?.id,
                    "orderId": paymentStatus.ORDERID,
                    "transactionId": paymentStatus.TXNID,
                    "paymentType": "Credit",
                    "paymentStatus": paymentStatus.STATUS,
                    "gatewayName": paymentStatus.GATEWAYNAME,
                    "orderAmount": parseInt(paymentStatus.TXNAMOUNT),
                    "createdBy": userData?.id,
                    "updatedBy": userData?.id
                };
                setNavigateToWallet();
                if (paymentStatus.STATUS === 'TXN_SUCCESS') {
                    axios({
                        method: 'post',
                        headers: commonHead,
                        url: USER_TRANSACTIONS,
                        data: datas,
                    }).then((res) => {
                        // setNavigateToWallet(false);
                        navigate(END_USERS);
                        getEndUsersList();
                        // dispatch(actions.getConnectorDropData({ type: "ABC", res }));
                        // setAddAmount(0);
                        // getUserTransactions();
                        // getUserData();
                    }).catch((err: any) => {
                    });
                } else {
                    showtoast();
                }
            },
            "notifyMerchant": function notifyMerchant(eventName: any, data: any) {
                (window as any).Paytm.CheckoutJS.close();
                setNavigateToWallet();
            },
        },

    };
    if ((window as any).Paytm && (window as any).Paytm.CheckoutJS) {
        (window as any).Paytm.CheckoutJS.init(configData).then(function onSuccess() {
            (window as any).Paytm.CheckoutJS.invoke();
            setNavigateToWallet();
        }).catch(function onError(err: any) {
            console.log("Error => ", err);
        });
    };

    const showtoast = () => {
        toast.current.show({ severity: 'error', summary: 'Payment Failure', life: 3000 });
    };

    // useEffect(() => {
    //     !setNavigateToWallet && getEndUsersList()
    // }, [setNavigateToWallet])
    return (
        <div>
            <Toast ref={toast} />

        </div>
    );
};
export default Wallet;