import { createSlice } from "@reduxjs/toolkit";
import { getUserRolesAPI } from "../../actions/adminUsers/getUserRoles";

interface AdminUsersState {
  UserRoles: any[];
  loading: boolean;
  error: { code: string; message: string };
}

const initialState: AdminUsersState = {
  UserRoles: [],
  loading: false,
  error: { code: "", message: "" },
};

export const userRolesSlice = createSlice({
  name: "getUserRoles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserRolesAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserRolesAPI.fulfilled, (state, action) => {
        state.UserRoles = action.payload.users.data;
        state.loading = false;
      })
      .addCase(getUserRolesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export default userRolesSlice.reducer;
