import { deleteNetworksAPI } from "../../actions/chargingNetworks/deleteChargingNetworks";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteNetwork: {},
  loading: false,
  error: null,
};

const deleteNetworkSlice = createSlice({
  name: "deleteNetwork",
  initialState,
  reducers: {
    deleteNetworkData: (state) => {
      state.deleteNetwork = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteNetworksAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteNetworksAPI.fulfilled, (state, action) => {
        state.deleteNetwork = action.payload.networkDeleted;
        state.loading = false;
      })
      .addCase(deleteNetworksAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteNetworkData } = deleteNetworkSlice.actions;
export default deleteNetworkSlice.reducer;
