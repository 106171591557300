import { createSlice } from "@reduxjs/toolkit";
import { regenerateQRCodeApi } from "../../actions/stations/regenerateQRCode";

const initialState = {
  regenerateQRCode: {},
  loading: false,
  error: null,
};

const regenerateQRCodeSlice = createSlice({
  name: "regenerateQRCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(regenerateQRCodeApi.pending, (state, action) => {
        state.regenerateQRCode = initialState.regenerateQRCode;
        state.loading = true;
      })
      .addCase(regenerateQRCodeApi.fulfilled, (state, action) => {
        state.regenerateQRCode = action.payload.generateQR;
        state.loading = false;
      })
      .addCase(regenerateQRCodeApi.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export default regenerateQRCodeSlice.reducer;
