import axios from "axios";
import { looupStationNameDropdownSlice } from "../../slice/chargingDevice/getLookupStationsSlice";
import apiHeader from "../../../Components/ApiHeader";

const { actions } = looupStationNameDropdownSlice;

export const lookUpStationNameDropdownAPI: any =
  ({ ApiUrl }: any) =>
  (dispatch: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    axios
      .get(ApiUrl, {
        headers: commonHead,
      })
      .then((response) => {
        const { data } = response;
        dispatch(actions.getDropData({ type: data }));
      })
      .catch((err) => {
        dispatch(actions.getDropData({ type: "error", paylod: err.message }));
      });
  };
