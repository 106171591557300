import React, { useState } from "react";
import config from "../../../Constants/config";
import { Calendar } from 'primereact/calendar';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

type tableHeaderProps = {
    heading?: string;
    label: string;
    addEvent: (e: any) => void;
    onSearch?: (val: string) => void;
    dateRangePicker?: any;
    selectLocation?: any;
};

const TableHeaders = ({ heading, addEvent, label, onSearch, dateRangePicker, selectLocation }: tableHeaderProps) => {
    const [searchValue, setSearchValue] = useState("");
    const handleSearchData = (e: any) => {
        const seacrhValue = e.target.value;
        setSearchValue(seacrhValue);
        onSearch!(seacrhValue);
    };
    return (
        <div className='tableHeader p-2 grid'>
            <div className="col-12 md:col-3">
                <div className="w-full p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder="Search" value={searchValue} onChange={handleSearchData} className='p-inputtext-sm w-full ' />
                </div>
            </div>
            <div className="col-12 md:col-3 ">
                {selectLocation}
            </div>
            <div className="col-8 md:col-4">
                {dateRangePicker}
            </div>
            <div className="col-4 md:col-2">
                <Button label={label} size='small' className='addButton w-full' icon={label !== "SUBMIT" && "pi pi-plus"} onClick={(e) => { addEvent(e); }} />
            </div>
        </div>
    );
};

const TransactionReport = () => {
    const [dateRange, setDateRange] = useState(null);
    // const [applyClicked, setApplyClicked] = useState(false);
    const maxSelectableDate = new Date();

    const vehicleChargeReport = [
        {
            txId: 1,
            custName: "Manohar",
            date: "19-08-2023",
            mobileNo: "9876543980",
            email: "manohar.mudi@gmail.com",
            chargingStartTime: "05:00",
            chargingEndTime: "06:00",
            chargingPoint: "Hyderabad - 3kw Charger",
        },
        {
            txId: 2,
            custName: "Shri",
            date: "19-08-2023",
            mobileNo: "9876543210",
            email: "shri@gmail.com",
            chargingStartTime: "05:00",
            chargingEndTime: "06:00",
            chargingPoint: "Hyderabad - 1kw Charger",
        }
    ];

    // const enterbtn = () => {
    //     return (
    //         <>
    //             <button onClick={() => setApplyClicked(true)}>Apply</button>
    //             <button>Clear</button>
    //         </>
    //     );
    // };

    return (
        <div className='m-3'>
            <h4>Vehicle Charged Report</h4>
            <div className="card">
                <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow'
                    header={
                        <TableHeaders label="SUBMIT"
                            addEvent={(e: any) => { console.log(e.target.value); }}
                            onSearch={() => { }}
                            dateRangePicker={
                                <div>
                                    <Calendar
                                        value={dateRange}
                                        onChange={(e: any) => { setDateRange(e.target.value); }}
                                        selectionMode="range"
                                        readOnlyInput
                                        numberOfMonths={2}
                                        showIcon
                                        style={{
                                            height: "43px",
                                        }}
                                        maxDate={maxSelectableDate}
                                    />
                                </div>
                            }
                        />
                    }
                    value={vehicleChargeReport}
                    scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                    <Column field="txId" header="txId" sortable style={{ width: '12%' }}></Column>
                    <Column field="custName" header="Customer Name" sortable style={{ width: '12%' }}></Column>
                    <Column field="date" header="Date" sortable style={{ width: '12%' }}></Column>
                    <Column field="mobileNo" header="Mobile No" sortable style={{ width: '12%' }}></Column>
                    <Column field="email" header="Email" sortable style={{ width: '12%' }}></Column>
                    <Column field="chargingStartTime" header="Start Time" sortable style={{ width: '12%' }}></Column>
                    <Column field="chargingEndTime" header="End Time" sortable style={{ width: '12%' }}></Column>
                    <Column field="chargingPoint" header="Charging Point" sortable style={{ width: '12%' }}></Column>
                </DataTable>
                {/* <Paginator first={currentPage} rows={rows} totalRecords={chargingDevicesData.devices.total_count} onPageChange={(e) => onPageChange(e)} /> */}
            </div>
        </div>
    );
};

export default TransactionReport;