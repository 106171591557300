import { createSlice } from "@reduxjs/toolkit";
import { getStationImageListAPI } from "../../actions/stations/getStationImageList";

const initialState = {
  stationImages: [],
  loading: false,
  error: null,
};

export const getStationImageSlice = createSlice({
  name: "getStationImageList",
  initialState,
  reducers: {
    resetGetStationImages: (state) => {
      state.stationImages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStationImageListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStationImageListAPI.fulfilled, (state, action) => {
        state.stationImages = action.payload.users.data;
        state.loading = false;
      })
      .addCase(getStationImageListAPI.rejected, (state, action) => {
        state.stationImages = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetStationImages } = getStationImageSlice.actions;
export default getStationImageSlice.reducer;
