import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { addStationDevicesAPI } from "../../../store/actions/stations/addStationDevice";
import { getChargingDeviceConnectorListAPI } from "../../../store/actions/chargingDevice/getChargingDeviceConnectorList";
import { addStationDeviceConnectorsAPI } from "../../../store/actions/stations/addStationDeviceConnectors";
import { getStationDevicesListAPI } from "../../../store/actions/stations/getStationDeviceList";
import { getStationDeviceConnectorsListAPI } from "../../../store/actions/stations/getStationDeviceConnectors";
import { getChargingDeviceListAPI } from "../../../store/actions/chargingDevice/getChargingDeviceList";
import { LOOKUP_CODES } from "../../../API/UserApi";
import { connectorStatusLookUpDropdownAPI } from "../../../store/actions/stations/getConnectorStatusLookup";
import { deviceStatusDropdownAPI } from "../../../store/actions/stations/getDeviceStatusLookup";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import PostToaster from "../../../Components/PostToaster";

interface FormValues {
    stationDeviceUid: string;
    pricePerUnit: string;
    status: string;
    chargingDeviceId: string;
    // stationDeviceId: string;
    connectorStatus: any;
}

const initialValues: FormValues = {
    stationDeviceUid: '',
    pricePerUnit: '',
    status: '',
    chargingDeviceId: '',
    // stationDeviceId: '',
    connectorStatus: ''
};

const SubmitBtn: any = styled.div`
max-width: 1100px;
margin: 0px;
padding: 20px;
`;

interface Status {
    name: string;
    code: string;
}

const AddDevices = ({ devices, visible, setVisible, onSubmit, editStationId, editData, isEditing }: any) => {
    const [deviceValue, setDeviceValue] = useState<any>(editData?.chargingDeviceId);
    const connectorStatusList: Status[] = [
        { name: 'IN_USE', code: 'NY' },
        { name: 'NOT_IN_USE', code: 'RM' }
    ];
    const device_Name = useSelector((state: any) => state?.getChargingDevices?.devices?.rows);
    const deviceConnectorList = useSelector((state: any) => state?.getChargingDeviceConnectors?.deviceConnectors?.rows);
    const editDeviceConnectorList = useSelector((state: any) => state?.getStationDeviceConnectors?.stationDeviceConnectors?.rows);
    const stationId = useSelector((state: any) => state?.addStation?.addStation?.data?.stationId);
    const isLoadingAddStations = useSelector((state: any) => state?.addStation?.loading);
    const addStationDeviceError = useSelector((state: any) => state?.addStation?.error);
    const stationDeviceId = useSelector((state: any) => state?.addStationDevice?.stationDevice?.data?.stationDeviceId);
    const connectorStatusLists = useSelector((state: any) => state?.getStatusDropList?.connectorStatus?.data);
    const deviceStatusList = useSelector((state: any) => state?.getDeviceStatusDropList?.deviceStatus?.data);
    const [statusValue, setStatusValue] = useState<any>(editData?.status);
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [temp, setTemp] = useState<any>();
    const dispatch = useDispatch();
    // const location = useLocation();
    const deviceName = device_Name?.map((item: any) => ({
        value: item.chargingDeviceId,
        label: item.chargingDeviceName,
    }));
    // console.log("divce api============>",  device_Name);
    // console.log("status", connectorStatusList);
    // console.log("connectorStatusLists", connectorStatusLists);
    // console.log("editDeviceConnectorList", editDeviceConnectorList);
    const defaultChargingDeviceId: any = device_Name?.[0].chargingDeviceId;
    const [selectDevice, setSelectDevice] = useState<number | string | null>(device_Name?.[0].chargingDeviceId);
    const editedStationDeviceId = editData?.stationDeviceId;
    let getStationDeviceListPayload = {
        type: "list",
        limit: 5,
        skip: 1,
        search: "",
        stationid: stationId ?? editStationId ?? editData?.stationId ?? temp?.stationId
    };
    // console.log("stationId", stationId, "editStationId ", editData, "editData?.stationId", location?.state?.editdata?.stationId);
    let defaultDeviceStatus: any;
    if (deviceStatusList) {
        defaultDeviceStatus = deviceStatusList[0]?.lookupName;
    }
    if (editData?.stationDeviceUid) {
        initialValues.stationDeviceUid = editData?.stationDeviceUid;
        initialValues.pricePerUnit = editData?.pricePerUnit;
        initialValues.status = editData?.status;
        initialValues.chargingDeviceId = editData?.chargingDeviceId;
    } else {
        initialValues.stationDeviceUid = "";
        initialValues.pricePerUnit = "";
        initialValues.status = defaultDeviceStatus;
        initialValues.chargingDeviceId = defaultChargingDeviceId;
    }

    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        let connectorStatus = '';
        if (editDeviceConnectorList?.length <= 3) {
            connectorStatus = editDeviceConnectorList?.[0]?.status;
        }
        if (!values.chargingDeviceId) {
            errors.chargingDeviceId = 'Required';
        }
        if (!values.stationDeviceUid) {
            errors.stationDeviceUid = 'Required';
        }
        if (!values.pricePerUnit) {
            errors.pricePerUnit = 'Required';
        }
        if (!values.status) {
            errors.status = 'Required';
        }
        if (!connectorStatus && !values.connectorStatus) {
            errors.connectorStatus = 'Required';
        }
        // else if (!values.connectorStatus) {

        //     console.log("111111111");
        //     errors.connectorStatus = 'Required';

        // }

        return errors;
    };

    useEffect(() => {
        !visible && setDeviceValue(null);
    }, [visible]);

    const getConnectorStatusList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "CONNECTOR_STATUS"
        };
        dispatch(connectorStatusLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "CONNECTOR_STATUS", params: params }));
    };

    const getDeviceStatusList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "STATION_DEVICE_STATUSES"
        };
        dispatch(deviceStatusDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "STATION_DEVICE_STATUSES", params: params }));
    };

    useEffect(() => {
        getConnectorStatusList();
        getDeviceStatusList();
        getChargingDevicesList();
        // eslint-disable-next-line
    }, []);


    const getChargingDevicesList = () => {
        let params = {
            type: "list",
            limit: 1000,
        };
        dispatch(getChargingDeviceListAPI(params));
    };

    useEffect(() => {
        if (stationDeviceId) {
            handleSubmitAPI(connectorStatusArray);
        }
        // eslint-disable-next-line
    }, [stationDeviceId]);


    const handleSubmitForm = (values: any) => {
        const pricePerUnit = values.pricePerUnit === null ? 0 : parseInt(values.pricePerUnit);
        const formData = { ...values,pricePerUnit, stationId: stationId ?? editStationId };
        const editFormData = { ...values, stationId: editData?.stationId };
        if (!editedStationDeviceId) {
            setTemp(formData);
            dispatch(addStationDevicesAPI({ formData: formData, method: "POST" }));
        } else {
            setTemp(editFormData);
            dispatch(addStationDevicesAPI({ formData: editFormData, method: "PUT", id: `/${editedStationDeviceId}` }));
            handleEditSubmitAPI(editConnectorStatusArray);
        }
        setSubmitClicked(true);
    };

    useEffect(() => {
        !isLoadingAddStations && dispatch(getStationDevicesListAPI(getStationDeviceListPayload));
        // eslint-disable-next-line
    }, [isLoadingAddStations]);

    const getConnectors = () => {
        let params = {
            type: "list",
            limit: 10,
            skip: 1,
            search: "",
            chargingdeviceid: selectDevice || defaultChargingDeviceId,
        };
        dispatch(getChargingDeviceConnectorListAPI(params));
    };

    const getEditConnectors = () => {
        let params = {
            type: "list",
            limit: 10,
            skip: 1,
            search: "",
            stationdeviceid: editedStationDeviceId,
        };
        dispatch(getStationDeviceConnectorsListAPI(params));
    };

    // useEffect(() => {
    //     !editStationId && dispatch(deleteStationDeviceConnectors());
    //     // eslint-disable-next-line
    // }, [editStationId]);
    useEffect(() => {
        if (selectDevice) {
            getConnectors();
        }
        // eslint-disable-next-line
    }, [selectDevice]);
    useEffect(() => {
        visible && getEditConnectors();
        // eslint-disable-next-line
    }, [visible]);
    // editedStationDeviceId
    const initialEditConnectorStatusArray = editDeviceConnectorList?.map(() => '') || [];

    const [editConnectorStatusArray, setEditConnectorStatusArray] = useState(initialEditConnectorStatusArray);
    const handleEditConnectorStatusChange = (e: any, i: any) => {
        const { value } = e.target;
        const updatedStatusArray = [...editConnectorStatusArray];
        updatedStatusArray[i] = value;
        setEditConnectorStatusArray(updatedStatusArray);
    };
    const initialConnectorStatusArray = deviceConnectorList?.map(() => '') || [];

    const [connectorStatusArray, setConnectorStatusArray] = useState(initialConnectorStatusArray);

    const handleConnectorStatusChange = (e: any, i: any) => {
        const { value } = e.target;
        const updatedStatusArray = [...connectorStatusArray];
        updatedStatusArray[i] = value;
        setConnectorStatusArray(updatedStatusArray);
    };
    const handleSubmitAPI = (data: any[]) => {
        const conectors = data.filter((item, index) => item !== '');
        const payload = conectors.length > 0 && conectors.map((status: any, i: any) => ({
            stationDeviceId: stationDeviceId,
            status: status.name,
            chargingDeviceConnectorId: deviceConnectorList[i]?.chargingDeviceConnectorId,
        }));
        if (!editedStationDeviceId) {
            dispatch(addStationDeviceConnectorsAPI({ formData: payload, method: "POST" }));
        }
    };

    const handleEditSubmitAPI = (data: any[]) => {
        // const conectors = data.filter((item, index) => item !== '');
        const payload = data.map((status: any, i: any) => ({
            stationDeviceConnectorId: editDeviceConnectorList[i]?.stationDeviceConnectorId,
            stationDeviceId: editedStationDeviceId,
            status: status,
            chargingDeviceConnectorId: editDeviceConnectorList[i]?.chargingDeviceConnectorId,
        }));
        const newPayload = payload.filter((item: any) => item !== '');
        dispatch(addStationDeviceConnectorsAPI({ formData: newPayload, method: "PUT" }));
    };

    useEffect(() => {
        !visible && setSelectDevice('');
        !visible && setEditConnectorStatusArray([]);
        !visible && setStatusValue('');
        if (visible && !editData?.chargingDeviceId) {
            setConnectorStatusArray(initialConnectorStatusArray);
        }
        visible && setSelectDevice(deviceValue || initialValues.chargingDeviceId);
        // eslint-disable-next-line
    }, [visible]);

    useEffect(() => {
        submitClicked && !isLoadingAddStations && addStationDeviceError.code === '' && setVisible(false);
        submitClicked && !isLoadingAddStations && addStationDeviceError.code !== '' && setShowErrorComponent(true);
    }, [addStationDeviceError, isLoadingAddStations, setVisible, submitClicked]);

    return (
        <>
            <Dialog header={editedStationDeviceId ? "Edit Devices" : "Add Devices"} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validate={validateForm}
                    render={({ handleSubmit, values }) => (
                        <>
                            <form onSubmit={handleSubmit} className="p-fluid row grid" autoComplete="off">
                                <div className="row">
                                    <h3 className="mt-2 ml-4">Device: </h3>
                                    <div className={window.innerWidth < 786 ? "col-12 inputwidth" : "col-4 inputwidth"}>
                                        <Field name="chargingDeviceId" >
                                            {({ input, meta }) => (
                                                <div className="field">
                                                    <label>Select Device* </label>
                                                    <Dropdown
                                                        {...input}
                                                        value={deviceValue || initialValues.chargingDeviceId}
                                                        options={deviceName}
                                                        placeholder="Select Device"
                                                        className="w-full md:w-40rem"
                                                        onSelect={() => setSelectDevice(values.chargingDeviceId)}
                                                        onChange={(e: any) => { input.onChange(e.target.value); setDeviceValue(e.target.value); }}
                                                    />
                                                    {meta.error && meta.touched && (
                                                        <span className="error_msg">{meta.error}</span>
                                                    )}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    <div className={window.innerWidth < 786 ? "col-12 inputwidth" : "col-4 inputwidth"}>
                                        <Field name="stationDeviceUid">
                                            {({ input, meta }) => (
                                                <div className="field">
                                                    <label htmlFor="deviceUid">Device Uid*</label>
                                                    <InputText 
                                    id="deviceUid" 
                                    {...input} 
                                    placeholder="Device Uid"
                                    disabled={isEditing}
                                    keyfilter={/^[a-zA-Z0-9 ]*$/}
                                />
                                                    {meta.error && meta.touched && (
                                                        <span className="error_msg">{meta.error}</span>
                                                    )}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-6 inputwidth">
                                        <Field name="pricePerUnit">
                                            {({ input, meta }) => (
                                                <div className="field">
                                                    <label htmlFor="pricePerUnit">Price Per Unit*</label>
                                                    <InputText id="pricePerUnit" {...input} placeholder="Price Per Unit" keyfilter="int" type="number"/>
                                                    {meta.error && meta.touched && (
                                                        <span className="error_msg">{meta.error}</span>
                                                    )}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-6 inputwidth">
                                        <Field name="status" >
                                            {({ input, meta }) => (
                                                <div className="field">
                                                    <label>Device Status* </label>
                                                    <Dropdown
                                                        {...input}
                                                        value={statusValue || initialValues.status}
                                                        optionValue="lookupName"
                                                        options={deviceStatusList}
                                                        optionLabel="lookupDisplayName"
                                                        placeholder="Device Status"
                                                        className="w-full md:w-40rem"
                                                        onChange={(e: any) => { input.onChange(e.target.value); setStatusValue(e.target.value); }}
                                                    />
                                                    {meta.error && meta.touched && (
                                                        <span className="error_msg">{meta.error}</span>
                                                    )}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    {selectDevice && !editData?.stationDeviceId &&
                                        <>
                                            <h5>Device Connectors List : </h5>
                                            <div className="flex align-items-center">
                                                <div className="col-1">position</div>
                                                <div className="col-1">Type</div>
                                                <div className="col-6">Select Status</div>
                                            </div>
                                            {deviceConnectorList?.map((item: any, i: number) => (
                                                <div key={i + 1} className="flex align-items-center">
                                                    <div className="col-1">{item.positionOfConnector}</div>
                                                    <div className="col-1">{item.chargerTypeName}</div>
                                                    <div className="col-6 inputwidth">
                                                        <Field name="connectorStatus" >
                                                            {({ input, meta }) => (
                                                                <div className="field">
                                                                    <Dropdown
                                                                        {...input}
                                                                        value={connectorStatusArray[i] || ""}
                                                                        onChange={(e) => { input.onChange(e.target.value); handleConnectorStatusChange(e, i); }}
                                                                        options={connectorStatusList}
                                                                        optionLabel="name"
                                                                        placeholder="Select status"
                                                                        className="w-full md:w-14rem"
                                                                        name={`name${i}`}
                                                                    />
                                                                    {meta.error && meta.touched && (
                                                                        <span className="error_msg">{meta.error}</span>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            ))}
                                            <p onClick={() => handleSubmitAPI(connectorStatusArray)}></p>
                                        </>
                                    }
                                    {editData?.stationDeviceId &&
                                        <>
                                            <h5>Device Connectors List : </h5>
                                            <div className="flex align-items-center">
                                                <div className="col-1">Position</div>
                                                <div className="col-1">Type</div>
                                                <div className="col-6">Select Status</div>
                                            </div>
                                            {editDeviceConnectorList?.map((item: any, i: number) => {
                                                return <div key={i + 1} className="flex align-items-center">
                                                    <div className="col-1">{item.positionOfConnector}</div>
                                                    <div className="col-1">{item.chargerTypeName}</div>
                                                    <div className="col-6 inputwidth">
                                                        <Field name="connectorStatus" >
                                                            {({ input, meta }) => (
                                                                <div className="field">
                                                                    <Dropdown
                                                                        {...input}
                                                                        // value={editConnectorStatusArray[i] || ""}
                                                                        value={editConnectorStatusArray[i] || item.status}
                                                                        // value={item.status}
                                                                        onChange={(e) => { input.onChange(e.target.value); handleEditConnectorStatusChange(e, i); }}
                                                                        optionValue="lookupName"
                                                                        options={connectorStatusLists}
                                                                        optionLabel="lookupDisplayName"
                                                                        // placeholder={item.status}
                                                                        className="w-full md:w-14rem"
                                                                        name={`name${i}`}
                                                                    />
                                                                    {meta.error && meta.touched && (
                                                                        <span className="error_msg">{meta.error}</span>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>;
                                            })}
                                            <p onClick={() => handleEditSubmitAPI(editConnectorStatusArray)}></p>
                                        </>
                                    }
                                    <SubmitBtn>
                                        <button className={window.innerWidth < 786 ? 'col-4 submit_btn' : 'col-1 submit_btn'} type="submit">
                                            Submit
                                        </button>
                                    </SubmitBtn>
                                </div>
                            </form>

                            <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />
                        </>
                    )} >
                </Form >
            </Dialog >
        </>
    );
};

export default AddDevices;