import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../Constants/config";
import TableHeader from "../../../Components/TableHeader";
import { CommonPaths } from "../../../Routes/Paths";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import { getStationsListAPI } from "../../../store/actions/stations/getStationsList";
import { deleteStationsAPI } from "../../../store/actions/stations/deleteStations";
import { getStationViewByIdApi } from "../../../store/actions/stations/getStationViewById";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import {
  Paginator,
  PaginatorCurrentPageReportOptions,
} from "primereact/paginator";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
// import { Panel } from 'primereact/panel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPen,
  faEye,
  faPhotoFilm,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
// import { Carousel, CarouselResponsiveOption } from 'primereact/carousel';
import { Image } from "primereact/image";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Badge } from "primereact/badge";
import { lookUpCountryDropdownAPI } from "../../../store/actions/stations/getLookupCountryList";
import { lookUpStatesDropdownAPI } from "../../../store/actions/stations/getLookupStatesList";
import { stationStatusesLookUpDropdownAPI } from "../../../store/actions/stations/getStationStatusLookup";
import { LOOKUP_CODES } from "../../../API/UserApi";
import { getNetworkListAPI } from "../../../store/actions/chargingNetworks/getNetworkList";
import { getChargingDeviceListAPI } from "../../../store/actions/chargingDevice/getChargingDeviceList";
import { getStationEmenityListAPI } from "../../../store/actions/stationEmenities/getStationEmenitiesList";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import { deleteStationData } from "../../../store/slice/stations/deleteStationSlice";
import { regenerateQRCodeApi } from "../../../store/actions/stations/regenerateQRCode";

type TableRow = {
  stationName: string;
  status: string;
  street: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  latitude: string;
  longitude: string;
  phoneNumber: string;
  email: string;
  stationId: number;
  networkName: string;
  image: null;
};
// interface Product {
//     image: string,
//     stationImageId: number,
// }
const Stations = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rows, setRows] = useState<number>(5);
  const [viewPopup, setViewPopup] = useState<boolean>(false);
  const [rowId, setRowId] = useState<number>();
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [associatedDeletePopup, setAssociatedDeletePopup] =
    useState<boolean>(false);
  const [viewData, setViewData] = useState<any>({});
  const [stationSkipValue, setStationSkipValue] = useState<number>(0);
  const [searchVal, setSearchVal] = useState<string>("");
  // const [visible, setVisible] = useState(false);
  const [regenerateQRCode, setRegenerateQRCode] = useState<boolean>(false);
  const [regenerateQRCodeRowData, setRegenerateQRCodeRowData] = useState<any>(
    {}
  );
  const dispatch = useDispatch();
  const stationsData = useSelector((state: any) => state?.getStations);
  const errorMessage = useSelector(
    (state: any) => state?.getStations?.stations?.errorMessage
  );
  const stationView = useSelector(
    (state: any) => state?.getStationViewById?.stationViewById?.data
  );
  const stationImages = useSelector(
    (state: any) => state?.getStationViewById?.stationViewById?.data?.images
  );
  const isLoading = useSelector(
    (state: any) => state?.getStationViewById?.loading
  );
  const associatedDeleteErrorMessage = useSelector(
    (state: any) => state?.deleteStations?.deleteStation?.data?.error
  );
  const { loading: isDeleteLoading } = useSelector(
    (state: any) => state?.deleteStations
  );
  const navigate = useNavigate();
  const { ADD_STATIONS } = CommonPaths;
  const popup = true;
  const { GLOBAL_IMAGE_DOMAIN } = config;
  // const [products, setProducts] = useState<Product[]>();
  const [products, setProducts] = useState<any>();
  const [imageIndex, setImageIndex] = useState<number>(0);

  useEffect(() => {
    setImageIndex(0);
    setProducts(stationImages);
  }, [stationImages]);
  const handleViewPopup = (rowData: TableRow) => {
    dispatch(getStationViewByIdApi(rowData.stationId));
    // console.log(dispatch(getStationViewByIdApi(rowData.stationId)));
    setViewPopup(true);
    setViewData(rowData);
  };
  const handleEditPopup = (rowData: TableRow) => {
    navigate(ADD_STATIONS, { state: { editdata: rowData, popup: popup } });
  };
  const handleDeleteRowId = (rowData: TableRow) => {
    setDeletePopup(true);
    setRowId(rowData.stationId);
  };
  const handleDeleteRow = (rowId: any) => {
    dispatch(deleteStationsAPI(rowId));
    setDeletePopup(false);
  };
  const actionItems = (rowData: TableRow) => {
    return (
      <div className="actionItems">
        <FontAwesomeIcon
          icon={faEye}
          onClick={() => {
            handleViewPopup(rowData);
            setImageIndex(imageIndex + 1);
          }}
        />
        <FontAwesomeIcon
          icon={faPen}
          onClick={() => handleEditPopup(rowData)}
        />
        <FontAwesomeIcon
          icon={faTrash}
          onClick={() => handleDeleteRowId(rowData)}
        />
      </div>
    );
  };

  const getStationsList = useCallback(
    (cp: number, searchVal: string, rows: number) => {
      let params = {
        type: "list",
        limit: rows,
        skip: cp,
        search: searchVal === "" ? "" : searchVal,
      };
      dispatch(getStationsListAPI(params));
    },
    [dispatch]
  );

  useEffect(() => {
    !isDeleteLoading && getStationsList(stationSkipValue, searchVal, rows);
    // eslint-disable-next-line
  }, [isDeleteLoading, searchVal, rows]);

  const updateRefreshButton = (value: any) => {
    value && getStationsList(stationSkipValue, searchVal, rows);
  };

  const getCountryList = () => {
    let params = {
      type: "drop",
      lookupTypeName: "COUNTRY",
    };
    dispatch(
      lookUpCountryDropdownAPI({
        ApiUrl: LOOKUP_CODES,
        Type: "COUNTRY",
        params: params,
      })
    );
  };
  const getStatesList = () => {
    let params = {
      type: "drop",
      lookupTypeName: "STATES",
    };
    dispatch(
      lookUpStatesDropdownAPI({
        ApiUrl: LOOKUP_CODES,
        Type: "STATES",
        params: params,
      })
    );
  };

  const getStationStatusesList = () => {
    let params = {
      type: "drop",
      lookupTypeName: "STATION_STATUSES",
    };
    dispatch(
      stationStatusesLookUpDropdownAPI({
        ApiUrl: LOOKUP_CODES,
        Type: "STATION_STATUSES",
        params: params,
      })
    );
  };

  const getNetworkAndDeviceAndAminityList = () => {
    let params = {
      type: "list",
      limit: "",
      skip: 1,
      search: "",
    };
    dispatch(getNetworkListAPI(params));
    dispatch(getChargingDeviceListAPI(params));
    dispatch(getStationEmenityListAPI(params));
  };

  useEffect(() => {
    getCountryList();
    getStatesList();
    getStationStatusesList();
    getNetworkAndDeviceAndAminityList();
    // eslint-disable-next-line
  }, []);

  const onPageChange = (event: any) => {
    setCurrentPage(event.first);
    setStationSkipValue(event.page + 1);
    getStationsList(event.page + 1, searchVal, rows);
    setRows(event.rows);
  };

  const handleSearch = (value: string) => {
    setSearchVal(value);
  };

  const imgBody = (row: any) => {
    return (
      row.images &&
      row.images.map((img: any, idx: any) => {
        return (
          <Image
            key={idx}
            src={GLOBAL_IMAGE_DOMAIN + img.image}
            width="25"
            height="25"
            className="mr-2 mt-2"
            preview
          />
        );
      })
    );
  };

  // const responsiveOptions: CarouselResponsiveOption[] = [
  //     {
  //         breakpoint: '1199px',
  //         numVisible: 1,
  //         numScroll: 1
  //     },
  //     {
  //         breakpoint: '991px',
  //         numVisible: 2,
  //         numScroll: 1
  //     },
  //     {
  //         breakpoint: '767px',
  //         numVisible: 1,
  //         numScroll: 1
  //     }
  // ];
  // const checkImages = (product: any) => {
  //     return (
  //         <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
  //             <div className="mb-3">
  //                 <img src={`${GLOBAL_IMAGE_DOMAIN + product.image}`} alt='logo' className="carousel-img w-6 shadow-2" />
  //                 {/* <img src={`${product.image}`} alt='logo' className="w-6 shadow-2" /> */}
  //             </div>
  //         </div>
  //     );
  // };
  useEffect(() => {
    associatedDeleteErrorMessage && setAssociatedDeletePopup(true);
    // eslint-disable-next-line
  }, [associatedDeleteErrorMessage]);

  const handlePre = () => {
    setImageIndex(!imageIndex ? stationImages?.length - 1 : imageIndex - 1);
    // setImageIndex((imageIndex) => (!imageIndex ? stationImages?.length - 1 : imageIndex - 1));
  };
  const handleNext = () => {
    if (stationImages?.length > 0) {
      setImageIndex((imageIndex + 1) % stationImages?.length);
    } else {
      setImageIndex(imageIndex + 1);
    }
    // setImageIndex((imageIndex) => ((imageIndex + 1) % stationImages?.length));
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [imageIndex]);

  const pagenationTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport",
    CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const handleGenerateQRid = (rowData: any) => {
    setRegenerateQRCode(true);
    setRegenerateQRCodeRowData(rowData);
};

const regenerateQRfn = async (data: any) => {
    await dispatch(regenerateQRCodeApi({ method: "PUT", id: data }));
};
const handleRegenerateQR = (regenerateQRCodeRowData: any) => {
    regenerateQRfn(regenerateQRCodeRowData?.stationDeviceId);
    setRegenerateQRCode(false);
};

  return (
    <div className="mx-3">
      {stationsData?.error.code !== "" ? (
        <NetworkErrorPage updateRefreshButton={updateRefreshButton} />
      ) : (
        <>
          <TableHeader
            heading="All Stations"
            searchKeys="Search Station Name"
            label="Add Stations"
            addEvent={(e) => {
              navigate(ADD_STATIONS);
            }}
            fontSize="1rem"
            width="11rem"
            onSearch={handleSearch}
          />
          <div className="card">
            <DataTable
              scrollHeight={config.tableHeight}
              className="tableHeadingRow"
              value={stationsData?.stations?.rows}
              loading={stationsData?.loading}
              scrollable
              removableSort
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                field="networkName"
                header="Network Name"
                sortable
              ></Column>
              <Column
                field="stationName"
                header="Station Name"
                sortable
              ></Column>
              <Column field="phoneNumber" header="Mobile" sortable></Column>
              <Column field="city" header="City" sortable></Column>
              <Column field="state" header="State" sortable></Column>
              <Column field="country" header="Country" sortable></Column>
              <Column field="status" header="Status" sortable></Column>
              <Column body={imgBody} header="Images"></Column>
              <Column
                frozen={true}
                alignFrozen="right"
                header="Actions"
                body={actionItems}
              ></Column>
            </DataTable>
            <Paginator
              first={currentPage}
              rows={rows}
              totalRecords={stationsData.stations.total_count}
              rowsPerPageOptions={[5, 10, 15]}
              template={pagenationTemplate}
              onPageChange={(e) => onPageChange(e)}
            />

            <Dialog
              className="stationViewPopup"
              header={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {viewData.stationName}{" "}
                  {
                    <button className="svbinfo_sbtn">
                      {stationView?.status}
                    </button>
                  }
                </div>
              }
              visible={viewPopup}
              onHide={() => {
                setViewPopup(false);
                setImageIndex(0);
              }}
            >
              <TabView>
                <TabPanel header="Station Info">
                  <div className="svbinfo">
                    {/* <div className="card"> */}
                    {!isLoading && products && products.length ? (
                      products.length > 1 ? (
                        // <Carousel
                        //     value={products} numVisible={1} numScroll={1} className="custom-carousel" circular responsiveOptions={responsiveOptions}
                        //     autoplayInterval={3000} itemTemplate={checkImages} />
                        <div className="flex justify-center w-3 mt-5">
                          <FontAwesomeIcon
                            onClick={handlePre}
                            style={{
                              paddingRight: "10px",
                              position: "relative",
                              top: "65px",
                            }}
                            icon={faChevronLeft}
                          />
                          <div style={{ width: "200px", objectFit: "cover" }}>
                            {stationImages?.map((url: any, i: any) => (
                              <img
                                key={i}
                                src={`${GLOBAL_IMAGE_DOMAIN + url?.image}`}
                                className={
                                  "w-100 h-10rem " +
                                  (imageIndex === i ? "active" : "hidden")
                                }
                                alt="walle paper"
                              />
                            ))}
                          </div>
                          <FontAwesomeIcon
                            style={{
                              paddingLeft: "10px",
                              position: "relative",
                              top: "65px",
                            }}
                            icon={faChevronRight}
                            onClick={handleNext}
                          />
                        </div>
                      ) : (
                        <img
                          src={GLOBAL_IMAGE_DOMAIN + products[0]?.image}
                          alt="logo"
                          width="200px"
                          height="200px"
                          style={{ padding: "5% 0.5% 0.5%" }}
                        />
                      )
                    ) : (
                      <FontAwesomeIcon
                        icon={faPhotoFilm}
                        style={{
                          width: "155px",
                          height: "165px",
                          padding: "0.5%",
                        }}
                      />
                    )}
                    {/* </div> */}
                    <div className="sv-basic-info">
                      <div>
                        <div className="d-flex m-2 ">
                          <div>Station Name :</div>
                          <div className="ml-2">{stationView?.stationName}</div>
                        </div>
                        <div className="d-flex m-2">
                          <div>Station ID :</div>
                          <div className="ml-2">{stationView?.stationId}</div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex m-2">
                          <div>Email :</div>
                          <div className="ml-2">{stationView?.email}</div>
                        </div>
                        <div className="d-flex m-2">
                          <div>Phone Number :</div>
                          <div className="ml-2">{stationView?.phoneNumber}</div>
                        </div>
                      </div>
                      <div className="m-2">
                        <div>Address :</div>
                        <div>{`${stationView?.street}, ${stationView?.city}, ${stationView?.state}, ${stationView?.country}, ${stationView?.pincode}`}</div>
                      </div>
                      <div>
                        <div className="d-flex ml-2">
                          <div>latitude :</div>
                          <div className="ml-2">{stationView?.latitude}</div>
                        </div>
                        <div className="d-flex ml-2">
                          <div>longitude :</div>
                          <div className="ml-2">{stationView?.longitude}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header="Devices Info">
                  {stationView?.fastChargerDevices?.length > 0 && (
                    <h5>Fast Chargers</h5>
                  )}
                  {stationView?.fastChargerDevices?.map((fastCharger: any) => {
                    return (
                      // <panel header={fastCharger.chargingDeviceName} toggleable style={{
                      // marginBottom: "10px"
                      // }}>
                      <Accordion>
                        <AccordionTab
                          header={fastCharger.chargingDeviceName}
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <div className="sv-deviceC-container">
                            <h5 className="mt-2 px-3">Device QR Code:</h5>
                            <div className="sv-deviceC-display">
                              <div className="col-4">
                                <img
                                  src={`${GLOBAL_IMAGE_DOMAIN}${fastCharger.stationDeviceQrCode}`}
                                  alt="QR Code"
                                  className="sv-deviceC-image"
                                />
                                <Button
                                  label="Re-Generate QR Code"
                                  severity="info"
                                  text
                                  onClick={() => handleGenerateQRid(fastCharger)}
                                />
                                 <Dialog className='deletePopup' header="You want to re-generate the QR Code ?" visible={regenerateQRCode} style={{ width: '50vw' }} onHide={() => setRegenerateQRCode(false)} >
                <div className="delpop_btn_div">
                    <Button className='delpop_No_btn' onClick={() => setRegenerateQRCode(false)} >No</Button>
                    <Button className='delpop_Yes_btn' onClick={() => handleRegenerateQR(regenerateQRCodeRowData)} >Yes</Button>
                </div>
            </Dialog>
                              </div>
                              <div className="sv-deviceC-info">
                                <div className="sv-devicei-item">
                                  <h5>Brand:</h5>
                                  <div className="sv-devicei-font">
                                    {fastCharger?.brand}
                                  </div>
                                </div>
                                <div className="sv-devicei-item">
                                  <h5>Model:</h5>
                                  <div className="sv-devicei-font">
                                    {fastCharger?.model}
                                  </div>
                                </div>
                                <div className="sv-devicei-item">
                                  <h5>Price Per Unit (Rs):</h5>
                                  <div className="sv-devicei-font">
                                    {fastCharger?.pricePerUnit}
                                  </div>
                                </div>
                                <div className="sv-devicei-item">
                                  <h5>Power Rating (kW):</h5>
                                  <div className="sv-devicei-font">
                                    {fastCharger.powerRating}
                                  </div>
                                </div>
                                {fastCharger?.connectorTypes?.length > 0 && (
                                  <>
                                    <h5 className="mt-3">Connector Info:</h5>
                                    <div className="sv-deviceC-connector">
                                      {fastCharger.connectorTypes.map(
                                        (connector: any, index: any) => (
                                          <div
                                            key={index}
                                            className="sv-deviceC-content"
                                          >
                                            <div className="sv-deviceC-i">
                                              <img
                                                src={`${GLOBAL_IMAGE_DOMAIN}${connector.image}`}
                                                alt="Connector"
                                                width="90px"
                                                height="90px"
                                              />
                                            </div>
                                            <div className="sv-deviceC-status">
                                              {connector.chargerTypeName}
                                              <span
                                                style={{ marginLeft: "10px" }}
                                              >
                                                <Badge
                                                  severity={
                                                    connector.status ===
                                                    "IN_USE"
                                                      ? "success"
                                                      : "warning"
                                                  }
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </AccordionTab>
                      </Accordion>
                    );
                  })}
                  {stationView?.normalChargerDevices?.length > 0 && (
                    <h5
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      Normal Chargers
                    </h5>
                  )}
                  {stationView?.normalChargerDevices?.map(
                    (normalCharger: any) => {
                      return (
                        // <Panel header={normalCharger.chargingDeviceName} toggleable style={{
                        // marginBottom: "10px"
                        // }}>
                        <Accordion>
                          <AccordionTab
                            header={normalCharger.chargingDeviceName}
                            style={{
                              marginBottom: "10px",
                            }}
                          >
                             <div className="sv-deviceC-container">
                            <h5 className="mt-2 px-3">Device QR Code:</h5>
                            <div className="sv-deviceC-display">
                              <div className="col-4">
                                <img
                                  src={`${GLOBAL_IMAGE_DOMAIN}${normalCharger.stationDeviceQrCode}`}
                                  alt="QR Code"
                                  className="sv-deviceC-image"
                                />
                                <Button
                                  label="Re-Generate QR Code"
                                  severity="info"
                                  text
                                  onClick={() => handleGenerateQRid(normalCharger)}
                                />
                                <Dialog className='deletePopup' header="You want to re-generate the QR Code ?" visible={regenerateQRCode} style={{ width: '50vw' }} onHide={() => setRegenerateQRCode(false)} >
                <div className="delpop_btn_div">
                    <Button className='delpop_No_btn' onClick={() => setRegenerateQRCode(false)} >No</Button>
                    <Button className='delpop_Yes_btn' onClick={() => handleRegenerateQR(regenerateQRCodeRowData)} >Yes</Button>
                </div>
            </Dialog>
                              </div>
                              <div className="sv-deviceC-info">
                                <div className="sv-devicei-item">
                                  <h5>Brand:</h5>
                                  <div className="sv-devicei-font">
                                    {normalCharger?.brand}
                                  </div>
                                </div>
                                <div className="sv-devicei-item">
                                  <h5>Model:</h5>
                                  <div className="sv-devicei-font">
                                    {normalCharger?.model}
                                  </div>
                                </div>
                                <div className="sv-devicei-item">
                                  <h5>Price Per Unit (Rs):</h5>
                                  <div className="sv-devicei-font">
                                    {normalCharger?.pricePerUnit}
                                  </div>
                                </div>
                                <div className="sv-devicei-item">
                                  <h5>Power Rating (kW):</h5>
                                  <div className="sv-devicei-font">
                                    {normalCharger.powerRating}
                                  </div>
                                </div>
                                {normalCharger?.connectorTypes?.length > 0 && (
                                  <>
                                    <h5 className="mt-3">Connector Info:</h5>
                                    <div className="sv-deviceC-connector">
                                      {normalCharger.connectorTypes.map(
                                        (connector: any, index: any) => (
                                          <div
                                            key={index}
                                            className="sv-deviceC-content"
                                          >
                                            <div className="sv-deviceC-i">
                                              <img
                                                src={`${GLOBAL_IMAGE_DOMAIN}${connector.image}`}
                                                alt="Connector"
                                                width="90px"
                                                height="90px"
                                              />
                                            </div>
                                            <div className="sv-deviceC-status">
                                              {connector.chargerTypeName}
                                              <span
                                                style={{ marginLeft: "10px" }}
                                              >
                                                <Badge
                                                  severity={
                                                    connector.status ===
                                                    "IN_USE"
                                                      ? "success"
                                                      : "warning"
                                                  }
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          </AccordionTab>
                        </Accordion>
                      );
                    }
                  )}
                </TabPanel>
                <TabPanel header="Amenities Info">
                  <div className="sv-aminity-display">
                    {stationView?.amenities?.map((amenities: any) => {
                      return (
                        <div className="sv-aminity-display-content">
                          <div className="sv-aminity-i">
                            <img
                              src={GLOBAL_IMAGE_DOMAIN + amenities?.image}
                              alt="logo"
                              width="50px"
                              height="50px"
                            />
                          </div>
                          <div>
                            <div className="d-flex m-2">
                              <div>Amenitiy Name : &nbsp;</div>
                              <div>{amenities?.amenityName}</div>
                            </div>
                            <div className="d-flex m-2">
                              <div>Description : &nbsp;</div>
                              <div>{amenities?.description}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </TabPanel>
              </TabView>
            </Dialog>
            <Dialog
              className="deletePopup"
              header="You want to delete the record ?"
              visible={deletePopup}
              style={{ width: "50vw" }}
              onHide={() => setDeletePopup(false)}
            >
              <div className="delpop_btn_div">
                <Button
                  className="delpop_No_btn"
                  onClick={() => setDeletePopup(false)}
                >
                  No
                </Button>
                <Button
                  className="delpop_Yes_btn"
                  onClick={() => handleDeleteRow(rowId)}
                >
                  Yes
                </Button>
              </div>
            </Dialog>
            {associatedDeleteErrorMessage && (
              <Dialog
                className="deletePopup"
                header="Record is not Deleted"
                visible={associatedDeletePopup}
                style={{ width: "50vw" }}
                onHide={() => {
                  setAssociatedDeletePopup(false);
                  dispatch(deleteStationData());
                }}
              >
                <div className="delpop_btn_div">
                  {associatedDeleteErrorMessage}
                </div>
              </Dialog>
            )}
            <TokenHandleFunction
              navigate={navigate}
              errorMessage={errorMessage}
              referenceKey="stationList"
            />
          </div>
        </>
      )}
    </div>
  );
};
export default Stations;
