import { createAsyncThunk } from "@reduxjs/toolkit";
import { AMENITIES } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const deleteStationEmenitiesAPI: any = createAsyncThunk(
  "deleteStationEmenities",
  async (rowId: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios.delete(AMENITIES + "/" + rowId, {
        headers: commonHead,
      });
      return { amenitiesDeleted: res };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
