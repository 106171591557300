import axios from "axios";
import { bannersTypeLookupSlice } from "../../slice/banners/getBannersTypeLookupSlice";
import apiHeader from "../../../Components/ApiHeader";

const { actions } = bannersTypeLookupSlice;

export const bannersTypeLookUpDropdownAPI: any =
  ({ ApiUrl, Type, params }: any) =>
  (dispatch: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    axios
      .get(ApiUrl, {
        params: params,
        // headers: {
        //   //   istoken: "false",
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
      })
      .then((response) => {
        const { data } = response;
        dispatch(actions.getBannersTypeData({ type: Type, data }));
      })
      .catch((err) => {
        dispatch(
          actions.getBannersTypeData({ type: "error", paylod: err.message })
        );
      });
  };
