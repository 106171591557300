import { createAsyncThunk } from "@reduxjs/toolkit";
import { ADD_WALLET_AMOUNT } from "../../../API/UserApi";
import apiHeader from "../../../Components/ApiHeader";
import axios from "axios";

export const addAmountUserWalletAPI: any = createAsyncThunk(
  "addAmountToUserWallet",
  async ({ formData, method }: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios({
        method: method,
        url: ADD_WALLET_AMOUNT,
        data: formData,
        headers: commonHead,
      });
      return res;
    } catch (error: any) {
      return rejectWithValue({
        error: { code: error.response.status, message: error.message },
      });
    }
  }
);
