import { createSlice } from "@reduxjs/toolkit";
import { deleteUsersAPI } from "../actions/deleteUserList";

const initialState = {
  addUser: {},
  loading: false,
  error: null,
};

const deleteUserSlice = createSlice({
  name: "deleteUser",
  initialState,
  reducers: {
    deleteUserData: (state, action) => {
      state.addUser = action.payload.userAdded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteUsersAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteUsersAPI.fulfilled, (state, action) => {
        state.addUser = action.payload.userDeleted;
        state.loading = false;
      })
      .addCase(deleteUsersAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteUserData } = deleteUserSlice.actions;
export default deleteUserSlice.reducer;
