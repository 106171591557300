import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { Dialog } from "primereact/dialog";
// import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { addAndEditStationImageAPI } from "../../../store/actions/stations/addStationImages";
import PostToaster from "../../../Components/PostToaster";

// const SubmitBtn: any = styled.div`
// max-width: 1100px;
// margin: 0px;
// padding: 20px;
// `;

interface FormValues {
    amenityId: string;
    description: string;
}
const AddStationImages = ({ visible, setVisible, editStationId }: any) => {
    const station_Id = useSelector((state: any) => state?.addStation?.addStation?.data?.stationId);
    const dispatch = useDispatch();
    const location = useLocation();
    const [imageData, setImageData] = useState<any>(null);
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [invalidExtension, setInvalidExtension] = useState<boolean>(false);
    const [file, setFile] = useState<any>(location?.state?.editdata?.image);
    const addStationImageError = useSelector((state: any) => state?.addAndEditStationImage?.error);
    const addStationImageLoading = useSelector((state: any) => state?.addAndEditStationImage?.loading);

    const handleSubmit = (values: FormValues) => {
        const formData = new FormData();
        formData.append('image', imageData ?? file);
        formData.append('stationId', station_Id ?? editStationId);
        dispatch(addAndEditStationImageAPI({ formData: formData, method: "POST" }));
        setFile(null);
        setImageData(null);
        setSubmitClicked(true);
    };
    const handleImageChange = (e: any) => {
        if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg") {
            setInvalidExtension(false);
            setImageData(e.target.files[0]);
        } else {
            setInvalidExtension(true);
        }
        // setImageData(e.target.files[0]);
    };
    useEffect(() => {
        submitClicked && !addStationImageLoading && addStationImageError.code === '' && setVisible(false);
        submitClicked && !addStationImageLoading && addStationImageError.code !== '' && setShowErrorComponent(true);
    }, [addStationImageError, addStationImageLoading, setVisible, submitClicked]);

    const validateForm = (values: any) => {
        const errors: Partial<any> = {};
        // if (!values.image) {
        //     errors.image = "Required";
        // } else if (imageData == null || imageData == '') {
        //     errors.image = "Required";
        // }
        if (!location?.state?.editdata) {
            if (imageData?.name == null || imageData?.name === '') {
                errors.image = "Required";
            }
        } else {
            if (!file && !imageData?.name) {
                errors.image = "Required";
            }
            // else if (imageData?.name == null || imageData?.name == '') {
            //     errors.image = "Required";
            // }
        }
        return errors;
    };
    return (

        <Dialog className={"addImg-dialog"} header="Add Station Images" visible={visible} style={{ width: '50vw' }} onHide={() => { setVisible(false); setImageData(null); setInvalidExtension(false); }}>
            <Form
                // initialValues={initialValues}
                onSubmit={handleSubmit}
                validate={validateForm}
                render={({ handleSubmit }) => (
                    <>
                        <form onSubmit={handleSubmit} className="p-fluid row">
                            <div className="d-flex align-items-center justify-content-around">
                                <div className="banners_inputwidth">
                                    <Field name="image">
                                        {({ input, meta }) => (
                                            <>
                                                {(imageData?.name || file) && <div onClick={() => { file && setFile(null); imageData?.name && setImageData({ ...imageData, name: null }); }} className="image_close" style={{
                                                    left: '15%', top: '39%',
                                                }}>x</div>}

                                                {(imageData?.name) ?
                                                    <div className='col-6' style={{ textAlign: 'center' }}>
                                                        <img src={URL.createObjectURL(imageData)} alt="something wrong" style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                                    </div>
                                                    :
                                                    file ?
                                                        <img src={file} alt='something wrong' style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                                        :
                                                        <>
                                                            <div className='col-6 ' style={{ textAlign: 'center' }}>
                                                                <label htmlFor='uploadimg' style={{ margin: '0.5%' }}>
                                                                    <div style={{ width: "110px" }}>Upload Image*</div>
                                                                    <FontAwesomeIcon icon={faPhotoFilm} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                                                                </label>
                                                            </div>
                                                        </>
                                                }
                                                {/* <input
                                    id='uploadimg'
                                    type='file'
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleImageChange(e)}
                                    accept="image/png, image/jpeg"
                                /> */}
                                                {/* <div className="d-none"> */}
                                                <div className="col-6 banners_inputwidth">
                                                    <Field name="image" >
                                                        {({ input, meta }) => (
                                                            <div >
                                                                <input
                                                                    {...input}
                                                                    id='uploadimg'
                                                                    type='file'
                                                                    style={{ display: 'none' }}
                                                                    onChange={(e) => { input.onChange(e.target.value); handleImageChange(e); }}
                                                                    accept="image/png, image/jpeg, image/jpg"
                                                                />
                                                                {meta.error && meta.touched && (
                                                                    <span className="error_msg">{meta.error}</span>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    {invalidExtension && <div className="error_msg" style={{ position: 'relative', top: '-25px', left: '-40px', width: '155px' }}>Select Image</div>}
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </div>
                                {/* <SubmitBtn> */}
                                <button className={'col-2 addStaImg_submit_btn'} type="submit">
                                    Submit
                                </button>
                                {/* </SubmitBtn> */}
                            </div>
                        </form>

                        {showErrorComponent && <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />}

                    </>
                )} >
            </Form >
        </Dialog >
    );
};

export default AddStationImages;