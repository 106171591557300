import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  DeviceName: [],
  error: null,
};

export const looupDeviceNameDropdownSlice = createSlice({
  name: "getDevices",
  initialState,
  reducers: {
    getDropData: (state, action) => {
      ;
      state.DeviceName = action.payload;
    },
  },
});

export const { getDropData } = looupDeviceNameDropdownSlice.actions;
