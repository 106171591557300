import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connectorStatus: [],
  error: null,
};

export const connectorStatusLookupSlice = createSlice({
  name: "getConnectorStatus",
  initialState,
  reducers: {
    getConnectorDropData: (state, action) => {
      if (action.payload.type === "CONNECTOR_STATUS") {
        state.connectorStatus = action.payload;
      } else if (action.payload.type === "error") {
        state.error = action.payload;
      }
    },
  },
});

export const { getConnectorDropData } = connectorStatusLookupSlice.actions;
