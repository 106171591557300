import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
// import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import { CommonPaths } from '../../../Routes/Paths';
import { getStationEmenitiesByIdApi } from '../../../store/actions/stationEmenities/getStationEmenityById';
import { addUpdateSTationEmenitiesAPI } from '../../../store/actions/stationEmenities/addStationEmenities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import PostToaster from '../../../Components/PostToaster';
import config from '../../../Constants/config';


interface FormValues {
    amenityName: string;
    description: string;
    image: string;
}

const initialValues: FormValues = {
    amenityName: '',
    description: '',
    image: '',
};

const AddStationEmenities = () => {
    const navigate = useNavigate();
    const { STATION_EMINITIES } = CommonPaths;
    const location = useLocation();
    const dispatch = useDispatch();
    const [imageData, setImageData] = useState<any>(null);
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [invalidExtension, setInvalidExtension] = useState<boolean>(false);
    const addAminitiesError = useSelector((state: any) => state?.addStationEmenities?.error);
    const addAminitiesLoading = useSelector((state: any) => state?.addStationEmenities?.loading);
    const [file, setFile] = useState<any>(location?.state?.editdata?.image);
    const { GLOBAL_IMAGE_DOMAIN } = config;

    if (!!location.state?.editdata?.amenityId) {
        initialValues.amenityName = location?.state?.editdata?.amenityName;
        initialValues.description = location?.state?.editdata?.description;
        // initialValues.image = `${GLOBAL_IMAGE_DOMAIN} + location?.state?.editdata?.image`;
    } else {
        initialValues.amenityName = "";
        initialValues.description = "";
        initialValues.image = "";
    }
    useEffect(() => {
        if (!!location.state?.editdata?.amenityId) {
            dispatch(getStationEmenitiesByIdApi(location.state.editdata.amenityId));
        }
        // eslint-disable-next-line
    }, [location.state]);

    const handleSubmit = (values: FormValues) => {
        // Handle form submission
        const formData = new FormData();
        formData.append('image', imageData ?? file);
        formData.append('amenityName', values?.amenityName);
        formData.append('description', values?.description);

        if (!location?.state?.editdata?.amenityId) {
            dispatch(addUpdateSTationEmenitiesAPI({ formData: formData, method: "POST" }));
        } else {
            const editRowId = location.state?.editdata?.amenityId;
            dispatch(addUpdateSTationEmenitiesAPI({ formData: formData, method: "PUT", id: `/${editRowId}` }));
        }
        setSubmitClicked(true);
    };

    useEffect(() => {
        submitClicked && !addAminitiesLoading && addAminitiesError?.code === '' && navigate(STATION_EMINITIES);
        submitClicked && !addAminitiesLoading && addAminitiesError?.code !== '' && setShowErrorComponent(true);
    }, [STATION_EMINITIES, addAminitiesError, addAminitiesLoading, navigate, submitClicked]);
    const validateForm = (values: FormValues) => {

        const errors: Partial<FormValues> = {};
        if (!values.amenityName) {
            errors.amenityName = 'Required';
        }
        if (!values.description) {
            errors.description = 'Required';
        }
        if (!location?.state?.editdata?.amenityId) {
            if (imageData?.name == null || imageData?.name === '') {
                errors.image = "Required";
            }
        } else {
            if (!file && !imageData?.name) {
                errors.image = "Required";
            }
            // else if (imageData?.name == null || imageData?.name == '') {
            //     errors.image = "Required";
            // }
        }
        return errors;
    };

    const handleClosePage = () => {
        navigate(STATION_EMINITIES);
    };
    const handleImageChange = (e: any) => {
        if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg") {
            setInvalidExtension(false);
            setImageData(e.target.files[0]);
        } else {
            setInvalidExtension(true);
        }
        // setImageData(e.target.files[0]);
    };

    return (
        <div>
            <div className='addPage_header'>
                <button className='back_Btn' onClick={handleClosePage}><i className="pi pi-arrow-left" style={{ fontSize: '1rem', color: 'white' }}></i></button>
                <h5 className='heading'>{location?.state?.popup ? "Edit Station Amenities" : "Add Station Amenities"}</h5>
                
            </div>
            {/*!!stationEmenityData.error &&  <Message severity='error' text={stationEmenityData.error} />*/}
            <div className='card mt-3 mx-3' >

                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid row">
                            <div className='col-4 inputwidth'>
                                <Field name="amenityName">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='amenityName'>Amenity Name*</label>
                                            <InputText id='amenityName' className="p-inputtext p-component w-full input_height" {...input} placeholder="Amenity Name" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-4 inputwidth'>
                                <Field name="description">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='description'>Description*</label>
                                            <InputText id='description' className="p-inputtext p-component w-full input_height" {...input} placeholder="Description" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="inputwidth">
                                <Field name="image">
                                    {({ input, meta }) => (
                                        <>
                                            {(imageData?.name || file) && <div onClick={() => { file && setFile(null); imageData?.name && setImageData({ ...imageData, name: null }); }} className='image_close' style={{
                                                left: '72%', marginTop: '1.5%',
                                            }}>x</div>}

                                            {(imageData?.name) ?
                                                <div className=' inputwidth' style={{ textAlign: 'center', marginTop: '15px' }}>
                                                    <img src={URL.createObjectURL(imageData)} alt="Select the valid file" style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                                </div>
                                                :
                                                file ?
                                                    <img src={GLOBAL_IMAGE_DOMAIN + file} alt='something wrong' style={{ width: '130px', height: '65px', borderRadius: '15px', marginTop: '15px' }} />
                                                    :
                                                    <>
                                                        <div className=' inputwidth' style={{ textAlign: 'center' }}>
                                                            <label htmlFor='uploadimg' style={{ margin: '0.5%' }}>
                                                                <div style={{ width: "110px", marginTop: "8px" }}>Upload Image*</div>
                                                                <FontAwesomeIcon icon={faPhotoFilm} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                                                            </label>
                                                        </div>

                                                    </>
                                            }
                                            {/* {imageData === null ?
                                                <label htmlFor='uploadimg' style={{ margin: '0.5%' }} className='col-4'>
                                                    <div style={{ width: "110px" }}>Upload Image*</div>
                                                    <FontAwesomeIcon icon={faPhotoFilm} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                                                </label> :
                                                <>
                                                    <div onClick={() => { setFile(null); imageData?.name && setImageData(null); }} style={{
                                                        position: 'absolute', left: '71%', width: '25px', height: '25px', marginTop: '1.5%',
                                                        border: '1px solid red', background: 'red', borderRadius: '25px', textAlign: "center",
                                                        color: 'whitesmoke', fontSize: '15px', fontWeight: '600', cursor: 'pointer',
                                                    }}>x</div>

                                                    {(imageData?.name) ?
                                                        <img src={URL.createObjectURL(imageData)} alt="something wrong" style={{ width: '12vw', height: '15vh', borderRadius: '15px', marginTop: '15px' }} /> :
                                                        null
                                                    }
                                                </>
                                            } */}
                                            {/* <input
                                id='uploadimg'
                                type='file'
                                style={{ display: 'none' }}
                                onChange={(e) => handleImageChange(e)}
                                accept="image/png, image/jpeg"
                            /> */}
                                            <div className="col-4 banners_inputwidth">
                                                <Field name="image" >
                                                    {({ input, meta }) => (
                                                        <span className="field">
                                                            <input
                                                                {...input}
                                                                id='uploadimg'
                                                                // value={initialValues.image}
                                                                type='file'
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => { input.onChange(e.target.value); handleImageChange(e); }}
                                                                accept="image/png, image/jpeg, image/jpg"
                                                            />
                                                            {meta.error && meta.touched && (
                                                                <span className="error_msg">{meta.error}</span>
                                                            )}
                                                        </span>
                                                    )}
                                                </Field>
                                                {invalidExtension && <div className="error_msg" style={{ position: 'relative', top: '-20px', width: '155px' }}>Select Image</div>}
                                            </div>
                                        </>
                                    )}
                                </Field>
                            </div>
                            <button className={window.innerWidth < 786 ? 'col-4 submit_btn' : 'col-1 submit_btn'} type="submit" >Submit</button>
                        </form>
                    )}
                />
            </div>
            <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />
        </div >
    );
};

export default AddStationEmenities;