import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHARGER_TYPES } from "../../../API/UserApi";
import axios from "axios";

export const addChargerConnectorTypesAPI: any = createAsyncThunk(
  "addStations",
  async ({ formData, method, id }: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");

    try {
      const res = await axios({
        method: method,
        url: !!id ? CHARGER_TYPES + id : CHARGER_TYPES,
        data: formData,
        headers: {
          // istoken:'false'
          "access-control-allow-origin": "*",
          token: JSON.parse(token),
          "Content-Type": "multipart/form-data",
        },
      });
      return res;
    } catch (error: any) {
      console.log(error, "error charger connector");
      // if (error instanceof Error) {
      return rejectWithValue({
        error: { code: error.response.status, message: error.message },
      });
      // }
    }
  }
);
