import { Menubar } from 'primereact/menubar';
import { Avatar } from 'primereact/avatar';
// import logo from '../Images/logo.png';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CommonPaths } from '../Routes/Paths';
import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import styled from 'styled-components';

const logo = require('../Images/logo.png');

const { HOME } = CommonPaths;

type HeaderProps = {
    setVisible: (val: boolean) => void;
};

const ButtonOutline = styled.button`
    :focus{
        outline:none;
        box-shadow: 0px 0px 27px -7px;
    }
    background:none;
    border:none;
  
`;

const Header = ({ setVisible }: HeaderProps) => {

    const userClass = `col-7 ${window.innerWidth < 300 ? "d-none" : "d-block m-auto"}`;
    const menu = useRef<Menu>(null);
    const [toogle, setToogle] = useState<boolean>(window.innerWidth < 786 ? false : true);
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || '{}');
    const navigate = useNavigate();
    useEffect(() => {
        setVisible(toogle);
        // eslint-disable-next-line
    }, [toogle]);

    const { LOGIN_PATH, CONFIGSETTINGS } = CommonPaths;

    const items = [
        {
            items: [
                {
                    label: 'Profile',
                    icon: 'pi pi-refresh',
                },
                {
                    label: 'Change Password',
                    icon: 'pi pi-refresh',
                },
                {
                    label: 'Notifications',
                    icon: 'pi pi-refresh',
                },
                {
                    label: 'Settings',
                    icon: 'pi pi-refresh',
                    command: () => {
                        navigate(CONFIGSETTINGS);
                    }
                },
                {
                    label: 'Logout',
                    icon: 'pi pi-refresh',
                    command: () => {
                        localStorage.removeItem("token");
                        navigate(LOGIN_PATH);
                        // router.push('/fileupload');
                    }
                },
            ]
        }
    ];

    const Start = () => {

        return (
            <>
                <div className='container'>
                    <div className="row">
                        <div className="col-4 m-auto">
                            <ButtonOutline>

                                <i typeof="button" className="pi pi-align-justify" onClick={(e) => { setToogle(!toogle); }}></i>

                            </ButtonOutline>
                        </div>
                        <div className="col-8 text-center">
                            <Link to={HOME}>
                                <img src={logo} alt='logo' className='w-8rem' />
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    };



    const End = () => {

        return (
            <>
                <div className='row' >
                    <div className={userClass}>
                        <p className='mb-0'>
                            {/* Admin User */}
                            {userDetails?.data?.data?.userData?.firstName}&nbsp;{userDetails?.data?.data?.userData?.lastName}
                        </p>
                    </div>
                    <div className="col-5">
                        <Avatar onClick={(e) => menu.current!.toggle(e)} icon="pi pi-user" className="mr-2" size="large" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />
                        <Menu model={items} popup ref={menu} />
                    </div>
                </div>
            </>
        );
    };

    return (
        <Menubar start={<Start />} end={<End />} />
    );
};

export default Header;