import { createSlice } from "@reduxjs/toolkit";
import { getChargingDeviceListAPI } from "../../actions/chargingDevice/getChargingDeviceList";

interface ChargingDeviceListState {
  devices: any[];
  loading: any;
  error: string | null;
}

const initialState: ChargingDeviceListState = {
  devices: [],
  loading: false,
  error: null,
};

export const chargingDevicesListSlice = createSlice({
  name: "getChargingDevices",
  initialState,
  reducers: {
    resetGetChargingDevices: (state) => {
      state.devices = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChargingDeviceListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getChargingDeviceListAPI.fulfilled, (state, action) => {
        state.devices = action.payload.device.data;
        state.loading = false;
      })
      .addCase(getChargingDeviceListAPI.rejected, (state, action) => {
        state.devices = action.payload.error;
        state.loading = "failed";
      });
  },
});

export const { resetGetChargingDevices } = chargingDevicesListSlice.actions;
export default chargingDevicesListSlice.reducer;
