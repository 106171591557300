import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviceStatus: [],
  error: null,
};

export const deviceStatusLookupSlice = createSlice({
  name: "getDeviceStatus",
  initialState,
  reducers: {
    getDeviceStatusData: (state, action) => {
      if (action.payload.type === "STATION_DEVICE_STATUSES") {
        state.deviceStatus = action.payload;
      } else if (action.payload.type === "error") {
        state.error = action.payload;
      }
    },
  },
});

export const { getDeviceStatusData } = deviceStatusLookupSlice.actions;
