import { createSlice } from "@reduxjs/toolkit";
import { getLookupTypesListAPI } from "../../actions/lookupTypes/getLookupTypesList";

interface LookupTypeListState {
  getLookupTypes: any[];
  loading: boolean;
  error: { code: string; message: string };
}

const initialState: LookupTypeListState = {
  getLookupTypes: [],
  loading: false,
  error: { code: "", message: "" },
};

export const getLookupTypesSlice = createSlice({
  name: "getLookupTypes",
  initialState,
  reducers: {
    resetGetLookupTypes: (state) => {
      state.getLookupTypes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLookupTypesListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getLookupTypesListAPI.fulfilled, (state, action) => {
        state.getLookupTypes = action.payload.lookupType.data;
        state.loading = false;
      })
      .addCase(getLookupTypesListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetLookupTypes } = getLookupTypesSlice.actions;
export default getLookupTypesSlice.reducer;
