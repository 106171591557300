import { createSlice } from "@reduxjs/toolkit";
import { addStationDeviceConnectorsAPI } from "../../actions/stations/addStationDeviceConnectors";

const initialState = {
  stationDeviceConnector: {},
  loading: false,
  error: null,
};

const addStationDeviceConnectorSlice = createSlice({
  name: "addStationDeviceConnectors",
  initialState,
  reducers: {
    resetUserData: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addStationDeviceConnectorsAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStationDeviceConnectorsAPI.fulfilled, (state, action) => {
        state.stationDeviceConnector = action.payload.data;
        state.loading = false;
      })
      .addCase(addStationDeviceConnectorsAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetUserData } = addStationDeviceConnectorSlice.actions;
export default addStationDeviceConnectorSlice.reducer;
