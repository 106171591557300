import { createSlice } from "@reduxjs/toolkit";
import { deleteStationsAPI } from "../../actions/stations/deleteStations";

const initialState = {
  addStation: {},
  loading: false,
  error: null,
};

const deleteStationDeviceSlice = createSlice({
  name: "deleteStationDevice",
  initialState,
  reducers: {
    deleteUserData: (state, action) => {
      state.addStation = action.payload.userAdded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStationsAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteStationsAPI.fulfilled, (state, action) => {
        state.addStation = action.payload.userDeleted;
        state.loading = false;
      })
      .addCase(deleteStationsAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteUserData } = deleteStationDeviceSlice.actions;
export default deleteStationDeviceSlice.reducer;
