import { createSlice } from "@reduxjs/toolkit";
import { getStationDevicesListAPI } from "../../actions/stations/getStationDeviceList";

interface StationDeviceListState {
  stationDevices: any[];
  loading: boolean;
  error: { code: string; message: string };
}

const initialState: StationDeviceListState = {
  stationDevices: [],
  loading: false,
  error: { code: "", message: "" },
};

export const stationDevicesListSlice = createSlice({
  name: "getStationDevices",
  initialState,
  reducers: {
    resetGetStationDevices: (state) => {
      state.stationDevices = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStationDevicesListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStationDevicesListAPI.fulfilled, (state, action) => {
        state.stationDevices = action.payload.stationDevice.data;
        state.loading = false;
      })
      .addCase(getStationDevicesListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetStationDevices } = stationDevicesListSlice.actions;
export default stationDevicesListSlice.reducer;
