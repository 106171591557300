import axios from "axios";
import { devicePowerRatingLookupSlice } from "../../slice/chargingDevice/getDevicePowerRatingLookupSlice";
import apiHeader from "../../../Components/ApiHeader";

const { actions } = devicePowerRatingLookupSlice;

export const devicePowerRatingLookUpDropdownAPI: any =
  ({ ApiUrl, Type, params }: any) =>
  (dispatch: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    axios
      .get(ApiUrl, {
        params: params,
        headers: commonHead,
      })
      .then((response) => {
        const { data } = response;
        dispatch(actions.getDevicePowerRatingData({ type: Type, data }));
      })
      .catch((err) => {
        dispatch(
          actions.getDevicePowerRatingData({
            type: "error",
            paylod: err.message,
          })
        );
      });
  };
