import axios from "axios";
import { chargerTypeLookupSlice } from "../../slice/chargingDevice/getChargerTypeLookupSlice";
import apiHeader from "../../../Components/ApiHeader";

const { actions } = chargerTypeLookupSlice;

export const chargerTypeLookUpDropdownAPI: any =
  ({ ApiUrl, Type, params }: any) =>
  (dispatch: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    axios
      .get(ApiUrl, {
        params: params,
        headers: commonHead,
      })
      .then((response) => {
        const { data } = response;
        dispatch(actions.getChargerTypeData({ type: Type, data }));
      })
      .catch((err) => {
        dispatch(
          actions.getChargerTypeData({ type: "error", paylod: err.message })
        );
      });
  };
