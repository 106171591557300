import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  States: [],
  error: null,
};

export const looupStatesDropdownSlice = createSlice({
  name: "getStates",
  initialState,
  reducers: {
    getStatesDropData: (state, action) => {
      state.States = action.payload;
    },
  },
});

export const { getStatesDropData } = looupStatesDropdownSlice.actions;
