import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../Constants/config";
import TableHeader from "../../../Components/TableHeader";
import { CommonPaths } from "../../../Routes/Paths";
import { getChargingDeviceListAPI } from "../../../store/actions/chargingDevice/getChargingDeviceList";
import { deleteChargingDevicesAPI } from "../../../store/actions/chargingDevice/deleteChargingDevice";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import { deviceBrandLookUpDropdownAPI } from "../../../store/actions/chargingDevice/getDeviceBrandLookup";
import { LOOKUP_CODES } from "../../../API/UserApi";
import { devicePowerRatingLookUpDropdownAPI } from "../../../store/actions/chargingDevice/getDevicePowerRatingLookup";
import { deviceModelLookUpDropdownAPI } from "../../../store/actions/chargingDevice/getDeviceModelLookup";
import { chargerTypeLookUpDropdownAPI } from "../../../store/actions/chargingDevice/getChargerTypeLookup";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import { deleteChargingDeviceData } from "../../../store/slice/chargingDevice/deleteChargingDeviceSlice";

type TableRow = {
    stationId: number,
    deviceUid: number,
    chargingDeviceName: string,
    chargingDeviceId: number,
    brand: string,
    model: string,
    status: string,
    type: string,
    powerRating: string,
};

const ChargingDevice = () => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [rows, setRows] = useState<number>(5);
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const popup = true;
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [associatedDeletePopup, setAssociatedDeletePopup] = useState<boolean>(false);
    const [rowId, setRowId] = useState<number>();
    const [viewData, setViewData] = useState<any>({});
    const [chargingDevicePage, setChargingDevicePage] = useState<number>(0);
    const [searchVal, setSearchVal] = useState<string>('');
    const dispatch = useDispatch();
    const chargingDevicesData = useSelector((state: any) => state?.getChargingDevices);
    const errorMessage = useSelector((state: any) => state?.getChargingDevices?.devices?.errorMessage);
    const associatedDeleteErrorMessage = useSelector((state: any) => state?.deleteChargingDevice?.deleteDevice?.data?.error);
    const { loading } = useSelector((state: any) => state?.deleteChargingDevice);

    const navigate = useNavigate();
    const { ADD_CHARGING_DEVICE } = CommonPaths;

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };

    const handleEditRow = (rowData: TableRow) => {
        navigate(ADD_CHARGING_DEVICE, { state: { editdata: rowData, popup: popup } });
    };

    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.chargingDeviceId);
    };

    const handleDeleteRow = (rowId: any) => {
        dispatch(deleteChargingDevicesAPI(rowId));
        setDeletePopup(false);
    };

    const updateRefreshButton = (value: any) => {
        value && getChargingDevicesList(chargingDevicePage, searchVal, rows);
    };
    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon icon={faPen} onClick={() => handleEditRow(rowData)} />
                <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };

    useEffect(() => {
        !loading && getChargingDevicesList(chargingDevicePage, searchVal, rows);
        // eslint-disable-next-line
    }, [loading, searchVal, rows]);

    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setChargingDevicePage(event.page + 1);
        getChargingDevicesList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };

    const getChargingDevicesList = (cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
        };
        dispatch(getChargingDeviceListAPI(params));
    };

    const getChargingDeviceBrandList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "BRAND"
        };
        dispatch(deviceBrandLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "BRAND", params: params }));
    };
    const getChargingDevicePowerRatingList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "POWER_RATING"
        };
        dispatch(devicePowerRatingLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "POWER_RATING", params: params }));
    };
    const getChargingDeviceModelList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "MODEL"
        };
        dispatch(deviceModelLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "MODEL", params: params }));
    };
    const getChargerTypeList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "CHARGER_TYPE"
        };
        dispatch(chargerTypeLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "CHARGER_TYPE", params: params }));
    };
    useEffect(() => {
        getChargingDeviceBrandList();
        getChargingDevicePowerRatingList();
        getChargingDeviceModelList();
        getChargerTypeList();
        // eslint-disable-next-line
    }, []);
    const handleSearch = (val: string) => {
        setSearchVal(val);
    };
    useEffect(() => {
        associatedDeleteErrorMessage && setAssociatedDeletePopup(true);
        // eslint-disable-next-line
    }, [associatedDeleteErrorMessage]);
    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className='mx-3'>
            {
                chargingDevicesData?.error?.code ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <>
                        <TableHeader label="Add Devices" heading='All Charging Devices' searchKeys="Search Device name" addEvent={() => {  navigate(ADD_CHARGING_DEVICE); }} fontSize="1rem" width="11rem" onSearch={handleSearch} />
                        <div className="card">
                            <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow'
                                value={chargingDevicesData?.devices?.rows} loading={chargingDevicesData?.loading}
                                scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                                <Column field="chargingDeviceName" header="Device Name" sortable ></Column>
                                <Column field="brand" header="Brand" sortable ></Column>
                                <Column field="model" header="Model" sortable ></Column>
                                <Column field="type" header="Type" sortable ></Column>
                                <Column field="powerRating" header="Power Rating (kW)" sortable ></Column>
                                <Column frozen={true} alignFrozen="right" header="Actions" body={actionItems} ></Column>
                            </DataTable>
                            <Paginator first={currentPage} rows={rows} totalRecords={chargingDevicesData.devices.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />

                            <Dialog className='viewPopup' header={`Device : ${viewData.chargingDeviceName}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                                <div >
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Device Name</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.chargingDeviceName}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Brand</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.brand}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Model </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.model}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Type </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.type}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Device Specifications </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.deviceSpecifications}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Power Rating (kW) </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.powerRating}</div>
                                    </div>
                                </div>
                            </Dialog>

                            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                                <div className="delpop_btn_div">
                                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)} >Yes</Button>
                                </div>
                            </Dialog>
                            {
                                associatedDeleteErrorMessage &&
                                <Dialog className='deletePopup' header="Record is not Deleted" visible={associatedDeletePopup} style={{ width: '50vw' }} onHide={() => { setAssociatedDeletePopup(false); dispatch(deleteChargingDeviceData()); }} >
                                    <div className="delpop_btn_div">
                                        {associatedDeleteErrorMessage}
                                    </div>
                                </Dialog>
                            }
                            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='chargingDeviceList' />
                        </div>
                    </>
            }
        </div>
    );
};

export default ChargingDevice;