import axios from "axios";
import { looupDropdownSlice } from "../slice/getDropdownSlice";
import apiHeader from "../../Components/ApiHeader";

const { actions } = looupDropdownSlice;

export const lookUpDropdownAPI: any =
  ({ ApiUrl, Type, params }: any) =>
  (dispatch: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    axios
      .get(ApiUrl, {
        params: params,
        headers: commonHead,
      })
      .then((response) => {
        const { data } = response;
        dispatch(actions.getDropData({ type: Type, data }));
      })
      .catch((err) => {
        dispatch(actions.getDropData({ type: "error", paylod: err.message }));
      });
  };
