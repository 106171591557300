import { createSlice } from "@reduxjs/toolkit";
import { getChargerConnectorsListAPI } from "../../actions/chargerConnectorType/getChargingConnectorsList";

interface ConnectorListState {
  chargerConnectors: any[];
  loading: boolean;
  error: { code: string; message: string };
}
const initialState: ConnectorListState = {
  chargerConnectors: [],
  loading: false,
  error: { code: "", message: "" },
};

export const chargerConnectorsListSlice = createSlice({
  name: "getChargerConnectors",
  initialState,
  reducers: {
    resetGetChargerConnectors: (state) => {
      state.chargerConnectors = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChargerConnectorsListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getChargerConnectorsListAPI.fulfilled, (state, action) => {
        state.chargerConnectors = action.payload.connectors.data;
        state.loading = false;
      })
      .addCase(getChargerConnectorsListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetChargerConnectors } = chargerConnectorsListSlice.actions;
export default chargerConnectorsListSlice.reducer;
