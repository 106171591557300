import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CommonPaths } from "../../../Routes/Paths";
import TableHeader from "../../../Components/TableHeader";
import config from "../../../Constants/config";
import { getBannersListAPI } from "../../../store/actions/banners/getBannersList";
import { deleteBannersAPI } from "../../../store/actions/banners/deleteBanners";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Image } from 'primereact/image';
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

export type TableRow = {
    type: string,
    images: [],
    bannerId?: number,
};
const Banners = () => {
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>();
    const [bannerSkipValue, setBannerSkipValue] = useState<number>(0);
    const [searchVal, setSearchVal] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [rows, setRows] = useState<number>(5);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [rowId, setRowId] = useState<number>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bannersList = useSelector((state: any) => state?.getBannersList);
    const errorMessage = useSelector((state: any) => state?.getBannersList?.banners?.errorMessage);
    const totalCount = useSelector((state: any) => state?.getBannersList?.banners?.total_count);
    const isLoadingDelete = useSelector((state: any) => state?.deleteBanners?.loading);
    const { ADD_BANNERS } = CommonPaths;
    const { GLOBAL_IMAGE_DOMAIN } = config;
    const popup = true;

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };
    const handleEditRow = (rowData: TableRow) => {
        navigate(ADD_BANNERS, { state: { editdata: rowData, popup: popup, } });
    };

    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.bannerId);
    };

    const handleDeleteRow = (rowId: any) => {
        dispatch(deleteBannersAPI(rowId));
        setDeletePopup(false);
    };
    const updateRefreshButton = (value: any) => {
        value && getBannersList(bannerSkipValue, searchVal, rows);
    };

    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon className="testPopUp" icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon className="testEdit" icon={faPen} onClick={() => handleEditRow(rowData)} />
                <FontAwesomeIcon className="testDelete" icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };

    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setBannerSkipValue(event.page + 1);
        getBannersList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };

    const getBannersList = (cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
        };
        dispatch(getBannersListAPI(params));
    };

    useEffect(() => {
        !isLoadingDelete && getBannersList(bannerSkipValue, searchVal, rows);
        // eslint-disable-next-line
    }, [isLoadingDelete, bannerSkipValue, searchVal, rows]);

    const imgBody = (row: any) => {
        const imageArray = Object.values(row)?.find(Array.isArray);
        return imageArray && imageArray?.map((image, idx: any) => {
            return <Image key={idx} src={GLOBAL_IMAGE_DOMAIN + image.img} width="30" height="30" className="mr-2" preview />;
        });
    };

    let viewImageData;
    if (viewData) {
        viewImageData = Object.values(viewData)?.find(Array.isArray);
    }

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };

    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className="mx-3">
            {
                bannersList?.error.code !== "" ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <>
                        <TableHeader heading='Banners List' searchKeys='Search Type' label="Add Banners" fontSize="1rem" width="12rem" addEvent={() => {navigate(ADD_BANNERS); }} onSearch={handleSearch} />
                        <div className="card">
                            <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow' value={bannersList?.banners?.rows} loading={bannersList?.loading} scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                                <Column field="type" header="Type" sortable ></Column>
                                <Column body={imgBody} header="Image"  ></Column>
                                <Column frozen={true} alignFrozen="right" header="Actions" className="test" body={actionItems}></Column>
                            </DataTable>
                            <Paginator first={currentPage} rows={rows} totalRecords={totalCount} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />

                            <Dialog className='viewPopup' header={`Banner -${viewData?.type}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                                <div >
                                    <div >
                                        <span className="col-3">Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :</span>
                                        <span className="col-5">{viewData?.type}</span>
                                    </div>
                                    <div className="mt-4">
                                        <span className="col-3">Images &nbsp; :</span>
                                        <span >
                                            {viewImageData?.map((image: any) => { return <Image src={GLOBAL_IMAGE_DOMAIN + image?.img} style={{ borderRadius: "3px !important", marginRight: '10px' }} width="55" height="55" preview />; })}
                                        </span>
                                    </div>
                                </div>
                            </Dialog>
                            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                                <div className="delpop_btn_div">
                                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)}>Yes</Button>
                                </div>
                            </Dialog>
                            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='bannersList' />
                        </div>
                    </>
            }
        </div >
    );
};

export default Banners;