import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { addChargingDeviceConnectorsAPI } from "../../../store/actions/chargingDevice/addChargingDeviceConnectors";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import PostToaster from "../../../Components/PostToaster";
// import styled from "styled-components";

// const SubmitBtn: any = styled.div`
// margin: 0px;
// padding: 0px;
// `;

interface DeviceFormValues {
    chargerConnectorTypeId: string;
    positionOfConnector: string;
}

const deviceInitialValues: DeviceFormValues = {
    chargerConnectorTypeId: '',
    positionOfConnector: '',
};


const AddDeviceConnector = ({ onSubmit, visible, header, setVisible, device_Id, newDeviceId, editData }: any) => {
    const connectorType_Name = useSelector((state: any) => state?.getChargerConnectors?.chargerConnectors?.rows);
    const dispatch = useDispatch();
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const addDeviceConnectorError = useSelector((state: any) => state?.addChargingDeviceConnectors?.error);
    const addDeviceConnectorLoading = useSelector((state: any) => state?.addChargingDeviceConnectors?.loading);
    const [connectorTypeValue, setConnectorTypeValue] = useState<any>(editData?.chargerConnectorTypeId);
    if (editData?.chargerConnectorTypeId) {
        deviceInitialValues.chargerConnectorTypeId = editData?.chargerConnectorTypeId;
        deviceInitialValues.positionOfConnector = editData?.positionOfConnector;
    }
    else {
        deviceInitialValues.chargerConnectorTypeId = connectorType_Name?.[0].chargerConnectorTypeId;
        // deviceInitialValues.chargerConnectorTypeId = connectorType_Name[0]?.chargerConnectorTypeId;
        deviceInitialValues.positionOfConnector = "";
    }

    // let defaultConnectorType: any;
    // if (connectorType_Name) {
    //     defaultConnectorType = connectorType_Name[0]?.chargerConnectorTypeId;
    // }
    const validateForm = (values: DeviceFormValues) => {
        const errors: Partial<DeviceFormValues> = {};
        // Add validation logic for each field
        if (!values.chargerConnectorTypeId) {
            errors.chargerConnectorTypeId = 'Required';
        }
        if (!values.positionOfConnector) {
            errors.positionOfConnector = 'Required';
        }
        return errors;
    };
    const connectorTypeName = connectorType_Name?.map((item: any) => (
        {
            value: item.chargerConnectorTypeId,
            label: item.chargerTypeName
        }
    ));

    const handleSubmitForm = (values: any) => {
        const addConnetors = { ...values, chargingDeviceId: device_Id ?? newDeviceId };

        if (!editData.chargingDeviceConnectorId) {
            dispatch(addChargingDeviceConnectorsAPI({ formData: addConnetors, method: "POST" }));
        } else {
            dispatch(addChargingDeviceConnectorsAPI({ formData: values, method: "PUT", id: `/${editData.chargingDeviceConnectorId}` }));
        }
        setSubmitClicked(true);
    };

    useEffect(() => {
        !visible && setConnectorTypeValue(null);
    }, [visible]);
    useEffect(() => {
        submitClicked && !addDeviceConnectorLoading && addDeviceConnectorError.code === '' && setVisible(false);
        submitClicked && !addDeviceConnectorLoading && addDeviceConnectorError.code !== '' && setShowErrorComponent(true);
    }, [addDeviceConnectorError, addDeviceConnectorLoading, setVisible, submitClicked]);

    return (
        <>
            <Dialog className={window.innerWidth < 786 ? '' : "dialog_form"} header={header} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <Form
                    initialValues={deviceInitialValues}
                    onSubmit={handleSubmitForm}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <>
                            <form onSubmit={handleSubmit} className="p-fluid row">
                                <div className="row">
                                    {/* <h3 className="mt-2 ml-4">Device: </h3> */}
                                    <div className="col-sm-12 col-md-6 inputwidth">
                                        <Field name="chargerConnectorTypeId">
                                            {({ input, meta }) => (
                                                <div className="field">
                                                    <label>Select Connector Type*</label>
                                                    <Dropdown
                                                        {...input}
                                                        value={connectorTypeValue || deviceInitialValues.chargerConnectorTypeId}
                                                        options={connectorTypeName}
                                                        placeholder="Select Connector Type"
                                                        className="w-full md:w-40rem"
                                                        onChange={(e: any) => { input.onChange(e.target.value); setConnectorTypeValue(e.target.value); }}
                                                    />
                                                    {meta.error && meta.touched && (
                                                        <span className="error_msg">{meta.error}</span>
                                                    )}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-sm-12 col-md-6 inputwidth">
                                        <Field name="positionOfConnector">
                                            {({ input, meta }) => (
                                                <div className="field">
                                                    <label htmlFor="positionOfConnectors">Position Of Connector*</label>
                                                    <InputText id="positionOfConnectors" {...input} placeholder="Position Of Connector" />
                                                    {meta.error && meta.touched && (
                                                        <span className="error_msg">{meta.error}</span>
                                                    )}
                                                </div>
                                            )}
                                        </Field>
                                    </div>

                                    {/* <div className="col-sm-12 col-md-6 inputwidth">
                                    <SubmitBtn>

                                        <button className="col-1 submit_btn" type="submit">
                                            Submit
                                        </button>

                                    </SubmitBtn>
                                </div> */}
                                    <div className="col-sm-12 col-md-6">
                                        <button className="submit_btn" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </div>

                            </form>

                            <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />

                        </>
                    )} />
            </Dialog>

        </>
    );
};

export default AddDeviceConnector;