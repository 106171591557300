import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonPaths } from '../../../Routes/Paths';
import { useDispatch, useSelector } from 'react-redux';
import { addStationsAPI } from '../../../store/actions/stations/addStations';
// import { FileUpload } from 'primereact/fileupload';
import Select from 'react-select';
import { lookUpNetworkNameDropdownAPI } from '../../../store/actions/stations/getLookupNetworkNames';
import { CHARGING_NETWORKS } from '../../../API/UserApi';
import { InputText } from 'primereact/inputtext';

interface FormValues {
    stationName: string;
    phoneNumber: string;
    street: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    latitude: string;
    longitude: string;
    status: string;
    chargingNetworkId: string;
    email: string;
}

const initialValues: FormValues = {
    stationName: '',
    phoneNumber: '',
    street: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    latitude: '',
    longitude: '',
    status: '',
    chargingNetworkId: '',
    email: '',
};

// interface City {
//     value: string;
//     label: string;
// }

const AddChargerBookings = () => {

    // const [selectedCities, setSelectedCities] = useState<City | null>(null);
    const [selectedOption, setSelectedOption] = useState<null>(null);
    const [networkLabelName, setNetworkLabelName] = useState<string>("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { CHARGER_BOOKINGS } = CommonPaths;
    const location = useLocation();
    const network_Name = useSelector((state: any) => state?.getNetworkList?.StationName?.type?.rows);

    
    const networkName = network_Name?.map((item: any) => (
        {
            value: item.networkId,
            label: item.networkName
        }

    ));
    // useEffect(() => {

    if (location.state) {
        initialValues.stationName = location.state.editdata.stationName;
        initialValues.chargingNetworkId = location.state.editdata.chargingNetworkId;
        initialValues.phoneNumber = location.state.editdata.phoneNumber;
        initialValues.street = location.state.editdata.street;
        initialValues.city = location.state.editdata.city;
        initialValues.state = location.state.editdata.state;
        initialValues.country = location.state.editdata.country;
        initialValues.pincode = location.state.editdata.pincode;
        initialValues.latitude = location.state.editdata.latitude;
        initialValues.longitude = location.state.editdata.longitude;
        initialValues.status = location.state.editdata.status;
        initialValues.email = location.state.editdata.email;
    } else {
        initialValues.stationName = "";
        initialValues.phoneNumber = "";
        initialValues.street = "";
        initialValues.city = "";
        initialValues.state = "";
        initialValues.country = "";
        initialValues.status = "";
        initialValues.pincode = "";
        initialValues.latitude = "";
        initialValues.longitude = "";
        initialValues.chargingNetworkId = "";
        initialValues.email = "";
    }
    // }, []);
    // STATION_ID
    const handleSubmit = (values: FormValues) => {
        // Handle form submission
        const newStation = { ...values, chargingNetworkId: selectedOption };
        if (!location.state) {
            dispatch(addStationsAPI({ formData: newStation, method: "POST" }));
        } else {
            dispatch(addStationsAPI({ formData: values, method: "PUT", id: `/${location.state.editdata.stationId}` }));
        }
        navigate(CHARGER_BOOKINGS);
    };

    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        // Add validation logic for each field
        if (!values.stationName) {
            errors.stationName = 'Required';
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = 'Required';
        }
        if (!values.city) {
            errors.city = 'Required';
        }
        if (!values.state) {
            errors.state = 'Required';
        }
        if (!values.country) {
            errors.country = 'Required';
        }
        if (!values.status) {
            errors.status = 'Required';
        }
        if (!values.street) {
            errors.street = 'Required';
        }
        if (!values.pincode) {
            errors.pincode = 'Required';
        }
        if (!values.email) {
            errors.email = 'Required';
        }
        if (!values.latitude) {
            errors.latitude = 'Required';
        }
        if (!values.longitude) {
            errors.longitude = 'Required';
        }
        return errors;
    };


    const getNetworkName = () => {
        dispatch(lookUpNetworkNameDropdownAPI({ ApiUrl: CHARGING_NETWORKS }));
    };

    useEffect(() => {
        getNetworkName();
        // eslint-disable-next-line
    }, []);

    const handleChange = (e: any) => {
        setSelectedOption(e.value);
        setNetworkLabelName(e.label);
    };
    const handleClosePage = () => {
        navigate(CHARGER_BOOKINGS);
    };
    return (
        <div>
            {/* <h5 className='heading'>Add Charger Bookings</h5> */}
            <div className="addPage_header container">
                <button className='back_Btn' onClick={handleClosePage}><i className="pi pi-arrow-left" style={{ fontSize: '1rem', color: 'white' }}></i></button>
                <h5 className='heading'>Add Charger Bookings</h5>
            </div>
            <div className='card mt-3 mx-3' >
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid row">
                            <div className='col-6 inputwidth'>
                                <div className="field">
                                    <label>Charging Network Id*</label>
                                    <Select defaultValue={networkLabelName} options={networkName} onChange={handleChange} />
                                    {/* {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>} */}
                                </div>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="stationName">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Station Name*</label>
                                            <InputText  {...input} placeholder="Station" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className='col-6 inputwidth'>
                                <Field name="phoneNumber">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Mobile Number*</label>
                                            <InputText  {...input} placeholder="PHONE_NUMBER" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="street">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Street*</label>
                                            <InputText  {...input} placeholder="Street" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="city">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>City*</label>
                                            <InputText  {...input} placeholder="city" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="state">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>State*</label>
                                            <InputText  {...input} placeholder="state" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="country">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Country*</label>
                                            <InputText  {...input} placeholder="country" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="pincode">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Pincode*</label>
                                            <InputText  {...input} placeholder="Pincode" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="latitude">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Latitude*</label>
                                            <InputText  {...input} placeholder="LATITUDE" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="longitude">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Longitude*</label>
                                            <InputText  {...input} placeholder="LONGITUDE" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="status">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Status*</label>
                                            <InputText  {...input} placeholder="status" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="email">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Email*</label>
                                            <InputText  {...input} placeholder="Email" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <button className='col-1 submit_btn' type="submit" >Submit</button>
                        </form>
                    )}
                />
            </div>
        </div>
    );
};

export default AddChargerBookings;