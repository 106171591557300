import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteStationAmenitiesAPI } from "../../../store/actions/stations/deleteStationAmenities";
import { getStationAminityListAPI } from "../../../store/actions/stations/getStationAminityList";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";

type TableRow = {
    amenityId: string,
    stationAmenityId?: string,
};
const ListOfAmenities = ({ editStationId }: any) => {
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>({});
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [rowId, setRowId] = useState<string>();
    const location = useLocation();
    const aminitiesList = useSelector((state: any) => state?.getStationAminity?.stationAminities);
    const aminitiesData = useSelector((state: any) => state?.getStationAminity);
    const station_Id = useSelector((state: any) => state?.addStation?.addStation?.data?.stationId);
    const isLoadingDelete = useSelector((state: any) => state?.deleteStationAmenities?.loading);
    const errorMessage = useSelector((state: any) => state?.getStationAminity?.stationAminities?.errorMessage);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let getStationAminityListPayload = useMemo(() => {
        return {
            type: "list",
            limit: 10,
            skip: 1,
            search: "",
            stationid: station_Id ?? location?.state?.editdata?.stationId ?? editStationId
        };
    }, [station_Id, location?.state?.editdata?.stationId, editStationId]);

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };
    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.stationAmenityId);
    };
    const handleDeleteRow = (rowId: any) => {
        setRowId((prev: any) => prev + 1);
        dispatch(deleteStationAmenitiesAPI(rowId));
        setDeletePopup(false);
    };
    useEffect(() => {
        dispatch(getStationAminityListAPI(getStationAminityListPayload));
    }, [dispatch, getStationAminityListPayload]);

    useEffect(() => {
        !isLoadingDelete && dispatch(getStationAminityListAPI(getStationAminityListPayload));
        // eslint-disable-next-line
    }, [isLoadingDelete]);
    const updateRefreshButton = (value: any) => {
        value && dispatch(getStationAminityListAPI(getStationAminityListPayload));
    };
    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };
    const getErrorComponent = () => {
        let errorDiv;
        if (aminitiesData?.stationAminities?.code) {
            if (aminitiesData?.stationAminities?.code === 400) {
                errorDiv = <div>400 error code</div>;
            } else if (aminitiesData?.stationAminities?.code === 500) {
                errorDiv = <div>500 error code</div>;
            } else if (aminitiesData?.stationAminities?.code === 404) {
                errorDiv = <NetworkErrorPage updateRefreshButton={updateRefreshButton} />;
            }
        }
        return errorDiv;
    };

    return (
        <div style={{ overflowY: "auto" }}>
            {
                aminitiesData?.loading === "failed" ?
                    getErrorComponent() :
                    <DataTable className='tableHeadingRow' value={aminitiesList.rows} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="amenityName" header="Amenity Name"></Column>
                        <Column field="description" header="Description"></Column>
                        <Column body={actionItems} header="Actions"></Column>
                    </DataTable>
            }
            <Dialog className='viewPopup' header={`Device Details -${viewData.amenityName}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                <div >
                    <div className="grid">
                        <div className="col-3 col-offset-2">Amenity Id</div>
                        <div className="col-1">:</div>
                        <div className="col-3">{viewData.amenityId}</div>
                    </div>
                    <div className="grid">
                        <div className="col-3 col-offset-2">Amenity Name</div>
                        <div className="col-1">:</div>
                        <div className="col-3">{viewData.amenityName}</div>
                    </div>
                    <div className="grid">
                        <div className="col-3 col-offset-2">Description</div>
                        <div className="col-1">:</div>
                        <div className="col-3">{viewData.description}</div>
                    </div>
                </div>
            </Dialog>
            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                <div className="delpop_btn_div">
                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)} >Yes</Button>
                </div>
            </Dialog>
            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='stationAminitiesList' />
        </div>
    );
};

export default ListOfAmenities;