import { createSlice } from "@reduxjs/toolkit";
import { addLookupCodesAPI } from "../../actions/lookupCodes/addLookupCodes";

const initialState = {
  addLookupCodes: {},
  loading: false,
  error: { code: "", message: "" },
};

const addLookupCodesSlice = createSlice({
  name: "addLookupCodes",
  initialState,
  reducers: {
    // resetUserData: (state) => {
    //   state = initialState;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addLookupCodesAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLookupCodesAPI.fulfilled, (state, action) => {
        state.addLookupCodes = action.payload.data;
        state.loading = false;
      })
      .addCase(addLookupCodesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

// export const { resetUserData } = addLookupTypesSlice.actions;
export default addLookupCodesSlice.reducer;
