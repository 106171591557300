import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHARGING_DEVICES_CONNECTORS } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const getChargingDeviceConnectorListAPI: any = createAsyncThunk(
  "getChargingDeviceConnectorList",
  async (params: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios.get(CHARGING_DEVICES_CONNECTORS, {
        // headers: {
        //   // istoken: "false",
        //   token : JSON.parse(token)
        // },
        headers: commonHead,
        params: params,
      });
      return { deviceConnectors: res };
    } catch (error: any) {
      // if (error instanceof Error) {
      return rejectWithValue({
        error: { code: error.response.status, message: error.message },
      });
      // }
    }
  }
);
