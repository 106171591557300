import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  StationName: [],
  error: null,
};

export const looupStationNameDropdownSlice = createSlice({
  name: "getStations",
  initialState,
  reducers: {
    getDropData: (state, action) => {
      state.StationName = action.payload;
    },
  },
});

export const { getDropData } = looupStationNameDropdownSlice.actions;
