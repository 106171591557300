import { Navigate, Outlet, useLocation } from "react-router-dom";
import { CommonPaths } from "./Paths";

const AuthorisedRoutes = () => {

    const isLoggedIn = localStorage.getItem("token");
    const location = useLocation();

    return (
        <>
            {
                isLoggedIn ?
                    (
                        <Outlet />
                    ) :
                    (
                        <Navigate to={CommonPaths.LOGIN_PATH} state={{ from: location }} />
                    )
            }
        </>
    );
};

export default AuthorisedRoutes;