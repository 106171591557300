import { createAsyncThunk } from "@reduxjs/toolkit";
import { AMENITIES } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const getStationEmenitiesByIdApi: any = createAsyncThunk(
  "getStationEmenitiesById",
  async (rowId: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios.get(AMENITIES + "/" + rowId, {
        // headers: {
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
      });
      return { user: res };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
