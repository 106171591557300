import { createAsyncThunk } from "@reduxjs/toolkit";
import { END_USERS } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const addUsersAPI: any = createAsyncThunk(
  "addUsers",
  async ({ formData, method, id }: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios({
        method: method,
        url: !!id ? END_USERS + id : END_USERS,
        data: formData,
        headers: commonHead,
      });

      ;
      return res;
    } catch (error: any) {
      // if (error instanceof Error) {
      return rejectWithValue({
        error: { code: error.response.status, message: error.message },
      });
      // }
    }
  }
);
