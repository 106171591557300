import axios from "axios";
import { connectorStatusLookupSlice } from "../../slice/stations/getConnectorStatusLookupSlice";
import apiHeader from "../../../Components/ApiHeader";

const { actions } = connectorStatusLookupSlice;

export const connectorStatusLookUpDropdownAPI: any =
  ({ ApiUrl, Type, params }: any) =>
  (dispatch: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    axios
      .get(ApiUrl, {
        params: params,
        // headers: {
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
      })
      .then((response) => {
        const { data } = response;
        dispatch(actions.getConnectorDropData({ type: Type, data }));
      })
      .catch((err) => {
        dispatch(
          actions.getConnectorDropData({ type: "error", paylod: err.message })
        );
      });
  };
