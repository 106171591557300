import { createSlice } from "@reduxjs/toolkit";
import { getStationEmenityListAPI } from "../../actions/stationEmenities/getStationEmenitiesList";

interface AminitiesListState {
  emenities: any[];
  loading: boolean;
  error: { code: string; message: string };
}

const initialState: AminitiesListState = {
  emenities: [],
  loading: false,
  error: { code: "", message: "" },
};

export const stationEmenitiesListSlice = createSlice({
  name: "getStationEmenities",
  initialState,
  reducers: {
    resetGetAmenities: (state) => {
      state.emenities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStationEmenityListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStationEmenityListAPI.fulfilled, (state, action) => {
        state.emenities = action.payload.amenities.data;
        state.loading = false;
      })
      .addCase(getStationEmenityListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetAmenities } = stationEmenitiesListSlice.actions;
export default stationEmenitiesListSlice.reducer;
