import { createSlice } from "@reduxjs/toolkit";
import { addChargerConnectorTypesAPI } from "../../actions/chargerConnectorType/addChargerConnectorTypes";

const initialState = {
  addChargerConnectorType: {},
  loading: false,
  error: { code: "", message: "" },
};

const addChargerConnectorTypesSlice = createSlice({
  name: "addChargerConnectorType",
  initialState,
  reducers: {
    resetUserData: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addChargerConnectorTypesAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(addChargerConnectorTypesAPI.fulfilled, (state, action) => {
        state.addChargerConnectorType = action.payload.data;
        state.loading = false;
      })
      .addCase(addChargerConnectorTypesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetUserData } = addChargerConnectorTypesSlice.actions;
export default addChargerConnectorTypesSlice.reducer;
