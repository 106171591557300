import { deleteLookupTypesAPI } from "../../actions/lookupTypes/deleteLookupTypes";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addUser: {},
  loading: false,
  error: null,
};

const deleteLookupTypesSlice = createSlice({
  name: "deleteLookupTypes",
  initialState,
  reducers: {
    deleteUserData: (state, action) => {
      state.addUser = action.payload.userAdded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteLookupTypesAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteLookupTypesAPI.fulfilled, (state, action) => {
        state.addUser = action.payload.userDeleted;
        state.loading = false;
      })
      .addCase(deleteLookupTypesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteUserData } = deleteLookupTypesSlice.actions;
export default deleteLookupTypesSlice.reducer;
