import { createSlice } from "@reduxjs/toolkit";
import { addUpdateSTationEmenitiesAPI } from "../../actions/stationEmenities/addStationEmenities";

const initialState = {
  addUser: {},
  loading: false,
  error: { code: "", message: "" },
};

const addStationEmenitiesSlice = createSlice({
  name: "addStationEmenities",
  initialState,
  reducers: {
    resetUserData: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUpdateSTationEmenitiesAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUpdateSTationEmenitiesAPI.fulfilled, (state, action) => {
        state.addUser = action.payload.data;
        state.loading = false;
      })
      .addCase(addUpdateSTationEmenitiesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export default addStationEmenitiesSlice.reducer;
