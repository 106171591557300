import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import { faTrash, faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import config from "../../../Constants/config";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import { getChargerConnectorsListAPI } from "../../../store/actions/chargerConnectorType/getChargingConnectorsList";
import { deleteChargerConnectorTypesAPI } from "../../../store/actions/chargerConnectorType/deleteChargerConnectorType";
import { addChargerConnectorTypesAPI } from "../../../store/actions/chargerConnectorType/addChargerConnectorTypes";
import { deleteConnectorTypeData } from "../../../store/slice/chargerConnectorType/deleteChargerConnectorTypeSlice";

type TableRow = {
    image: null,
    chargerConnectorTypeId: number,
    chargerTypeName: string,
    price: number,
};
interface FormValues {
    chargerTypeName: string;
    image: string;
}

const initialValues: FormValues = {
    chargerTypeName: '',
    image: '',
};

const ChargerConnectorType = () => {
    const [file, setFile] = useState<any>();
    const [edit, setEdit] = useState<any>({});
    const [rows, setRows] = useState<number>(5);
    const [rowId, setRowId] = useState<number>();
    const [viewData, setViewData] = useState<any>({});
    const [chargerType, setChargerType] = useState("");
    const [imageData, setImageData] = useState<any>(null);
    const [searchVal, setSearchVal] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [chargeConSkipValue, setChargeConSkipValue] = useState<number>(0);
    const [associatedDeletePopup, setAssociatedDeletePopup] = useState<boolean>(false);
    const [invalidExtension, setInvalidExtension] = useState<boolean>(false);
    const chargerConnectorData = useSelector((state: any) => state?.getChargerConnectors);
    const isLoading = useSelector((state: any) => state?.addChargerConnectorType?.loading);
    const isDeletePopup = useSelector((state: any) => state?.deleteChargerConnectorType?.loading);
    const errorMessage = useSelector((state: any) => state?.getChargerConnectors?.chargerConnectors?.errorMessage);
    const associatedDeleteErrorMessage = useSelector((state: any) => state?.deleteChargerConnectorType?.deleteChargerConnectorType?.data?.error);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { GLOBAL_IMAGE_DOMAIN } = config;

    if (edit?.chargerConnectorTypeId) {
        initialValues.chargerTypeName = edit?.chargerTypeName;
        initialValues.image = edit?.image;
    } else {
        initialValues.chargerTypeName = "";
        initialValues.image = "";
    }
    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };
    const handleEditPopup = (rowData: TableRow) => {
        setEdit(rowData);
        setFile(rowData?.image);
    };
    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.chargerConnectorTypeId);
    };
    const handleDeleteRow = (rowId: any) => {
        dispatch(deleteChargerConnectorTypesAPI(rowId));
        setDeletePopup(false);
    };

    useEffect(() => {
        !isDeletePopup && getChargerConnectorsList(chargeConSkipValue, searchVal, rows);
        // eslint-disable-next-line
    }, [isDeletePopup, searchVal, rows]);

    useEffect(() => {
        !isLoading && getChargerConnectorsList(chargeConSkipValue, searchVal, rows);
        setChargerType('');
        setEdit('');
        setFile('');
        // eslint-disable-next-line
    }, [isLoading]);

    const updateRefreshButton = (value: any) => {
        value && getChargerConnectorsList(chargeConSkipValue, searchVal, rows);
    };
    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon className="testView" icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon className="testEdit" icon={faPen} onClick={() => handleEditPopup(rowData)} />
                <FontAwesomeIcon className="testDelete" icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };

    const getChargerConnectorsList = useCallback((cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
        };
        dispatch(getChargerConnectorsListAPI(params));
    }, [dispatch]);

    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setChargeConSkipValue(event.page + 1);
        getChargerConnectorsList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };
    const imgBody = (row: any) => {
        return <Image src={GLOBAL_IMAGE_DOMAIN + row.image} width="30" height="30" preview />;
    };
    const handleSearch = (e: any) => {
        const search = e.target.value;
        setSearchVal(search);
    };
    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        // Add validation logic for each field
        if (!edit?.chargerTypeName && !values.chargerTypeName) {
            errors.chargerTypeName = 'Required';
        }
        if (!edit?.chargerConnectorTypeId) {
            if (!values.image && (imageData?.name == null || imageData?.name === '')) {
                errors.image = "Required";
            }
        } else {
            if (!file && !imageData?.name) {
                errors.image = "Required";
            }
        }
        return errors;
    };

    const handleSubmit = (values: FormValues) => {
        // Handle form submission
        const formData = new FormData();
        formData.append('image', imageData ?? file);
        formData.append('chargerTypeName', values?.chargerTypeName);
        if (!edit.chargerConnectorTypeId) {
            dispatch(addChargerConnectorTypesAPI({ formData: formData, method: "POST" }));
        } else {
            // let editChargerType = chargerType ?? edit?.chargerTypeName;
            let editChargerType;
            if (chargerType) {
                editChargerType = chargerType;
            } else {
                editChargerType = edit?.chargerTypeName;
            }
            const editFormData = new FormData();
            editFormData.append('image', imageData ?? file);
            editFormData.append('chargerTypeName', editChargerType);
            dispatch(addChargerConnectorTypesAPI({ formData: editFormData, method: "PUT", id: `/${edit?.chargerConnectorTypeId}` }));
        }
        imageData?.name && setImageData({ ...imageData, name: null });
    };
    const handleImageChange = (e: any) => {
        if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg") {
            setInvalidExtension(false);
            setImageData(e.target.files[0]);
        } else {
            setInvalidExtension(true);
        }
    };
    useEffect(() => {
        associatedDeleteErrorMessage && setAssociatedDeletePopup(true);
        // eslint-disable-next-line
    }, [associatedDeleteErrorMessage]);

    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className='mx-3 mb-3' style={{ height: '87vh', overflowY: "scroll" }}>
            <div className="bg-white border-round mt-3" style={{ height: "160px" }}>
                <div style={{ borderBottom: '1px solid #00000024', padding: '10px' }}>Add Charger Connector Type</div>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid grid ml-3">
                            <div className='col-4 mt-2'>
                                <Field name="chargerTypeName" id="chargerName" autoComplete='off'>
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='chargerName'>Charger Type Name*</label>
                                            <InputText id="chargerName" {...input} placeholder="Charger Type Name" autoComplete='off' value={chargerType || initialValues?.chargerTypeName} onChange={(e) => {
                                                input.onChange(e.target.value);
                                                setChargerType(e.target.value);
                                                let { chargerTypeName, ...restOfData } = edit;
                                                setEdit(restOfData);
                                            }} />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="inputwidth">
                                <Field name="image">
                                    {({ input, meta }) => (
                                        <>
                                            {(imageData?.name || file) && <div onClick={() => { file && setFile(null); imageData?.name && setImageData({ ...imageData, name: null }); }} style={{
                                                position: 'absolute', left: '62%', width: '25px', height: '25px', marginTop: '1.5%',
                                                border: '1px solid red', background: 'red', borderRadius: '25px', textAlign: "center",
                                                color: 'whitesmoke', fontSize: '15px', fontWeight: '600', cursor: 'pointer',
                                            }}>x</div>}

                                            {(imageData?.name) ?
                                                <div className='col-4 mt-4' style={{ textAlign: 'center' }}>
                                                    <img src={URL.createObjectURL(imageData)} alt="something wrong" style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                                </div>
                                                :
                                                file ?
                                                    <div className='col-4 mt-4' style={{ textAlign: 'center' }}>
                                                        <img src={GLOBAL_IMAGE_DOMAIN + file} alt='something wrong' style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                                    </div>
                                                    :
                                                    <>
                                                        <div className='col-4 mt-2' style={{ textAlign: 'center' }}>
                                                            <label htmlFor='uploadimg' style={{ margin: '0.5%' }}>
                                                                <div style={{ width: '110px' }}>Upload Image*</div>
                                                                <FontAwesomeIcon icon={faPhotoFilm} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                                                            </label>
                                                        </div>
                                                    </>
                                            }
                                            <div className="col-4 mt-2">
                                                <Field name="image" >
                                                    {({ input, meta }) => (
                                                        <span className="field">
                                                            <input
                                                                {...input}
                                                                id='uploadimg'
                                                                type='file'
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => { input.onChange(e.target.value); handleImageChange(e); }}
                                                                accept="image/png, image/jpeg, image/jpg"
                                                            />
                                                            {meta.error && meta.touched && (
                                                                <span className="error_msg" style={{ position: 'relative', top: '-35px' }}>{meta.error}</span>
                                                            )}
                                                        </span>
                                                    )}
                                                </Field>
                                                {invalidExtension && <div className="error_msg" style={{ position: 'relative', top: '-35px', width: '155px' }}>Select Image</div>}
                                            </div>
                                        </>
                                    )}
                                </Field>
                            </div>
                            <button className="col-2 connector_submit_btn"  style = {{color: "white"}}type="submit" >Add Charger type</button>
                        </form>
                    )}
                />

            </div>
            {
                chargerConnectorData?.error.code !== "" ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <>
                        <div className="bg-white border-round mt-3">
                            <div className="px-2 d-flex align-items-center">
                                <span className="col-9" style={{ alignContent: 'center' }}>All Charger Connectors</span>
                                <div className="col-3 p-input-icon-left ">
                                    <i className="pi pi-search" />
                                    <InputText name="searchbar" placeholder="Search Charger Type" value={searchVal} onChange={(e: any) => handleSearch(e)} className='p-inputtext-sm col-10' />
                                </div>
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                                <DataTable value={chargerConnectorData?.chargerConnectors?.rows} loading={chargerConnectorData?.loading} scrollable removableSort tableStyle={{ maxWidth: '98%', marginTop: '10px', border: '1px solid #00000022', }} >
                                    <Column field="chargerTypeName" header="Charger Type" sortable ></Column>
                                    <Column body={imgBody} header="Image" ></Column>
                                    <Column frozen={true} alignFrozen="right" header="Actions" body={actionItems} ></Column>
                                </DataTable>
                            </div>
                            <Paginator first={currentPage} rows={rows} totalRecords={chargerConnectorData.chargerConnectors.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} style={{ width: 'auto' }} />
                        </div>
                        <Dialog className='viewPopup' header={`Connector Type : ${viewData.chargerTypeName}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                            <div >
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Charger Type Name</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.chargerTypeName}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Image</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5"><img src={GLOBAL_IMAGE_DOMAIN + viewData.image} alt="logo" /></div>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                            <div className="delpop_btn_div">
                                <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                                <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)} >Yes</Button>
                            </div>
                        </Dialog>
                        {
                            associatedDeleteErrorMessage &&
                            <Dialog className='deletePopup' header="Record is not Deleted" visible={associatedDeletePopup} style={{ width: '50vw' }} onHide={() => { setAssociatedDeletePopup(false); dispatch(deleteConnectorTypeData()); }} >
                                <div className="delpop_btn_div">
                                    {associatedDeleteErrorMessage}
                                </div>
                            </Dialog>
                        }
                        {errorMessage && <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='chargerConnectorList' />}
                    </>
            }
        </div>
    );
};

export default ChargerConnectorType;