import { createSlice } from "@reduxjs/toolkit";
import { deleteStationEmenitiesAPI } from "../../actions/stationEmenities/deleteStationEmenities";

const initialState = {
  deleteAmenities: {},
  loading: false,
  error: null,
};

const deleteStationEmenitiesSlice = createSlice({
  name: "deleteStationEmenities",
  initialState,
  reducers: {
    deleteAmenitiesData: (state) => {
      state.deleteAmenities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStationEmenitiesAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteStationEmenitiesAPI.fulfilled, (state, action) => {
        state.deleteAmenities = action.payload.amenitiesDeleted;
        state.loading = false;
      })
      .addCase(deleteStationEmenitiesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteAmenitiesData } = deleteStationEmenitiesSlice.actions;
export default deleteStationEmenitiesSlice.reducer;
