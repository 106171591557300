import { createSlice } from "@reduxjs/toolkit";
import { getBannerImagesListAPI } from "../../actions/banners/getBannerImagesList";

const initialState = {
  bannerImages: [],
  loading: false,
  error: null,
};

export const bannerImagesListSlice = createSlice({
  name: "getBannerImagesList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBannerImagesListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBannerImagesListAPI.fulfilled, (state, action) => {
        
        state.bannerImages = action.payload.bannerImages.data;
        state.loading = false;
      })
      .addCase(getBannerImagesListAPI.rejected, (state, action) => {
        state.bannerImages = action.payload.error;
        state.loading = false;
      });
  },
});

export default bannerImagesListSlice.reducer;
