import { createSlice } from "@reduxjs/toolkit";
import { startChargingAPI } from "../../actions/chargerBookings/startCharging";

const initialState = {
  startChargerBookings: {},
  loading: false,
  error: null,
};

const startChargingSlice = createSlice({
  name: "startCharging",
  initialState,
  reducers: {
    resetStartChargingData: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startChargingAPI.pending, (state) => {
        
        state.loading = true;
      })
      .addCase(startChargingAPI.fulfilled, (state, action) => {
        
        state.startChargerBookings = action.payload.data;
        state.loading = false;
      })
      .addCase(startChargingAPI.rejected, (state, action) => {
       
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetStartChargingData } = startChargingSlice.actions;
export default startChargingSlice.reducer;
