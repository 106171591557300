import React, { useState } from "react";
import config from "../../../Constants/config";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

type tableHeaderProps = {
    heading?: string;
    label?: string;
    addEvent?: (e: any) => void;
    onSearch?: (val: string) => void;
    dateRangePicker?: any;
    selectLocation?: any;
};

const TableHeaders = ({ heading, addEvent, label, onSearch, dateRangePicker, selectLocation }: tableHeaderProps) => {
    const [searchValue, setSearchValue] = useState("");
    const handleSearchData = (e: any) => {
        const seacrhValue = e.target.value;
        setSearchValue(seacrhValue);
        onSearch!(seacrhValue);
    };
    return (
        <div className='tableHeader p-2 grid'>
            <div className="col-12 md:col-3">
                <div className="w-full p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder="Search" value={searchValue} onChange={handleSearchData} className='p-inputtext-sm w-full ' />
                </div>
            </div>
        </div>
    );
};

const UtilizationReport = () => {
    const utilizationReport = [
        {
            number: 1,
            name: "ATUM",
            mobileNo: "9876543210",
            address: "test address",
            stationManagerName: "subesh",
            status: "Active",
            hoursConsumed: "432.22",
            powerUnit: "9023.4",
        },
        {
            number: 2,
            name: "Visaka Towers",
            mobileNo: "9876543201",
            address: "visaka towers hyderabad",
            stationManagerName: "suresh",
            status: "Active",
            hoursConsumed: "332.82",
            powerUnit: "8023.9",
        }
    ];

    return (
        <div className='m-3'>
            <h4>Utilization Report</h4>
            <div className="card">
                <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow'
                    header={
                        <TableHeaders
                            // addEvent={(e: any) => { console.log(e.target.value); }}
                            onSearch={() => { }}
                        />
                    }
                    value={utilizationReport}
                    scrollable removableSort tableStyle={{ minWidth: '50rem' }}
                >
                    <Column field="number" header="Sr.No" sortable style={{ width: '12%' }}></Column>
                    <Column field="name" header="Name" sortable style={{ width: '12%' }}></Column>
                    <Column field="mobileNo" header="Mobile No" sortable style={{ width: '12%' }}></Column>
                    <Column field="address" header="Address" sortable style={{ width: '12%' }}></Column>
                    <Column field="stationManagerName" header="station Manager Name" sortable style={{ width: '12%' }}></Column>
                    <Column field="status" header="Status" sortable style={{ width: '12%' }}></Column>
                    <Column field="hoursConsumed" header="Hours Consumed" sortable style={{ width: '12%' }}></Column>
                    <Column field="powerUnit" header="power Unit" sortable style={{ width: '12%' }}></Column>
                </DataTable>
                {/* <Paginator first={currentPage} rows={rows} totalRecords={chargingDevicesData.devices.total_count} onPageChange={(e) => onPageChange(e)} /> */}
            </div>
        </div>
    );
};

export default UtilizationReport;