import { createSlice } from "@reduxjs/toolkit";
import { getChargerBookingsListAPI } from "../../actions/chargerBookings/getChargerBookingsList";

interface BookingListState {
  chargerBookings: any[];
  loading: boolean;
  error: { code: string; message: string };
}

const initialState: BookingListState = {
  chargerBookings: [],
  loading: false,
  error: { code: "", message: "" },
};

export const chargerBookingsListSlice = createSlice({
  name: "getChargerBookings",
  initialState,
  reducers: {
    resetGetChargerBookings: (state) => {
      state.chargerBookings = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChargerBookingsListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getChargerBookingsListAPI.fulfilled, (state, action) => {
        state.chargerBookings = action.payload.bookings.data;
        state.loading = false;
      })
      .addCase(getChargerBookingsListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetChargerBookings } = chargerBookingsListSlice.actions;
export default chargerBookingsListSlice.reducer;
