import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//Actions
import { getChargingDeviceConnectorListAPI } from "../../../store/actions/chargingDevice/getChargingDeviceConnectorList";
import { getStationDevicesListAPI } from "../../../store/actions/stations/getStationDeviceList";
import { deleteStationDeviceAPI } from "../../../store/actions/stations/deleteStationDevice";
import { regenerateQRCodeApi } from "../../../store/actions/stations/regenerateQRCode";
//Components and Utilities
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
//PrimeReact
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
//FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faEye, faDownload } from '@fortawesome/free-solid-svg-icons';
//Configuration
import config from "../../../Constants/config";

type TableRow = {
    chargerConnectorTypeId: string,
    chargingDeviceConnectorUid: string,
    positionOfConnector: string,
    chargingDeviceConnectorId: string,
    chargingDeviceName: string,
    stationDeviceId: string,
    stationId?: string,
};

const ListOfDevices = ({ setVisible, visible, setEditData,setIsEditing, station_id}: any) => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [rows, setRows] = useState<number>(5);
    const [stationSkipValue, setStationSkipValue] = useState<number>(0);
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>({});
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [regenerateQRCode, setRegenerateQRCode] = useState<boolean>(false);
    const [regenerateQRCodeRowData, setRegenerateQRCodeRowData] = useState<any>({});
    const [rowId, setRowId] = useState<string>();
    const [getDeleteStationId, setGetDeleteStationId] = useState<string>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //Redux State management
    const stationDeviceList = useSelector((state: any) => state?.getStationDevices?.stationDevices);
    const stationDeviceData = useSelector((state: any) => state?.getStationDevices);
    const errorMessage = useSelector((state: any) => state?.getStationDevices?.stationDevices?.errorMessage);
    const stationId = useSelector((state: any) => state?.addStationDevice?.stationDevice?.data);
    const { GLOBAL_IMAGE_DOMAIN } = config;

     useEffect(() => {
        if (stationId) {
            getAddStationDevices(stationSkipValue, rows);
        }
        // eslint-disable-next-line
    }, [stationId, stationSkipValue, rows]);
    const getAddStationDevices = (stationSkipValue: number, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: stationSkipValue,
            search: "",
            stationid: station_id
        };
        dispatch(getStationDevicesListAPI(params));
    };

    const getDeleteStationDevices = (stationSkipValue: number, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: stationSkipValue,
            search: "",
            stationid: getDeleteStationId,
        };
        dispatch(getStationDevicesListAPI(params));
    };

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };
    const handleEditRow = (rowData: TableRow) => {
        setEditData(rowData);
        setVisible(true);
        setIsEditing(true);
    };
    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.stationDeviceId);
        setGetDeleteStationId(rowData.stationId);
    };
    const handleDeleteRow = async (rowId: any) => {
        setRowId((prev: any) => prev + 1);
        await dispatch(deleteStationDeviceAPI(rowId));
        !!stationId ? getAddStationDevices(stationSkipValue, rows) : getDeleteStationDevices(stationSkipValue, rows); //add
        setDeletePopup(false);
    };

    const getChargingDeviceConnectorsList = () => {
        let params = {
            type: "list",
            limit: 10,
            skip: 1,
            search: "",
        };
        dispatch(getChargingDeviceConnectorListAPI(params));
    };

    useEffect(() => {
        getChargingDeviceConnectorsList();
    });

    const updateRefreshButton = (value: any) => {
        value && getAddStationDevices(stationSkipValue, rows);
    };
    const actionItems = (rowData: TableRow) => {

        return (
            <div className='actionItems'>
                <FontAwesomeIcon icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon icon={faPen} onClick={() => handleEditRow(rowData)} />
                <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };
    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setStationSkipValue(event.page + 1);
        getAddStationDevices(event.page + 1,event.rows)
        setRows(event.rows);
    };
    const handleDownload = (qrCodeUrl: any) => {
        // Create a link element to trigger download
        const qrImageUrl = GLOBAL_IMAGE_DOMAIN + qrCodeUrl;
        const downloadLink = document.createElement('a');
        downloadLink.href = qrImageUrl;
        downloadLink.download = 'qrcode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleGenerateQRid = (rowData: any) => {
        setRegenerateQRCode(true);
        setRegenerateQRCodeRowData(rowData);
    };

    const qrCode = (rowData: any) => {
        return (
            <>
                {
                    <div className="d-flex align-items-center">
                        <div>
                            <Image src={GLOBAL_IMAGE_DOMAIN + rowData?.stationDeviceQrCode} alt="something wrong" width="50" height="50" className="mr-2 mt-2" preview />
                        </div>
                        <div>
                            <div className="underline text-blue-500 hover:text-green-500 text-overflow-ellipsis" style={{ width: "100px" }} onClick={() => handleGenerateQRid(rowData)}>Re-generate QR Code</div>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faDownload} onClick={() => handleDownload(rowData?.stationDeviceQrCode)} className="ml-2" />
                        </div>
                    </div>
                }
            </>
        );
    };

    const regenerateQRfn = async (data: any) => {
        await dispatch(regenerateQRCodeApi({ method: "PUT", id: data }));
        getAddStationDevices(stationSkipValue, rows);
    };
    const handleRegenerateQR = (regenerateQRCodeRowData: any) => {
        regenerateQRfn(regenerateQRCodeRowData?.stationDeviceId);
        setRegenerateQRCode(false);
    };
    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const dynamicClassName = stationDeviceList?.rows?.length !== 0 ? "Divice_table" : '';
    return (
        <div className={dynamicClassName}>
            {/* "Divice_table" */}
            {
                stationDeviceData?.error?.code !== "" ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <>
                    <DataTable scrollHeight={"250px"} className='tableHeadingRow' value={stationDeviceList.rows} loading={stationDeviceList.loading} scrollable tableStyle={{ minWidth: '50rem' }}>
                            <Column field="chargingDeviceName" header="Device Name"   ></Column>
                            <Column field="stationDeviceUid" header="Device UID"></Column>
                            <Column field="powerRating" header="Power Rating (kW)"   ></Column>
                            <Column field="pricePerUnit" header="Price/Unit (Rs)" ></Column>
                            <Column field="status" header="Status" ></Column>
                            <Column field="stationDeviceQrCode" body={qrCode} header="QR Code" ></Column>
                            <Column body={actionItems} header="Actions"></Column>
                        </DataTable>
                    </>
            }
            {
                stationDeviceList?.rows?.length !== 0 && <Paginator first={currentPage} rows={rows} totalRecords={stationDeviceData.stationDevices.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />
            }
            <Dialog className='viewPopup' header={`Device : ${viewData.chargingDeviceName}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                <div >
                    <div className="grid">
                        <div className="col-4 col-offset-1">Device Name</div>
                        <div className="col-1">:</div>
                        <div className="col-5">{viewData.chargingDeviceName}</div>
                    </div>
                    <div className="grid">
                        <div className="col-4 col-offset-1">Station Device UID</div>
                        <div className="col-1">:</div>
                        <div className="col-5">{viewData.stationDeviceUid}</div>
                    </div>
                    <div className="grid">
                        <div className="col-4 col-offset-1">Power Rating (kW)</div>
                        <div className="col-1">:</div>
                        <div className="col-5">{viewData.powerRating}</div>
                    </div>
                    <div className="grid">
                        <div className="col-4 col-offset-1">Price Per Unit (Rs)</div>
                        <div className="col-1">:</div>
                        <div className="col-5">{viewData.pricePerUnit}</div>
                    </div>
                    <div className="grid">
                        <div className="col-4 col-offset-1">Status</div>
                        <div className="col-1">:</div>
                        <div className="col-5">{viewData.status}</div>
                    </div>
                </div>
            </Dialog>
            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                <div className="delpop_btn_div">
                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)} >Yes</Button>
                </div>
            </Dialog>
            <Dialog className='deletePopup' header="You want to re-generate the QR Code ?" visible={regenerateQRCode} style={{ width: '50vw' }} onHide={() => setRegenerateQRCode(false)} >
                <div className="delpop_btn_div">
                    <Button className='delpop_No_btn' onClick={() => setRegenerateQRCode(false)} >No</Button>
                    <Button className='delpop_Yes_btn' onClick={() => handleRegenerateQR(regenerateQRCodeRowData)} >Yes</Button>
                </div>
            </Dialog>
            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='stationDevicesList' />
        </div>
    );
};

export default ListOfDevices;