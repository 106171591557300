import { createSlice } from "@reduxjs/toolkit";
import { getUserListAPI } from "../actions/getUsersList";

interface UsersListState {
  users: any[];
  loading: boolean;
  error: { code: string; message: string };
}
const initialState: UsersListState = {
  users: [],
  loading: false,
  error: { code: "", message: "" },
};

export const usersListSlice = createSlice({
  name: "getUsers",
  initialState,
  reducers: {
    resetGetAdminUsers: (state) => {
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserListAPI.fulfilled, (state, action) => {
        state.users = action.payload.users.data;
        state.loading = false;
      })
      .addCase(getUserListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetAdminUsers } = usersListSlice.actions;
export default usersListSlice.reducer;
