import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonPaths } from '../../../Routes/Paths';
import { useDispatch, useSelector } from 'react-redux';
// import { CHARGING_DEVICES } from '../../../API/UserApi';
// import { lookUpDeviceNameDropdownAPI } from '../../../store/actions/chargerConnectorType/getLookupDevices';
import { addChargerConnectorTypesAPI } from '../../../store/actions/chargerConnectorType/addChargerConnectorTypes';
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import PostToaster from '../../../Components/PostToaster';
import config from '../../../Constants/config';
// import { FileUpload } from 'primereact/fileupload';

interface FormValues {
    // chargerConnectorTypeId: string;
    chargerTypeName: string;
    image: string;
}

const initialValues: FormValues = {
    // chargerConnectorTypeId: '',
    chargerTypeName: '',
    image: '',
};

const AddChargerConnectorType = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [imageData, setImageData] = useState<any>(null);
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const addNetworkConnectorTyperror = useSelector((state: any) => state?.addChargerConnectorType?.error);
    const addConnectorTypeLoading = useSelector((state: any) => state?.addChargerConnectorType?.loading);
    const [file, setFile] = useState<any>(location?.state?.editdata?.image);
    const { CHARGER_CONNECTOR_TYPE } = CommonPaths;
    const { GLOBAL_IMAGE_DOMAIN } = config;

    if (location.state) {
        initialValues.chargerTypeName = location?.state?.editdata?.chargerTypeName;
        initialValues.image = location?.state?.editdata?.image;
    } else {
        initialValues.chargerTypeName = "";
        initialValues.image = "";
    }

    const handleSubmit = (values: FormValues) => {
        // Handle form submission
        const formData = new FormData();
        formData.append('image', imageData ?? file);
        formData.append('chargerTypeName', values?.chargerTypeName);
        if (!location?.state) {
            dispatch(addChargerConnectorTypesAPI({ formData: formData, method: "POST" }));
        } else {
            dispatch(addChargerConnectorTypesAPI({ formData: formData, method: "PUT", id: `/${location?.state?.editdata?.chargerConnectorTypeId}` }));
        }
        setSubmitClicked(true);
    };
    useEffect(() => {
        submitClicked && !addConnectorTypeLoading && addNetworkConnectorTyperror.code === '' && navigate(CHARGER_CONNECTOR_TYPE);
        submitClicked && !addConnectorTypeLoading && addNetworkConnectorTyperror.code !== '' && setShowErrorComponent(true);
        return () => { setShowErrorComponent(false); };
    }, [CHARGER_CONNECTOR_TYPE, addNetworkConnectorTyperror, addConnectorTypeLoading, navigate, submitClicked]);
    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        // Add validation logic for each field
        if (!values.chargerTypeName) {
            errors.chargerTypeName = 'Required';
        }
        if (!values.image) {
            errors.image = "Required";
        } else if (imageData == null || imageData === '') {
            errors.image = "Required";
        }
        return errors;
    };

    // const getDeviceName = () => {
    //     dispatch(lookUpDeviceNameDropdownAPI({ ApiUrl: CHARGING_DEVICES }));
    // };

    // useEffect(() => {
    //     getDeviceName();
    // });

    const handleClosePage = () => {
        navigate(CHARGER_CONNECTOR_TYPE);
    };
    const handleImageChange = (e: any) => {
        setImageData(e.target.files[0]);
    };
    return (
        <div>
            <div className='addPage_header container'>
                <button className='back_Btn' onClick={handleClosePage}><i className="pi pi-arrow-left" style={{ color: 'white' }}></i></button>
                <h5 className='heading'>{location?.state?.popup ? "Edit Charger Connector Type" : "Add Charger Connector Type"}</h5>
            </div>
            <div className='card mt-3 mx-3' >
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid row">
                            <div className='col-6 inputwidth'>
                                <Field name="chargerTypeName" id="chargerName" autoComplete='off'>
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='chargerName'>Charger Type Name*</label>
                                            <InputText id="chargerName" {...input} placeholder="Charger Type Name" autoComplete='off' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            {/* {imageData === null ?
                                <div className='col-6 inputwidth' style={{ textAlign: 'center' }}>
                                    <label htmlFor='uploadimg' style={{ margin: '0.5%' }}>
                                        <FontAwesomeIcon icon={faPhotoFilm} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                                        <div>Upload Image*</div>
                                    </label>
                                </div>
                                :
                                <>
                                    <div onClick={() => setFile(null)} style={{
                                        position: 'absolute', left: '73%', width: '25px', height: '25px', marginTop: '1.5%',
                                        border: '1px solid red', background: 'red', borderRadius: '25px', textAlign: "center",
                                        color: 'whitesmoke', fontSize: '15px', fontWeight: '600', cursor: 'pointer',
                                    }}>x</div>

                                    {(imageData?.name) ?
                                        <div className='col-6 inputwidth' style={{ textAlign: 'center', marginTop: '15px' }}>
                                            <img src={URL.createObjectURL(imageData)} alt="something wrong" style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                        </div>
                                        :
                                        // <img src={BANNERS + imageData} alt="something wrong" style={{ width: '12vw', height: '20vh', borderRadius: '15px' }} />
                                        null
                                    }
                                </>
                            } */}
                            {(imageData?.name || file) && <div onClick={() => { file && setFile(null); imageData?.name && setImageData({ ...imageData, name: null }); }} className='image_close' style={{
                                left: '39%', marginTop: '1.5%',
                            }}>x</div>}

                            {(imageData?.name) ?
                                <div className='col-6 inputwidth' style={{ textAlign: 'center', marginTop: '15px' }}>
                                    <img src={URL.createObjectURL(imageData)} alt="something wrong" style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                </div>
                                :
                                file ?
                                    <img src={GLOBAL_IMAGE_DOMAIN + file} alt='something wrong' style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                    :
                                    <>
                                        <div className='col-6 inputwidth' style={{ textAlign: 'center' }}>
                                            <label htmlFor='uploadimg' style={{ margin: '0.5%' }}>
                                                <FontAwesomeIcon icon={faPhotoFilm} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                                                <div>Upload Image*</div>
                                            </label>
                                        </div>
                                    </>
                            }
                            {/* <input
                                id='uploadimg'
                                type='file'
                                style={{ display: 'none' }}
                                onChange={(e) => handleImageChange(e)}
                                accept="image/png, image/jpeg"
                            /> */}
                            <div className="col-6 inputwidth">
                                <Field name="image" >
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <input
                                                {...input}
                                                id='uploadimg'
                                                type='file'
                                                style={{ display: 'none' }}
                                                onChange={(e) => { input.onChange(e.target.value); handleImageChange(e); }}
                                                accept="image/png, image/jpeg"
                                            />
                                            {meta.error && meta.touched && (
                                                <span className="error_msg">{meta.error}</span>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <button className={window.innerWidth < 786 ? 'col-4 connector_submit_btn' : 'col-1 connector_submit_btn'} type="submit" >Submit</button>
                        </form>
                    )}
                />
            </div>
            <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />
        </div>
    );
};

export default AddChargerConnectorType;