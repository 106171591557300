import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { addAndEditStationAminityAPI } from "../../../store/actions/stations/addStationAminityList";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
// import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import { useEffect, useState } from "react";
import PostToaster from "../../../Components/PostToaster";

const SubmitBtn: any = styled.div`
max-width: 1100px;
margin: 0px;
display:flex;
justify-content:center;
`;

interface FormValues {
    amenityId: string;
    description: string;
}
const AddAmenities = ({ visible, setVisible, editStationId, addEditStationId }: any) => {
    const amenity_Name = useSelector((state: any) => state?.getStationEmenities?.emenities?.rows);
    const station_Id = useSelector((state: any) => state?.addStation?.addStation?.data?.stationId);
    const addStationAminityError = useSelector((state: any) => state?.addSationAmenitiesList?.error);
    const addStationAminityLoading = useSelector((state: any) => state?.addSationAmenitiesList?.loading);
    const dispatch = useDispatch();
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);

    const amenityName = amenity_Name?.map((item: any) => (
        {
            value: item.amenityId,
            label: item.amenityName
        }
    ));
    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        if (!values.amenityId) {
            errors.amenityId = 'Required';
        }
        return errors;
    };
    const handleSubmit = (values: FormValues) => {
        const amenities = { ...values, stationId: station_Id ? station_Id : addEditStationId ? addEditStationId : editStationId };
        dispatch(addAndEditStationAminityAPI({ formData: amenities, method: "POST" }));
        setSubmitClicked(true);
    };
    useEffect(() => {
        submitClicked && !addStationAminityLoading && addStationAminityError.code === '' && setVisible(false);
        submitClicked && !addStationAminityLoading && addStationAminityError.code !== '' && setShowErrorComponent(true);
    }, [addStationAminityError, addStationAminityLoading, setVisible, submitClicked]);

    return (

        <Dialog className={window.innerWidth < 786 ? '' : "add_amenity_dialog"} header="Add Amenities" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            <Form
                // initialValues={initialValues}
                onSubmit={handleSubmit}
                validate={validateForm}
                render={({ handleSubmit }) => (
                    <>

                        <form onSubmit={handleSubmit} className="p-fluid row">
                            <div>
                                <div>
                                    <Field name="amenityId" >
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label>Select Amenity* </label>
                                                <Dropdown
                                                    {...input}
                                                    options={amenityName}
                                                    placeholder="Select Amenity"
                                                    className="w-full md:w-40rem"
                                                />
                                                {meta.error && meta.touched && (
                                                    <span className="error_msg">{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                {/* <div >
                                    <Field name="description">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label htmlFor="description">Description</label>
                                                <InputText id="description" {...input} placeholder="Description" className="amenity_des" />
                                                {meta.error && meta.touched && (
                                                    <span className="error_msg">{meta.error}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                </div> */}
                                <SubmitBtn>
                                    <button className={window.innerWidth < 786 ? 'col-4 submit_btn' : 'col-4 aminity_submit'} type="submit">
                                        Submit
                                    </button>
                                </SubmitBtn>
                            </div>
                        </form>

                        {
                            showErrorComponent && <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />
                        }
                    </>
                )} >
            </Form >

        </Dialog >
    );
};

export default AddAmenities;