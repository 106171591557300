import { createSlice } from "@reduxjs/toolkit";
import { getNetworkListAPI } from "../../actions/chargingNetworks/getNetworkList";

export interface IErrorState {
  code: string;
  message: string;
}
interface NetworkListState {
  networks: any[];
  loading: boolean;
  error: IErrorState;
  // error: string | null;
}

const initialState: NetworkListState = {
  networks: [],
  loading: false,
  error: {
    code: "",
    message: "",
  },
  // error: null,
};

export const networksListSlice = createSlice({
  name: "getNetworks",
  initialState,
  reducers: {
    resetGetNetworks: (state) => {
      state.networks = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNetworkListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getNetworkListAPI.fulfilled, (state, action) => {
        state.networks = action?.payload?.users?.data;
        state.loading = false;
      })
      .addCase(getNetworkListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetNetworks } = networksListSlice.actions;
export default networksListSlice.reducer;
