import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { STATION_DEVICES } from "../../../API/UserApi";
import apiHeader from "../../../Components/ApiHeader";

export const regenerateQRCodeApi: any = createAsyncThunk(
  "regenerateQRCode",
  async ({ method, id }: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios(STATION_DEVICES + "/" + id, {
        method: method,
        headers: commonHead,
        params: { regenerateQrCode: true },
      });
      return { generateQR: res };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
