import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { BANNERIMAGES } from "../../../API/UserApi";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
// import { Toast } from "primereact/toast";
import { getBannerImagesListAPI } from "../../../store/actions/banners/getBannerImagesList";
import PostToaster from "../../../Components/PostToaster";

type FormValues = {
    // sequence: string,
    imageUrl: string,
};

const initialValues: FormValues = {
    // sequence: '',
    imageUrl: '',
};
const AddBannerImages = ({ visible, setVisible, header, editRowData, editBannerId, submitClicked, setSubmitClicked }: any) => {

    // const [file, setFile] = useState<any>(null);
    const [editImageData, setEditImageData] = useState<any>();
    const [imageData, setImageData] = useState<any>(null);
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [invalidExtension, setInvalidExtension] = useState<boolean>(false);
    // const [submitClicked, setSubmitClicked] = useState(false);
    // const toast = useRef<Toast>(null);
    const banner_Id = useSelector((state: any) => state?.addBanners?.addBanners?.data?.bannerId);
    const showBannerImagesData = useSelector((state: any) => state?.getBannerImagesList?.bannerImages?.rows);
    const [addBannerImgError, setAddBannerImgError] = useState('');
    // const addBannerImgLoading = useSelector((state: any) => state?.addStation?.loading);
    // const [error, setError] = useState<any>('');
    const dispatch = useDispatch();
    const bannerSkipValue = 0;
    const rows = 10;
    // const { GLOBAL_DOMAIN } = config;
    const token: any = localStorage.getItem("token");
    const initialSequenceId = showBannerImagesData?.map(() => '') || [];

    const [newSequence, setNewSequence] = useState<any>(initialSequenceId);

    // const handleSequenceId = (e: any, i: any) => {
    //     const { value } = e.target;
    //     const updatedSequenceId = [...newSequence];
    //     updatedSequenceId[i] = value;
    //     setNewSequence(updatedSequenceId);
    // };

    // const handleAddImageSequnce = (e: any) => {
    //     setAddImageSequenceId(e.target.value);
    // };
    const getBannerImagesList = (cp: number, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: "",
            bannerid: editBannerId ?? banner_Id,
        };
        params.bannerid && dispatch(getBannerImagesListAPI(params));
    };

    const currentSequenceIds = showBannerImagesData?.map((item: any) => {
        return item.sequence;
    });

    // const addSequence = currentSequenceIds?.at(-1);
    // let newAdd = [];
    // newAdd.push(addSequence + 1);
    // const newlyAddedSequence = currentSequenceIds?.concat(newAdd);
    // const test = [...Array(5).keys()];
    currentSequenceIds?.push(showBannerImagesData.length + 1);
    const [addImageSequenceId, setAddImageSequenceId] = useState<any>(showBannerImagesData?.length + 1);

    const handleSubmit = async (values: FormData) => {
        let arrObj = [];
        arrObj.push(editImageData[0]);
        arrObj.push(editImageData[1]);

        const formdata = new FormData();
        formdata.append('imageUrl', imageData);
        formdata.append('bannerId', banner_Id ?? editRowData?.bannerId ?? editBannerId);

        setEditImageData({ ...editImageData, formdata });

        const newValues = {
            ...values,
            imageUrl: imageData,
            bannerId: banner_Id ?? editRowData?.bannerId ?? editBannerId,
            sequence: addImageSequenceId,
            bannerImageId: showBannerImagesData[0]?.bannerImageId
        };
        
        const payload1 = [...editImageData];
        payload1.push(formdata);
        !invalidExtension &&
            await axios.post(BANNERIMAGES, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: JSON.parse(token),
                }
            }).then((e) => setAddBannerImgError(''))
                .catch((e) => setAddBannerImgError(e.message));
        getBannerImagesList(bannerSkipValue, rows);
        setNewSequence(initialSequenceId);
        setImageData(null);
        setSubmitClicked(true);
    };

    if (editRowData) {
        initialValues.imageUrl = editRowData.imageUrl;
    } else {
        initialValues.imageUrl = '';
    }

    const handleEditSubmitAPI = (data: any[]) => {
        const payload = data?.map((sequence: any, i: number) => ({
            bannerId: banner_Id ?? editRowData?.bannerId ?? editBannerId,
            imageUrl: showBannerImagesData[i]?.imageUrl,
            bannerImageId: showBannerImagesData[i]?.bannerImageId,
            sequence: sequence || showBannerImagesData[i]?.sequence
        }));
        !invalidExtension && setEditImageData(payload);
    };

    useEffect(() => {
        setNewSequence(initialSequenceId);
        setAddImageSequenceId(showBannerImagesData?.length + 1);
        // eslint-disable-next-line
    }, [visible]);
    const handleImageChange = (e: any) => {

        if (e.target.files[0]?.type === "image/jpg" || e.target.files[0]?.type === "image/png" || e.target.files[0]?.type === "image/jpeg") {
            setInvalidExtension(false);
            setImageData(e.target.files[0]);
        } else {
            setInvalidExtension(true);
        }
        // setImageData(e.target.files[0]);
    };
    useEffect(() => {
        submitClicked && addBannerImgError === '' && setVisible(false);
        submitClicked && addBannerImgError !== '' && setShowErrorComponent(true);
    }, [addBannerImgError, setSubmitClicked, setVisible, submitClicked]);

    const validateForm = (values: any) => {
        const errors: Partial<any> = {};
        if (!invalidExtension) {
            if (!values.imageUrl) {
                errors.imageUrl = "Required";
            } else if (imageData == null || imageData === '') {
                errors.imageUrl = "Required";
            }
            // setInvalidExtension(false);
        }
        return errors;
    };
    return (
        <>
            <Dialog className="bannersAdd_popup" header={header} visible={visible} style={{ width: '50vw' }} onHide={() => { setVisible(false); setNewSequence(initialSequenceId); setAddImageSequenceId(showBannerImagesData?.length + 1); setImageData(null); setInvalidExtension(false); }}>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid row">
                            <div className="flex align-items-center">
                                <div className="banners_inputwidth">
                                    <Field name="imageUrl">
                                        {({ input, meta }) => (
                                            <>
                                                {imageData === null ?
                                                    <label htmlFor='uploadimg' style={{ margin: '0.5%' }}>
                                                        <FontAwesomeIcon icon={faPhotoFilm} style={{ width: '55px', height: '65px', padding: '0.5%', position: 'relative' }} />
                                                        <div>Upload Image*</div>
                                                    </label> :
                                                    <>
                                                        <div onClick={() => { imageData?.name && setImageData(null); }} className="image_close" style={{ left: '29%', marginTop: '0.5%' }}>x</div>

                                                        {(imageData?.name)
                                                            ?
                                                            <img src={URL.createObjectURL(imageData)} alt="something wrong" style={{ width: '12vw', height: '20vh', borderRadius: '15px' }} />
                                                            :
                                                            null
                                                        }
                                                    </>
                                                }

                                                <div className="col-6 banners_inputwidth">
                                                    <Field name="imageUrl" >
                                                        {({ input, meta }) => (
                                                            <div >
                                                                <input
                                                                    {...input}
                                                                    id='uploadimg'
                                                                    type='file'
                                                                    style={{ display: 'none' }}
                                                                    onChange={(e) => { input.onChange(e.target.value); handleImageChange(e); }}
                                                                    accept="image/png, image/jpeg, image/jpg"
                                                                />
                                                                {meta.error && meta.touched && (
                                                                    <span className="error_msg">{meta.error}</span>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Field>
                                                    {invalidExtension && <div className="error_msg" style={{ position: 'relative', top: '-15px', left: '-45px', width: '155px' }}>Select Image</div>}
                                                </div>
                                            </>
                                        )}
                                    </Field>
                                </div>
                                {/* {
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <h5>Current Sequence</h5>
                                            <h5 style={{ marginRight: '50px' }}>Image</h5>
                                        </div>
                                        {
                                            showBannerImagesData?.map((item: any, i: number) => (
                                                <div key={i + 1} style={{ display: "flex", marginBottom: "15px", justifyContent: "space-between" }}>
                                                    <div className="col-6 inputwidth">
                                                        <Dropdown
                                                            value={newSequence[i] || item.sequence}
                                                            onChange={(e) => handleSequenceId(e, i)}
                                                            options={currentSequenceIds}
                                                            // optionLabel="name"
                                                            placeholder="Select sequence"
                                                            className="w-full md:w-14rem"
                                                            name={`name${i}`}
                                                        />
                                                    </div>
                                                    <img src={item.imageUrl} alt="something wrong" width="100px" height="50px" style={{ marginRight: '40px' }} />
                                                </div>
                                            ))
                                        }
                                        <p onClick={() => handleEditSubmitAPI(newSequence)}></p>
                                    </div>
                                } */}
                                <div >
                                    <button className="bannerImage_submit_btn" type="submit" onClick={() => handleEditSubmitAPI(newSequence)}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    )} />
                {showErrorComponent && <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />}
            </Dialog>
        </>
    );
};

export default AddBannerImages;