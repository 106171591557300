

const Home = () => {

    return (
        <div>
            This is Home Page
        </div>
    );
};

export default Home;