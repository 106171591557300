import { createAsyncThunk } from "@reduxjs/toolkit";
import { STATION_IMAGES } from "../../../API/UserApi";

import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const deleteStationImagesAPI: any = createAsyncThunk(
  "deleteStationImages",
  async (rowId: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios.delete(STATION_IMAGES + "/" + rowId, {
        headers: commonHead,
      });
      return { stationImageDeleted: res };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
