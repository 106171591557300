import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../Constants/config";
import TableHeader from "../../../Components/TableHeader";
import { CommonPaths } from "../../../Routes/Paths";
import { getUserListAPI } from "../../../store/actions/getUsersList";
import { deleteUsersAPI } from "../../../store/actions/deleteUserList";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import NetworkErrorPage from "../../../Components/NetworkErrorPage";

export type TableRow = {
    id: number,
    networkManager: string,
    networkName: string,
    phoneNumber: string,
    email: string,
};

const AdminUsers = () => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [adminUserSkipValue, setAdminUserSkipValue] = useState<number>(0);
    const [rows, setRows] = useState<number>(5);
    const [searchVal, setSearchVal] = useState<string>('');
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>({});
    const [editRowId, setEditRowId] = useState<number>();
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [rowId, setRowId] = useState<number>();
    const userData = useSelector((state: any) => state?.getUsersList);
    const errorMessage = useSelector((state: any) => state?.getUsersList?.users?.errorMessage);
    const isDeletingUser = useSelector((state: any) => state?.deleteUser?.loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ADD_ADMIN_USERS } = CommonPaths;
    const popup = true;

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };

    const handleEditRow = (rowData: TableRow) => {
        setEditRowId(rowData.id);
        navigate(ADD_ADMIN_USERS, { state: { editdata: rowData, popup: popup, id: editRowId } });
    };
    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.id);
    };
    const handleDeleteRow = (rowId: any) => {
        dispatch(deleteUsersAPI(rowId));
        setDeletePopup(false);
    };
    useEffect(() => {
        !isDeletingUser && getAdminUsersList(adminUserSkipValue, searchVal, rows);
        // eslint-disable-next-line
    }, [isDeletingUser, searchVal, rows]);

    const updateRefreshButton = (value: any) => {
        value && getAdminUsersList(adminUserSkipValue, searchVal, rows);
    };
    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon className="testPopUp" icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon className="testEdit" icon={faPen} onClick={() => handleEditRow(rowData)} />
                <FontAwesomeIcon className="testDelete" icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };

    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setAdminUserSkipValue(event.page + 1);
        getAdminUsersList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };

    const getAdminUsersList = (cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
            userroleid: 1,
        };
        dispatch(getUserListAPI(params));
    };

    const UserName = (row: any) => {
        return <span>{row.firstName + ' ' + row.lastName}</span>;
    };

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };
    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    return (
        <div className='mx-3'>
            {
                userData?.error?.code !== "" ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <>
                        <TableHeader heading='Admin Users' searchKeys="Search Name,Email,Num" label="ADD" addEvent={() => { navigate(ADD_ADMIN_USERS); }} onSearch={handleSearch} />
                        <div className="card">
                            <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow' value={userData?.users?.rows} loading={userData?.loading} scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                                <Column field="firstName" body={UserName} header="Admin" sortable ></Column>
                                <Column field="email" header="Email" sortable ></Column>
                                <Column field="mobileNumber" header="Mobile" sortable ></Column>
                                <Column field="userRole" header="User Role" sortable ></Column>
                                <Column field="gender" header="Gender" sortable ></Column>
                                <Column frozen={true} alignFrozen="right" header="Actions" className="test" body={actionItems} ></Column>
                            </DataTable>
                            <Paginator first={currentPage} rows={rows} totalRecords={userData?.users?.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />

                            <Dialog className='viewPopup' header={`User : ${viewData.firstName}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                                <div >
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>First Name</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.firstName}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Last Name</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.lastName}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Mobile No.</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.mobileNumber}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Email</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.email}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Gender</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.gender}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>User Role </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.userRole}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Address</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.address}</div>
                                    </div>
                                </div>
                            </Dialog>

                            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                                <div className="delpop_btn_div">
                                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)}>Yes</Button>
                                </div>
                            </Dialog>
                            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='adminList' />
                        </div>
                    </>
            }
        </div>
    );
};

export default AdminUsers;