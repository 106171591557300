import { useEffect, useRef } from 'react';
import { CommonPaths } from "../Routes/Paths";
import { Toast } from 'primereact/toast';
import { resetGetNetworks } from '../store/slice/chargingNetworks/getNetworkSlice';
import { useDispatch } from 'react-redux';
import { resetGetAdminUsers } from '../store/slice/getUsersReducer';
import { resetGetStations } from '../store/slice/stations/getStationsSlice';
import { resetGetChargingDevices } from '../store/slice/chargingDevice/getChargingDeviceSlice';
import { resetGetEndUsers } from '../store/slice/endUsers/getEndUsersSlice';
import { resetGetChargerConnectors } from '../store/slice/chargerConnectorType/getChargingConnectorsListSlice';
import { resetGetAmenities } from '../store/slice/stationEmenities/getStationEmenitiesSlice';
import { resetGetChargerBookings } from '../store/slice/chargerBookings/getChargerBookingsSlice';
import { resetGetBanners } from '../store/slice/banners/getBannersSlice';
import { resetGetLookupTypes } from '../store/slice/lookupTypes/getLookupTypesSlice';
import { resetGetLookupCodes } from '../store/slice/lookupCodes/getLookupCodesSlice';
import { resetGetStationDevices } from '../store/slice/stations/getStationDevicesSlice';
import { resetGetStationAminities } from '../store/slice/stations/getStationAminitySlice';
import { resetGetChargingDeviceConnectors } from '../store/slice/chargingDevice/getChargingDeviceConnectorSlice';
import { resetGetTransactionList } from '../store/slice/transactionReport/getTransactionReportListSlice';

const TokenHandleFunction = ({ errorMessage, navigate, referenceKey }: any) => {
    const toast = useRef<Toast>(null);
    const dispatch = useDispatch();
    const { LOGIN_PATH } = CommonPaths;

    const show = () => {
        toast.current?.show({ severity: 'info', summary: 'Info', detail: 'Your Session Expired Please Login' });
    };

    useEffect(() => {
        if (errorMessage === 'token expired') {
            show();
            setTimeout(() => {
                localStorage.removeItem("token");
                navigate(LOGIN_PATH);
                if (referenceKey === 'networkList') {
                    dispatch(resetGetNetworks());
                } else if (referenceKey === 'adminList') {
                    dispatch(resetGetAdminUsers());
                } else if (referenceKey === 'stationList') {
                    dispatch(resetGetStations());
                } else if (referenceKey === 'chargingDeviceList') {
                    dispatch(resetGetChargingDevices());
                } else if (referenceKey === 'endUsersList') {
                    dispatch(resetGetEndUsers());
                } else if (referenceKey === 'chargerConnectorList') {
                    dispatch(resetGetChargerConnectors());
                } else if (referenceKey === 'amenitiesList') {
                    dispatch(resetGetAmenities());
                } else if (referenceKey === 'chargerBookingList') {
                    dispatch(resetGetChargerBookings());
                } else if (referenceKey === 'bannersList') {
                    dispatch(resetGetBanners());
                } else if (referenceKey === 'lookupTypesList') {
                    dispatch(resetGetLookupTypes());
                } else if (referenceKey === 'lookupCodesList') {
                    dispatch(resetGetLookupCodes());
                } else if (referenceKey === 'stationDevicesList') {
                    dispatch(resetGetStationDevices());
                } else if (referenceKey === 'stationAminitiesList') {
                    dispatch(resetGetStationAminities());
                } else if (referenceKey === 'chargingDeviceConnectorsList') {
                    dispatch(resetGetChargingDeviceConnectors());
                } else if (referenceKey === 'transactionReportList') {
                    dispatch(resetGetTransactionList());
                }
            }, 4000);
        }
    }, [LOGIN_PATH, dispatch, errorMessage, navigate, referenceKey]);

    return (
        <div className="card flex justify-content-center">
            <Toast ref={toast} />
        </div>
    );
};

export default TokenHandleFunction;