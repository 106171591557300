import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonPaths } from '../../../Routes/Paths';
import { addNetworksAPI } from '../../../store/actions/chargingNetworks/addNetworks';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
// import { Toast } from 'primereact/toast';
import PostToaster from '../../../Components/PostToaster';

type FormValues = {
    networkName: string;
    networkManager: string;
    email: string;
    phoneNumber: string;
};

const initialValues: FormValues = {
    networkName: '',
    networkManager: '',
    email: '',
    phoneNumber: '',
};

const AddChargingNetworks = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    // const toast = useRef<Toast>(null);
    const { CHARGING_NETWORKS } = CommonPaths;
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const addNetworkError = useSelector((state: any) => state?.addNetwork?.error);
    const addNetworkLoading = useSelector((state: any) => state?.addNetwork?.loading);
    if (location?.state) {
        initialValues.networkName = location?.state?.editdata?.networkName;
        initialValues.networkManager = location?.state?.editdata?.networkManager;
        initialValues.email = location?.state?.editdata?.email;
        initialValues.phoneNumber = location?.state?.editdata?.phoneNumber;
    } else {
        initialValues.networkName = "";
        initialValues.networkManager = "";
        initialValues.email = "";
        initialValues.phoneNumber = "";
    }

    const handleSubmit = (values: FormValues) => {
        // Handle form submission
        if (!location?.state?.editdata?.networkId) {
            dispatch(addNetworksAPI({ formData: values, method: "POST" }));
        } else {
            dispatch(addNetworksAPI({ formData: values, method: "PUT", id: `/${location.state.editdata.networkId}` }));
        }
        setSubmitClicked(true);
    };

    useEffect(() => {
        submitClicked && !addNetworkLoading && addNetworkError.code === '' && navigate(CHARGING_NETWORKS);
        submitClicked && !addNetworkLoading && addNetworkError.code !== '' && setShowErrorComponent(true);
        return () => { setShowErrorComponent(false); };
    }, [CHARGING_NETWORKS, addNetworkError, addNetworkLoading, navigate, submitClicked]);

    const validateForm = (values: FormValues) => {

        const errors: Partial<FormValues> = {};
        // Add validation logic for each field
        if (!values.networkName) {
            errors.networkName = 'Required';
        }
        if (!values.networkManager) {
            errors.networkManager = 'Required';
        }
        if (!values.email) {
            errors.email = 'Required';
        } else {
            let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (!values.email.match(mailformat)) {
                errors.email = 'Please enter a valid email address.';
            }
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = 'Required';
        } else if (values?.phoneNumber) {
            let regex = new RegExp(/(0|91)?[6-9][0-9]{9}/);
            if (!regex.test(values.phoneNumber)) {
                errors.phoneNumber = 'Number should start with 9,8,7 or 6.';
            }
            if (values?.phoneNumber?.length !== 10) {
                errors.phoneNumber = 'Please enter your 10 digits mobile number.';
            }
        }
        return errors;
    };

    const handleClosePage = () => {
        navigate(CHARGING_NETWORKS);
    };

    // const show = () => {
    //     toast.current?.show({ severity: 'info', summary: 'Info', detail: 'Something went wrong' });
    // };
    // useEffect(() => {
    //     showErrorComponent && show();
    // }, [showErrorComponent]);

    return (
        <div>
            <div className="addPage_header container">
            <button className='back_Btn' onClick={handleClosePage}><i className="pi pi-arrow-left" style={{ color: 'white' }}></i></button>
            <h5 className='heading'>{location?.state?.popup ? "Edit Charging Networks" : "Add Charging Networks"}</h5>
            </div>
            <div className='card mt-3 mx-3' >
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid row" name='networkForm'>
                            <div className='col-6 inputwidth'>
                                <Field name="networkName">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='networkName'>Network Name*</label>
                                            <InputText id='networkName' keyfilter={/^[a-zA-Z\s]*$/} {...input} placeholder="Network Name" autoComplete='off' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="networkManager">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='networkManager'>Network Manager*</label>
                                            <InputText id='networkManager' keyfilter={/^[a-zA-Z\s]*$/} {...input} placeholder="Network Manager" autoComplete='off' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="email">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='email'>Email*</label>
                                            <InputText id="email" role='textbox' keyfilter="email" {...input} placeholder="EMAIL" autoComplete='off' type='email' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="phoneNumber">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='phoneNumber'>Mobile Number*</label>
                                            <InputText id='phoneNumber' keyfilter="pint"  {...input} placeholder="Mobile Number" autoComplete='off' maxLength={10} />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <button className={window.innerWidth < 786 ? 'col-4 submit_btn' : 'col-1 network_submit_btn'} type="submit" >Submit</button>
                        </form>
                    )}
                />
            </div>
            <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />
            {/* {
                showErrorComponent &&
                <div className="card flex justify-content-center">
                    <Toast ref={toast} />
                </div>
            } */}
        </div>
    );
};


export default AddChargingNetworks;