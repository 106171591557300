import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bannersType: [],
  error: null,
};

export const bannersTypeLookupSlice = createSlice({
  name: "getBannersType",
  initialState,
  reducers: {
    getBannersTypeData: (state, action) => {
      ;
      if (action.payload.type === "BANNERS") {
        state.bannersType = action.payload;
      } else if (action.payload.type === "error") {
        state.error = action.payload;
      }
    },
  },
});

export const { getBannersTypeData } = bannersTypeLookupSlice.actions;
