import { createSlice } from "@reduxjs/toolkit";
import { getBannersListAPI } from "../../actions/banners/getBannersList";

interface BannersListState {
  banners: any[];
  loading: boolean;
  error: { code: string; message: string };
}

const initialState: BannersListState = {
  banners: [],
  loading: false,
  error: { code: "", message: "" },
};

export const bannersListSlice = createSlice({
  name: "getBannersList",
  initialState,
  reducers: {
    resetGetBanners: (state) => {
      state.banners = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBannersListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBannersListAPI.fulfilled, (state, action) => {
        state.banners = action.payload.banners.data;
        state.loading = false;
      })
      .addCase(getBannersListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetBanners } = bannersListSlice.actions;
export default bannersListSlice.reducer;
