import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import TableHeader from "../../../Components/TableHeader";
import { getChargerBookingsListAPI } from "../../../store/actions/chargerBookings/getChargerBookingsList";
import { startChargingAPI } from "../../../store/actions/chargerBookings/startCharging";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import config from "../../../Constants/config";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlugCircleCheck } from '@fortawesome/free-solid-svg-icons';
type TableRow = {
    chargingDeviceId: string,
    connectorTypeId: string,
    userId: string,
    startDate: string,
    startTime: string,
    endTime: string,
    extendedTime: null,
    createdBy: number,
    updatedBy: number;
    bookingId: number;
    bookingStatus: string;
    stationDeviceConnectorId: string;
    stationDeviceUid: string;
    stationId: string;
    positionOfConnector: number;
};


const ChargerBookings = () => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [rows, setRows] = useState<number>(5);
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>({});
    const [chargerBookingPage, setChargerBookingPage] = useState<number>(0);
    const [searchVal, setSearchVal] = useState<string>('');
    const dispatch = useDispatch();
    const chargerBookingsData = useSelector((state: any) => state?.getChargerBookings);
    const errorMessage = useSelector((state: any) => state?.getChargerBookings?.chargerBookings?.errorMessage);
    const { startChargerBookings, loading } = useSelector((state: any) => state?.startCharging);
    const [start, setStart] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };
    // {
    //     "status": false,
    //         "message": "You cannot start future bookings",
    //             "data": {
    //         "datetime": {
    //             "istBookingStartTime": "2024-02-19T12:01:00.000Z",
    //                 "istBookingEndTime": "2024-02-19T13:01:00.000Z",
    //                     "istCurrentDate": "2024-02-19T11:47:51.583Z";
    //         }
    //     }
    // }
    const handleStartCharging = (rowData: TableRow) => {
        let payload = {
            connectorId: rowData.stationDeviceConnectorId,
            positionOfConnector: rowData.positionOfConnector,
            idTag: 1,
            deviceId: rowData.stationDeviceUid,
            bookingId: rowData.bookingId,
            userId: rowData.userId,
            stationId: rowData.stationId
        };
        dispatch(startChargingAPI({
            formData: payload,
            method: "POST",
            stationDeviceId: rowData.stationDeviceUid
        }));
        setStart(true);
    };
    const getChargerBookingsList = (cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
            // deviceId: userData.id
        };
        dispatch(getChargerBookingsListAPI(params));
    };

    const alertFunction = () => {
        return (
            startChargerBookings.status ? alert('Successfully Charging Started') : (startChargerBookings.message === "You cannot start future bookings") ? alert("You cannot start future bookings") : (startChargerBookings.message === "minimum 50 rupees of Wallet amount is required to start charging") ? alert('You do not have sufficient balance to start the charging') : alert("Check the Device connection"),
            setStart(false)
        );
    };

    useEffect(() => {
        start && !loading && alertFunction();
        // eslint-disable-next-line
    }, [start, loading]);

    useEffect(() => {
        start && !loading && setTimeout(() => {
            getChargerBookingsList(chargerBookingPage, searchVal, rows);
        }, 3000);
        // eslint-disable-next-line
    }, [start, loading]);
    useEffect(() => {
        getChargerBookingsList(chargerBookingPage, searchVal, rows);
        // eslint-disable-next-line
    }, [searchVal, rows]);
    const updateRefreshButton = (value: any) => {
        value && getChargerBookingsList(chargerBookingPage, searchVal, rows);
    };
    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon icon={faEye} onClick={() => handleViewPopup(rowData)} />
                {rowData.bookingStatus === "CONFIRMED" &&
                    <>
                        <Tooltip target=".start_charge" mouseTrack mouseTrackLeft={10} />
                        <button style={{ background: 'none', border: 'none' }}>
                            <FontAwesomeIcon className="start_charge" data-pr-tooltip={"Start Charge"} data-pr-position="left" icon={faPlugCircleCheck} onClick={() => handleStartCharging(rowData)} />
                        </button>
                    </>
                }
            </div>
        );
    };

    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setChargerBookingPage(event.page + 1);
        getChargerBookingsList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };

    const User = (row: any) => {
        const name = row.firstName + ' ' + row.lastName;
        return row.firstName !== null ? name : "Guest";
    };

    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className='mx-3'>
            {
                chargerBookingsData?.error.code !== "" ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <div>
                        <TableHeader heading='All Charger Bookings'
                            searchKeys="Search Booking ID"
                            onSearch={handleSearch}
                        />
                        <div className="card">
                            <DataTable
                                scrollHeight={config.tableHeight}
                                className='tableHeadingRow'
                                value={chargerBookingsData?.chargerBookings?.rows}
                                loading={chargerBookingsData?.loading}
                                scrollable
                                removableSort
                                tableStyle={{ minWidth: '50rem' }}
                            >
                                <Column field="stationName" header="Station_Name" sortable></Column>
                                <Column field="firstName" body={User} header="User_Name" sortable></Column>
                                <Column field="mobileNumber" header="Mobile" sortable></Column>
                                <Column field="bookingId" header="Booking_ID" sortable></Column>
                                <Column field="chargerTypeName" header="Charger_Type" sortable></Column>
                                <Column field="startDate" header="Start_Date" sortable></Column>
                                <Column field="startTime" header="Start_Time" sortable></Column>
                                <Column field="endTime" header="End_Time" sortable></Column>
                                <Column field="bookingStatus" header="Booking_Status" sortable></Column>
                                <Column frozen={true} alignFrozen="right" header="Actions" body={actionItems}></Column>
                            </DataTable>

                            <Paginator first={currentPage} rows={rows} totalRecords={chargerBookingsData.chargerBookings.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />
                        </div>
                        <Dialog className='viewPopup' header={`User ID -${viewData.userId}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                            <div >
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Station Name</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.stationName}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>User ID</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.userId}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>First Name</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.firstName}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Last Name</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.lastName}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Mobile Number</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.mobileNumber}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Booking ID</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.bookingId}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>TimeSlot ID</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.timeSlotId}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Charger Type Name</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.chargerTypeName}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Booking Status</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.bookingStatus}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Start Date</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.startDate}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Start Time</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.startTime}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>End Time</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.endTime}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Extended Time</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.extendedTime}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Price Per Unit</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.pricePerUnit}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>Street</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.street}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>City</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.city}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-3 col-offset-2"}>State</div>
                                    <div className="col-1">:</div>
                                    <div className="col-5">{viewData.state}</div>
                                </div>
                            </div>
                        </Dialog>
                        <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='chargerBookingList' />
                    </div>
            }
        </div>
    );
};

export default ChargerBookings;

