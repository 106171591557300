import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHARGER_TYPES } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const getChargerConnectorsListAPI: any = createAsyncThunk(
  "getChargerConnectorList",
  async (params: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios.get(CHARGER_TYPES, {
        headers: commonHead,
        params: params,
      });
      return { connectors: res };
    } catch (error: any) {
      // if (error instanceof Error) {
      return rejectWithValue({
        error: { code: error.response.status, message: error.message },
      });
      // }
    }
  }
);
