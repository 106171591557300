import { createSlice } from "@reduxjs/toolkit";
import { deleteStationImagesAPI } from "../../actions/stations/deleteStationImages";

const initialState = {
  addStation: {},
  loading: false,
  error: null,
};

const deleteStationImageSlice = createSlice({
  name: "deleteStationImages",
  initialState,
  reducers: {
    deleteStationImageData: (state, action) => {
      state.addStation = action.payload.userAdded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStationImagesAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteStationImagesAPI.fulfilled, (state, action) => {
        state.addStation = action.payload.stationImageDeleted;
        state.loading = false;
      })
      .addCase(deleteStationImagesAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { deleteStationImageData } = deleteStationImageSlice.actions;
export default deleteStationImageSlice.reducer;
