import { createAsyncThunk } from "@reduxjs/toolkit";
import { AMENITIES } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const getStationEmenityListAPI: any = createAsyncThunk(
  "getStationEmenityList",
  async (params: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios.get(AMENITIES, {
        // headers: {
        //   token: JSON.parse(token),
        // },
        headers: commonHead,
        params: params,
      });
      return { amenities: res };
    } catch (error: any) {
      // if (error instanceof Error) {
      return rejectWithValue({
        error: { code: error.response.status, message: error.message },
      });
      // }
    }
  }
);
