import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  powerRating: [],
  error: null,
};

export const devicePowerRatingLookupSlice = createSlice({
  name: "getDevicePowerRating",
  initialState,
  reducers: {
    getDevicePowerRatingData: (state, action) => {
      if (action.payload.type === "POWER_RATING") {
        state.powerRating = action.payload;
      } else if (action.payload.type === "error") {
        state.error = action.payload;
      }
    },
  },
});

export const { getDevicePowerRatingData } =
  devicePowerRatingLookupSlice.actions;
