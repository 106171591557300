import { createAsyncThunk } from "@reduxjs/toolkit";
import { STATION_DEVICE_CONNECTORS } from "../../../API/UserApi";
import axios from "axios";
import apiHeader from "../../../Components/ApiHeader";

export const addStationDeviceConnectorsAPI: any = createAsyncThunk(
  "addStationDeviceConnector",
  async ({ formData, method, id }: any, { rejectWithValue }: any) => {
    const token: any = localStorage.getItem("token");
    const commonHead = apiHeader(token);
    try {
      const res = await axios({
        method: method,
        // url: !!id ? STATION_DEVICE_CONNECTORS+id : STATION_DEVICE_CONNECTORS,
        url: STATION_DEVICE_CONNECTORS,
        data: formData,
        headers: commonHead,
      });

      ;
      return res;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ error: error.message });
      }
    }
  }
);
