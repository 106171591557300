import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Field, Form } from "react-final-form";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
// import { Card } from "primereact/card";

import AddDevices from "./AddDevice";
import ListOfDevices from "./ListOfDevices";
import ListOfAmenities from "./ListOfAmenities";
import AddAmenities from "./AddAmenities";
import { CommonPaths } from "../../../Routes/Paths";
// import { LOOKUP_CODES } from "../../../API/UserApi";
import { addStationsAPI } from "../../../store/actions/stations/addStations";
// import { getNetworkListAPI } from "../../../store/actions/chargingNetworks/getNetworkList";
// import { getChargingDeviceListAPI } from "../../../store/actions/chargingDevice/getChargingDeviceList";
// import { getStationEmenityListAPI } from "../../../store/actions/stationEmenities/getStationEmenitiesList";
import { getStationDevicesListAPI } from "../../../store/actions/stations/getStationDeviceList";
import { getStationAminityListAPI } from "../../../store/actions/stations/getStationAminityList";
// import { lookUpStatesDropdownAPI } from "../../../store/actions/stations/getLookupStatesList";
// import { stationStatusesLookUpDropdownAPI } from "../../../store/actions/stations/getStationStatusLookup";
// import { lookUpCountryDropdownAPI } from "../../../store/actions/stations/getLookupCountryList";
import ListOfStationImages from "./ListOfStationImages";
import AddStationImages from "./AddStationImages";
import PostToaster from "../../../Components/PostToaster";
import { lookUpCountryDropdownAPI } from "../../../store/actions/stations/getLookupCountryList";
import { lookUpStatesDropdownAPI } from "../../../store/actions/stations/getLookupStatesList";
import { stationStatusesLookUpDropdownAPI } from "../../../store/actions/stations/getStationStatusLookup";
import { LOOKUP_CODES } from "../../../API/UserApi";
import { getNetworkListAPI } from "../../../store/actions/chargingNetworks/getNetworkList";
import { getChargingDeviceListAPI } from "../../../store/actions/chargingDevice/getChargingDeviceList";
import { getStationEmenityListAPI } from "../../../store/actions/stationEmenities/getStationEmenitiesList";
// import { getStationImageListAPI } from "../../../store/actions/stations/getStationImageList";

interface FormValues {
  stationName: string;
  phoneNumber: string;
  street: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  latitude: string;
  longitude: string;
  status: string;
  chargingNetworkId: string;
  email: string;
}

const initialValues: FormValues = {
  stationName: "",
  phoneNumber: "",
  street: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  latitude: "",
  longitude: "",
  status: "",
  chargingNetworkId: "",
  email: "",
};

const formContainerStyle = {
  // maxWidth: "1100px",
  margin: "0px",
};
// const formTitleStyle = {
//     marginBottom: "10px",
// };
const formInputStyle = {
  marginBottom: "10px",
};
const formButtonStyle = {
  marginRight: "10px",
  color: "white",
};

function BasicDetailsStep({ nextStep, editStationId }: any) {
  const network_Name = useSelector(
    (state: any) => state?.getNetworks?.networks?.rows
  );
  const statesList = useSelector(
    (state: any) => state?.getStatesList?.States?.data
  );
  const countryList = useSelector(
    (state: any) => state?.getCountries?.Countries?.data
  );
  const stationStatusesList = useSelector(
    (state: any) => state?.getStationStatusesDropList?.stationStatus?.data
  );
  const addStationError = useSelector((state: any) => state?.addStation?.error);
  const addStationLoading = useSelector(
    (state: any) => state?.addStation?.loading
  );
  const { STATIONS } = CommonPaths;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [networkValue, setNetworkValue] = useState<any>(
    location?.state?.editdata?.chargingNetworkId
  );
  const [stateValue, setStateValue] = useState<any>(
    location?.state?.editdata?.state
  );
  const [countryValue, setCountryValue] = useState<any>(
    location?.state?.editdata?.country
  );
  const [statusValue, setStatusValue] = useState<any>(
    location?.state?.editdata?.status
  );
  const [showErrorComponent, setShowErrorComponent] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  if (editStationId) {
    let params = {
      type: "list",
      limit: 5,
      skip: 1,
      search: "",
      stationid: editStationId,
    };
    dispatch(getStationDevicesListAPI(params));
  }
  const networkName = network_Name?.map((item: any) => ({
    value: item.networkId,
    label: item.networkName,
  }));

  let defaultNetwork: any;
  if (networkName) {
    defaultNetwork = networkName[0]?.value;
  }
  let defaultState: any;
  if (statesList) {
    defaultState = statesList[23]?.lookupName;
  }
  let defaultCountry: any;
  if (countryList) {
    defaultCountry = countryList[0]?.lookupName;
  }
  let defaultStatus: any;
  if (stationStatusesList) {
    defaultStatus = stationStatusesList[0]?.lookupName;
  }
  if (location?.state) {
    initialValues.stationName = location?.state?.editdata?.stationName;
    initialValues.chargingNetworkId =
      location?.state?.editdata?.chargingNetworkId;
    initialValues.phoneNumber = location?.state?.editdata?.phoneNumber;
    initialValues.street = location?.state?.editdata?.street;
    initialValues.city = location?.state?.editdata?.city;
    initialValues.state = location?.state?.editdata?.state;
    initialValues.country = location?.state?.editdata?.country;
    initialValues.pincode = location?.state?.editdata?.pincode;
    initialValues.latitude = location?.state?.editdata?.latitude;
    initialValues.longitude = location?.state?.editdata?.longitude;
    initialValues.status = location?.state?.editdata?.status;
    initialValues.email = location?.state?.editdata?.email;
  } else {
    initialValues.stationName = "";
    initialValues.phoneNumber = "";
    initialValues.street = "";
    initialValues.city = "";
    initialValues.state = defaultState;
    initialValues.country = defaultCountry;
    initialValues.status = defaultStatus;
    initialValues.pincode = "";
    initialValues.latitude = "";
    initialValues.longitude = "";
    initialValues.chargingNetworkId = defaultNetwork;
    initialValues.email = "";
  }

  const handleSubmit = (values: FormValues) => {
    if (!location.state) {
      const newStation = values;
      dispatch(addStationsAPI({ formData: newStation, method: "POST" }));
    } else {
      dispatch(
        addStationsAPI({
          formData: values,
          method: "PUT",
          id: `/${location.state.editdata.stationId}`,
        })
      );
    }
    setSubmitClicked(true);
  };

  useEffect(() => {
    submitClicked &&
      !addStationLoading &&
      addStationError.code === "" &&
      nextStep();
    submitClicked &&
      !addStationLoading &&
      addStationError.code !== "" &&
      setShowErrorComponent(true);
  }, [addStationError, addStationLoading, nextStep, submitClicked]);
  const validateForm = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.stationName) {
      errors.stationName = "Required";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    } else if (values?.phoneNumber) {
      let regex = new RegExp(/(0|91)?[6-9][0-9]{9}/);
      if (!regex.test(values.phoneNumber)) {
        errors.phoneNumber = "Number should start with 9,8,7 or 6.";
      }
      if (values?.phoneNumber?.length !== 10) {
        errors.phoneNumber = "Please enter your 10 digits mobile number.";
      }
    }
    if (!values.city) {
      errors.city = "Required";
    }
    if (!values.state) {
      errors.state = "Required";
    }
    if (!values.country) {
      errors.country = "Required";
    }
    if (!values.status) {
      errors.status = "Required";
    }
    if (!values.street) {
      errors.street = "Required";
    }
    let regex = new RegExp(/^(\d{6})$/);

    if (!values.pincode) {
      errors.pincode = "Required";
    } else if (!regex.test(values.pincode)) {
      errors.pincode = "Enter 6 digit numeric pin";
    }
    if (!values.email) {
      errors.email = "Required";
    } else {
      let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!values.email.match(mailformat)) {
        errors.email = "Please enter a valid email address.";
      }
    }
    if (!values.latitude) {
      errors.latitude = "Required";
    }
    if (!values.longitude) {
      errors.longitude = "Required";
    }

    if (!values.chargingNetworkId) {
      errors.chargingNetworkId = "Required";
    }
    return errors;
  };

  const getNetworkAndDeviceAndAminityList = () => {
    let params = {
        type: "list",
        limit: 30,
        skip: 1,
        search: "",
    };
    dispatch(getNetworkListAPI(params));
    dispatch(getChargingDeviceListAPI(params));
    dispatch(getStationEmenityListAPI(params));
};
  // const getCountryList = () => {
  //     let params = {
  //         type: "drop",
  //         lookupTypeName: "COUNTRY"
  //     };
  //     dispatch(lookUpCountryDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "COUNTRY", params: params }));
  // };
  // const getStatesList = () => {
  //     let params = {
  //         type: "drop",
  //         lookupTypeName: "STATES"
  //     };
  //     dispatch(lookUpStatesDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "STATES", params: params }));
  // };

  const getCountryList = () => {
    let params = {
      type: "drop",
      lookupTypeName: "COUNTRY"
    };
    dispatch(lookUpCountryDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "COUNTRY", params: params }));
  };

  const getStationStatusesList = () => {
      let params = {
          type: "drop",
          lookupTypeName: "STATION_STATUSES"
      };
      dispatch(stationStatusesLookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "STATION_STATUSES", params: params }));
  };

  const getStatesList = () => {
    let params = {
      type: "drop",
      lookupTypeName: "STATES"
    };
    dispatch(lookUpStatesDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "STATES", params: params }));
  };

  useEffect(() => {
      getCountryList();
      getStatesList();
      getStationStatusesList();
      // eslint-disable-next-line
  }, []);

  // const getNetworkAndDeviceAndAminityList = () => {
  //     let params = {
  //         type: "list",
  //         limit: 30,
  //         skip: 1,
  //         search: "",
  //     };
  //     dispatch(getNetworkListAPI(params));
  //     dispatch(getChargingDeviceListAPI(params));
  //     dispatch(getStationEmenityListAPI(params));
  // };
  useEffect(() => {
    getNetworkAndDeviceAndAminityList();
    setNetworkValue(
      location?.state?.editdata?.chargingNetworkId || defaultNetwork
    );
    setStateValue(location?.state?.editdata?.state || defaultState);
    setStatusValue(location?.state?.editdata?.status || defaultStatus);
    setCountryValue(location?.state?.editdata?.country || defaultCountry);
    // eslint-disable-next-line
  }, []);
  const handleClosePage = () => {
    navigate(STATIONS);
  };

  return (
    <div style={formContainerStyle} className = "container">
      <div className="addPage_header container">
        <button className="back_Btn" onClick={handleClosePage}>
          <i
            className="pi pi-arrow-left"
            style={{ color: "white" }}
          ></i>
        </button>
        <h5 className="heading">Station Details</h5>
       
      </div>
      <div
        className="card mt-3 mx-3"
        style={{ height: "400px", overflowY: "scroll" }}
      >
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validateForm}
          render={({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit} className="p-fluid row">
                <div className="col-6 inputwidth">
                  <Field name="chargingNetworkId">
                    {({ input, meta }) => (
                      <div className="field">
                        <label>Charging Network Name*</label>
                        <Dropdown
                          {...input}
                          value={
                            networkValue || initialValues.chargingNetworkId
                          }
                          options={networkName}
                          placeholder="Charging Network Name"
                          className="w-full md:w-40rem"
                          onChange={(e: any) => {
                            input.onChange(e.target.value);
                            setNetworkValue(e.target.value);
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="stationName">
                    {({ input, meta }) => (
                      <div className="field">
                        <label htmlFor="stationName">Station Name*</label>
                        <InputText
                          id="stationName"
                          {...input}
                          placeholder="Station"
                          autoComplete="off"
                          keyfilter={/^[a-zA-Z0-9 ]*$/}
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="phoneNumber">
                    {({ input, meta }) => (
                      <div className="field">
                        <label htmlFor="mobileNumber">Mobile Number*</label>
                        <InputText
                          id="mobileNumber"
                          {...input}
                          placeholder="Mobile Number"
                          autoComplete="off"
                          maxLength={10}
                          keyfilter="pint"
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="email">
                    {({ input, meta }) => (
                      <div className="field">
                        <label htmlFor="email">Email*</label>
                        <InputText
                          id="email"
                          {...input}
                          placeholder="Email"
                          autoComplete="off"
                          type="email"
                          keyfilter="email"
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="country">
                    {({ input, meta }) => (
                      <div className="field">
                        <label>Country*</label>
                        <Dropdown
                          {...input}
                          value={countryValue || initialValues.country}
                          optionValue="lookupName"
                          options={countryList}
                          optionLabel="lookupDisplayName"
                          placeholder="Select Country"
                          className="w-full md:w-40rem"
                          onChange={(e: any) => {
                            input.onChange(e.target.value);
                            setCountryValue(e.target.value);
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="state">
                    {({ input, meta }) => (
                      <div className="field">
                        <label>State*</label>
                        <Dropdown
                          {...input}
                          value={stateValue || initialValues.state}
                          optionValue="lookupName"
                          options={statesList}
                          optionLabel="lookupDisplayName"
                          placeholder="Select State"
                          className="w-full md:w-40rem"
                          onChange={(e: any) => {
                            input.onChange(e.target.value);
                            setStateValue(e.target.value);
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="city">
                    {({ input, meta }) => (
                      <div className="field">
                        <label htmlFor="city">City*</label>
                        <InputText
                          id="city"
                          {...input}
                          placeholder="city"
                          autoComplete="off"
                          keyfilter={/^[a-zA-Z\s]*$/}
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="street">
                    {({ input, meta }) => (
                      <div className="field">
                        <label htmlFor="street">Street*</label>
                        <InputText
                          id="street"
                          {...input}
                          placeholder="Street"
                          autoComplete="off"
                          keyfilter={/^[a-zA-Z\s]*$/}
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="pincode">
                    {({ input, meta }) => (
                      <div className="field">
                        <label htmlFor="pincode">Pincode*</label>
                        <InputText
                          id="pincode"
                          {...input}
                          placeholder="Pincode"
                          autoComplete="off"
                          maxLength={6}
                          keyfilter="int"
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="latitude">
                    {({ input, meta }) => (
                      <div className="field">
                        <label htmlFor="latitude">Latitude*</label>
                        <InputText
                          id="latitude"
                          {...input}
                          placeholder="LATITUDE"
                          autoComplete="off"
                          keyfilter={/^-?\d*\.?\d*$/}
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="longitude">
                    {({ input, meta }) => (
                      <div className="field">
                        <label htmlFor="longitude">Longitude*</label>
                        <InputText
                          id="longitude"
                          {...input}
                          placeholder="LONGITUDE"
                          autoComplete="off"
                          keyfilter={/^-?\d*\.?\d*$/}
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-6 inputwidth">
                  <Field name="status">
                    {({ input, meta }) => (
                      <div className="field">
                        <label>Status* </label>
                        <Dropdown
                          {...input}
                          value={statusValue || initialValues.status}
                          optionValue="lookupName"
                          options={stationStatusesList}
                          optionLabel="lookupDisplayName"
                          placeholder="Status"
                          className="w-full md:w-40rem"
                          onChange={(e: any) => {
                            input.onChange(e.target.value);
                            setStatusValue(e.target.value);
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span className="error_msg">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div style={formInputStyle}>
                  <button
                    className={
                      window.innerWidth < 786
                        ? "col-4 submit_btn"
                        : "col-1 submit_btn"
                    }
                    style={{color: "white"}}
                    type="submit"
                  >
                    Next
                  </button>
                </div>
              </form>
            </>
          )}
        />
      </div>

      <PostToaster
        showErrorComponent={showErrorComponent}
        setShowErrorComponent={setShowErrorComponent}
        setSubmitClicked={setSubmitClicked}
      />
    </div>
  );
}

interface stationDevice {
  chargingDeviceId: string;
  stationDeviceUid: string;
  pricePerUnit: string;
  status: string;
  stationId?: string;
}

function DeviceDetailsStep({
  setFormData,
  nextStep,
  prevStep,
  editStationId,
  setAddEditStationId,
}: any) {
  const devices: any = [];
  const [visible, setVisible] = useState<boolean>(false);
  const [editData, setEditData] = useState<stationDevice | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const navigate = useNavigate();
  const { STATIONS } = CommonPaths;

  useEffect(() => {
    if (editData?.stationId) {
      setAddEditStationId(editData?.stationId);
    }
  }, [editData?.stationId, setAddEditStationId]);

  const handleNextStep = (e: any) => {
    e.preventDefault();
    setFormData((prevState: any) => ({
      chargingDevices: devices,
    }));
    nextStep();
  };

  const handlePrevStep = (e: any) => {
    e.preventDefault();
    prevStep();
  };
  const handleClosePage = () => {
    navigate(STATIONS);
  };
  const handleAddClick = () => {
    setVisible(true);
    setIsEditing(false);
    setEditData({
      stationDeviceUid: "",
      pricePerUnit: "",
      status: "",
      chargingDeviceId: "",
    });
  };
  return (
    <div style={formContainerStyle} className="h-100">
      <div className="addPage_header container">
        <button className="back_Btn" onClick={handleClosePage}>
          <i
            className="pi pi-arrow-left"
            style={{ fontSize: "1rem", color: "white" }}
          ></i>
        </button>
        <h5 className="heading">Device Details</h5>
       
      </div>
      <div
        className="card mt-3 mx-3"
        style={{
          height: "auto",
          padding: "2px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <div className="d-flex align-items-end px-2 ">
          <div className="col-12 d-flex justify-content-end">
            <Button className="addDeviceBtn px-5" onClick={handleAddClick}>
              Add
            </Button>
          </div>
        </div>
        <ListOfDevices
          visible={visible}
          setVisible={setVisible}
          setIsEditing={setIsEditing}
          setEditData={setEditData}
          station_id={editStationId}
        />
        <div style={formInputStyle}>
          <button
            type="button"
            className={
              window.innerWidth < 786
                ? "col-4 add_btn ml-4 mt-4"
                : "col-1 add_btn ml-4 mt-4"
            }
            style={formButtonStyle}
            onClick={handlePrevStep}
          >
            Previous
          </button>
          <button
            type="button"
            className="col-1 add_btn ml-4 mt-4"
            style={formButtonStyle}
            onClick={handleNextStep}
          >
            Next
          </button>
        </div>
        <Divider />
      </div>
      <AddDevices
        devices={devices}
        visible={visible}
        setVisible={setVisible}
        onSubmit={() => {
          console.log("on submit called");
        }}
        editStationId={editStationId}
        editData={editData}
        isEditing={isEditing}
      />
    </div>
  );
}
interface stationAmenity {
  amenityId: string;
}

function AmenitiesStep({
  setFormData,
  nextStep,
  prevStep,
  editStationId,
  addEditStationId,
}: any) {
  const station_Id = useSelector(
    (state: any) => state?.addStation?.addStation?.data?.stationId
  );
  const [editData, setEditData] = useState<stationAmenity | null>();
  const [visible, setVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { STATIONS } = CommonPaths;

  const handleNextStep = (e: any) => {
    e.preventDefault();
    // setFormData((prevState: any) => ({
    //     ...prevState,
    //     chargingDevices: devices,
    // }));
    nextStep();
  };

  const handlePrevStep = (e: any) => {
    e.preventDefault();
    prevStep();
  };
  let getStationAminityListPayload = {
    type: "list",
    limit: 10,
    skip: 1,
    search: "",
    stationid:
      station_Id ?? location?.state?.editdata?.stationId ?? editStationId,
  };
  console.log(editData);
  useEffect(() => {
    !visible &&
      dispatch(getStationAminityListAPI(getStationAminityListPayload));
    // eslint-disable-next-line
  }, [visible]);

  // const handleNavigateToStation = () => {
  //     navigate(STATIONS);
  // };
  const handleClosePage = () => {
    navigate(STATIONS);
  };
  return (
    <div style={formContainerStyle}>
     <div className="addPage_header container">
        <button className="back_Btn" onClick={handleClosePage}>
          <i
            className="pi pi-arrow-left"
            style={{ fontSize: "1rem", color: "white" }}
          ></i>
        </button>
        <h5 className="heading">Station Amenity Details</h5>
      </div>
      <div
        className="card mt-3 mx-3"
        style={{ height: "400px", overflowY: "scroll" }}
      >
        <div className="d-flex align-items-center px-5 ">
          <h4 className="col-6">Add Station Amenities :--</h4>
          <div className="col-6 d-flex justify-content-end ">
            <Button
              className="addDeviceBtn"
              onClick={() => {
                setVisible(true);
                setEditData({
                  amenityId: "",
                });
              }}
            >
              Add
            </Button>
          </div>
        </div>

        <Divider />

        <ListOfAmenities editStationId={editStationId} />

        <div style={formInputStyle}>
          <button
            type="button"
            className={
              window.innerWidth < 786
                ? "col-4 add_btn ml-4 mt-4"
                : "col-1 add_btn ml-4 mt-4"
            }
            style={formButtonStyle}
            onClick={handlePrevStep}
          >
            Previous
          </button>
          {/* <button className={window.innerWidth < 786 ? "col-4 add_btn ml-4 mt-4" : "col-1 add_btn ml-4 mt-4"} type="submit" style={formButtonStyle} onClick={handleNavigateToStation}>
                        Submit
                    </button> */}
          <button
            type="button"
            className={
              window.innerWidth < 786
                ? "col-4 add_btn ml-4 mt-4"
                : "col-1 add_btn ml-4 mt-4"
            }
            style={formButtonStyle}
            onClick={handleNextStep}
          >
            Next
          </button>
        </div>
      </div>
      <AddAmenities
        visible={visible}
        setVisible={setVisible}
        editStationId={editStationId}
        addEditStationId={addEditStationId}
      />
    </div>
  );
}

function AddStationImageStep({ setFormData, prevStep }: any) {
  const location = useLocation();
  const editStationId = location?.state?.editdata?.stationId;
  const [visible, setVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const { STATIONS } = CommonPaths;

  const handlePrevStep = (e: any) => {
    e.preventDefault();
    prevStep();
  };
  const handleClosePage = () => {
    navigate(STATIONS);
  };
  const handleNavigateToStation = () => {
    navigate(STATIONS);
  };

  return (
    <div style={formContainerStyle}>
     <div className="addPage_header container">
        <button className="back_Btn" onClick={handleClosePage}>
          <i
            className="pi pi-arrow-left"
            style={{ fontSize: "1rem", color: "white" }}
          ></i>
        </button>
        <h5 className="heading">Station Image Details</h5>
      </div>
      <div
        className="card mt-1 mx-3"
        style={{ height: "440px", overflowY: "scroll" }}
      >
        <div className="d-flex align-items-center px-5 ">
          <h4 className="col-6">Add Station Images:</h4>
          <div className="col-6 d-flex justify-content-end ">
            <Button
              className="addDeviceBtn"
              onClick={() => {
                setVisible(true);
              }}
            >
              Add
            </Button>
          </div>
        </div>

        <ListOfStationImages editStationId={editStationId} />

        <div style={formInputStyle}>
          <button
            type="button"
            className={
              window.innerWidth < 786
                ? "col-4 add_btn ml-4 mt-4"
                : "col-1 add_btn ml-4 mt-2"
            }
            style={formButtonStyle}
            onClick={handlePrevStep}
          >
            Previous
          </button>
          <button
            className={
              window.innerWidth < 786
                ? "col-4 add_btn ml-4 mt-4"
                : "col-1 add_btn ml-4 mt-2"
            }
            type="submit"
            style={formButtonStyle}
            onClick={handleNavigateToStation}
          >
            Submit
          </button>
        </div>
      </div>
      <AddStationImages
        visible={visible}
        setVisible={setVisible}
        editStationId={editStationId}
      />
    </div>
  );
}

function ChargingStationForm() {
  const [formData, setFormData] = useState({
    stationId: 1,
    stationName: "",
    status: "OPENED",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    latitude: 0,
    longitude: 0,
    phoneNumber: "",
    email: "",
    chargingNetworkId: 1,
    amenities: "",
    chargingDevices: [],
    isDeleted: false,
    isEnabled: true,
    createdBy: 1,
    updatedBy: 1,
    createdAt: "",
    updatedAt: "",
  });
  const [step, setStep] = useState(1);
  const [addEditStationId, setAddEditStationId] = useState<string>("");

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const location = useLocation();
  const editStationId = location?.state?.editdata?.stationId;

  const renderFormStep = () => {
    switch (step) {
      case 1:
        return (
          <BasicDetailsStep nextStep={nextStep} editStationId={editStationId} />
        );
      case 2:
        return (
          <DeviceDetailsStep
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
            editStationId={editStationId}
            setAddEditStationId={setAddEditStationId}
          />
        );
      case 3:
        return (
          <AmenitiesStep
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
            editStationId={editStationId}
            addEditStationId={addEditStationId}
          />
        );
      case 4:
        return (
          <AddStationImageStep
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
            editStationId={editStationId}
            addEditStationId={addEditStationId}
          />
        );
      default:
        return null;
    }
  };

  return <div>{renderFormStep()}</div>;
}

export default ChargingStationForm;