

const Vehicles = () => {
    return(
        <div>This is Vehicle page</div>
    )
}

export default Vehicles;

// import React, { useEffect, useState } from 'react';
// import { Form, Field } from 'react-final-form';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { CommonPaths } from '../../../Routes/Paths';
// import { useDispatch, useSelector } from 'react-redux';
// import { addStationsAPI } from '../../../store/actions/stations/addStations';
// import { FileUpload } from 'primereact/fileupload';
// import Select from 'react-select';
// import { lookUpNetworkNameDropdownAPI } from '../../../store/actions/stations/getLookupNetworkNames';
// import { CHARGING_NETWORKS } from '../../../API/UserApi';
// // import { number } from 'yup';
// import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
// import { InputText } from 'primereact/inputtext';
// import { Dropdown } from 'primereact/dropdown';
// import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
// import { getStationEmenityListAPI } from '../../../store/actions/stationEmenities/getStationEmenitiesList';
// import { getChargingDeviceListAPI } from '../../../store/actions/chargingDevice/getChargingDeviceList';
// import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";

// interface FormValues {
//     stationName: string;
//     phoneNumber: string;
//     street: string;
//     city: string;
//     state: string;
//     country: string;
//     pincode: string;
//     latitude: string;
//     longitude: string;
//     status: string;
//     chargingNetworkId: string;
//     email: string;
// }

// const initialValues: FormValues = {
//     stationName: '',
//     phoneNumber: '',
//     street: '',
//     city: '',
//     state: '',
//     country: '',
//     pincode: '',
//     latitude: '',
//     longitude: '',
//     status: '',
//     chargingNetworkId: '',
//     email: '',
// };

// // interface City {
// //     value: string;
// //     label: string;
// // }

// const AddStations = () => {

//     // const [selectedCities, setSelectedCities] = useState<City | null>(null);
//     const [selectedOption, setSelectedOption] = useState<null>(null);
//     const [networkLabelName, setNetworkLabelName] = useState<string>("");
//     const [selectedAmenities, setSelectedAmenities] = useState(null);
//     const [selectedDevices, setSelectedDevices] = useState(null);
//     const [deviceStatus, setDeviceStatus] = useState<string>('');
//     const [selectedConnector, setSelectedConnector] = useState(null);
//     const [connectorStatus, setConnectorStatus] = useState<string>('');

//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const { STATIONS } = CommonPaths;
//     const location = useLocation();
//     const network_Name = useSelector((state: any) => state?.getNetworkList?.StationName?.type?.rows);
//     const amenity_Name = useSelector((state: any) => state?.getStationEmenities?.emenities?.rows);
//     const device_Name = useSelector((state: any) => state?.getChargingDevices?.devices?.rows);
//     const connectorType_Name = useSelector((state: any) => state?.getChargerConnectors?.chargerConnectors?.rows);


//     const networkName = network_Name?.map((item: any) => (
//         {
//             value: item.networkId,
//             label: item.networkName
//         }

//     ));
//     console.log(network_Name, "networkName");

//     const amenityName = amenity_Name?.map((item: any) => (
//         {
//             value: item.amenitiesId,
//             label: item.amenityName
//         }

//     ));

//     const deviceName = device_Name?.map((item: any) => (
//         {
//             value: item.deviceId,
//             label: item.deviceName
//         }

//     ));

//     const connectorTypeName = connectorType_Name?.map((item: any) => (
//         {
//             value: item.chargerConnectorTypeId,
//             label: item.chargerTypeName
//         }

//     ));

//     // useEffect(() => {
//     console.log(location.state, "location.state");

//     if (location.state) {
//         initialValues.stationName = location.state.editdata.stationName;
//         initialValues.chargingNetworkId = location.state.editdata.chargingNetworkId;
//         initialValues.phoneNumber = location.state.editdata.phoneNumber;
//         initialValues.street = location.state.editdata.street;
//         initialValues.city = location.state.editdata.city;
//         initialValues.state = location.state.editdata.state;
//         initialValues.country = location.state.editdata.country;
//         initialValues.pincode = location.state.editdata.pincode;
//         initialValues.latitude = location.state.editdata.latitude;
//         initialValues.longitude = location.state.editdata.longitude;
//         initialValues.status = location.state.editdata.status;
//         initialValues.email = location.state.editdata.email;
//     } else {
//         initialValues.stationName = "";
//         initialValues.phoneNumber = "";
//         initialValues.street = "";
//         initialValues.city = "";
//         initialValues.state = "";
//         initialValues.country = "";
//         initialValues.status = "";
//         initialValues.pincode = "";
//         initialValues.latitude = "";
//         initialValues.longitude = "";
//         initialValues.chargingNetworkId = "";
//         initialValues.email = "";
//     }
//     // }, []);
//     // STATION_ID
//     const handleSubmit = (values: FormValues) => {
//         // Handle form submission
//         console.log(values, "values");
//         const newStation = { ...values, chargingNetworkId: selectedOption, amenities: selectedAmenities,chargingDevices:[{selectedDevices,deviceStatus,connectors:[selectedConnector,connectorStatus]}] };
//        console.log(newStation);
//         if (!location.state) {
//             dispatch(addStationsAPI({ formData: newStation, method: "POST" }));
//         } else {
//             dispatch(addStationsAPI({ formData: values, method: "PUT", id: `/${location.state.editdata.stationId}` }));
//         }
//         navigate(STATIONS);
//     };

//     const validateForm = (values: FormValues) => {
//         const errors: Partial<FormValues> = {};

//         // Add validation logic for each field
//         if (!values.stationName) {
//             errors.stationName = 'Required';
//         }
//         if (!values.phoneNumber) {
//             errors.phoneNumber = 'Required';
//         }
//         if (!values.city) {
//             errors.city = 'Required';
//         }
//         if (!values.state) {
//             errors.state = 'Required';
//         }
//         if (!values.country) {
//             errors.country = 'Required';
//         }
//         if (!values.status) {
//             errors.status = 'Required';
//         }
//         if (!values.street) {
//             errors.street = 'Required';
//         }
//         if (!values.pincode) {
//             errors.pincode = 'Required';
//         }
//         if (!values.email) {
//             errors.email = 'Required';
//         }
//         if (!values.latitude) {
//             errors.latitude = 'Required';
//         }
//         if (!values.longitude) {
//             errors.longitude = 'Required';
//         }
//         // if (!values.chargingNetworkId) {
//         //     errors.chargingNetworkId = 'Required';
//         // }
//         return errors;
//     };

//     const getNetworkName = () => {
//         dispatch(lookUpNetworkNameDropdownAPI({ ApiUrl: CHARGING_NETWORKS }));
//     };

//     useEffect(() => {
//         getNetworkName();
//         let params = {
//             type: "list",
//             limit: 20,
//             skip: 1,
//             search: "",
//         };
//         dispatch(getStationEmenityListAPI(params));
//         dispatch(getChargingDeviceListAPI(params));
//     }, []);

//     const handleChange = (e: any) => {
//         console.log(e, "network_id");
//         setSelectedOption(e.value);
//         setNetworkLabelName(e.label);
//     };

//     return (
//         <div>
//             <h5 className='heading'>Add Station</h5>
//             <div className='card mt-3 mx-3' style={{height:"440px",overflowY:"scroll"}}>
//                 <Form
//                     initialValues={initialValues}
//                     onSubmit={handleSubmit}
//                     validate={validateForm}
//                     render={({ handleSubmit }) => (
//                         <form onSubmit={handleSubmit} className="p-fluid row">
//                             <div className='col-6 inputwidth'>
//                                 <div className="field">
//                                     <label>Charging Network Id</label>
//                                     <Select defaultValue={networkLabelName} options={networkName} onChange={handleChange} />
//                                     {/* {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>} */}
//                                 </div>
//                             </div>
//                             {/* <div className='col-6 inputwidth'>
//                                 <Field name="chargingNetworkId">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Charging Network Id</label>
//                                             <Dropdown {...input} optionLabel="label" options={networkName} optionValue='value'
//                                                 placeholder="Select a station" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div> */}
//                             <div className='col-6 inputwidth'>
//                                 <Field name="stationName">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Station Name</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="Station" /> */}
//                                             <InputText  {...input} placeholder="Station" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             {/* <div className='col-6 inputwidth'>
//                                 <Field name="STATION_ID">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Station ID</label>
//                                             <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="Station ID" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div> */}
//                             <div className='col-6 inputwidth'>
//                                 <Field name="phoneNumber">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Mobile Number</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="PHONE_NUMBER" /> */}
//                                             <InputText  {...input} placeholder="PHONE_NUMBER" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="street">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Street</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="Street" /> */}
//                                             <InputText  {...input} placeholder="Street" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="city">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>City</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="city" /> */}
//                                             <InputText  {...input} placeholder="city" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="state">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>State</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="state" /> */}
//                                             <InputText  {...input} placeholder="state" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="country">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Country</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="country" /> */}
//                                             <InputText  {...input} placeholder="country" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="pincode">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Pincode</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="Pincode" /> */}
//                                             <InputText  {...input} placeholder="Pincode" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="latitude">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Latitude</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="LATITUDE" /> */}
//                                             <InputText  {...input} placeholder="LATITUDE" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="longitude">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Longitude</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="LONGITUDE" /> */}
//                                             <InputText  {...input} placeholder="LONGITUDE" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="status">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Status</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="status" /> */}
//                                             <InputText  {...input} placeholder="status" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="email">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Email</label>
//                                             {/* <input className="p-inputtext p-component w-full input_height" {...input} type="text" placeholder="Email" /> */}
//                                             <InputText  {...input} placeholder="Email" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             {/* <div className='col-6 inputwidth'>
//                                 <Field name="IMAGE">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Upload Image</label>
//                                             <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} />
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div> */}

//                             <div className='col-6 inputwidth'>
//                                 <label>Select Amenities</label>
//                                 <MultiSelect value={selectedAmenities} onChange={(e) => { console.log(e); setSelectedAmenities(e.value); }} options={amenityName} optionLabel="label"
//                                     placeholder="Select Amenities" className="w-full md:w-40rem" />
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <label>Select Device</label>
//                                 <Dropdown value={selectedDevices} onChange={(e) => { console.log(e); setSelectedDevices(e.value); }} options={deviceName} optionLabel="label"
//                                     placeholder="Select Device" className="w-full md:w-40rem" />
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="deviceUid">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Device Uid</label>
//                                             <InputText  {...input} placeholder="Device Uid" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 flex flex-wrap gap-3">
//                                 <span style={{marginLeft:"40px"}}>Device Status :</span>
//                                 <div className="flex align-items-center">
//                                     <RadioButton inputId="ingredient1" name="pizza" value="ACTIVE" onChange={(e: RadioButtonChangeEvent) => setDeviceStatus(e.value)} checked={deviceStatus === 'ACTIVE'} />
//                                     <label htmlFor="ingredient1" className="ml-2">ACTIVE</label>
//                                 </div>
//                                 <div className="flex align-items-center">
//                                     <RadioButton inputId="ingredient2" name="pizza" value="IN_ACTIVE" onChange={(e: RadioButtonChangeEvent) => setDeviceStatus(e.value)} checked={deviceStatus === 'IN_ACTIVE'} />
//                                     <label htmlFor="ingredient2" className="ml-2">IN_ACTIVE</label>
//                                 </div>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <label>Select Connector Type</label>
//                                 <Dropdown value={selectedConnector} onChange={(e) => setSelectedConnector(e.value)} options={connectorTypeName} optionLabel="label"
//                                     placeholder="Select Connector Type" className="w-full md:w-40rem" />
//                             </div>
//                             <div className='col-6 inputwidth'>
//                                 <Field name="connectorUid">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Connector Uid</label>
//                                             <InputText  {...input} placeholder="Connector Uid" />
//                                             {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 flex flex-wrap gap-3">
//                                 <span style={{marginLeft:"40px"}}>connector Status :</span>
//                                 <div className="flex align-items-center">
//                                     <RadioButton inputId="ingredient1" name="pizza" value="IN_USE" onChange={(e: RadioButtonChangeEvent) => setConnectorStatus(e.value)} checked={connectorStatus === 'IN_USE'} />
//                                     <label htmlFor="ingredient1" className="ml-2">IN_USE</label>
//                                 </div>
//                                 <div className="flex align-items-center">
//                                     <RadioButton inputId="ingredient2" name="pizza" value="NOT_IN_USE" onChange={(e: RadioButtonChangeEvent) => setConnectorStatus(e.value)} checked={connectorStatus === 'NOT_IN_USE'} />
//                                     <label htmlFor="ingredient2" className="ml-2">NOT_IN_USE</label>
//                                 </div>
//                             </div>
//                             <button className='col-1 submit_btn' type="submit" >Submit</button>
//                         </form>
//                     )}
//                 />
//             </div>
//         </div>
//     );
// };

// export default AddStations;

// import React, { useEffect, useState } from "react";
// import { Dropdown } from "primereact/dropdown";
// import { InputText } from "primereact/inputtext";
// import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
// import { Field, Form } from "react-final-form";
// import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
// import Select from "react-select";
// import { addStationsAPI } from "../../../store/actions/stations/addStations";
// import { getNetworkListAPI } from "../../../store/actions/chargingNetworks/getNetworkList";
// import { addStationDevicesAPI } from "../../../store/actions/stations/addStationDevice";
// import { MultiSelect } from "primereact/multiselect";
// import { addStationsAPI } from "../../store/actions/stations/addStations";
// import { addStationDevicesAPI } from "../../store/actions/stations/addStationDevice";
// import {useFormState} from "react-final-form"

// interface FormValues {
//     stationName: string;
//     phoneNumber: string;
//     street: string;
//     city: string;
//     state: string;
//     country: string;
//     pincode: string;
//     latitude: string;
//     longitude: string;
//     status: string;
//     chargingNetworkId: string;
//     email: string;
// }

// const formContainerStyle = {
//     maxWidth: "1100px",
//     margin: "0px",
//     padding: "20px",
// };

// const formTitleStyle = {
//     marginBottom: "10px",
// };

// const formInputStyle = {
//     marginBottom: "10px",
// };

// const formButtonStyle = {
//     marginRight: "10px",
// };

// function BasicDetailsStep({ nextStep }: any) {
//     const [networkLabelName, setNetworkLabelName] = useState<string>("");
//     const [selectedOption, setSelectedOption] = useState<null>(null);
//     const network_Name = useSelector(
//         (state: any) => state?.getNetworks?.networks?.rows
//     );
//     const location = useLocation();
//     const dispatch = useDispatch();

//     const networkName = network_Name?.map((item: any) => ({
//         value: item.networkId,
//         label: item.networkName,
//     }));

    // const handleInputChange = (e: any) => {
    //     const { name, value } = e.target;
    //     setFormData((prevState: any) => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    // const handleNextStep = (e: FormValues) => {
    //     // e.preventDefault();
    //     nextStep();
    // };
    // const handleSubmit = (values: FormValues) => {
    //     if (!location.state) {
    //         const newStation = { ...values, chargingNetworkId: selectedOption };
    //         dispatch(addStationsAPI({ formData: newStation, method: "POST" }));
    //         // console.log(addStationsAPI);
    //     } else {
    //         dispatch(
    //             addStationsAPI({
    //                 formData: values,
    //                 method: "PUT",
    //                 id: `/${location.state.editdata.stationId}`,
    //             })
    //         );
    //     }
    //     nextStep();
    // };
    // const handleChange = (e: any) => {
    //     setSelectedOption(e.value);
    //     setNetworkLabelName(e.label);
    // };

    // const validateForm = (values: FormValues) => {
    //     const errors: Partial<FormValues> = {};

    //     // Add validation logic for each field
    //     if (!values.stationName) {
    //         errors.stationName = "Required";
    //     }
    //     if (!values.phoneNumber) {
    //         errors.phoneNumber = "Required";
    //     }
    //     if (!values.city) {
    //         errors.city = "Required";
    //     }
    //     if (!values.state) {
    //         errors.state = "Required";
    //     }
    //     if (!values.country) {
    //         errors.country = "Required";
    //     }
    //     if (!values.status) {
    //         errors.status = "Required";
    //     }
    //     if (!values.street) {
    //         errors.street = "Required";
    //     }
    //     if (!values.pincode) {
    //         errors.pincode = "Required";
    //     }
    //     if (!values.email) {
    //         errors.email = "Required";
    //     }
    //     if (!values.latitude) {
    //         errors.latitude = "Required";
    //     }
    //     if (!values.longitude) {
    //         errors.longitude = "Required";
    //     }
    //     // if (!values.chargingNetworkId) {
    //     //     errors.chargingNetworkId = 'Required';
    //     // }
    //     return errors;
    // };
    // useEffect(() => {
    //     let params = {
    //         type: "list",
    //         limit: 10,
    //         skip: 1,
    //         search: "",
    //     };
    //     dispatch(getNetworkListAPI(params));
    // })

//     return (
//         <div style={formContainerStyle}>
//             <h4 style={formTitleStyle}>Station Details</h4>
//             <div
//                 className="card mt-3 mx-3"
//                 style={{ height: "400px", overflowY: "scroll" }}
//             >
//                 <Form
//                     // initialValues={initialValues}
//                     onSubmit={handleSubmit}
//                     // validate={validateForm}
//                     render={({ handleSubmit }) => (
//                         <form onSubmit={handleSubmit} className="p-fluid row">
//                             <div className="col-6 inputwidth">
//                                 <div className="field">
//                                     <label>Charging Network Name</label>
//                                     <Select
//                                         defaultValue={networkLabelName}
//                                         options={networkName}
//                                         onChange={handleChange}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="stationName">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Station Name</label>
//                                             <InputText {...input} placeholder="Station" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="phoneNumber">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Mobile Number</label>
//                                             <InputText {...input} placeholder="PHONE_NUMBER" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="email">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Email</label>
//                                             <InputText {...input} placeholder="Email" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="street">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Street</label>
//                                             <InputText {...input} placeholder="Street" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="city">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>City</label>
//                                             <InputText {...input} placeholder="city" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="state">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>State</label>
//                                             <InputText {...input} placeholder="state" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="country">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Country</label>
//                                             <InputText {...input} placeholder="country" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="pincode">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Pincode</label>
//                                             <InputText {...input} placeholder="Pincode" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="latitude">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Latitude</label>
//                                             <InputText {...input} placeholder="LATITUDE" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="longitude">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Longitude</label>
//                                             <InputText {...input} placeholder="LONGITUDE" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="powerType">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Power Type</label>
//                                             <InputText
//                                                 {...input}
//                                                 placeholder="Power Type"
//                                             />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">
//                                                     {meta.error}
//                                                 </span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="pricePerUnit">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Price per unit</label>
//                                             <InputText
//                                                 {...input}
//                                                 placeholder="Price Per Unit"
//                                             />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">
//                                                     {meta.error}
//                                                 </span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div className="col-6 inputwidth">
//                                 <Field name="status">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Status</label>
//                                             <InputText {...input} placeholder="status" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>

//                             <div style={formInputStyle}>
//                                 <button className="col-1 submit_btn" type="submit">
//                                     Next
//                                 </button>
//                                 {/* <button
//                                 type="submit"
//                                 style={formButtonStyle}
//                             >
//                                 Next
//                             </button> */}
//                             </div>
//                         </form>
//                     )}
//                 />
//             </div>
//         </div>
//     );
// }

// function DeviceDetailsStep({ formData, setFormData, nextStep, prevStep }: any) {
//     const [devices, setDevices] = useState<any>([]);
//     const [deviceStatus, setDeviceStatus] = useState<string>("");
//     const [selectedDevices, setSelectedDevices] = useState(null);
//     const [selectedConnector, setSelectedConnector] = useState(null);
//     const [connectorStatus, setConnectorStatus] = useState<string>("");


//     const device_Name = useSelector(
//         (state: any) => state?.getChargingDevices?.devices?.rows
//     );
//     const connectorType_Name = useSelector(
//         (state: any) => state?.getChargerConnectors?.chargerConnectors?.rows
//     );
//     const station_Id = useSelector(
//         (state: any) => state?.addStation?.addStation?.data?.stationId
//     );

//     const Device_Id = useSelector(
//         (state: any) => state?.addStationDevice?.stationDevice?.data?.stationDeviceId
//     );

//     const dispatch = useDispatch();

//     const handleInputChange = (deviceIndex: any, e: any) => {
//         const { name, value } = e.target;
//         const updatedDevices = [...devices];
//         updatedDevices[deviceIndex][name] = value;
//         setDevices(updatedDevices);
//     };

//     const handleNextStep = (e: any) => {
//         e.preventDefault();
//         setFormData((prevState: any) => ({
//             ...prevState,
//             chargingDevices: devices,
//         }));
//         nextStep();
//     };

//     const handlePrevStep = (e: any) => {
//         e.preventDefault();
//         prevStep();
//     };

//     const addDevice = () => {
//         setDevices((prevDevices: any) => [
//             ...prevDevices,
//             {
//                 deviceUid: "",
//                 connectors: [],
//             },
//         ]);
//     };

//     const handleConnectorInputChange = (
//         deviceIndex: any,
//         connectorIndex: any,
//         e: any
//     ) => {
//         const { name, value } = e.target;
//         const updatedDevices = [...devices];
//         updatedDevices[deviceIndex].connectors[connectorIndex][name] = value;
//         setDevices(updatedDevices);
//     };

//     const addConnector = (deviceIndex: any) => {
//         const updatedDevices = [...devices];
//         updatedDevices[deviceIndex].connectors.push({
//             connectorUid: "",
//             connectorStatus: "AVAILABLE",
//         });
//         setDevices(updatedDevices);
//     };

//     const handleSubmitForm = (values: any) => {

//         setFormData((prevState: any) => ({
//             ...prevState,
//             chargingDevices: devices,
//         }));
//         const newStation = { ...values, stationId: station_Id };
//         dispatch(addStationDevicesAPI({ formData: newStation, method: "POST" }));
//     };

//     const deviceName = device_Name?.map((item: any) => ({
//         value: item.deviceId,
//         label: item.deviceName,
//     }));

//     const connectorTypeName = connectorType_Name?.map((item: any) => ({
//         value: item.chargerConnectorTypeId,
//         label: item.chargerTypeName,
//     }));

//     return (
//         <div style={formContainerStyle}>
//             <h4 style={formTitleStyle}>Device Details</h4>
            
//             <div
//                 className="card mt-3 mx-3"
//                 style={{ height: "400px", overflowY: "scroll" }}
//             >
//                 <Form
//                     // initialValues={initialValues}
//                     onSubmit={handleSubmitForm}
//                     //    validate={validateForm}
//                     render={({ handleSubmit }) => (
//                         <form onSubmit={handleSubmit} className="p-fluid row">
//                             {devices.map((device: any, deviceIndex: any) => (
//                                 <div key={deviceIndex}>
//                                     <h3 className="mt-2 ml-4">Device {deviceIndex + 1} :</h3>

//                                     <div className="col-6 inputwidth">
//                                         <Field name="deviceId">
//                                             {({ input, meta }) => (
//                                                 <div className="field">
//                                                     <label>Select Device</label>
//                                                     <Dropdown
//                                                         {...input}
//                                                         options={deviceName}
//                                                         placeholder="Select Device"
//                                                         className="w-full md:w-40rem"
//                                                     />

//                                                     {meta.error && meta.touched && (
//                                                         <span className="error_msg">{meta.error}</span>
//                                                     )}
//                                                 </div>
//                                             )}
//                                         </Field>
//                                     </div>
//                                     <div className="col-6 inputwidth">
//                                         <Field name="stationDeviceUid">
//                                             {({ input, meta }) => (
//                                                 <div className="field">
//                                                     <label>Device Uid</label>
//                                                     <InputText {...input} placeholder="Device Uid" />
//                                                     {meta.error && meta.touched && (
//                                                         <span className="error_msg">{meta.error}</span>
//                                                     )}
//                                                 </div>
//                                             )}
//                                         </Field>
//                                     </div>
//                                     <div className="col-6 flex flex-wrap gap-3">
//                                         <span style={{ marginLeft: "40px" }}>Device Status :</span>
//                                         <Field name="status" type="radio" value="ACTIVE">
//                                             {({ input, meta }) => (
//                                                 <div className="field">
//                                                     <div className="flex align-items-center">
//                                                         <RadioButton {...input} value="ACTIVE" />
//                                                         <label htmlFor="ingredient1" className="ml-2">
//                                                             ACTIVE
//                                                         </label>
//                                                     </div>
//                                                 </div>
//                                             )}
//                                         </Field>
//                                         <Field name="status" type="radio" value="IN_ACTIVE">
//                                             {({ input, meta }) => (
//                                                 <div className="field">
//                                                     <div className="flex align-items-center">
//                                                         <RadioButton {...input} value="IN_ACTIVE" />
//                                                         <label htmlFor="ingredient2" className="ml-2">
//                                                             IN_ACTIVE
//                                                         </label>
//                                                     </div>
//                                                 </div>
//                                             )}
//                                         </Field>
//                                     </div>

//                                     <div style={formInputStyle}>
//                                         <button className="col-1 submit_btn" type="submit">
//                                             Submit
//                                         </button>
//                                     </div>
//                                     {device.connectors.map(
//                                         (connector: any, connectorIndex: any) => (
//                                             <div key={connectorIndex}>
//                                                 <h4 className="mt-2 ml-4">Connector {connectorIndex + 1} :</h4>
//                                                 <div className="col-6 inputwidth">
//                                                     <Field name="chargerConnectorTypeId">
//                                                         {({ input, meta }) => (
//                                                             <div className="field">
//                                                                 <label>Select Connector Type</label>
//                                                                 <Dropdown
//                                                                     {...input}
//                                                                     options={connectorTypeName}
//                                                                     placeholder="Select Connector Type"
//                                                                     className="w-full md:w-40rem"
//                                                                 />

//                                                                 {meta.error && meta.touched && (
//                                                                     <span className="error_msg">{meta.error}</span>
//                                                                 )}
//                                                             </div>
//                                                         )}
//                                                     </Field>
//                                                 </div>

//                                                 <div className="col-6 inputwidth">
//                                                     <Field name="deviceConnectorUid">
//                                                         {({ input, meta }) => (
//                                                             <div className="field">
//                                                                 <label>Connector Uid</label>
//                                                                 <InputText
//                                                                     {...input}
//                                                                     placeholder="Connector Uid"
//                                                                 />
//                                                                 {meta.error && meta.touched && (
//                                                                     <span className="error_msg">
//                                                                         {meta.error}
//                                                                     </span>
//                                                                 )}
//                                                             </div>
//                                                         )}
//                                                     </Field>
//                                                 </div>

//                                                 <div className="col-6 flex flex-wrap gap-3">
//                                                     <span style={{ marginLeft: "40px" }}> connector Status :</span>
//                                                     <Field name="status" type="radio" value="IN_USE">
//                                                         {({ input, meta }) => (
//                                                             <div className="field">
//                                                                 <div className="flex align-items-center">
//                                                                     <RadioButton {...input} value="IN_USE" />
//                                                                     <label htmlFor="ingredient1" className="ml-2">
//                                                                         IN_USE
//                                                                     </label>
//                                                                 </div>
//                                                             </div>
//                                                         )}
//                                                     </Field>
//                                                     <Field name="status" type="radio" value="NOT_IN_USE">
//                                                         {({ input, meta }) => (
//                                                             <div className="field">
//                                                                 <div className="flex align-items-center">
//                                                                     <RadioButton {...input} value="NOT_IN_USE" />
//                                                                     <label htmlFor="ingredient2" className="ml-2">
//                                                                         NOT_IN_USE
//                                                                     </label>
//                                                                 </div>
//                                                             </div>
//                                                         )}
//                                                     </Field>
//                                                     <div style={formInputStyle}>
//                                                         <button className="col-1 submit_btn ml-4 w-9" type="submit">
//                                                             Submit
//                                                         </button>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         )
//                                     )}

//                                     <div className="d-flex" >
//                                         <h4 className="ml-4">Add Connector : </h4>
//                                         <button
//                                             className="col-1 add_btn ml-5"
//                                             type="button"
//                                             onClick={() => addConnector(deviceIndex)}
//                                         >
//                                             Add
//                                         </button>

//                                         {/* <button
//                                         type="button"
//                                         style={formButtonStyle}
//                                         onClick={() => addConnector(deviceIndex)}
//                                     >
//                                         Add Connector
//                                     </button> */}
//                                     </div>

//                                 </div>
//                             ))}
//                             <div className="d-flex mt-4 ml-4">
//                                 <h4>Add Device : </h4>
//                                 <button
//                                     className="col-1 add_btn ml-5"
//                                     type="button"
//                                     onClick={addDevice}
//                                 >
//                                     Add
//                                 </button>
//                                 {/* <button type="button" style={formButtonStyle} onClick={addDevice}>
//                                 Add
//                             </button> */}
//                             </div>
//                             <div style={formInputStyle}>
//                                 <button
//                                     type="button"
//                                     className="col-1 add_btn ml-4 mt-4"
//                                     style={formButtonStyle}
//                                     onClick={handlePrevStep}
//                                 >
//                                     Previous
//                                 </button>
//                                 <button
//                                     type="button"
//                                     className="col-1 add_btn mt-4"
//                                     style={formButtonStyle}
//                                     onClick={handleNextStep}
//                                 >
//                                     Next
//                                 </button>
//                             </div>
//                         </form>
//                     )}
//                 />

//             </div>
//         </div>
//     );
// }

// function AmenitiesStep({ formData, setFormData, nextStep, prevStep }: any) {
//     const [devices, setDevices] = useState<any>([]);
//     const [selectedAmenities, setSelectedAmenities] = useState(null);
//     const amenity_Name = useSelector((state: any) => state?.getStationEmenities?.emenities?.rows);

//     const amenityName = amenity_Name?.map((item: any) => (
//         {
//             value: item.amenitiesId,
//             label: item.amenityName
//         }

//     ));
//     const handleSubmit = (values: FormValues) => {
//         nextStep();
//     };
//     const handleNextStep = (e: any) => {
//         e.preventDefault();
//         setFormData((prevState: any) => ({
//             ...prevState,
//             chargingDevices: devices,
//         }));
//         nextStep();
//     };
//     const handlePrevStep = (e: any) => {
//         e.preventDefault();
//         prevStep();
//     };
//     return (
//         <div style={formContainerStyle}>
//             <h4 style={formTitleStyle}>Station Amenity Details</h4>
//             <div
//                 className="card mt-3 mx-3"
//                 style={{ height: "400px", overflowY: "scroll" }}
//             >
//                 <Form
//                     // initialValues={initialValues}
//                     onSubmit={handleSubmit}
//                     // validate={validateForm}
//                     render={({ handleSubmit }) => (
//                         <form onSubmit={handleSubmit} className="p-fluid row">
//                             <div className='col-6 inputwidth'>
//                                 <label>Select Amenities</label>
//                                 <MultiSelect value={selectedAmenities} onChange={(e) => { console.log(e); setSelectedAmenities(e.value); }} options={amenityName} optionLabel="label"
//                                     placeholder="Select Amenities" className="w-full md:w-40rem" />
//                             </div>

//                             <div className="col-6 inputwidth">
//                                 <Field name="status">
//                                     {({ input, meta }) => (
//                                         <div className="field">
//                                             <label>Status</label>
//                                             <InputText {...input} placeholder="status" />
//                                             {meta.error && meta.touched && (
//                                                 <span className="error_msg">{meta.error}</span>
//                                             )}
//                                         </div>
//                                     )}
//                                 </Field>
//                             </div>
//                             <div style={formInputStyle}>
//                                 <button
//                                     type="button"
//                                     className="col-1 add_btn ml-4 mt-4"
//                                     style={formButtonStyle}
//                                     onClick={handlePrevStep}
//                                 >
//                                     Previous
//                                 </button>
//                                 {/* <button
//                                     type="button"
//                                     style={formButtonStyle}
//                                     onClick={handleNextStep}
//                                 >
//                                     Next
//                                 </button> */}
//                             </div>
//                             <div style={formInputStyle}>
//                                 <button className="col-1 submit_btn" type="submit">
//                                     Next
//                                 </button>
//                                 {/* <button
//                                 type="submit"
//                                 style={formButtonStyle}
//                             >
//                                 Next
//                             </button> */}
//                             </div>
//                         </form>
//                     )}
//                 />
//             </div>
//         </div>
//     );
// }
// function ChargingStationForm() {
//     const [formData, setFormData] = useState({
//         stationId: 1,
//         stationName: "",
//         status: "OPENED",
//         street: "",
//         city: "",
//         state: "",
//         country: "",
//         pincode: "",
//         latitude: 0,
//         longitude: 0,
//         phoneNumber: "",
//         email: "",
//         chargingNetworkId: 1,
//         amenities: "",
//         chargingDevices: [],
//         isDeleted: false,
//         isEnabled: true,
//         createdBy: 1,
//         updatedBy: 1,
//         createdAt: "",
//         updatedAt: "",
//     });
//     const [step, setStep] = useState(1);

//     const nextStep = () => {
//         setStep((prevStep) => prevStep + 1);
//     };

//     const prevStep = () => {
//         setStep((prevStep) => prevStep - 1);
//     };

//     const handleSubmit = () => {
//         // Here you can perform the API POST request with the formData
//         console.log(formData);
//     };

//     const renderFormStep = () => {
//         switch (step) {
//             case 1:
//                 return <BasicDetailsStep nextStep={nextStep} />;
//             case 2:
//                 return (
//                     <DeviceDetailsStep
//                         formData={formData}
//                         setFormData={setFormData}
//                         nextStep={nextStep}
//                         prevStep={prevStep}
//                     />
//                 );
//             case 3:
//                 return (
//                     <AmenitiesStep
//                         formData={formData}
//                         setFormData={setFormData}
//                         nextStep={nextStep}
//                         prevStep={prevStep}
//                     />
//                 );
//             case 4:
//                 return (
//                     <div style={formContainerStyle}>
//                         <h2 style={formTitleStyle}>Confirmation</h2>
//                         <pre>{JSON.stringify(formData, null, 2)}</pre>
//                         <div style={formInputStyle}>
//                             <button type="button" style={formButtonStyle} onClick={prevStep}>
//                                 Previous
//                             </button>
//                             <button
//                                 type="button"
//                                 style={formButtonStyle}
//                                 onClick={handleSubmit}
//                             >
//                                 Submit
//                             </button>
//                         </div>
//                     </div>
//                 );
//             default:
//                 return null;
//         }
//     };

//     return <div>{renderFormStep()}</div>;
// }

// export default ChargingStationForm;
