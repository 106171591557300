import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CommonPaths } from "../Routes/Paths";
import { SidebarItem } from "../Types/SidebarItem";
import {
    faChargingStation, faLocationDot, faGlobe, faBatteryHalf, faPerson, faPeopleCarryBox, faPlug, faChartColumn,
    faPersonWalking, faCartShopping, faBasketShopping, faUsersRectangle, faUsers, faImage, faShapes
} from "@fortawesome/free-solid-svg-icons";

const { HOME, MANAGE_USERS, STATION_MANAGERS, VEHICLES, CHARGING_NETWORKS,
    ADMIN_USERS, END_USERS, STATIONS, STATION_EMINITIES, CHARGING_DEVICE,
    CHARGER_CONNECTOR_TYPE, BANNERS, CHARGER_BOOKINGS, LOOKUP_TYPES, LOOKUP_CODES,
    REPORTS_TRANSACTIONS, REPORTS_FINANCE, REPORTS_UTILIZATION, REPORTS_VEHICLES_CHARGED,
    CHARGING_SESSIONS, CATEGORIES, PRODUCTS } = CommonPaths;

export const SidebarData: SidebarItem[] = [
    {
        title: 'Home',
        path: HOME,
        icon: <i className="pi pi-home"></i>,
    },
    {
        title: 'Admin Users',
        path: ADMIN_USERS,
        icon: <FontAwesomeIcon icon={faUsersRectangle} />,
    },
    {
        title: 'End Users',
        path: END_USERS,
        icon: <FontAwesomeIcon icon={faUsers} />,
    },
    {
        title: 'Charging Networks',
        path: CHARGING_NETWORKS,
        icon: <FontAwesomeIcon icon={faGlobe} />,
    },
    {
        title: 'Charging Stations',
        path: STATIONS,
        icon: <FontAwesomeIcon icon={faLocationDot} />,
    },
    {
        title: 'Charging Device',
        path: CHARGING_DEVICE,
        icon: <FontAwesomeIcon icon={faChargingStation} />,
    },
    {
        title: 'Connector Type',
        path: CHARGER_CONNECTOR_TYPE,
        icon: <FontAwesomeIcon icon={faPlug} />,
    },
    {
        title: 'Station Amenities',
        path: STATION_EMINITIES,
        icon: <FontAwesomeIcon icon={faPerson} />,
    },
    {
        title: 'Charger Bookings',
        path: CHARGER_BOOKINGS,
        icon: <FontAwesomeIcon icon={faPerson} />,
    },
    {
        title: 'Banners',
        path: BANNERS,
        icon: <FontAwesomeIcon icon={faImage} />,
    },
    {
        title: 'Manage Users',
        path: MANAGE_USERS,
        icon: <i className="pi pi-tag"></i>,
    },
    {
        title: 'Station Managers',
        path: STATION_MANAGERS,
        icon: <FontAwesomeIcon icon={faPeopleCarryBox} />,
    },
    {
        title: 'Vehicles',
        path: VEHICLES,
        icon: <i className="pi pi-truck"></i>,
    },
    {
        title: 'Charger Type',
        path: '/orders',
        icon: <FontAwesomeIcon icon={faBatteryHalf} />,
    },
    {
        title: 'Charging Sessions',
        path: CHARGING_SESSIONS,
        icon: <i className="pi pi-book"></i>,
    },
    {
        title: 'Visitors',
        path: '/orders',
        icon: <FontAwesomeIcon icon={faPersonWalking} />,
    },
    {
        title: 'Reports',
        path: "#",
        icon: <FontAwesomeIcon icon={faChartColumn} />,
        subNav: [
            {
                subNavTitle: "Transactions",
                subNavPath: REPORTS_TRANSACTIONS,
                subNavIcon: <FontAwesomeIcon icon={faChartColumn} />,
            },
            {
                subNavTitle: "No.of Vehicles Charged",
                subNavPath: REPORTS_VEHICLES_CHARGED,
                subNavIcon: <FontAwesomeIcon icon={faChartColumn} />,
            },
            {
                subNavTitle: "Utilizatioln",
                subNavPath: REPORTS_UTILIZATION,
                subNavIcon: <FontAwesomeIcon icon={faChartColumn} />,
            },
            {
                subNavTitle: "Finance",
                subNavPath: REPORTS_FINANCE,
                subNavIcon: <FontAwesomeIcon icon={faChartColumn} />,
            },
        ],
    },
    {
        title: 'LookUp Types',
        path: LOOKUP_TYPES,
        icon: <i className="pi pi-list"></i>,
    },
    {
        title: 'LookUp Codes',
        path: LOOKUP_CODES,
        icon: <i className="pi pi-list"></i>,
    },
    {
        title: 'Categories',
        path: CATEGORIES,
        icon: <FontAwesomeIcon icon={faShapes} />,
    },
    {
        title: 'Products',
        path: PRODUCTS,
        icon: <FontAwesomeIcon icon={faCartShopping} />,
    },
    {
        title: 'E-Commerce',
        path: '/orders',
        icon: <FontAwesomeIcon icon={faCartShopping} />,
    },
    {
        title: 'Orders',
        path: '/orders',
        icon: <FontAwesomeIcon icon={faBasketShopping} />,
    },
    {
        title: 'Rewards',
        path: '/orders',
        icon: <i className="pi pi-book"></i>,
    },
    {
        title: 'Memberships',
        path: '/orders',
        icon: <i className="pi pi-book"></i>,
    },
    {
        title: 'Customers',
        path: '/orders',
        icon: <i className="pi pi-book"></i>,
    },
];