import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../Constants/config";
import TableHeader from "../../../Components/TableHeader";
import { getEndUserListAPI } from "../../../store/actions/endUsers/getEndUsersList";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons';
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import { Button } from "primereact/button";
import { InputNumber } from 'primereact/inputnumber';
import { Field, Form } from "react-final-form";
import { addAmountUserWalletAPI } from "../../../store/actions/endUsers/addAmountUserWallet";
import Wallet from "../wallet/Wallet";

type FormValues = {
    orderTotalAmount: any;
};
const initialValues: FormValues = {
    orderTotalAmount: null,
};

const EndUsers = () => {
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [viewData, setViewData] = useState<any>({});
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [endUserSkipValue, setEndUserSkipValue] = useState<number>(0);
    const [rows, setRows] = useState<number>(5);
    const [searchFilter, setSearchFilter] = useState<string>("");
    const [addWalletStatus, setAddWalletStatus] = useState<boolean>(false);
    const [userId, setUserId] = useState<any>();
    const [name, setName] = useState<any>();
    const [amountVal, setAmountVal] = useState<number>();
    const [navigateToWallet, setNavigateToWallet] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const endUserData = useSelector((state: any) => state?.getEndUsers);
    const errorMessage = useSelector((state: any) => state?.getEndUsers?.users?.errorMessage);
    const isLoading = useSelector((state: any) => state?.addAmountToUserWallet?.loading);
    const handleViewPopup = (rowData: any) => {
        setViewPopup(true);
        setViewData(rowData);
    };

    useEffect(() => {
        setSearchFilter('');
    }, []);
    const actionItems = (rowData: any) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon icon={faEye} onClick={() => handleViewPopup(rowData)} />
            </div>
        );
    };

    const getEndUsersList = (cp: number, searchFilter: any, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchFilter === "" ? "" : searchFilter,
            userroleid: 0,
        };
        dispatch(getEndUserListAPI(params));
    };
    const updateRefreshButton = (value: any) => {
        value && getEndUsersList(endUserSkipValue, searchFilter, rows);
    };
    useEffect(() => {
        getEndUsersList(endUserSkipValue, searchFilter, rows);
        // eslint-disable-next-line
    }, [searchFilter, rows]);

    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setEndUserSkipValue(event.page + 1);
        getEndUsersList(event.page + 1, searchFilter, rows);
        setRows(event.rows);
    };

    const handleSearch = (val: string) => {
        setSearchFilter(val);
    };
    const User = (row: any) => {
        const name = row.firstName + ' ' + row.lastName;
        return row.firstName !== null ? name : "Guest";
    };

    const handleAddWalletAmount = (rowData: any) => {
        localStorage.setItem("userData", JSON.stringify(rowData));
        setUserId(rowData.id);
        setName(rowData.firstName);
        setAddWalletStatus(true);
    };
    const AddWalletAmount = (row: any) => {
        return (
            <Button className="sm:col-12 md:col-6 lg:col-7" icon="pi pi-plus pr-2" onClick={() => handleAddWalletAmount(row)}>Add</Button>
        );
    };

    const WalletAmount = (row: any) => {
        let walletAmount = parseFloat(row.totalWalletAmount);
        return walletAmount.toFixed(2);
    };
    const handleSubmit = (values: FormValues) => {
        const newValues = { ...values, "userId": userId, };
        dispatch(addAmountUserWalletAPI({ formData: newValues, method: "POST" }));
        dispatch(addAmountUserWalletAPI({ formData: newValues, method: "POST" }));
        setAddWalletStatus(false);
        setAmountVal(undefined);
        setNavigateToWallet(true);
    };

    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        // Add validation logic for each field
        if (!values.orderTotalAmount) {
            errors.orderTotalAmount = "required";
        }
        return errors;
    };

    const disablflags = useCallback(() => {
        setNavigateToWallet(false);
        setSearchFilter('');
        // eslint-disable-next-line
    }, [navigateToWallet]);

    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className='mx-3'>
            {
                endUserData?.error.code !== "" ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <>
                        <TableHeader heading='End Users List' searchKeys="Search Name,Email,Num" searchItem={searchFilter} onSearch={handleSearch} />
                        <div className="card">
                            <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow' value={endUserData?.users?.rows} loading={endUserData?.loading} scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                                <Column field="firstName" body={User} header="UserName" sortable ></Column>
                                <Column field="email" header="Email" sortable ></Column>
                                <Column field="mobileNumber" header="Mobile" sortable ></Column>
                                <Column header="Add Wallet Amount" body={AddWalletAmount} ></Column>
                                <Column field="totalWalletAmount" body={WalletAmount} header="Wallet (Rs)" sortable ></Column>
                                <Column frozen={true} alignFrozen="right" header="Actions" body={actionItems} ></Column>
                            </DataTable>
                            <Paginator first={currentPage} rows={rows} totalRecords={endUserData?.users?.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />

                            <Dialog className='viewPopup' header={`User : ${User(viewData)}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                                <div >
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4"}>Email</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{viewData.email}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4"}>Mobile No.</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{viewData.mobileNumber}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4"}>Atum Coins</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{viewData.totalAtumCoins}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4"}>Wallet Amount (Rs) </div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{WalletAmount(viewData)}</div>
                                    </div>
                                </div>
                            </Dialog>

                            <Dialog className='addWallPopup' header={`Add Amount to Guest Wallet`} visible={addWalletStatus} style={{ width: '50vw' }} onHide={() => { setAmountVal(undefined); setAddWalletStatus(false); }} >
                                <Form
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                    validate={validateForm}
                                    render={({ handleSubmit }) => (
                                        <form onSubmit={handleSubmit} className="p-fluid addAmountPage">
                                            <div className='col-12'>
                                                <Field name="orderTotalAmount">
                                                    {({ input, meta }) => (
                                                        <div className="field">
                                                            <label className="font-bold block mb-2">Add Amount</label>
                                                            <InputNumber {...input} inputId="currency-india" value={amountVal} onValueChange={(e: any) => { input.onChange(e.target.value); setAmountVal(e.value); }} mode="currency" currency="INR" currencyDisplay="code" locale="en-IN" className="lg:w-full" />
                                                            {/* <InputNumber  {...input} placeholder="Add Amount" type="number" minFractionDigits={2} className="lg:w-full" /> */}
                                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <button className={window.innerWidth < 786 ? 'col-4 addAmountSub' : 'col-4 addAmountSub'} type="submit" >Submit</button>
                                        </form>
                                    )}
                                />
                            </Dialog>
                            {navigateToWallet && !isLoading && <Wallet setNavigateToWallet={disablflags} />}
                            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='endUsersList' />
                        </div>
                    </>
            }
        </div>
    );
};

export default EndUsers;