import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";

interface TypeShowError {
    showErrorComponent: boolean;
    setShowErrorComponent: (val: boolean) => void;
    setSubmitClicked: (val: boolean) => void;
}

const PostToaster = ({ showErrorComponent, setShowErrorComponent, setSubmitClicked }: TypeShowError) => {
    const toast = useRef<Toast>(null);
    const show = () => {
        toast.current?.show({ severity: 'info', summary: 'Info', detail: 'Something went wrong' });
    };
    useEffect(() => {
        showErrorComponent && show();
    }, [showErrorComponent]);
    const handleHide = () => {
        setShowErrorComponent(!showErrorComponent);
        setSubmitClicked(false);
    };
    return (
        <div className="card flex justify-content-center">
            <Toast ref={toast} onHide={() => handleHide()} />
        </div>
    );
};

export default PostToaster;