import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { CommonPaths } from '../../../Routes/Paths';
import { LOOKUP_CODES } from '../../../API/UserApi';
import { lookUpDropdownAPI } from '../../../store/actions/getLookupDropdown';
import { addUsersAPI } from '../../../store/actions/postRequests/addUsers';
import { getUserRolesAPI } from '../../../store/actions/adminUsers/getUserRoles';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import PostToaster from '../../../Components/PostToaster';

type FormValues = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    mobileNumber: string;
    gender: string;
    userRoleId: any;
};

const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    mobileNumber: '',
    gender: '',
    userRoleId: '',
};

const AddAdminUsers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const gender_data = useSelector((state: any) => state?.getDropList?.Gender?.data);
    const user_roles = useSelector((state: any) => state?.getUserRoles?.UserRoles?.rows);
    const [genderId, setGenderId] = useState<number>();
    const [userRoleId, setUserRoleId] = useState<number>();
    const [submitClicked, setSubmitClicked] = useState(false);
    const [showErrorComponent, setShowErrorComponent] = useState(false);
    const addUserLoading = useSelector((state: any) => state?.addUser?.loading);
    const addUserError = useSelector((state: any) => state?.addUser?.error);
    const { ADMIN_USERS } = CommonPaths;

    if (location?.state) {
        initialValues.firstName = location?.state?.editdata?.firstName;
        initialValues.lastName = location?.state?.editdata?.lastName;
        initialValues.email = location?.state?.editdata?.email;
        initialValues.mobileNumber = location?.state?.editdata?.mobileNumber;
        initialValues.gender = location?.state?.editdata?.gender;
        initialValues.userRoleId = parseInt(location?.state?.editdata?.userRoleId);
    } else {
        initialValues.firstName = "";
        initialValues.lastName = "";
        initialValues.email = "";
        initialValues.password = "";
        initialValues.mobileNumber = '';
        initialValues.gender = "";
        initialValues.userRoleId = "";
    }

    const userRoles = user_roles?.map((item: any) => ({
        value: item.userRoleId,
        label: item.userRole,
    }));
    const handleGenderId = (e: any) => {
        const data = gender_data?.filter((data: any) => data.lookupName === e.target.value);
        setGenderId(data[0]?.id);
    };

    const handleSubmit = (values: FormValues) => {
        // Handle form submission
        const newData = { ...values, genderId: genderId, createdBy: 1, updatedBy: 1 };
        if (!location?.state?.editdata?.id) {
            dispatch(addUsersAPI({ formData: newData, method: "POST" }));
        } else {
            const { password, ...newObj } = newData;
            dispatch(addUsersAPI({ formData: newObj, method: "PUT", id: `/${location?.state?.editdata?.id}` }));
        }
        setSubmitClicked(true);
    };
    useEffect(() => {
        submitClicked && !addUserLoading && addUserError?.code === '' && navigate(ADMIN_USERS);;
        submitClicked && !addUserLoading && addUserError?.code !== '' && setShowErrorComponent(true);
    }, [ADMIN_USERS, addUserError, addUserLoading, navigate, submitClicked]);

    const getGenderList = () => {
        let params = {
            type: "drop",
            lookupTypeName: "gender"
        };
        dispatch(lookUpDropdownAPI({ ApiUrl: LOOKUP_CODES, Type: "gender", params: params }));
    };
    const getUserrolesList = () => {
        let params = {
            type: "list",
            limit: 10,
            skip: 0,
            search: '',
        };
        dispatch(getUserRolesAPI(params));

    };
    useEffect(() => {
        getGenderList();
        getUserrolesList();
        // eslint-disable-next-line
    }, []);
    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        // Add validation logic for each field
        let nameRegex = /^[A-Za-z\s]+$/;
        if (!values.firstName) {
            errors.firstName = 'Required';
        } else {
            if (!values.firstName.match(nameRegex)) {
                errors.firstName = 'Please enter alphabets only';
            }
        }
        if (!values.lastName) {
            errors.lastName = 'Required';
        } else {
            if (!values.lastName.match(nameRegex)) {
                errors.lastName = 'Please enter alphabets only';
            }
        }
        if (!values.email) {
            errors.email = 'Required';
        } else {
            let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (!values.email.match(mailformat)) {
                errors.email = 'Please enter a valid email address.';
            }
        }
        if (!values.password && !location?.state?.editdata?.id) {
            errors.password = 'Required';
        }
        if (!values.mobileNumber) {
            errors.mobileNumber = 'Required';
        } else if (values?.mobileNumber) {
            let regex = new RegExp(/(0|91)?[6-9][0-9]{9}/);
            if (!regex.test(values.mobileNumber)) {
                errors.mobileNumber = 'Number should start with 9,8,7 or 6.';
            }
            if (values?.mobileNumber?.length !== 10) {
                errors.mobileNumber = 'Please enter your 10 digits mobile number.';
            }
        }
        if (!values.gender) {
            errors.gender = 'Required';
        }
        if (!values.userRoleId) {
            errors.userRoleId = 'Required';
        }
        return errors;
    };

    const handleClosePage = () => {
        navigate(ADMIN_USERS);
    };

    return (
        <div>
            <div className="addPage_header container">
            <button className='back_Btn' onClick={handleClosePage}><i className="pi pi-arrow-left" style={{ fontSize: '1rem', color: 'white' }}></i></button>
                <h5 className='heading'>{location?.state?.popup ? "Edit Admin User" : "Add Admin User"}</h5>
            </div>
            <div className='card mt-1 mx-3 pt-3 add_card_height'>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} className="p-fluid row grid">
                            <div className={window.innerWidth < 786 ? "col-12 inputwidth" : "col-4 inputwidth"}>
                                <Field name="firstName">
                                    {({ input, meta }) => (
                                        <div className='field'>
                                            <label htmlFor='firstNAme'>First Name*</label>
                                            <InputText id='firstNAme' keyfilter={/^[a-zA-Z\s]*$/} {...input} placeholder="First Name" type='text' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className={window.innerWidth < 786 ? "col-12 inputwidth" : "col-4 inputwidth"}>
                                <Field name="lastName">
                                    {({ input, meta }) => (
                                        <div className='field'>
                                            <label htmlFor='lastName'>Last Name*</label>
                                            <InputText id='lastName' keyfilter={/^[a-zA-Z\s]*$/} {...input} placeholder="Last Name" />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className={window.innerWidth < 786 ? "col-12 inputwidth" : "col-4 inputwidth"}>
                                <Field name="email">
                                    {({ input, meta }) => (
                                        <div className='field'>
                                            <label htmlFor='email'>Email*</label>
                                            <InputText id='email' keyfilter="email" role='textbox' {...input} placeholder="Email" type='email' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className={window.innerWidth < 786 ? "col-12 inputwidth" : "col-4 inputwidth"}>
                                <Field name="password" >
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='password'>Password*</label>
                                            <Password   {...input} id='password'  role='textbox' feedback={false} tabIndex={1} placeholder="Password" disabled={location?.state?.editdata?.id ? true : false}  />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className={window.innerWidth < 786 ? "col-12 inputwidth" : "col-4 inputwidth"}>
                                <Field name="mobileNumber">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='mobileNumber'>Mobile Number*</label>
                                            <InputText id='mobileNumber' keyfilter="pint" {...input} placeholder="Mobile Number" maxLength={10} />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className={window.innerWidth < 786 ? "col-12 inputwidth" : "col-4 inputwidth"}>
                                <Field name="gender" >
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>Gender* </label>
                                            <Dropdown
                                                {...input}
                                                optionValue="lookupName"
                                                options={gender_data}
                                                optionLabel="lookupDisplayName"
                                                placeholder="Gender"
                                                className="md:w-40rem"
                                                onChange={(e: any) => { input.onChange(e.target.value); handleGenderId(e); }}
                                            />
                                            {meta.error && meta.touched && (
                                                <span className="error_msg">{meta.error}</span>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className={window.innerWidth < 786 ? "col-12 inputwidth" : "col-4 inputwidth"}>
                                <Field name="userRoleId" >
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label>User Role* </label>
                                            <Dropdown
                                                {...input}
                                                value={userRoleId || initialValues.userRoleId}
                                                options={userRoles}
                                                optionValue='value'
                                                optionLabel="label"
                                                placeholder="User Role"
                                                className="md:w-40rem"
                                                onChange={(e: any) => { input.onChange(e.target.value); setUserRoleId(e.target.value); }}
                                            />
                                            {meta.error && meta.touched && (
                                                <span className="error_msg">{meta.error}</span>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <button className={window.innerWidth < 786 ? 'col-4 admin_submit' : 'col-1 admin_submit'} type="submit" style={{ marginTop: '10%' }}>Submit</button>
                        </form>
                    )}
                />
            </div>
            {
                showErrorComponent && <PostToaster showErrorComponent={showErrorComponent} setShowErrorComponent={setShowErrorComponent} setSubmitClicked={setSubmitClicked} />
            }
        </div>
    );
};


export default AddAdminUsers;