import { createSlice } from "@reduxjs/toolkit";
import { getChargingSessionListAPI } from "../../actions/chargingSessions/getChargingSession";

interface SessionListState {
  sessionList: any[];
  loading: boolean;
  error: { code: string; message: string };
}

const initialState: SessionListState = {
  sessionList: [],
  loading: false,
  error: { code: "", message: "" },
};

export const getChargingSessionListSlice = createSlice({
  name: "getChargingSessionList",
  initialState,
  reducers: {
    resetGetSessionList: (state) => {
      state.sessionList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChargingSessionListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getChargingSessionListAPI.fulfilled, (state, action) => {
        state.sessionList = action.payload.cSessionList.data;
        state.loading = false;
      })
      .addCase(getChargingSessionListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetSessionList } = getChargingSessionListSlice.actions;
export default getChargingSessionListSlice.reducer;
