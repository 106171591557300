import { createSlice } from "@reduxjs/toolkit";
import { getWalletSettingsListAPI } from "../../actions/configSettings/getWalletSettingsList";

export interface ErrorState {
  code: string;
  message: string;
}

interface WalletSettingsListState {
  walletSettings: any[];
  loading: any;
  error: ErrorState;
}

const initialState: WalletSettingsListState = {
  walletSettings: [],
  loading: false,
  error: {
    code: "",
    message: "",
  },
};

export const getWalletSettingsSlice = createSlice({
  name: "getWalletSettingsList",
  initialState,
  reducers: {
    resetGetWalletSettings: (state) => {
      state.walletSettings = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWalletSettingsListAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getWalletSettingsListAPI.fulfilled, (state, action) => {
        state.walletSettings = action?.payload?.walletSettings?.data;
        state.loading = false;
      })
      .addCase(getWalletSettingsListAPI.rejected, (state, action) => {
        state.error = action.payload.error;
        state.loading = false;
      });
  },
});

export const { resetGetWalletSettings } = getWalletSettingsSlice.actions;
export default getWalletSettingsSlice.reducer;
