import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../Constants/config";
import TableHeader from "../../../Components/TableHeader";
import { CommonPaths } from "../../../Routes/Paths";
import { getStationEmenityListAPI } from "../../../store/actions/stationEmenities/getStationEmenitiesList";
import { deleteStationEmenitiesAPI } from "../../../store/actions/stationEmenities/deleteStationEmenities";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Paginator, PaginatorCurrentPageReportOptions } from "primereact/paginator";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import { Image } from "primereact/image";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import { deleteAmenitiesData } from "../../../store/slice/stationEmenities/deleteStationEmenitiesSlice";

type TableRow = {
    amenityName: string,
    description: string,
    image: null,
    amenityId: number,
};

const StationEmenities = () => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [rows, setRows] = useState<number>(5);
    const [viewPopup, setViewPopup] = useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [associatedDeletePopup, setAssociatedDeletePopup] = useState<boolean>(false);
    const [rowId, setRowId] = useState<number>();
    const [viewData, setViewData] = useState<any>({});
    const [stationEmeSkipValue, setStationEmeSkipValue] = useState<number>(0);
    const [searchVal, setSearchVal] = useState<string>('');
    const dispatch = useDispatch();
    const emenitiesData = useSelector((state: any) => state?.getStationEmenities);
    const errorMessage = useSelector((state: any) => state?.getStationEmenities?.emenities?.errorMessage);
    const associatedDeleteErrorMessage = useSelector((state: any) => state?.deleteStationEmenities?.deleteAmenities?.data?.error);
    const isDeleted = useSelector((state: any) => state?.deleteStationEmenities?.loading);
    const navigate = useNavigate();
    const popup = true;
    const { ADD_STATION_EMINITIES } = CommonPaths;
    const { GLOBAL_IMAGE_DOMAIN } = config;

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };
    const handleEditRow = (rowData: TableRow) => {
        // setPopup(true);
        navigate(ADD_STATION_EMINITIES, { state: { editdata: rowData, popup: popup } });
    };
    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.amenityId);
    };
    const handleDeleteRow = (rowId: any) => {
        dispatch(deleteStationEmenitiesAPI(rowId));
        setDeletePopup(false);
    };

    useEffect(() => {
        !isDeleted && getEmenitiesList(stationEmeSkipValue, searchVal, rows);
        // eslint-disable-next-line
    }, [isDeleted, searchVal, rows]);

    const updateRefreshButton = (value: any) => {
        value && getEmenitiesList(stationEmeSkipValue, searchVal, rows);
    };
    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon icon={faPen} onClick={() => handleEditRow(rowData)} />
                <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };

    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setStationEmeSkipValue(event.page + 1);
        getEmenitiesList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };

    const getEmenitiesList = useCallback((cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
        };
        dispatch(getStationEmenityListAPI(params));
    }, [dispatch]);

    const imgBody = (row: any) => {
        return <Image src={GLOBAL_IMAGE_DOMAIN + row.image} width="30" height="30" preview />;
    };

    const handleSearch = (value: string) => {
        setSearchVal(value);
    };
    useEffect(() => {
        associatedDeleteErrorMessage && setAssociatedDeletePopup(true);
        // eslint-disable-next-line
    }, [associatedDeleteErrorMessage]);

    const pagenationTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className='mx-3'>
            {
                emenitiesData?.error.code !== "" ?
                    <NetworkErrorPage updateRefreshButton={updateRefreshButton} /> :
                    <>
                        <TableHeader heading='All Station Amenities' searchKeys="Search Amenity Name" label="Station Amenities"  fontSize="1rem" width="15rem" addEvent={(e) => { navigate(ADD_STATION_EMINITIES); }} onSearch={handleSearch} />
                        <div className="card">
                            <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow' value={emenitiesData?.emenities?.rows} loading={emenitiesData?.loading} scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                                <Column field="amenityName" header="Amenity Name" sortable ></Column>
                                <Column field="description" header="Description" sortable ></Column>
                                <Column body={imgBody} header="Image" ></Column>
                                <Column frozen={true} alignFrozen="right" header="Actions" body={actionItems} ></Column>
                            </DataTable>
                            <Paginator first={currentPage} rows={rows} totalRecords={emenitiesData.emenities.total_count} rowsPerPageOptions={[5, 10, 15]} template={pagenationTemplate} onPageChange={(e) => onPageChange(e)} />
                            <Dialog className='viewPopup' header={`Amenity : ${viewData.amenityName}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                                <div >
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Amenity Name</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.amenityName}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Description</div>
                                        <div className="col-1">:</div>
                                        <div className="col-6">{viewData.description}</div>
                                    </div>
                                    <div className="grid">
                                        <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Image </div>
                                        <div className="col-1">:</div>
                                        <div className="col-6"><img src={GLOBAL_IMAGE_DOMAIN + viewData.image} alt="logo" width="30px" height="30px" /></div>
                                    </div>
                                </div>
                            </Dialog>
                            <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                                <div className="delpop_btn_div">
                                    <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)}>No</Button>
                                    <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)}>Yes</Button>
                                </div>
                            </Dialog>
                            {
                                associatedDeleteErrorMessage &&
                                <Dialog className='deletePopup' header="Record is not Deleted" visible={associatedDeletePopup} style={{ width: '50vw' }} onHide={() => { setAssociatedDeletePopup(false); dispatch(deleteAmenitiesData()); }} >
                                    <div className="delpop_btn_div">
                                        {associatedDeleteErrorMessage}
                                    </div>
                                </Dialog>
                            }
                            <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='amenitiesList' />
                        </div>
                    </>
            }
        </div>
    );
};

export default StationEmenities;