import { Form, Field } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonPaths } from '../../../Routes/Paths';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

type FormValues = {
    price: string;
    category: string;
    productName: string;
    discountType: string;
    quantity: string;
    discountValue: string;
    barcode: string;
    description: string;
    productImage1: string;
    productImage2: string;
    productImage3: string;
};

const initialValues: FormValues = {
    price: "",
    category: "",
    productName: "",
    discountType: "",
    quantity: "",
    discountValue: "",
    barcode: "",
    description: "",
    productImage1: "",
    productImage2: "",
    productImage3: ""
};

const AddProducts = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { PRODUCTS } = CommonPaths;
    const [file1, setFile1] = useState<any>(location?.state?.editdata?.image);
    const [imageData1, setImageData1] = useState<any>(null);
    const [file2, setFile2] = useState<any>(location?.state?.editdata?.image);
    const [imageData2, setImageData2] = useState<any>(null);
    const [file3, setFile3] = useState<any>(location?.state?.editdata?.image);
    const [imageData3, setImageData3] = useState<any>(null);
    const [discountType, setDiscountType] = useState<any>(null);
    const [categoryValue, setCategoryValue] = useState<any>(null);
    const [descriptionValue, setDescriptionValue] = useState<any>("");

    const discountTypeOptions = [{ value: 1, label: "Percentage" }, { value: 2, label: "Amount" }];
    const categoryOptions = [
        { value: 1, label: "Halmet" },
        { value: 2, label: "Gloves" },
        { value: 3, label: "Battery" }
    ];
    // if (location?.state) {
    initialValues.price = "";
    initialValues.category = "";
    initialValues.barcode = "";
    initialValues.description = "";
    initialValues.quantity = "";
    initialValues.discountType = "";
    initialValues.discountValue = "";
    initialValues.productName = "";
    initialValues.productImage1 = "";
    initialValues.productImage2 = "";
    initialValues.productImage3 = "";
    // }

    const handleSubmit = (values: FormValues) => {
    };

    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        // Add validation logic for each field
        if (!values.barcode) {
            errors.barcode = 'Required';
        }
        if (!values.category) {
            errors.category = 'Required';
        }
        if (!values.description) {
            errors.description = 'Required';
        }
        if (!values.discountType) {
            errors.discountType = 'Required';
        }
        if (!values.discountValue) {
            errors.discountValue = 'Required';
        }
        if (!values.price) {
            errors.price = 'Required';
        }
        if (!values.productName) {
            errors.productName = 'Required';
        }
        if (!values.quantity) {
            errors.quantity = 'Required';
        }
        if (!values.productImage1) {
            errors.productImage1 = 'Required';
        }
        return errors;
    };

    const handleClosePage = () => {
        navigate(PRODUCTS);
    };
    const handleImageChange1 = (e: any) => {
        setImageData1(e.target.files[0]);
    };
    const handleImageChange2 = (e: any) => {
        setImageData2(e.target.files[0]);
    };
    const handleImageChange3 = (e: any) => {
        setImageData3(e.target.files[0]);
    };
    return (
        <div style={{ overflowY: "scroll", height: '88vh' }}>
            <div className="addPage_header container">
                <button className='back_Btn' onClick={handleClosePage}><i className="pi pi-arrow-left" style={{ color: 'white' }}></i></button>
                <h5 className='heading'>{location?.state?.popup ? "Edit Products" : "Add Products"}</h5>    
            </div>
            <div className='mt-3 mx-3'>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid row" name='networkForm'>
                            {(imageData1?.name || file1) && <div onClick={() => { file1 && setFile1(null); imageData1?.name && setImageData1({ ...imageData1, name: null }); }} style={{
                                position: 'absolute', left: '34%', width: '25px', height: '25px', marginTop: '1.7%',
                                border: '1px solid red', background: 'red', borderRadius: '25px', textAlign: "center",
                                color: 'whitesmoke', fontSize: '15px', fontWeight: '600', cursor: 'pointer',
                            }}>x</div>}

                            {(imageData1?.name) ?
                                <div className='col-4 inputwidth' style={{ textAlign: 'center', marginTop: '15px' }}>
                                    <img
                                        src={URL.createObjectURL(imageData1)}
                                        alt="something wrong"
                                        style={{ width: '130px', height: '65px', borderRadius: '15px' }}
                                    />
                                </div>
                                :
                                file1 ?
                                    <img
                                        src={file1}
                                        alt='something wrong'
                                        style={{ width: '130px', height: '65px', borderRadius: '15px' }}
                                    />
                                    :
                                    <>
                                        <div
                                            className='col-4 inputwidth'
                                            style={{ textAlign: 'center', border: '1px solid #3f3c4157', borderRadius: '10px' }}
                                        >
                                            <label htmlFor='uploadimg1' style={{ margin: '0.5%' }}>
                                                <FontAwesomeIcon icon={faPhotoFilm} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                                                <div>Upload Image1*</div>
                                            </label>
                                        </div>
                                    </>
                            }
                            <div style={{ display: 'none' }}>
                                <Field name="productImage1">
                                    {({ input, meta }) => (
                                        <div style={{ display: 'none' }}>
                                            <input
                                                id='uploadimg1'
                                                type='file'
                                                style={{ display: 'none' }}
                                                onChange={(e) => { input.onChange(e.target.value); handleImageChange1(e); }}
                                                accept="image/png, image/jpeg"
                                            />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            {(imageData2?.name || file2) && <div
                                onClick={() => { file2 && setFile2(null); imageData2?.name && setImageData2({ ...imageData2, name: null }); }} style={{
                                    position: 'absolute', left: '61%', width: '25px', height: '25px', marginTop: '1.7%',
                                    border: '1px solid red', background: 'red', borderRadius: '25px', textAlign: "center",
                                    color: 'whitesmoke', fontSize: '15px', fontWeight: '600', cursor: 'pointer',
                                }}>x</div>}

                            {(imageData2?.name) ?
                                <div className='col-4 inputwidth' style={{ textAlign: 'center', marginTop: '15px' }}>
                                    <img src={URL.createObjectURL(imageData2)} alt="something wrong" style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                </div>
                                :
                                file2 ?
                                    <img src={file2} alt='something wrong' style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                    :
                                    <>
                                        <div className='col-4 inputwidth' style={{ textAlign: 'center', border: '1px solid #3f3c4157', borderRadius: '10px' }}>
                                            <label htmlFor='uploadimg2' style={{ margin: '0.5%' }}>
                                                <FontAwesomeIcon icon={faPhotoFilm} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                                                <div>Upload Image2</div>
                                            </label>
                                        </div>
                                    </>
                            }

                            <div style={{ display: 'none' }}>
                                <Field name="productImage2">
                                    {({ input, meta }) => (
                                        <div style={{ display: 'none' }}>
                                            <input
                                                id='uploadimg2'
                                                type='file'
                                                style={{ display: 'none' }}
                                                onChange={(e) => { input.onChange(e.target.value); handleImageChange2(e); }}
                                                accept="image/png, image/jpeg"
                                            />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            {(imageData3?.name || file3) && <div onClick={() => { file3 && setFile3(null); imageData3?.name && setImageData3({ ...imageData3, name: null }); }}
                                style={{
                                    position: 'absolute', left: '87%', width: '25px', height: '25px', marginTop: '1.7%',
                                    border: '1px solid red', background: 'red', borderRadius: '25px', textAlign: "center",
                                    color: 'whitesmoke', fontSize: '15px', fontWeight: '600', cursor: 'pointer', zIndex: '2'
                                }}>x</div>}

                            {(imageData3?.name) ?
                                <div className='col-4 inputwidth' style={{ textAlign: 'center', marginTop: '15px' }}>
                                    <img src={URL.createObjectURL(imageData3)} alt="something wrong" style={{ width: '130px', height: '65px', borderRadius: '15px', }} />
                                </div>
                                :
                                file3 ?
                                    <img src={file3} alt='something wrong' style={{ width: '130px', height: '65px', borderRadius: '15px' }} />
                                    :
                                    <>
                                        <div className='col-4 inputwidth' style={{ textAlign: 'center', border: '1px solid #3f3c4157', borderRadius: '10px' }}>
                                            <label htmlFor='uploadimg3' style={{ margin: '0.5%' }}>
                                                <FontAwesomeIcon icon={faPhotoFilm} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                                                <div>Upload Image3</div>
                                            </label>
                                        </div>
                                    </>
                            }
                            <div style={{ display: 'none' }}>
                                <Field name="productImage3">
                                    {({ input, meta }) => (
                                        <div style={{ display: 'none' }}>
                                            <input
                                                id='uploadimg3'
                                                type='file'
                                                style={{ display: 'none' }}
                                                onChange={(e) => { input.onChange(e.target.value); handleImageChange3(e); }}
                                                accept="image/png, image/jpeg"
                                            />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="productName">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='productName'>Product Name*</label>
                                            <InputText id='productName' {...input} placeholder="Product Name" autoComplete='off' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="category">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='category'>Category*</label>
                                            <Dropdown
                                                {...input}
                                                value={categoryValue}
                                                // optionValue="lookupName"
                                                options={categoryOptions}
                                                // optionLabel="lookupDisplayName"
                                                placeholder="Category"
                                                className="w-full md:w-40rem"
                                                onChange={(e: any) => { input.onChange(e.target.value); setCategoryValue(e.target.value); }}
                                            />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="price">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='price'>Price*</label>
                                            <InputText id="price" role='textbox' {...input} placeholder="Price" autoComplete='off' />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="discountType">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='discountType'>Discount Type*</label>
                                            <Dropdown
                                                {...input}
                                                value={discountType}
                                                // optionValue="lookupName"
                                                options={discountTypeOptions}
                                                // optionLabel="lookupDisplayName"
                                                placeholder="Discount Type"
                                                className="w-full md:w-40rem"
                                                onChange={(e: any) => { input.onChange(e.target.value); setDiscountType(e.target.value); }}
                                            />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="discountValue">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='discountValue'>Discount Value*</label>
                                            <InputText id='discountValue'  {...input} placeholder="Discount Value" autoComplete='off' maxLength={10} />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="quantity">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='quantity'>Quantity*</label>
                                            <InputText id='quantity'  {...input} placeholder="Quantity" autoComplete='off' maxLength={10} />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-6 inputwidth'>
                                <Field name="barcode">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='barcode'>Barcode*</label>
                                            <InputText id='barcode'  {...input} placeholder="Barcode" autoComplete='off' maxLength={10} />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className='col-7 ml-5'>
                                <Field name="description">
                                    {({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor='description'>Description*</label>
                                            {/* <InputText id='description'  {...input} placeholder="Description" autoComplete='off' maxLength={10} /> */}
                                            <InputTextarea
                                                {...input}
                                                id='description'
                                                placeholder="Description"
                                                value={descriptionValue}
                                                onChange={(e: any) => { input.onChange(e.target.value); setDescriptionValue(e.target.value); }}
                                                rows={1}
                                            // cols={80}
                                            />
                                            {meta.error && meta.touched && <span className='error_msg'>{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <button className={window.innerWidth < 786 ? 'col-4 submit_btn' : 'col-1 network_submit_btn'} type="submit" >Submit</button>
                        </form>
                    )}
                />
            </div>
        </div>
    );
};


export default AddProducts;