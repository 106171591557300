import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CommonPaths } from "../../../Routes/Paths";
import { getNetworkListAPI } from "../../../store/actions/chargingNetworks/getNetworkList";
import { deleteNetworksAPI } from "../../../store/actions/chargingNetworks/deleteChargingNetworks";
import TokenHandleFunction from "../../../Components/TokenHandleFunction";
import TableHeader from "../../../Components/TableHeader";
import NetworkErrorPage from "../../../Components/NetworkErrorPage";
import config from "../../../Constants/config";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faEye } from '@fortawesome/free-solid-svg-icons';

export type TableRow = {
    productName: string,
    productId: number,
    category: string,
    price: string,
    quantity: string,
    description: string,
    images: string,
};

const Products = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rows, setRows] = useState<number>(5);
    const [viewPopup, setViewPopup] = React.useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [rowId, setRowId] = useState<number>();
    const [editRowId, setEditRowId] = useState<number>();
    const [viewData, setViewData] = useState<any>({});
    const [chargeNetSkipValue, setChargeNetSkipValue] = useState<number>(0);
    const [searchVal, setSearchVal] = useState<string>('');
    const dispatch = useDispatch();
    const networksData = useSelector((state: any) => state?.getNetworks);
    const errorMessage = useSelector((state: any) => state?.getNetworks?.networks?.error);
    const navigate = useNavigate();
    const popup = true;
    const { ADD_PRODUCTS } = CommonPaths;

    const handleViewPopup = (rowData: TableRow) => {
        setViewPopup(true);
        setViewData(rowData);
    };

    const handleEditRow = (rowData: TableRow) => {
        setEditRowId(rowData.productId);
        navigate(ADD_PRODUCTS, { state: { editdata: rowData, popup: popup, id: editRowId } });
    };

    const handleDeleteRowId = (rowData: TableRow) => {
        setDeletePopup(true);
        setRowId(rowData.productId);
    };

    const handleDeleteRow = (rowId: any) => {
        dispatch(deleteNetworksAPI(rowId));
        setDeletePopup(false);
    };

    useEffect(() => {
        !deletePopup && getNetworksList(chargeNetSkipValue, searchVal, rows);
        // eslint-disable-next-line
    }, [deletePopup, searchVal, rows]);

    const updateRefreshButton = (value: any) => {
        value && getNetworksList(chargeNetSkipValue, searchVal, rows);
    };

    const actionItems = (rowData: TableRow) => {
        return (
            <div className='actionItems'>
                <FontAwesomeIcon aria-label="Eye" role="img" className="testPopUp" id="faEye" icon={faEye} onClick={() => handleViewPopup(rowData)} />
                <FontAwesomeIcon className="testEdit" data-testid="faPen" icon={faPen} onClick={() => handleEditRow(rowData)} />
                <FontAwesomeIcon className="testDelete" data-testid="faTrash" icon={faTrash} onClick={() => handleDeleteRowId(rowData)} />
            </div>
        );
    };

    const onPageChange = (event: any) => {
        setCurrentPage(event.first);
        setChargeNetSkipValue(event.page + 1);
        getNetworksList(event.page + 1, searchVal, rows);
        setRows(event.rows);
    };
    const getNetworksList = (cp: number, searchVal: string, rows: number) => {
        let params = {
            type: "list",
            limit: rows,
            skip: cp,
            search: searchVal === "" ? "" : searchVal,
        };
        dispatch(getNetworkListAPI(params));
    };

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };

    const getErrorComponent = () => {
        let errorDiv;
        if (networksData?.error?.code) {
            if (networksData?.error?.code === 400) {
                errorDiv = <div>400 error code</div>;
            } else if (networksData?.error?.code === 500) {
                errorDiv = <div>500 error code</div>;
            } else if (networksData?.error?.code === 404) {
                errorDiv = <NetworkErrorPage updateRefreshButton={updateRefreshButton} />;
            }
        }
        return errorDiv;
    };

    return (
        <div className='m-3'>
            {
                networksData?.loading === "failed" ?
                    getErrorComponent() :
                    <div className="card">
                        <DataTable scrollHeight={config.tableHeight} className='tableHeadingRow' header={<TableHeader heading='Products List' searchKeys="Search Product " label="ADD" addEvent={(e) => { navigate(ADD_PRODUCTS); }} onSearch={handleSearch} />} value={networksData?.networks?.rows} loading={networksData?.loading} scrollable removableSort tableStyle={{ minWidth: '50rem' }}>
                            <Column field="productName" header="Product Name" sortable ></Column>
                            <Column field="category" header="Category" sortable ></Column>
                            <Column field="price" header="Price" sortable ></Column>
                            <Column field="quantity" header="Quantity" sortable ></Column>
                            <Column field="description" header="Description" sortable ></Column>
                            <Column field="images" header="Images" sortable ></Column>
                            <Column frozen={true} alignFrozen="right" header="Actions" className="test" body={actionItems} ></Column>
                        </DataTable>
                        <Paginator data-testid="open-popup" first={currentPage} rows={rows} totalRecords={networksData?.networks?.total_count} rowsPerPageOptions={[5, 10, 15]} onPageChange={(e) => onPageChange(e)} />
                        <Dialog className='viewPopup' header={`Network : ${viewData.networkName}`} visible={viewPopup} onHide={() => setViewPopup(false)}>
                            <div >
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Network Name </div>
                                    <div className="col-1">:</div>
                                    <div className="col-6" data-testid="viewNetworkName">{viewData.networkName}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Network Manager</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6" data-testid="viewNetworkManager">{viewData.networkManager}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Email</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6" data-testid="viewEmail">{viewData.email}</div>
                                </div>
                                <div className="grid">
                                    <div className={window.innerWidth < 786 ? "col-3" : "col-4 col-offset-1"}>Mobile No.</div>
                                    <div className="col-1">:</div>
                                    <div className="col-6" data-testid="viewPhoneNumber">{viewData.phoneNumber}</div>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog className='deletePopup' header="You want to delete the record ?" visible={deletePopup} style={{ width: '50vw' }} onHide={() => setDeletePopup(false)} >
                            <div className="delpop_btn_div">
                                <Button className='delpop_No_btn' onClick={() => setDeletePopup(false)} >No</Button>
                                <Button className='delpop_Yes_btn' onClick={() => handleDeleteRow(rowId)}>Yes</Button>
                            </div>
                        </Dialog>
                        <TokenHandleFunction navigate={navigate} errorMessage={errorMessage} referenceKey='networkList' />
                    </div>
            }
        </div>
    );
};

export default Products;